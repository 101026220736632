// generic-edit-dialog.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormGroup } from '@angular/forms';
//import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-generic-edit-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    <!-- Dynamic form fields based on the provided FormGroup -->
      <!-- <form [formGroup]="editForm" (ngSubmit)="onSubmit()"> -->

      <td *ngFor="let column of columns">

      </td>
        <!-- <ng-container *ngFor="let controlName of editForm.controls | keyvalue"> -->
        <ng-container *ngFor="let controlName of editForm.controls | keyvalue">
          <pre>controlName</pre>
          <!-- <label for="{{ controlName.key }}">{{ controlName.key }}:</label> -->
          <!-- <input
            type="text"
            [id]="controlName.key"
            [formControl]="editForm.get(controlName.key)"
            class="form-control"
          /> -->
        </ng-container>

        <button type="submit" class="btn btn-primary">Save</button>
      <!-- </form> -->
    </div>
  `,
})
export class GenericEditDialogComponent implements OnInit {
  @Input() title: string = '';
  // @Input() editForm: FormGroup | null = null;

  @Input() editForm: FormGroup = new FormGroup({});

  // Explicitly importing KeyValue pipe
  //keyvalue: KeyValue<string, any>[] = [];

  columns: any[] = [];

  constructor(public activeModal: NgbActiveModal) {}
  
  ngOnInit(): void {
    let testtest = this.editForm;

    for (const field in this.editForm.controls) { // 'field' is a string
      this.columns.push({
        // title: columnDirective.title,
        field: this.editForm.get(field),
        isCommandColumn: false,
        //template: columnDirective.template
      });
      //const control = this.form.get(field); // 'control' is a FormControl  
    
    }

   let test2 = this.columns;

  }

  onSubmit(): void {
    // Add logic to save edited data
    this.activeModal.close(true);
  }

  public getFormControl(formGroup: AbstractControl | null, field: string): AbstractControl | null {
    //console.log('getFormControl - data:', this.data);
    return formGroup instanceof FormGroup ? formGroup.get(field) : null;
  }
}