import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { InboundMessageType, OutboundMessageType } from '../../models/message-type';
import { MessageTypeModel } from './message-type-model';
import { OutboundMessageTypesGridView } from './models/OutboundMessageTypesGridView';
import { InboundMessageTypesGridView } from './models/inbound-message-types-gridview';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class MessageTypeService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getMessageTypes(pageindex: number, pagesize: number, messageTypeName: string, messageTypeDescription: string): Observable<InboundMessageTypesGridView> {
        let queryStart = '';
        let queryString = '';

        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)

        if(messageTypeName != undefined && messageTypeName.length > 2) {
            queryString = queryString.concat(`${queryStart}messageTypeName=${messageTypeName}`)
        }
        if(messageTypeDescription != undefined && messageTypeDescription.length > 2) {
            queryString = queryString.concat(`${queryStart}messageTypeDescription=${messageTypeDescription}`)
        }

        return this.http.get<InboundMessageTypesGridView>(this.baseUrlLocal + `inboundmessagetypes/search` + queryString );
    }

    public getOutboundMessageTypes(pageindex: number, pagesize: number, messageTypeName: string, messageTypeDescription: string): Observable<OutboundMessageTypesGridView> {
        let queryStart = '';
        let queryString = '';

        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)

        if(messageTypeName != undefined && messageTypeName.length > 2) {
            queryString = queryString.concat(`${queryStart}messageTypeName=${messageTypeName}`)
        }
        if(messageTypeDescription != undefined && messageTypeDescription.length > 2) {
            queryString = queryString.concat(`${queryStart}messageTypeDescription=${messageTypeDescription}`)
        }

        return this.http.get<OutboundMessageTypesGridView>(this.baseUrlLocal + `outboundmessagetypes/search` + queryString );
    }

    public getMaxOutboundMessageTypeId(): Observable<number> {
        return this.http.get<number>(this.baseUrlLocal + `outboundmessagetypes/maxid/`);
    }

    public getMaxInboundMessageTypeId(): Observable<number> {
        return this.http.get<number>(this.baseUrlLocal + `inboundmessagetypes/maxid/`);
    }


    public addOutboundMessageType(outboundMessageType: OutboundMessageType) {
        return this.http.post(this.baseUrlLocal + 'outboundmessagetypes', outboundMessageType);
    }

    public updateOutboundMessageType(outboundMessageType: OutboundMessageType) {
        return this.http.put(this.baseUrlLocal + 'outboundmessagetypes', outboundMessageType);
    }

    public deleteOutboundMessageType(outboundMessageType: OutboundMessageType) {
        const options = {
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            }),
            body: outboundMessageType
        };
        return this.http.delete(this.baseUrlLocal + 'outboundmessagetypes', options);
    }

    public addInboundMessageType(messageType: InboundMessageType) {
        return this.http.post(this.baseUrlLocal + 'inboundmessagetypes', messageType);
    }

    public updateInboundMessageType(messageType: InboundMessageType) {
        return this.http.put(this.baseUrlLocal + 'inboundmessagetypes', messageType);
    }

    public deleteInboundMessageType(outboundMessageType: InboundMessageType) {
        const options = {
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            }),
            body: outboundMessageType
        };
        return this.http.delete(this.baseUrlLocal + 'inboundmessagetypes', options);
    }

    public setEditMode(gridRow: MessageTypeModel, messageTypeGridList: Array<MessageTypeModel>, editMode: boolean) {
        const foundGridRow = messageTypeGridList.filter((obj) => {
            return obj.messageTypeId === gridRow.messageTypeId;

        });
        foundGridRow[0].editMode = editMode;
    }

    public getMessageTypeModelList(messageTypes: Array<InboundMessageType>) {
        let messageTypeGridList: Array<MessageTypeModel> = new Array<MessageTypeModel>();

        messageTypes.forEach(m => {
            let messageType: MessageTypeModel = {
                messageTypeId: m.inboundMessageTypeId,
                messageName: m.messageName,
                messageTypeDescription: m.messageTypeDescription,
                createdBy: m.createdBy,
                createTimestamp: m.createTimestamp,
                editMode: false,
                isNew: false
            };
            messageTypeGridList.push(messageType);
        });

        return messageTypeGridList;
    }

    public getMessageType(gridRow: MessageTypeModel) {
        let messageType: InboundMessageType = new InboundMessageType()
        messageType.inboundMessageTypeId = gridRow.messageTypeId;
        messageType.messageName = gridRow.messageName;
        messageType.messageTypeDescription = gridRow.messageTypeDescription;

        if(!gridRow.isNew) {
            messageType.createTimestamp = gridRow.createTimestamp;
            messageType.createdBy = gridRow.createdBy;
        }

        return messageType;
    }

    public populateNewMessageType(highestId: number) {
        let messageType: MessageTypeModel = {
            messageTypeId: highestId + 1,
            messageName: '',
            messageTypeDescription: '',
            createdBy: null,
            createTimestamp: null,
            editMode: true,
            isNew: true
        };

        return messageType;
    }

    private getQueryStart(queryStart: string) {
        if(queryStart === '') {
            return  '?';
        } else {
            return '&';
        }
    }

    public getEmptyInboundMessageTypeForm() {
        return new FormGroup({
            inboundMessageTypeId: new FormControl('', Validators.required),
            messageName: new FormControl('', Validators.required),
            messageTypeDescription: new FormControl('', Validators.required)
        });
    }

    public getInboundMessageTypeForm(inboundMessageType: InboundMessageType) {
        return new FormGroup({
            inboundMessageTypeId: new FormControl(inboundMessageType.inboundMessageTypeId, Validators.required),
            messageName: new FormControl(inboundMessageType.messageName, Validators.required),
            messageTypeDescription: new FormControl(inboundMessageType.messageTypeDescription, Validators.required)
        });
    }

    public getEmptyOutboundMessageTypeForm() {
        return new FormGroup({
            outboundMessageTypeId: new FormControl('', Validators.required),
            messageName: new FormControl('', Validators.required),
            messageTypeDescription: new FormControl('', Validators.required)
        });
    }

    public getOutboundMessageTypeForm(outboundMessageType: OutboundMessageType) {
        return new FormGroup({
            outboundMessageTypeId: new FormControl(outboundMessageType.messageTypeId, Validators.required),
            messageName: new FormControl(outboundMessageType.messageName, Validators.required),
            messageTypeDescription: new FormControl(outboundMessageType.messageTypeDescription, Validators.required)
        });
    }

    public getInboundMessageTypeModel(formGroup: FormGroup) {
        let newInboundMessageType: InboundMessageType = new InboundMessageType();
        newInboundMessageType.inboundMessageTypeId = formGroup.value.inboundMessageTypeId;
        newInboundMessageType.messageName = formGroup.value.messageName;
        newInboundMessageType.messageTypeDescription = formGroup.value.messageTypeDescription;
        return newInboundMessageType;
    }

    public getOutboundMessageTypeModel(formGroup: FormGroup) {
        let newOutboundMessageType: OutboundMessageType = new OutboundMessageType();
        newOutboundMessageType.messageTypeId = formGroup.value.outboundMessageTypeId;
        newOutboundMessageType.messageName = formGroup.value.messageName;
        newOutboundMessageType.messageTypeDescription = formGroup.value.messageTypeDescription;
        return newOutboundMessageType;
    }

    public formatValue(value: string): string {
        // Convert input to uppercase and replace any invalid character with an empty string
        const onlyUpperNumberAndUnderscore = value.toUpperCase().replace(/[^A-Z0-9_]/g, '');
    
        // Ensure the format matches the pattern of uppercase letters or numbers followed by optional underscores and more uppercase letters or numbers
        const validPattern = /^[A-Z0-9]*(_[A-Z0-9]*)*$/;
    
        if (validPattern.test(onlyUpperNumberAndUnderscore)) {
          return onlyUpperNumberAndUnderscore;
        }
    
        // If it does not match, correct by removing invalid patterns
        const correctedValue = onlyUpperNumberAndUnderscore.replace(/_{2,}/g, '_'); // Replace multiple underscores with a single one
    
        return correctedValue;
      }
}
