<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Inbound partner</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Id: <span class="req-color">*</span></b></label>    
        </div>
        <div class="col-sm-6">
            <label *ngIf="rs.getNestedFormControlValue(form, 'inboundPartner', 'partnerId')">{{ rs.getNestedFormControlValue(form, 'inboundPartner', 'partnerId') }}</label>
            <label *ngIf="!rs.getNestedFormControlValue(form, 'inboundPartner', 'partnerId')" class="text-red">MISSING DATA</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Name: <span class="req-color">*</span></b></label>    
        </div>
        <div class="col-sm-6">
            <label *ngIf="rs.getNestedFormControlValue(form, 'inboundPartner', 'name')">{{ rs.getNestedFormControlValue(form, 'inboundPartner', 'name') }}</label>
            <label *ngIf="!rs.getNestedFormControlValue(form, 'inboundPartner', 'name')" class="text-red">MISSING DATA</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Description: <span class="req-color">*</span></b></label>    
        </div>
        <div class="col-sm-6">
            <label *ngIf="rs.getNestedFormControlValue(form, 'inboundPartner', 'description')">{{ rs.getNestedFormControlValue(form, 'inboundPartner', 'description') }}</label>
            <label *ngIf="!rs.getNestedFormControlValue(form, 'inboundPartner', 'description')" class="text-red">MISSING DATA</label>
        </div>
    </div>
</fieldset>