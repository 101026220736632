import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { MDInboundParsersInputService } from './md-inboundparsers-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { UtilService } from 'src/app/common-services/util.service';
import { InboundParserResponseModel } from '../../models/inboundparser-response-model';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'add-parser',
  templateUrl: './add-parser-modal.component.html'
})
export class AddParserModalComponent implements OnInit {
  @Input() partner: Partner;
  @Input() parsersGridList: Array<InboundParserResponseModel>;
  
  public inboundParserForm: FormGroup;
  public errorModel: ErrorModel = null;
  public showSpinner: boolean = false;
  public xPathParserId: number;
  public messageTypesDDL: DropDownListModel[];
  public parserClassesDDL: DropDownListModel[];
  public useDynamicParser: boolean = false;

  constructor(public activeModal: NgbActiveModal, private inboundParsersInputService: MDInboundParsersInputService, public utilService: UtilService, private errorParserService: ErrorParserService) {}

  ngOnInit(): void {
    this.showSpinner = true;
    this.inboundParserForm = this.inboundParsersInputService.getEmptyInboundParserFormNoDynamicParser(this.partner.id);

    this.inboundParsersInputService.getParserBaseData().subscribe(
      {
          next: (result) => {
            this.messageTypesDDL = new Array<DropDownListModel>();
            result.inboundMessageTypes.forEach(x => {
                let d: DropDownListModel = {
                    value: x.inboundMessageTypeId.toString(),
                    text: `${x.messageTypeDescription} (${x.messageName})`
                  };
                  this.messageTypesDDL.push(d);
            });

            this.parserClassesDDL = new Array<DropDownListModel>();
            result.parserClasses.forEach(x => {
              let d: DropDownListModel = {
                  value: x.id.toString(),
                  text: `${this.utilService.getClassName(x.parser)} (${x.description})`
                };
                this.parserClassesDDL.push(d);
            });

            // This is really ugly! I have created story for next sprint to move type to tblParserClasses so we do not have to search on name
            let xPathParser = result.parserClasses.find(x => x.parser === "al.goh.common.utilities.parsers.XPathParser")
            if(xPathParser != undefined) {
              this.xPathParserId = xPathParser.id;
            }

            this.showSpinner = false;
          },
          // TODO Add better error hanlding to present the issue!
          error: (error) => {
              this.showSpinner = false;
              this.errorModel = this.errorParserService.extractErrors(error);
              console.error(error);
          }
        }
    );    
  }

  get form() { return this.inboundParserForm.controls; }

  public save() {
    this.showSpinner = true;
    
    let model = this.inboundParsersInputService.getInboundParserRequestModel(this.inboundParserForm, this.useDynamicParser, this.xPathParserId);

    this.inboundParsersInputService.addParserPartnerConnection(model).subscribe(
        {
            next: (result) => {
                this.showSpinner = false;
                this.activeModal.close('save');
            },
            error: (error) => {
              this.showSpinner = false;
              this.errorModel = this.errorParserService.extractErrors(error);
              console.error(error);
            }
        }
    );
  }

  selectChangeHandler(event: any) {
    let messageTypeId = this.inboundParserForm.get('messageType').value;
    let foundConfiguredParser = this.parsersGridList.find(x => x.messageType === +messageTypeId)
    if(foundConfiguredParser != undefined) {
      alert('This message type is already configured for this partner');
    }
  }
  
  useDynamicParserChangeHandler(event: any) {
    this.useDynamicParser = this.inboundParserForm.get('useDynamicParser').value;
    this.inboundParserForm = this.useDynamicParser ? this.inboundParsersInputService.getEmptyInboundParserFormWithDynamicParser(this.partner.id, this.xPathParserId) : this.inboundParsersInputService.getEmptyInboundParserFormNoDynamicParser(this.partner.id);
  }
}
