<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}" >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ isNew === true ? 'Add ' : 'Edit ' }}agreement</h4>
        </div>
            <div class="modal-body">
              <div class="alert alert-danger" role="alert" *ngIf="error">
                <b>{{errorMessage}}</b>
              </div>
              <div *ngIf="isNew === true">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="editTypeOptions1" [value]="1" (click)="onclickAgreementTypeOptions(1)" [(ngModel)]="agreementRequest.agreementType" id="editTypeOptions1">
                  <label class="form-check-label" for="editTypeOptions1">
                    AS2 Agreement
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="editTypeOptions2" [value]="2" (click)="onclickAgreementTypeOptions(2)" [(ngModel)]="agreementRequest.agreementType" id="editTypeOptions2" checked>
                  <label class="form-check-label" for="editTypeOptions2">
                    X12 Agreement
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="editTypeOptions3" [value]="3" (click)="onclickAgreementTypeOptions(3)" [(ngModel)]="agreementRequest.agreementType" id="editTypeOptions3" checked>
                  <label class="form-check-label" for="editTypeOptions3">
                    Edifact Agreement
                  </label>
                </div>
                <br />
              </div>


              <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">{{getAgreementTypeName(agreementRequest.agreementType)}}</span></legend>
                <!-- name (all) -->
                <div class="form-group row mb5">
                  <label for="name required" class="col-sm-3 col-form-label">Name <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                      <input type="text" class="form-control" required id="name" name="name" placeholder="Name" [(ngModel)]="agreementRequest.name">
                  </div>
                </div>

                <!-- hostPartner (all) -->
                <div class="form-group row mb5">
                  <label for="hostPartner required" class="col-sm-3 col-form-label">Host partner <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                      <input type="text" class="form-control" disabled required id="hostPartner" name="hostPartner" placeholder="Host partner" [(ngModel)]="agreementRequest.hostPartner">
                  </div>
                </div>

                <!-- hostIdentity (all) -->
                <div class="form-group row mb5">
                  <label for="hostIdentity required" class="col-sm-3 col-form-label">Host identity <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                      <input type="text" class="form-control" required id="hostIdentity" name="hostIdentity" placeholder="Host identity" [(ngModel)]="agreementRequest.hostIdentity">
                  </div>
                </div>

                <!-- guestPartner (all) -->
                <div class="form-group row mb5">
                  <label for="guestPartner required" class="col-sm-3 col-form-label">Guest partner <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                      <select dropdown (change)="selectQuestPartnerChangeHandler($event)" id="guestPartner" name="guestPartner" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.guestPartner">
                        <option value="">--- Select guest partner ---</option>
                        <option *ngFor="let item of partnerListDDL" [value]="item.value">
                          {{item.text}}
                        </option>
                      </select>
                  </div>
                </div>

                <!-- guestIdentity (all) -->
                <div class="form-group row mb5">
                  <label for="guestIdentity required" class="col-sm-3 col-form-label">Guest identity <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                      <input type="text" class="form-control" required id="guestIdentity" name="guestIdentity" placeholder="Guest identity" [(ngModel)]="agreementRequest.guestIdentity">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="qualifierDDL" class="col-sm-3 col-form-label control-label">Qualifier <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectQualifierChangeHandler($event)" id="qualifierDDL" name="qualifierDDL" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.qualifier">
                      <option value="">--- Select qualifier ---</option>
                      <option *ngFor="let item of qualifierDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

            <!-- guestIdentity (all) -->
            <div class="form-group row mb5" *ngIf="certificates && agreementRequest.agreementType !== 1">
              <label for="schemaVersion required" class="col-sm-3 col-form-label">Schema version <span class="req-color">*</span></label>
              <div class="col-sm-6">
                  <input type="text" class="form-control" required id="schemaVersion" name="schemaVersion" placeholder="Schema version" [(ngModel)]="agreementRequest.schemaVersion">
              </div>
            </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="senderSigningCertificateNameDDL" class="col-sm-3 col-form-label control-label">Sender signing certificate <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectSenderSigningCertificateNameChangeHandler($event)" id="senderSigningCertificateNameDDL" name="senderSigningCertificateNameDDL" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.senderSigningCertificateName">
                      <option value="">--- Select sender signing certificate ---</option>
                      <option *ngFor="let item of certificatesPrivateDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="senderEncryptionCertificateDDL" class="col-sm-3 col-form-label control-label">Sender encryption certificate <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectSenderEncryptionCertificateNameChangeHandler($event)" id="senderEncryptionCertificateDDL" name="senderEncryptionCertificateDDL" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.senderEncryptionCertificateName">
                      <option value="">--- Select sender encryption certificate ---</option>
                      <option *ngFor="let item of certificatesPublicDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="receiverSigningCertificateDDL" class="col-sm-3 col-form-label control-label">Receiver signing certificate <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectReceiverSigningCertificateChangeHandler($event)" id="receiverSigningCertificateDDL" name="receiverSigningCertificateDDL" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.receiverSigningCertificateName">
                      <option value="">--- Select receiver signing certificate ---</option>
                      <option *ngFor="let item of certificatesPublicDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="receiverEncryptionCertificateDDL" class="col-sm-3 col-form-label control-label">Receiver encryption certificate <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectReceiverEncryptionCertificateChangeHandler($event)" id="receiverEncryptionCertificateDDL" name="receiverEncryptionCertificateDDL" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.receiverEncryptionCertificateName">
                      <option value="">--- Select receiver encryption certificate ---</option>
                      <option *ngFor="let item of certificatesPrivateDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- receiverSendAsynchronousMDN (for AS2) -->
                <div class="form-group row mb5" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="checkboxReceiverSendAsynchronousMDN" class="col-sm-3 col-form-label">Receiver Send MDN Asynchronous</label>
                  <div class="col-sm-6">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="checkboxReceiverSendAsynchronousMDN" value="" aria-label="..." [(ngModel)]="agreementRequest.receiverSendAsynchronousMDN">
                    </div>
                  </div>
                </div>

                <!-- receiverReceiptDeliveryUrl (for AS2) -->
                <div class="form-group row mb5" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="receiverReceiptDeliveryUrl required" class="col-sm-3 col-form-label">Receiver receipt delivery url <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                      <input type="text" class="form-control" required id="receiverReceiptDeliveryUrl" name="receiverReceiptDeliveryUrl" placeholder="Receiver receipt delivery url" [(ngModel)]="agreementRequest.receiverReceiptDeliveryUrl">
                  </div>
                </div>

                 <!-- senderReceiptDeliveryUrl (for AS2) -->
                <div class="form-group row mb5" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="senderReceiptDeliveryUrl required" class="col-sm-3 col-form-label">Sender receipt delivery url <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" required id="senderReceiptDeliveryUrl" name="senderReceiptDeliveryUrl" placeholder="Receiver receipt delivery url" [(ngModel)]="agreementRequest.senderReceiptDeliveryUrl">
                  </div>
                </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="reveiverEncryptionAlgorithm" class="col-sm-3 col-form-label control-label">Reveiver encryption algorithm <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectReveiverEncryptionAlgorithmChangeHandler($event)" id="reveiverEncryptionAlgorithm" name="reveiverEncryptionAlgorithm" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.reveiverEncryptionAlgorithm">
                      <option value="">--- Select reveiver encryption algorithm ---</option>
                      <option *ngFor="let item of encryptionAlgorithmsDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="receiverMdnMicHashingAlgorithm" class="col-sm-3 col-form-label control-label">Receiver mdn mic hashing algorithm <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectReceiverMdnMicHashingAlgorithmChangeHandler($event)" id="receiverMdnMicHashingAlgorithm" name="receiverMdnMicHashingAlgorithm" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.receiverMdnMicHashingAlgorithm">
                      <option value="">--- Select receiver mdn mic hashing algorithm ---</option>
                      <option *ngFor="let item of mdnMicHashingAlgorithmDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="senderEncryptionAlgorithm" class="col-sm-3 col-form-label control-label">Sender encryption algorithm <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectSenderEncryptionAlgorithmChangeHandler($event)" id="senderEncryptionAlgorithm" name="senderEncryptionAlgorithm" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.senderEncryptionAlgorithm">
                      <option value="">--- Select sender encryption algorithm ---</option>
                      <option *ngFor="let item of encryptionAlgorithmsDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="senderSigningAlgorithm" class="col-sm-3 col-form-label control-label">Sender signing algorithm <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectSenderSigningAlgorithmChangeHandler($event)" id="senderSigningAlgorithm" name="senderSigningAlgorithm" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.senderSigningAlgorithm">
                      <option value="">--- Select sender signing algorithm ---</option>
                      <option *ngFor="let item of signingAlgorithmsDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" *ngIf="certificates && agreementRequest.agreementType === 1">
                  <label for="senderMdnMicHashingAlgorithm" class="col-sm-3 col-form-label control-label">Sender mdn mic hashing algorithm <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                    <select dropdown (change)="selectSenderMdnMicHashingAlgorithmChangeHandler($event)" id="senderMdnMicHashingAlgorithm" name="senderMdnMicHashingAlgorithm" class="form-select form-select-sm control-label" [(ngModel)]="agreementRequest.senderMdnMicHashingAlgorithm">
                      <option value="">--- Select sender mdn mic hashing algorithm ---</option>
                      <option *ngFor="let item of mdnMicHashingAlgorithmDDL" [value]="item.value">
                        {{item.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div *ngIf="agreementRequest.agreementType !== 1">
                <fieldset class="border p-2">
                  <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Receiver schemas</span></legend>

                  <div class="form-group row " *ngIf="schemas && agreementRequest.agreementType === 3">
                    <label for="schemasEdifactDDL" class="col-sm-3 col-form-label control-label">Edifact Schemas <span class="req-color">*</span></label>
                    <div class="col-sm-6">
                      <table>
                        <tr>
                          <td style="width: 200px;">
                            <select dropdown (change)="selectEdifactSchemaChangeHandler($event)" id="schemasEdifactDDL" name="schemasEdifactDDL" class="form-select form-select-sm control-label" [(ngModel)]="selectedEdifactSchema">
                              <option value="">--- Select edifact schema ---</option>
                              <option *ngFor="let item of schemasEdifactDDL" [value]="item.value">
                                {{item.text}}
                              </option>
                            </select>
                          </td>
                          <td>
                            <button class="btn btn-outline-secondary btn-sm" (click)="addReceiverSchema()">Add</button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="schemas && agreementRequest.agreementType === 2">
                    <label for="schemasX12DDL" class="col-sm-3 col-form-label control-label">X12 Schemas <span class="req-color">*</span></label>
                    <div class="col-sm-6">
                      <table>
                        <tr>
                          <td style="width: 200px;">
                            <select dropdown (change)="selectEdifactSchemaChangeHandler($event)" id="schemasX12DDL" name="schemasX12DDL" class="form-select form-select-sm control-label" [(ngModel)]="selectedX12Schema">
                              <option value="">--- Select x12 schema ---</option>
                              <option *ngFor="let item of schemasX12DDL" [value]="item.value">
                                {{item.text}}
                              </option>
                            </select>
                          </td>
                          <td><button class="btn btn-outline-secondary btn-sm" (click)="addReceiverSchema()">Add</button></td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="agreementRequest && agreementRequest.agreementType !== 1">
                    <thead>
                        <tr>
                            <th><div>Name</div></th>
                            <th><div>Message Id</div></th>
                            <th><div>Schema version</div></th>
                            <th><div>Message version</div></th>
                            <th><div>Message release</div></th>
                            <th><div>Association assigned code</div></th>
                            <th></th>
                            </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let s of agreementRequest.receiverSchemaReferences">
                            <td>{{ s.schemaName }}</td>
                            <td>{{ s.messageId }}</td>
                            <td>{{ s.schemaVersion }}</td>
                            <td>{{ s.messageVersion }}</td>
                            <td>{{ s.messageRelease }}</td>
                            <td>{{ s.associationAssignedCode }}</td>
                            <td>
                              <button class="btn btn-outline-secondary btn-sm" (click)="removeReceiverSchema(s)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                </fieldset>

                <fieldset class="border p-2">
                  <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Sender schemas</span></legend>

                  <div class="form-group row " *ngIf="schemas && agreementRequest.agreementType === 3">
                    <label for="schemasEdifactDDL" class="col-sm-3 col-form-label control-label">Edifact Schemas <span class="req-color">*</span></label>
                    <div class="col-sm-6">
                      <table>
                        <tr>
                          <td style="width: 200px;">
                            <select dropdown (change)="selectEdifactSchemaChangeHandler($event)" id="schemasEdifactDDL" name="schemasEdifactDDL" class="form-select form-select-sm control-label" [(ngModel)]="selectedEdifactSchema">
                              <option value="">--- Select edifact schema ---</option>
                              <option *ngFor="let item of schemasEdifactDDL" [value]="item.value">
                                {{item.text}}
                              </option>
                            </select>
                          </td>
                          <td><button class="btn btn-outline-secondary btn-sm" (click)="addSenderSchema()">Add</button></td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="schemas && agreementRequest.agreementType === 2">
                    <label for="schemasX12DDL" class="col-sm-3 col-form-label control-label">X12 Schemas <span class="req-color">*</span></label>
                    <div class="col-sm-6">
                      <table>
                        <tr>
                          <td style="width: 200px;">
                            <select dropdown (change)="selectEdifactSchemaChangeHandler($event)" id="schemasX12DDL" name="schemasX12DDL" class="form-select form-select-sm control-label" [(ngModel)]="selectedX12Schema">
                              <option value="">--- Select x12 schema ---</option>
                              <option *ngFor="let item of schemasX12DDL" [value]="item.value">
                                {{item.text}}
                              </option>
                            </select>
                          </td>
                          <td><button class="btn btn-outline-secondary btn-sm" (click)="addSenderSchema()">Add</button></td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="agreementRequest && agreementRequest.agreementType !== 1">
                    <thead>
                        <tr>
                            <th><div>Name</div></th>
                            <th><div>Message Id</div></th>
                            <th><div>Schema version</div></th>
                            <th><div>Message version</div></th>
                            <th><div>Message release</div></th>
                            <th><div>Association assigned code</div></th>
                            <th></th>
                            </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let s of agreementRequest.senderSchemaReferences">
                            <td>{{ s.schemaName }}</td>
                            <td>{{ s.messageId }}</td>
                            <td>{{ s.schemaVersion }}</td>
                            <td>{{ s.messageVersion }}</td>
                            <td>{{ s.messageRelease }}</td>
                            <td>{{ s.associationAssignedCode }}</td>
                            <td>
                              <button class="btn btn-outline-secondary btn-sm" (click)="removeSenderSchema(s)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                    </tbody>
                    <!-- <tfoot>
                      <tr>
                        <td colspan="5">
                          <div class="pull-left">
                            <nav>
                              <ul class="pagination">
                                <li class="page-item" ng-class="{disabled: currentPage == 1}">
                                  <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                                </li>
                                <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (ccService.pageNumberBold(currentPage, 1, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(ccService.getPageNumber(currentPage, 1, totalPages), 1)">{{ccService.getPageNumber(currentPage, 1, totalPages)}}</a></li>
                                <li class="page-item" *ngIf="totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (ccService.pageNumberBold(currentPage, 2, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(ccService.getPageNumber(currentPage, 2, totalPages), 2)">{{ccService.getPageNumber(currentPage, 2, totalPages)}}</a></li>
                                <li class="page-item" *ngIf="totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (ccService.pageNumberBold(currentPage, 3, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(ccService.getPageNumber(currentPage, 3, totalPages), 3)">{{ccService.getPageNumber(currentPage, 3, totalPages)}}</a></li>
                                <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                                  <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          <div class="pull-right">
                            <label style="margin-right: 5px;">{{(((currentPage) - 1) * 10) + 1}} - {{lastDisplayIndex}} of {{configurationCount}}</label>
                          </div>

                        </td>
                      </tr>
                    </tfoot> -->
                </table>

                </fieldset>
              </div>
                <!--

                ReveiverEncryptionAlgorithm = "DES3",
                ReceiverMdnMicHashingAlgorithm = "SHA2256",
                SenderEncryptionAlgorithm = "DES3",
                SenderSigningAlgorithm = "SHA2256",
                SenderMdnMicHashingAlgorithm = "SHA2256",
                -->

              </fieldset>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary btn-sm" (click)="upsertAgreement()">Save</button>
          <button type="button" class="btn btn-outline-secondary btn-sm" (click)="closePopup()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
