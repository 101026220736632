import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MappingExplorerComponent } from './mapping-explorer.component';
import { MappingExplorerService } from './mapping-explorer.service';
import { PagingService } from 'src/app/common-services/paging.service';
import { InMappingExplorerComponent } from './child-components/in-mapping-explorer.component';
import { OutMappingExplorerComponent } from './child-components/out-mapping-explorer.component';
import { CustomerConfigurationsService } from 'src/app/customer-configurations/list/customer-configurations.service';
import { ViewConfigurationModalModule } from 'src/app/common-components/view-configuration/view-configuration-modal.module';

@NgModule({
  imports:      [ CommonModule, FormsModule, ViewConfigurationModalModule ],
  providers:    [ MappingExplorerService, PagingService, CustomerConfigurationsService ],
  declarations: [ MappingExplorerComponent, InMappingExplorerComponent, OutMappingExplorerComponent ]
})
export class MappingExplorerModule { }
