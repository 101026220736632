import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDisplayModule } from 'src/app/common-components/error-display/error-display.module';
import { ServiceModeComponent } from './service-mode.component';
import { ServiceModeService } from './service-mode.service';
import { CommonComponentsModule } from 'src/app/common-components/common-components.module';

@NgModule({
  imports:      [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule,
    ErrorDisplayModule,
    CommonComponentsModule
  ],
  providers:    [ ServiceModeService ],
  declarations: [    
    ServiceModeComponent
  ]
})
export class ServiceModeModule { }
