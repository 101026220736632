import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CanonicalMapXslt } from 'src/app/models/mapping/canonical-map-xslt';
import { MappingDocumentService } from '../mapping-document.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'delete-inbound-to-canonical-mapping-documents',
  templateUrl: './delete-inbound-to-canonical-mapping-documents.component.html',
})
export class DeleteInboundToCanonicalMappingDocumentComponent implements OnInit {
    @Input() canonicalMapXslt: CanonicalMapXslt;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;

    constructor(public activeModal: NgbActiveModal, private errorParserService: ErrorParserService, public mtService: MappingDocumentService) {}

    ngOnInit(): void {
    }

    public delete() {
      this.mtService.deleteInboundToCanonicalMappingDocument(this.canonicalMapXslt).subscribe(
            {
                next: (result) => {
                      this.activeModal.close('delete');
                },
                error: (error) => {
                  this.errorModel = this.errorParserService.extractErrors(error);
                  console.error(error);
                }
              }
        );
    }
}
