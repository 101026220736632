import { ConfDestination } from "src/app/models/destination/conf-destination";

export class SimplifiedFlowDestinationConnection {
    id: number;
    simpleEntityMapId: number;
    destinationId: number;
    createdBy: string;
    updatedBy: string;
    createTimestamp: string;
    updateTimestamp: string;
    confDestination: ConfDestination;
}
