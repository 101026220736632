import { AS2ConfIn } from "./edi/as2-conf-In";
import { AS2ConfOut } from "./edi/as2-conf-out";
import { CanonicalMap } from "./mapping/canonical-map";
import { ConfDestConnection } from "./destination/conf-dest-connection";
import { Enrichment } from "./enrichment";
import { EntityMap } from "./entity-map";
import { InboundMessageType, OutboundMessageType } from "./message-type";
//import { OutboundMap } from "./mapping/outbound-map";
import { InboundParser } from "./parser/parser";
import { ParserClass } from './parser/parser-class';
import { Partner } from "./partner/partner";
import { PostFormatting } from "./post-formatting";
import { PreFormatting } from "./pre-formatting";
import { Resolver } from "./resolver/resolver";
import { Scheduler } from "./source/scheduler";
import { KeyVaultSecret } from './key-vault/key-vault-secret';
import { EDILookup } from "./edi/edi-lookup";
import { X12Lookup } from "./edi/x12-lookup";
import { ResponseResolver } from './response-resolver/response-resolver';
import { OutboundMapXslt } from "./mapping/outbound-map-xslt";

export class WizardRequestModel {
    // inboundPartner: Partner | null; // Set in entity map
    // outboundPartner: Partner | null; // Set in entity map
    // messageType: InboundMessageType | null; // Set in entity map
    // outboundMessageType: OutboundMessageType | null; // Set in entity map
    entityMap: EntityMap;
    // resolver: Resolver;
    // inboundParser: InboundParser;
    parserClasses: Array<ParserClass>
    // canonicalMap: CanonicalMap;
    // outboundMapXslt: OutboundMapXslt;
    confDestConnections: ConfDestConnection[];
    aS2ConfIn: AS2ConfIn;
    aS2ConfOut: AS2ConfOut;
    ediLookup: EDILookup;
    x12Lookup: X12Lookup;
    // schedulers: Array<Scheduler>;
    preFormatting: PreFormatting;
    postFormatting: PostFormatting;
    enrichments: Array<Enrichment>;
    responseResolver: ResponseResolver;
    keyVaultSecret: KeyVaultSecret;
}
