import { Component, Input, OnInit } from '@angular/core';
import { PreFormattingClass } from 'src/app/models/pre-formatting-class';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'pre-formatting-input',
  templateUrl: './pre-formatting-input.component.html',
})
export class PreFormattingInputComponent implements OnInit {
  @Input() preFormatters: Array<PreFormattingClass>;  
  @Input() formGroupName: string;  
  
  public preFormattingForm: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.preFormattingForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  get form() { return this.preFormattingForm.controls; }

  selectChangeHandler(event: any) {
    let selectedPreFormatterData = this.preFormatters.find(x => x.id === +event.target.value);

    this.preFormattingForm.controls['preFormattingClassName'].setValue(selectedPreFormatterData.formattingClass);
    this.preFormattingForm.controls['preFormattingClassDescription'].setValue(selectedPreFormatterData.description);
  }

  public onRootTagChange(event: any) {    
  }
}
