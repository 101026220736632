<form [formGroup]="sourceFtpProfileForm">
    <hr>
    <!-- Host -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="host" class="form-control" id="sourceFtpProfileHost" name="sourceFtpProfileHost" placeholder="Host">
        <div *ngIf="form.host.invalid && (form.host.dirty || form.host.touched)" class="alert alert-danger">
            <div *ngIf="form.host.errors?.['required']">Host is required.</div>
        </div>
    </div>
</div>
<!-- Port -->
<div class="form-group row mb5">
    <label for="sourceFtpProfilePort" class="col-sm-3 col-form-label">Port<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="port" class="form-control" id="sourceFtpProfilePort" name="sourceFtpProfilePort" placeholder="Port">
        <div *ngIf="form.port.invalid && (form.port.dirty || form.port.touched)" class="alert alert-danger">
            <div *ngIf="form.port.errors?.['required']">Port is required.</div>
        </div>
    </div>
</div>
<!-- Path -->
<div class="form-group row mb5">
    <label for="sourceFtpProfilePath" class="col-sm-3 col-form-label">Path</label>
    <div class="col-sm-6">
        <input type="text" formControlName="path" class="form-control" id="sourceFtpProfilePath" name="sourceFtpProfilePath" placeholder="Path">
    </div>
</div>
<!-- Archive path -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileArchivePath" class="col-sm-3 col-form-label">Archive path</label>
    <div class="col-sm-6">
        <input type="text" formControlName="archivePath" class="form-control" id="sourceFtpProfileArchivePath" name="sourceFtpProfileArchivePath" placeholder="Archive path">
    </div>
</div>
<!-- Encoding -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileEncoding" class="col-sm-3 col-form-label">Encoding</label>
    <div class="col-sm-6">
        <input type="text" formControlName="encoding" class="form-control" id="sourceFtpProfileEncoding" name="sourceFtpProfileEncoding" placeholder="Encoding">
    </div>
</div>
<!-- Use RegEx -->
<div class="form-group row mb5">
    <div class="col-sm-3 col-form-label"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" formControlName="useRegEx" type="checkbox" id="sourceFtpProfileUseRegEx" value="" aria-label="...">
            <label class="form-check-label" for="sourceFtpProfileUseRegEx">
                Use RegEx
            </label>
        </div>
    </div>
</div>
<!-- FilterOrRegEx -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileFilterOrRegEx" class="col-sm-3 col-form-label">Filter/RegEx<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="filterOrRegEx" class="form-control" id="sourceFtpProfileFilterOrRegEx" name="sourceFtpProfileFilterOrRegEx" placeholder="Filter/RegEx">
    </div>
</div>
<!-- Delete -->
<div class="form-group row mb5">
    <div class="col-sm-3 col-form-label"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" formControlName="delete" type="checkbox" id="sourceFtpProfileDelete" value="" aria-label="...">
            <label class="form-check-label" for="sourceFtpProfileDelete">
                Delete file after collected
            </label>
        </div>
    </div>
</div>

<!-- Selection -->
<div class="row mb5">
    <div class="col-sm-3"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio" name="useExistingKeyVaultValue" [value]="1">
            <label class="form-check-label" for="useExistingKeyVaultValue">
                Use existing key vault secret
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio" name="useExistingKeyVaultValue" [value]="2" checked>
            <label class="form-check-label" for="useExistingKeyVaultValue">
                Add key vault secret
            </label>
        </div>
        <div class="form-check" *ngIf="sourceFtpProfileForm.value.keyVaultProfileId != null && this.isEdit != false">
            <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio" name="useExistingKeyVaultValue" [value]="3">
            <label class="form-check-label" for="useExistingKeyVaultValue">
                Update key vault secret
            </label>
        </div>
    </div>
</div>
<!-- Username -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileUsername" class="col-sm-3 col-form-label">Username<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="userName" class="form-control" id="sourceFtpProfileUsername" name="sourceFtpProfileUsername" placeholder="Username">
        <div *ngIf="form.userName.invalid && (form.userName.dirty || form.userName.touched)"
                class="alert alert-danger">
                <div *ngIf="form.userName.errors?.['required']">Username is required.</div>
            </div>
    </div>
</div>
<!-- Key vault secret -->
<div class="form-group row mb5" *ngIf="keyVaultProfiles && (sourceFtpProfileForm.value.useExistingKeyVaultValue === 1 || sourceFtpProfileForm.value.useExistingKeyVaultValue === 3)">
    <label for="keyVaultSecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
    <div class="col-sm-6">
        <select dropdown formControlName="keyVaultProfileId" id="keyVaultSecretDDL" name="keyVaultSecretDDL" class="form-select form-select-sm control-label">
            <option value="-1">--- Select secret ---</option>
            <option *ngFor="let item of keyVaultProfiles" [value]="item.id">
                {{item.name}}
            </option>
        </select>
    </div>
</div>
<!-- Password -->
<div class="form-group row mb5" *ngIf="sourceFtpProfileForm.value.useExistingKeyVaultValue === 2 || sourceFtpProfileForm.value.useExistingKeyVaultValue === 3">
    <label for="destinationPassword" class="col-sm-3 col-form-label">Password<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="password" formControlName="passPhrase" class="form-control" id="destinationPassword" name="destinationPassword" placeholder="Password">
    </div>
</div>
<!-- System name -->
<div class="form-group row mb5" *ngIf="sourceFtpProfileForm.value.useExistingKeyVaultValue === 2">
    <label for="systemName" class="col-sm-3 col-form-label">System name<span class="req-color">*</span> <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="The secret that will store the FTP password has the form of al-goh-ftp-auth-in-{system name}-password. The value you provide in the System name input will be used to construct the secret name."></i></label>
    <div class="col-sm-6">
        <input type="text" formControlName="systemName" class="form-control" id="systemName" name="systemName" placeholder="System name">
    </div>
</div>
</form>
