import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

import { HomeComponent } from './home/home.component';
import { RoleGuard } from './role.guard';
import { roles } from './auth-config';
import { CustomerConfigurationsComponent } from './customer-configurations/list/customer-configurations.component';
import { ConfigurationWizardComponent } from './customer-configurations/new/configuration-wizard.component';
import { LoginComponent } from './login/login.component';
import { MasterDataPartnersComponent } from './master-data/partners/md-partners.component';
import { MasterDataMappingDocumentsComponent } from './master-data/mapping-documents/md-mapping-documents.component';
import { MasterDataMessageTypesComponent } from './master-data/message-types/md-message-types.component';
import { MasterDataParsersComponent } from './master-data/parsers/md-parser-class.component';
import { TestComponent } from './testview/test.component';
import { EdiConfigurationsComponent } from './edi-configurations/edi-configurations.component';
import { EdiEncodeDecodeComponent } from './tools/edidecoder/edi-encode-decode.component';
import { ResolverTesterComponent } from './tools/resolver-tester/resolver-tester.component';
import { MappingExplorerComponent } from './tools/mapping-explorer/mapping-explorer.component';
import { MessageTrackerComponent } from './tools/message-tracker/messages/message-tracker.component';
import { CorrelatedMessageComponent } from './tools/message-tracker/correlated-messages/correlated-message.component';
import { MasterDataResolverComponent } from './master-data/resolvers/md-resolver.component';
import { TestFormValidationComponent } from './tools/test-form-validation/test-form-validation.component';
import { MasterDataPrestepClassesComponent } from './master-data/prestep-classes/md-prestep-classes.component';
import { TestGridViewComponent } from './tools/test-grid-view/test-grid-view.component';
import { SimpleFlowConfigurationsComponent } from './simpleflow-configurations/simpleflow-configurations.component';
import { ServiceModeComponent } from './master-data/service-mode/service-mode.component';

/**
 * MSAL Angular can protect routes in your application using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */

const routes: Routes = [
    {
        path: 'customer-configurations',
        component: CustomerConfigurationsComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder, roles.AdminToolReader]
        }
    },
    {
        path: 'new-customer-configuration',
        component: ConfigurationWizardComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolOnboarder, roles.AdminToolAdmin]
        }
    },
    {
        path: 'edi-configurations',
        component: EdiConfigurationsComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolOnboarder, roles.AdminToolAdmin]
        }
    },
    {
        path: 'partners',
        component: MasterDataPartnersComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolOnboarder, roles.AdminToolAdmin]
        }
    },
    {
        path: 'mapping-documents',
        component: MasterDataMappingDocumentsComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin]
        }
    },
    {
        path: 'message-types',
        component: MasterDataMessageTypesComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin]
        }
    },
    {
        path: 'parsers',
        component: MasterDataParsersComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolOnboarder, roles.AdminToolAdmin]
        }
    },
    {
        path: 'resolvers',
        component: MasterDataResolverComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin]
        }
    },
    {
        path: 'prestepclasses',
        component: MasterDataPrestepClassesComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin]
        }
    },
    {
        // Needed for handling redirect after login
        path: 'auth',
        component: MsalRedirectComponent
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder, roles.AdminToolReader]
        }
    },
    {
        path: 'testformvalidation',
        component: TestFormValidationComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder, roles.AdminToolReader]
        }
    },
    {
        path: 'edi-encode-decode',
        component: EdiEncodeDecodeComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder]
        }
    },
    {
        path: 'resolver-tester',
        component: ResolverTesterComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder]
        }
    },
    {
        path: 'mapping-explorer',
        component: MappingExplorerComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder]
        }
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'track-messages',
        component: MessageTrackerComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder, roles.AdminToolReader]
        }
    },
    {
        path: 'correlated-messages',
        component: CorrelatedMessageComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder, roles.AdminToolReader]
        }
    },
    {
        path: 'test-grid-view',
        component: TestGridViewComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder, roles.AdminToolReader]
        }
    },
    {
        path: 'simplified-flow-configurations',
        component: SimpleFlowConfigurationsComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolAdmin, roles.AdminToolOnboarder, roles.AdminToolReader]
        }
    },
    {
        path: 'service-mode',
        component: ServiceModeComponent,
        canActivate: [
            RoleGuard,
        ],
        data: {
            expectedRoles: [roles.AdminToolSuperUser]
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // Don't perform initial navigation in iframes or popups
        initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
