<form [formGroup]="resolverClassForm" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">Add resolver</h4>
    </div>
    <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
    <error-display [errorModel]="errorModel"></error-display>
    <div class="modal-body">
        <div>
            <div class="form-group row mb5">
                <label for="resolverClass" class="col-sm-3 col-form-label">Resolver <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="resolver" required id="resolverClass" name="resolverClass" placeholder="Resolver">
                    <div *ngIf="form.resolver.invalid && (form.resolver.dirty || form.resolver.touched)" class="alert alert-danger">
                        <div *ngIf="form.resolver.errors?.['required']">Resolver is required.</div>
                    </div>
                </div>
            </div>
    
            <div class="form-group row mb5">
                <label for="resolverDescription" class="col-sm-3 col-form-label">Description <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="description" required id="description" name="description" placeholder="Description">
                    <div *ngIf="form.description.invalid && (form.description.dirty || form.description.touched)" class="alert alert-danger">
                        <div *ngIf="form.description.errors?.['required']">Description is required.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="!resolverClassForm.valid">Add</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>
</form>

