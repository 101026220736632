import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Partner } from '../models/partner/partner';
import { GridView } from '../models/grid-view';
import { SimplifiedFlowConfigurationData } from './models/simplified-flow-configuration-data';
import { SimpleFlowConfiguration } from './models/simple-flow-configuration';
import { SimplifiedFlowConfigurationRequest } from './models/simplified-flow-configuration-request';
import { SimplifiedFlowConfigurationEntitymap } from './models/simplified-flow-configuration-entitymap';
import { SimplifiedFlowConfigurationResponse } from './models/simplified-flow-configuration-response';

@Injectable({
  providedIn: 'root',
})
export class SimpleFlowConfigurationsService {
  public _http: HttpClient;
  public baseUrlLocal: string = "";

  constructor(private http: HttpClient, 
    ) {
    this._http = http;
    this.baseUrlLocal = environment.apiBaseUrl;
  }

  public getData(usePrevEnv: boolean, page: number, pageCount: number): Observable<Array<SimpleFlowConfiguration>> {
    return this._http.get<Array<SimpleFlowConfiguration>>(this.baseUrlLocal + 'bff/simpleconfigurations');
  }

  public getSimpleConfiguration(id: number): Observable<SimplifiedFlowConfigurationResponse> {
    return this._http.get<SimplifiedFlowConfigurationResponse>(this.baseUrlLocal + `simpleconfigurations/${id}`);
  }

  public getBaseData(): Observable<SimplifiedFlowConfigurationData> {
    return this._http.get<SimplifiedFlowConfigurationData>(this.baseUrlLocal + `bff/SimplifiedFlowConfigurationData`);
  }

  public getPartners(): Observable<GridView<Partner>> {
    return this._http.get<GridView<Partner>>(this.baseUrlLocal + 'partners');
  }

  public addSimplifiedFlowConfiguration(simplifiedFlowConfigurationRequest: SimplifiedFlowConfigurationRequest) {
    return this.http.post(this.baseUrlLocal + 'simpleconfigurations', simplifiedFlowConfigurationRequest);
  }

  public updateSimplifiedFlowConfiguration(simplifiedFlowConfigurationRequest: SimplifiedFlowConfigurationRequest) {
    return this.http.put(this.baseUrlLocal + 'simpleconfigurations', simplifiedFlowConfigurationRequest);
  }

  public deleteSimplifiedFlowConfiguration(simplifiedFlowConfigurationEntitymap: SimplifiedFlowConfigurationEntitymap) {
    const options = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        }),
        body: simplifiedFlowConfigurationEntitymap
    };
    return this.http.delete(this.baseUrlLocal + 'simpleconfigurations', options);
  }

  // Forms
  public getSimplifiedFlowConfigurationModel(formGroup: FormGroup) {
    let simpleFlowConfigurationRequest: SimplifiedFlowConfigurationRequest = new SimplifiedFlowConfigurationRequest();
    
    simpleFlowConfigurationRequest.simpleEntityMap = new SimplifiedFlowConfigurationEntitymap();

    simpleFlowConfigurationRequest.simpleEntityMap.id = formGroup.value.id;
    simpleFlowConfigurationRequest.simpleEntityMap.inboundPartnerId = formGroup.value.inboundPartnerId;
    simpleFlowConfigurationRequest.simpleEntityMap.inboundMessageTypeId = formGroup.value.inboundMessageTypeId;
    simpleFlowConfigurationRequest.simpleEntityMap.outboundPartnerId = formGroup.value.outboundPartnerId;
    simpleFlowConfigurationRequest.simpleEntityMap.outboundMessageTypeId = formGroup.value.outboundMessageTypeId;

    return simpleFlowConfigurationRequest;
  }
}


