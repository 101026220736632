import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { Partner } from 'src/app/models/partner/partner';
import { Scheduler } from 'src/app/models/source/scheduler';
import { Source } from 'src/app/models/source/source';
import { environment } from '../../../../../environments/environment';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { InboundMessageType } from 'src/app/models/message-type';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SourceFtpProfile } from 'src/app/models/source/source-ftp-profile';
import { SourceSftpProfile } from 'src/app/models/source/source-sftp-profile';
import { SourceSharedFolderProfile } from 'src/app/models/source/source-shared-folder-profile';
import { SourceMqProfile } from 'src/app/models/source/source-mq-profile';
import { SourceSbProfile } from 'src/app/models/source/source-sb-profile';
import { access } from 'fs';

@Injectable({
  providedIn: 'root',
})
export class MDSourceInputService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor(private http: HttpClient) {
      this._http = http;
      this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getKeyVaultProfiles() {
      return this.http.get<Array<KeyVaultProfile>>(this.baseUrlLocal + 'bff/keyvaultprofiles');
    }

    public getAllInboundMessageTypes(): Observable<Array<InboundMessageType>> {
      return this.http.get<Array<InboundMessageType>>(this.baseUrlLocal + 'inboundmessagetypes');
    }

    public getPartnerSources(partnerId: number): Observable<Array<Scheduler>> {
      return this._http.get<Array<Scheduler>>(`${this.baseUrlLocal}scheduler/${partnerId}`);
  }

    public addScheduler(scheduler: Scheduler) {
      return this.http.post(this.baseUrlLocal + 'scheduler', scheduler);
    }

    public updateScheduler(scheduler: Scheduler) {
      return this.http.put(this.baseUrlLocal + 'scheduler', scheduler);
    }

    public deleteScheduler(scheduler: Scheduler) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: scheduler
      };
      return this.http.delete(this.baseUrlLocal + 'scheduler', options);
    }

    public validationTypes: DropDownListModel[] = [
      { value: '0', text: 'None' },
      { value: '1', text: 'Xml' },
      { value: '2', text: 'Xsd' },
      { value: '3', text: 'Json' },
    ];

    public ediTypes: Array<DropDownListModel> = [
      { value: '0', text: 'None' },
      { value: '1', text: 'EDIFACT' },
      { value: '2', text: 'X12' }
    ];

    public getValidationTypeText(protocolType: string) { 
      return this.validationTypes.find(x => x.value === protocolType).text;
    }

    public getEDITypeName(ediType: number) {
      let ediTypeName = '';
      switch(ediType) { 
        case 1: { 
          ediTypeName = 'EDIFACT';
           break; 
        }
        case 2: { 
          ediTypeName = 'X12';
           break; 
        }         
        default: { 
          ediTypeName = 'None'
          break; 
        } 
      }
  
      return ediTypeName;
    }

    public getEmptyInboundPartnerSourceForm(inboundPartnerId: number) : FormGroup {
      return new FormGroup({
          id: new FormControl(0, Validators.required),
          name: new FormControl('', Validators.required),
          inboundPartnerId: new FormControl(inboundPartnerId, Validators.required),
          protocolType: new FormControl('', Validators.required),
          enabled: new FormControl(false),
          ediType: new FormControl(0),
          validationType: new FormControl(0),
          callbackEnabled: new FormControl(false),
          callbackUrl: new FormControl(''),
          isSimplified: new FormControl(false),
          inboundMessageTypeId: new FormControl(''),
          profileId: new FormControl(0),
          sourceId: new FormControl(0)
      });
    }

    public getInboundPartnerSourceForm(inboundPartnerId: number, scheduler: Scheduler) : FormGroup {
      let inboundPartnerSourceForm: FormGroup = new FormGroup({
          id: new FormControl(scheduler.id, Validators.required),
          name: new FormControl(scheduler.name, Validators.required),
          inboundPartnerId: new FormControl(inboundPartnerId, Validators.required),
          protocolDDL: new FormControl({value: scheduler.source.protocolType, disabled: true}, Validators.required),
          protocolType: new FormControl(scheduler.source.protocolType, Validators.required),
          profileId: new FormControl(scheduler.source.profileId, Validators.required),
          enabled: new FormControl(scheduler.enabled),
          ediType: new FormControl(scheduler.source.ediType !=null ? scheduler.source.ediType : 0),
          validationType: new FormControl(scheduler.validationType),
          callbackEnabled: new FormControl(scheduler.callbackEnabled),
          callbackUrl: new FormControl(scheduler.callbackUrl),
          isSimplified: new FormControl(scheduler.isSimplified),
          inboundMessageTypeId: new FormControl(scheduler.inboundMessageTypeId !=null ? scheduler.inboundMessageTypeId.toString() : ''),
          sourceId:new FormControl(scheduler.source.id)
      });      

      switch(+scheduler.source.protocolType) { 
        case 1: { // SB
            const sourceSbProfileFormGroup: FormGroup = this.getSourceSbProfileFormGroup(scheduler.source);
            inboundPartnerSourceForm.addControl('sourceSbProfileFormGroup', sourceSbProfileFormGroup);
            break;
        }
        case 2: { // FILESHARE
          const sourceSharedFolderProfileFormGroup: FormGroup = this.getSourceSharedFolderprofileFormGroup(scheduler.source);
          inboundPartnerSourceForm.addControl('sourceSharedFolderProfileFormGroup', sourceSharedFolderProfileFormGroup);
            break; 
        } 
        case 3: { // FTP
          const sourceFtpProfileFormGroup: FormGroup = this.getSourceFtpProfileFormGroup(scheduler.source);
          inboundPartnerSourceForm.addControl('sourceFtpProfileFormGroup', sourceFtpProfileFormGroup);
            break;
        }
        case 7: { // MQ
          const sourceMqProfileFormGroup: FormGroup = this.getSourceMqprofileFormGroup(scheduler.source);
          inboundPartnerSourceForm.addControl('sourceMqProfileFormGroup', sourceMqProfileFormGroup);
            break;
        }
        case 8: { // SFTP
            const sourceSftpProfileFormGroup: FormGroup = this.getSourceSftpProfileFormGroup(scheduler.source);
            inboundPartnerSourceForm.addControl('sourceSftpProfileFormGroup', sourceSftpProfileFormGroup);
            break;
        }
        default: { 
            break; 
        } 
      }

      this.changeValidators(inboundPartnerSourceForm);

      return inboundPartnerSourceForm;
    }

    public changeValidators(inboundPartnerSourceForm: FormGroup)
      {
        if(inboundPartnerSourceForm.get("isSimplified").value == true)
        {          
          inboundPartnerSourceForm.controls["inboundMessageTypeId"].setValidators([Validators.required]);
          inboundPartnerSourceForm.controls["inboundMessageTypeId"].markAsTouched();
        }else
        {
          inboundPartnerSourceForm.controls["inboundMessageTypeId"].markAsUntouched();
          inboundPartnerSourceForm.controls["inboundMessageTypeId"].clearValidators();
        }
        inboundPartnerSourceForm.controls["inboundMessageTypeId"].updateValueAndValidity();
      }


    public getEmptySourceSbProfileFormGroup(): FormGroup {
      const sourceSbProfileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(1), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(1),
        keyVaultProfileId: new FormControl(-1),
        namespace: new FormControl(null, Validators.required),
        queue: new FormControl(null, Validators.required),
        sharedAccessKeyName: new FormControl('', Validators.required),
        systemName: new FormControl(null),
        useManagedIdentity: new FormControl(false),
        sharedAccessKey: new FormControl(null), // This is used to pass the access key to server side to store in key vault
      });

      return sourceSbProfileFormGroup;
    }

    public getSourceSbProfileFormGroup(source: Source): FormGroup {
      let useExistingKeyVaultValue: Number = 1;
      if(source.sourceSBProfile.keyVaultProfile == null) {
        useExistingKeyVaultValue = 2;
      }

      const sourceSbProfileFormGroup = new FormGroup({
        id: new FormControl(source.sourceSBProfile.id, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(source.sourceSBProfile.authentication), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(useExistingKeyVaultValue),
        keyVaultProfileId: new FormControl(source.sourceSBProfile.keyVaultProfileId),
        namespace: new FormControl(source.sourceSBProfile.namespace, Validators.required),
        queue: new FormControl(source.sourceSBProfile.queue, Validators.required),
        useManagedIdentity: new FormControl(source.sourceSBProfile.useManagedIdentity),
        sharedAccessKeyName: new FormControl(source.sourceSBProfile.sharedAccessKeyName, Validators.required),
        sharedAccessKey: new FormControl(null),
        systemName: new FormControl(null)
      });

      return sourceSbProfileFormGroup;
    }

    public getEmptySourceSftpProfileFormGroup(): FormGroup {
      const sourceSftpProfileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(5), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(1),
        keyVaultProfileId: new FormControl(-1),
        systemName: new FormControl(''),
        passPhrase: new FormControl(''),
        host: new FormControl('', Validators.required),
        port: new FormControl('', Validators.required),
        path: new FormControl(''),
        archivePath: new FormControl(''),
        delete: new FormControl(false),
        userName: new FormControl('', Validators.required),
        encoding: new FormControl(''),
        useRegEx: new FormControl(false),
        filterOrRegEx: new FormControl('')
      });

      return sourceSftpProfileFormGroup;
    }

    public getSourceSftpProfileFormGroup(source: Source): FormGroup {
      let useExistingKeyVaultValue: Number = 1;
      if(source.sourceSftpProfile.keyVaultProfile == null) {
        useExistingKeyVaultValue = 2;
      }

      const sourceSftpProfileFormGroup = new FormGroup({
        id: new FormControl(source.sourceSftpProfile.id, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(5), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(useExistingKeyVaultValue),
        keyVaultProfileId: new FormControl(source.sourceSftpProfile?.keyVaultProfileId),
        host: new FormControl(source.sourceSftpProfile.host, Validators.required),
        port: new FormControl(source.sourceSftpProfile.port),
        path: new FormControl(source.sourceSftpProfile.path),
        archivePath: new FormControl(source.sourceSftpProfile.archivePath),
        delete: new FormControl(source.sourceSftpProfile.delete),
        encoding: new FormControl(source.sourceSftpProfile.encoding),
        useRegEx: new FormControl(source.sourceSftpProfile.useRegEx),
        filterOrRegEx: new FormControl(source.sourceSftpProfile.filterOrRegEx),
        userName: new FormControl(source.sourceSftpProfile.userName),        
        passPhrase: new FormControl(null),
        systemName: new FormControl(null),
      });

      return sourceSftpProfileFormGroup;
    }

    public getEmptySourceFtpProfileFormGroup(): FormGroup {
      const sourceFtpProfileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(5), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(1),
        keyVaultProfileId: new FormControl(-1),
        systemName: new FormControl(''),
        host: new FormControl('', Validators.required),
        port: new FormControl('', Validators.required),
        path: new FormControl(''),
        archivePath: new FormControl(''),
        delete: new FormControl(false),
        userName: new FormControl('', Validators.required),
        encoding: new FormControl(''),
        useRegEx: new FormControl(false),
        filterOrRegEx: new FormControl(''),    
        passPhrase: new FormControl(''),
      });

      return sourceFtpProfileFormGroup;
    }

    public getSourceFtpProfileFormGroup(source: Source): FormGroup {
      let useExistingKeyVaultValue: Number = 1;
      if(source.sourceFtpProfile.keyVaultProfile == null) {
        useExistingKeyVaultValue = 2;
      }

      const sourceFtpProfileFormGroup = new FormGroup({
        id: new FormControl(source.sourceFtpProfile.id, Validators.required), // empty string to match DDL for not selected value
        authentication: new FormControl(5), // Hard coded to Basic
        useExistingKeyVaultValue: new FormControl(useExistingKeyVaultValue),
        keyVaultProfileId: new FormControl(source.sourceFtpProfile?.keyVaultProfileId),
        host: new FormControl(source.sourceFtpProfile.host, Validators.required),
        port: new FormControl(source.sourceFtpProfile.port, Validators.required),
        path: new FormControl(source.sourceFtpProfile.path),
        archivePath: new FormControl(source.sourceFtpProfile.archivePath),
        delete: new FormControl(source.sourceFtpProfile.delete),
        userName: new FormControl(source.sourceFtpProfile.userName),
        encoding: new FormControl(source.sourceFtpProfile.encoding),
        useRegEx: new FormControl(source.sourceFtpProfile.useRegEx),
        filterOrRegEx: new FormControl(source.sourceFtpProfile.filterOrRegEx),     
        passPhrase: new FormControl(null),
        systemName: new FormControl(null),
      });

      return sourceFtpProfileFormGroup;
    }

    public getEmptySourceMqProfileFormGroup(): FormGroup {
      const sourceMqProfileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required), // empty string to match DDL for not selected value
        host: new FormControl(null, Validators.required),
        port: new FormControl(null, Validators.required),
        channel: new FormControl(null, Validators.required),
        qm: new FormControl(null, Validators.required),
        queue: new FormControl(null, Validators.required),
      });

      return sourceMqProfileFormGroup;
    }

    public getSourceMqprofileFormGroup(source: Source): FormGroup {
      const sourceMqProfileFormGroup = new FormGroup({
        id: new FormControl(source.sourceMQProfile.id, Validators.required), // empty string to match DDL for not selected value
        host: new FormControl(source.sourceMQProfile.host, Validators.required),
        port: new FormControl(source.sourceMQProfile.port, Validators.required),
        channel: new FormControl(source.sourceMQProfile.channel, Validators.required),
        qm: new FormControl(source.sourceMQProfile.qm, Validators.required),
        queue: new FormControl(source.sourceMQProfile.queue, Validators.required),
      });

      return sourceMqProfileFormGroup;
    }

    public getEmptySourceSharedFolderProfileFormGroup(): FormGroup {
      const sourceSharedFolderProfileFormGroup = new FormGroup({
        id: new FormControl(0, Validators.required),
        server: new FormControl(null, Validators.required),
        path: new FormControl(null),
        filter: new FormControl(null),
        inputEncoding: new FormControl(null),
        outputEncoding: new FormControl(null),
      });

      return sourceSharedFolderProfileFormGroup;
    }

    public getSourceSharedFolderprofileFormGroup(source: Source): FormGroup {
      const sourceSharedFolderProfileFormGroup = new FormGroup({
        id: new FormControl(source.sourceSharedFolderProfile.id, Validators.required), 
        server: new FormControl(source.sourceSharedFolderProfile.server, Validators.required),
        path: new FormControl(source.sourceSharedFolderProfile.path),
        filter: new FormControl(source.sourceSharedFolderProfile.filter),
        inputEncoding: new FormControl(source.sourceSharedFolderProfile.inputEncoding),
        outputEncoding: new FormControl(source.sourceSharedFolderProfile.outputEncoding)
      });

      return sourceSharedFolderProfileFormGroup;
    }

    public setupSourceFormGroup(inboundPartnerSourceForm: FormGroup) {
      let sourceProtocol: string = inboundPartnerSourceForm.value.protocolType;

        switch(sourceProtocol) { 
            case '1': { // SB
                const sourceSbProfileFormGroup: FormGroup = this.getEmptySourceSbProfileFormGroup();
                inboundPartnerSourceForm.addControl('sourceSbProfileFormGroup', sourceSbProfileFormGroup);

                if (inboundPartnerSourceForm.contains('sourceFtpProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceFtpProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSftpProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSftpProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSharedFolderProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSharedFolderProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceMqProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceMqProfileFormGroup');
                }
                break; 
            }
            case '2': { // FILESHARE
                const sourceSharedFolderProfileFormGroup: FormGroup = this.getEmptySourceSharedFolderProfileFormGroup();
                inboundPartnerSourceForm.addControl('sourceSharedFolderProfileFormGroup', sourceSharedFolderProfileFormGroup);
                
                if (inboundPartnerSourceForm.contains('sourceFtpProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceFtpProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSftpProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSftpProfileFormGroup');
                }                
                if (inboundPartnerSourceForm.contains('sourceSbProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSbProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceMqProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceMqProfileFormGroup');
                }                
                break; 
            } 
            case '7': { // MQ
                const sourceMqProfileFormGroup: FormGroup = this.getEmptySourceMqProfileFormGroup();
                inboundPartnerSourceForm.addControl('sourceMqProfileFormGroup', sourceMqProfileFormGroup);

                if (inboundPartnerSourceForm.contains('sourceFtpProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceFtpProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSftpProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSftpProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSharedFolderProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSharedFolderProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSbProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSbProfileFormGroup');
                }
                break; 
            } 
            
            case '8': { // SFTP
                const sourceSftpProfileFormGroup: FormGroup = this.getEmptySourceSftpProfileFormGroup();
                inboundPartnerSourceForm.addControl('sourceSftpProfileFormGroup', sourceSftpProfileFormGroup);

                if (inboundPartnerSourceForm.contains('sourceFtpProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceFtpProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSharedFolderProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSharedFolderProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSbProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSbProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceMqProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceMqProfileFormGroup');
                }                
                break; 
            }
            case '3': { // FTP
                const sourceFtpProfileFormGroup: FormGroup = this.getEmptySourceFtpProfileFormGroup();
                inboundPartnerSourceForm.addControl('sourceFtpProfileFormGroup', sourceFtpProfileFormGroup);

                if (inboundPartnerSourceForm.contains('sourceSftpProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSftpProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSharedFolderProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSharedFolderProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceSbProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceSbProfileFormGroup');
                }
                if (inboundPartnerSourceForm.contains('sourceMqProfileFormGroup')) {
                    inboundPartnerSourceForm.removeControl('sourceMqProfileFormGroup');
                }                
                break; 
            }            
            default: { 
                break; 
            } 
        } 
    }

    public getSchedulerRequestModel(inboundPartnerSourceForm: FormGroup): Scheduler {
      let scheduler: Scheduler = new Scheduler();
      let source: Source = new Source();

      scheduler.id = +inboundPartnerSourceForm.value.id;
      scheduler.name = inboundPartnerSourceForm.value.name;
      scheduler.enabled = inboundPartnerSourceForm.value.enabled;
      scheduler.partnerId = +inboundPartnerSourceForm.value.inboundPartnerId;
      scheduler.validationType = +inboundPartnerSourceForm.value.validationType;
      scheduler.callbackUrl = inboundPartnerSourceForm.value.callbackUrl;
      scheduler.callbackEnabled = inboundPartnerSourceForm.value.callbackEnabled;
      scheduler.isSimplified = inboundPartnerSourceForm.value.isSimplified;
      scheduler.sourceId = +inboundPartnerSourceForm.value.sourceId;
      source.id = +inboundPartnerSourceForm.value.sourceId;
      scheduler.sourceId = +inboundPartnerSourceForm.value.sourceId;
      source.protocolType = +inboundPartnerSourceForm.value.protocolType;    
      source.name = inboundPartnerSourceForm.value.name;
      source.profileId = +inboundPartnerSourceForm.value.profileId;
      source.sourceFtpProfile = null;
      source.sourceSftpProfile = null;
      source.sourceSBProfile = null;
      source.sourceMQProfile = null;
      source.sourceSharedFolderProfile = null;
      scheduler.source = source;
      source.ediType = +inboundPartnerSourceForm.value.ediType;

      if(scheduler.isSimplified == true){        
      scheduler.inboundMessageTypeId = +inboundPartnerSourceForm.value.inboundMessageTypeId;
      }

      return scheduler;
    }
    
    public getSourceSbProfileRequestModel(inboundPartnerSourceForm: FormGroup, scheduler: Scheduler): SourceSbProfile {
      let sourceSbProfile: SourceSbProfile = new SourceSbProfile();
      
      sourceSbProfile.id = scheduler.source.profileId; // 0 for new
      sourceSbProfile.name = scheduler.name;
      sourceSbProfile.namespace = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.namespace;

      if(inboundPartnerSourceForm.value.sourceSbProfileFormGroup.namespace.includes(".servicebus.windows.net") === true) {
        sourceSbProfile.namespace = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.namespace;
      } else {
        sourceSbProfile.namespace = `${inboundPartnerSourceForm.value.sourceSbProfileFormGroup.namespace}.servicebus.windows.net`;
      }

      sourceSbProfile.authentication = +inboundPartnerSourceForm.value.sourceSbProfileFormGroup.authentication;
      sourceSbProfile.queue = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.queue;
      sourceSbProfile.sharedAccessKeyName = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.sharedAccessKeyName;
      sourceSbProfile.useManagedIdentity = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.useManagedIdentity;

      if(+inboundPartnerSourceForm.value.sourceSbProfileFormGroup.useExistingKeyVaultValue == 1 && inboundPartnerSourceForm.value.sourceSbProfileFormGroup.keyVaultProfileId !== -1) {
        sourceSbProfile.keyVaultProfileId = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.keyVaultProfileId;        
      }else if(+inboundPartnerSourceForm.value.sourceSbProfileFormGroup.useExistingKeyVaultValue == 2) {
        sourceSbProfile.sharedAccessKey = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.sharedAccessKey;
        sourceSbProfile.keyVaultProfile = new KeyVaultProfile();
        let systemName: string = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.systemName;
        sourceSbProfile.keyVaultProfile.secretName = `al-goh-servicebus-auth-in-${inboundPartnerSourceForm.value.sourceSbProfileFormGroup.systemName}-key`
        sourceSbProfile.keyVaultProfile.name = `${systemName} Service Bus Inbound Secret`;
      }else if(+inboundPartnerSourceForm.value.sourceSbProfileFormGroup.useExistingKeyVaultValue == 3 && inboundPartnerSourceForm.value.sourceSbProfileFormGroup.keyVaultProfileId !== -1) {
        sourceSbProfile.sharedAccessKey = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.sharedAccessKey;
        sourceSbProfile.keyVaultProfileId = inboundPartnerSourceForm.value.sourceSbProfileFormGroup.keyVaultProfileId;
      } else {
        sourceSbProfile.keyVaultProfile = null;
      }
      
      return sourceSbProfile;
    }

    public getSourceMqProfileRequestModel(inboundPartnerSourceForm: FormGroup, scheduler: Scheduler): SourceMqProfile {
      let sourceMqProfile: SourceMqProfile = new SourceMqProfile();
      
      sourceMqProfile.id = scheduler.source.profileId; // 0 for new
      sourceMqProfile.name = scheduler.name;
      sourceMqProfile.host = inboundPartnerSourceForm.value.sourceMqProfileFormGroup.host;
      sourceMqProfile.channel = inboundPartnerSourceForm.value.sourceMqProfileFormGroup.channel;
      sourceMqProfile.qm = inboundPartnerSourceForm.value.sourceMqProfileFormGroup.qm;
      sourceMqProfile.queue = inboundPartnerSourceForm.value.sourceMqProfileFormGroup.queue;
      sourceMqProfile.port = inboundPartnerSourceForm.value.sourceMqProfileFormGroup.port;

      return sourceMqProfile;
    }

    public getSourceSharedFolderProfileRequestModel(inboundPartnerSourceForm: FormGroup, scheduler: Scheduler): SourceSharedFolderProfile {
      let sourceSharedFolderProfile: SourceSharedFolderProfile = new SourceSharedFolderProfile();
      
      sourceSharedFolderProfile.id = scheduler.source.profileId; // 0 for new
      sourceSharedFolderProfile.name = scheduler.name;
      sourceSharedFolderProfile.server = inboundPartnerSourceForm.value.sourceSharedFolderProfileFormGroup.server;
      sourceSharedFolderProfile.path = inboundPartnerSourceForm.value.sourceSharedFolderProfileFormGroup.path;
      sourceSharedFolderProfile.filter = inboundPartnerSourceForm.value.sourceSharedFolderProfileFormGroup.filter;
      sourceSharedFolderProfile.inputEncoding = inboundPartnerSourceForm.value.sourceSharedFolderProfileFormGroup.inputEncoding;
      sourceSharedFolderProfile.outputEncoding = inboundPartnerSourceForm.value.sourceSharedFolderProfileFormGroup.outputEncoding;

      return sourceSharedFolderProfile;
    }

    public getSourceSftpProfileRequestModel(inboundPartnerSourceForm: FormGroup, scheduler: Scheduler): SourceSftpProfile {
      let sourceSftpProfile: SourceSftpProfile = new SourceSftpProfile();
      
      sourceSftpProfile.id = scheduler.source.profileId; // 0 for new
      sourceSftpProfile.name = scheduler.name;
      sourceSftpProfile.authenticationTypeId = +inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.authentication;

      sourceSftpProfile.host = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.host;
      sourceSftpProfile.port = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.port;
      sourceSftpProfile.path = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.path;
      sourceSftpProfile.archivePath = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.archivePath;
      sourceSftpProfile.encoding = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.encoding;
      sourceSftpProfile.useRegEx = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.useRegEx;
      sourceSftpProfile.filterOrRegEx = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.filterOrRegEx;
      sourceSftpProfile.delete = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.delete;
      sourceSftpProfile.userName = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.userName;


      if(+inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.useExistingKeyVaultValue == 1 && inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.keyVaultProfileId !== -1) {
        sourceSftpProfile.keyVaultProfileId = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.keyVaultProfileId;
      }else if(+inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.useExistingKeyVaultValue == 2 && sourceSftpProfile.authenticationTypeId !== 0) {
        sourceSftpProfile.passPhrase = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.passPhrase;
        sourceSftpProfile.keyVaultProfile = new KeyVaultProfile();
        let systemName: string = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.systemName;
        sourceSftpProfile.keyVaultProfile.secretName = `al-goh-sftp-auth-out-${inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.systemName}-password`
        sourceSftpProfile.keyVaultProfile.name = `${systemName} SFTP Inbound Secret`
      }else if(+inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.useExistingKeyVaultValue == 3 && inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.keyVaultProfileId !== -1) {
        sourceSftpProfile.passPhrase = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.passPhrase;
        sourceSftpProfile.keyVaultProfileId = inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.keyVaultProfileId;
      } else {
        sourceSftpProfile.keyVaultProfile = null;
      }

      return sourceSftpProfile;
    }

    public getSourceFtpProfileRequestModel(inboundPartnerSourceForm: FormGroup, scheduler: Scheduler): SourceFtpProfile {
      let sourceFtpProfile: SourceFtpProfile = new SourceFtpProfile();
      
      sourceFtpProfile.id = scheduler.source.profileId; // zero for new
      sourceFtpProfile.name = scheduler.name;
      sourceFtpProfile.authenticationTypeId = +inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.authentication;

      sourceFtpProfile.host = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.host;
      sourceFtpProfile.port = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.port;
      sourceFtpProfile.path = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.path;
      sourceFtpProfile.archivePath = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.archivePath;
      sourceFtpProfile.encoding = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.encoding;
      sourceFtpProfile.useRegEx = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.useRegEx;
      sourceFtpProfile.filterOrRegEx = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.filterOrRegEx;
      sourceFtpProfile.delete = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.delete;
      sourceFtpProfile.userName = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.userName;

      // TODO Handle new secret
      if(+inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.useExistingKeyVaultValue == 1 && inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.keyVaultProfileId !== -1) {
        sourceFtpProfile.keyVaultProfileId = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.keyVaultProfileId;
      }else if(+inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.useExistingKeyVaultValue == 2 && sourceFtpProfile.authenticationTypeId !== 0) {
        sourceFtpProfile.passPhrase = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.passPhrase;
        sourceFtpProfile.keyVaultProfile = new KeyVaultProfile();
        let systemName: string = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.systemName;
        sourceFtpProfile.keyVaultProfile.secretName = `al-goh-ftp-auth-in-${inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.systemName}-password`
        sourceFtpProfile.keyVaultProfile.name = `${systemName} FTP Inbound Secret`
      }else if(+inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.useExistingKeyVaultValue == 3 && inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.keyVaultProfileId !== -1) {
        sourceFtpProfile.passPhrase = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.passPhrase;
        sourceFtpProfile.keyVaultProfileId = inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.keyVaultProfileId;
      } else {
        sourceFtpProfile.keyVaultProfile = null;
      }

      return sourceFtpProfile;
    }

    public setupProfileRequestModel(inboundPartnerSourceForm: FormGroup, scheduler: Scheduler, isEdit: boolean) {
      switch(+scheduler.source.protocolType) { 
        case 1: { // SB
            if(isEdit || (inboundPartnerSourceForm.value.sourceSbProfileFormGroup.id === '' || inboundPartnerSourceForm.value.sourceSbProfileFormGroup.id === 0)) {
                scheduler.source.sourceSBProfile = this.getSourceSbProfileRequestModel(inboundPartnerSourceForm, scheduler);                    
            } else {
                scheduler.source.profileId = +inboundPartnerSourceForm.value.sourceSbProfileFormGroup.id;
            }
            break;
        }
        case 2: { // FILESHARE
            if(isEdit || (inboundPartnerSourceForm.value.sourceSharedFolderProfileFormGroup.id === '' || inboundPartnerSourceForm.value.sourceSharedFolderProfileFormGroup.id === 0)) {
                scheduler.source.sourceSharedFolderProfile = this.getSourceSharedFolderProfileRequestModel(inboundPartnerSourceForm, scheduler);                    
            } else {
                scheduler.source.profileId = +inboundPartnerSourceForm.value.sourceSharedFolderProfileFormGroup.id;
            }
            break; 
        } 
        case 3: { // SFTP
            if(isEdit || (inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.id === '' || inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.id === 0)) {
                scheduler.source.sourceFtpProfile = this.getSourceFtpProfileRequestModel(inboundPartnerSourceForm, scheduler);                    
            } else {
                scheduler.source.profileId = +inboundPartnerSourceForm.value.sourceFtpProfileFormGroup.id;
            }
            break;
        }
        case 7: { // MQ
            if(isEdit || (inboundPartnerSourceForm.value.sourceMqProfileFormGroup.id === '' || inboundPartnerSourceForm.value.sourceMqProfileFormGroup.id === 0)) {
                scheduler.source.sourceMQProfile = this.getSourceMqProfileRequestModel(inboundPartnerSourceForm, scheduler);                    
            } else {
                scheduler.source.profileId = +inboundPartnerSourceForm.value.sourceMqProfileFormGroup.id;
            }
            break;
        }
        case 8: { // SFTP
            if(isEdit || (inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.id === '' || inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.id === 0)) {
                scheduler.source.sourceSftpProfile = this.getSourceSftpProfileRequestModel(inboundPartnerSourceForm, scheduler);                    
            } else {
                scheduler.source.profileId = +inboundPartnerSourceForm.value.sourceSftpProfileFormGroup.id;
            }
            break;
        }
        default: { 
            break; 
        } 
      } 
    }
}
