<form [formGroup]="entitymapForm">    
    <div>
        <fieldset class="border p-2">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Configuration information</span></legend>
    
            <!-- SenderId -->
            <div class="form-group row mb5">
                <label for="senderId" class="col-sm-2 col-form-label">Sender Id <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="senderId" required id="senderId" name="senderId" placeholder="Sender Id" (change)="onDataChange($event)">
                    <div *ngIf="form.senderId.invalid && (form.senderId.dirty || form.senderId.touched)" class="alert alert-danger">
                        <div *ngIf="form.senderId.errors?.['required']">Sender Id is required.</div>
                    </div>
                </div>
            </div>
            <!-- SenderName -->
            <div class="form-group row mb5">
                <label for="senderName" class="col-sm-2 col-form-label">Sender name <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="senderName" id="senderName" name="senderName" placeholder="Sender name" (change)="onDataChange($event)">
                    <div *ngIf="form.senderName.invalid && (form.senderName.dirty || form.senderName.touched)" class="alert alert-danger">
                        <div *ngIf="form.senderName.errors?.['required']">Sender name is required.</div>
                    </div>
                </div>
            </div>
            <!-- ReceiverId -->
            <div class="form-group row mb5">
                <label for="receiverId" class="col-sm-2 col-form-label">Receiver Id <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="receiverId" required id="receiverId" name="receiverId" placeholder="Receiver Id" (change)="onDataChange($event)">
                    <div *ngIf="form.receiverId.invalid && (form.receiverId.dirty || form.receiverId.touched)" class="alert alert-danger">
                        <div *ngIf="form.receiverId.errors?.['required']">Receiver Id is required.</div>
                    </div>
                </div>
            </div>
            <!-- SenderName -->
            <div class="form-group row mb5">
                <label for="receiverName" class="col-sm-2 col-form-label">Receiver name <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="receiverName" id="receiverName" name="receiverName" placeholder="Receiver name" (change)="onDataChange($event)">
                    <div *ngIf="form.receiverName.invalid && (form.receiverName.dirty || form.receiverName.touched)" class="alert alert-danger">
                        <div *ngIf="form.receiverName.errors?.['required']">Receiver name is required.</div>
                    </div>
                </div>
            </div>
            <!-- LegalEntityId -->
            <div class="form-group row mb5">
                <label for="legalEntityId" class="col-sm-2 col-form-label">Legal Entity Id <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="legalEntityId" id="legalEntityId" name="legalEntityId" placeholder="Legal Entity Id" (change)="onDataChange($event)">
                    <div *ngIf="form.legalEntityId.invalid && (form.legalEntityId.dirty || form.legalEntityId.touched)" class="alert alert-danger">
                        <div *ngIf="form.legalEntityId.errors?.['required']">Legal Entity Id is required.</div>
                    </div>
                </div>
            </div>
            <!-- LegalEntityName -->
            <div class="form-group row mb5">
                <label for="legalEntityName" class="col-sm-2 col-form-label">Legal Entity name <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="legalEntityName" id="legalEntityName" name="legalEntityName" placeholder="Legal Entity name" (change)="onDataChange($event)">
                    <div *ngIf="form.legalEntityName.invalid && (form.legalEntityName.dirty || form.legalEntityName.touched)" class="alert alert-danger">
                        <div *ngIf="form.legalEntityName.errors?.['required']">Legal Entity name is required.</div>
                    </div>
                </div>
            </div>
            <!-- AreaPlantId -->
            <div class="form-group row mb5">
                <label for="areaPlantId" class="col-sm-2 col-form-label">Area Plant ID</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="areaPlantId" id="areaPlantId" name="areaPlantId" placeholder="Area Plant ID" (change)="onDataChange($event)">
                </div>
            </div>
            <!-- AreaPlantName -->
            <div class="form-group row mb5">
                <label for="areaPlantName" class="col-sm-2 col-form-label">Area Plant name</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="areaPlantName" id="areaPlantName" name="areaPlantName" placeholder="Area Plant name" (change)="onDataChange($event)">
                </div>
            </div>
            <!-- customLookupId -->
            <div class="form-group row mb5">
                <label for="customLookupId" class="col-sm-2 col-form-label">Custom Lookup ID</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="customLookupId" id="customLookupId" name="customLookupId" placeholder="Custom Lookup ID" (change)="onDataChange($event)">
                </div>
            </div>
            <!-- country -->
            <div class="form-group row mb5">
                <label for="country" class="col-sm-2 col-form-label">Country</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="country" id="country" name="country" placeholder="Country" (change)="onDataChange($event)">
                </div>
            </div>
        </fieldset>    
    </div>
</form>

