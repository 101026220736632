import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  public env: string = '';
  public isAdmin: boolean = false;
  public isReader: boolean = false;
  public isOnboarder: boolean = false;
  public isSuperUser: boolean = false;

  constructor(private authService: MsalService) { }

  ngOnInit(): void {
    this.env = environment.env;
   
    let allAccounts = this.authService.instance.getAllAccounts();
    if (allAccounts.length > 0) {
      let account = allAccounts[0];
      this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
      this.isReader = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Reader') == undefined ? false : true;
      this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
      this.isSuperUser = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.SuperUser') == undefined ? false : true;
    }
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public isCollapsed: boolean = true;

  // onHidden(): void {
  //   console.log('Dropdown is hidden');
  // }
  // onShown(): void {
  //   console.log('Dropdown is shown');
  // }
  // isOpenChange(): void {
  //   console.log('Dropdown state is changed');
  // }
}
