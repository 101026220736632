import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { MasterDataResolverService } from '../md-resolver.service';
import { ResolverClass } from 'src/app/models/resolver/resolver-class';
import { UtilService } from 'src/app/common-services/util.service';


@Component({
  selector: 'delete-resolver-class', // TODO: Is selector needed for modal?
  templateUrl: './delete-resolver-class.component.html',
})
export class DeleteResolverClassComponent implements OnInit {
    @Input() resolverClass: ResolverClass;
    // public showError: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = false;

    constructor(public activeModal: NgbActiveModal, public rService: MasterDataResolverService, public utilService: UtilService) {}

    ngOnInit(): void {
    }

    public delete() {
      this.rService.deleteResolverClass(this.resolverClass).subscribe(
            {
                next: (result) => {
                      this.activeModal.close('delete');
                },
                error: (error) => {
                  this.displayErrorMessage(error);
                }
              }
        );
    }

    private displayErrorMessage(error: any) {
      let formattedError = '';
      if(error?.error && error?.error.error == undefined) {
          if(error.error.includes('at al.goh.admin.tool.api')) {
              formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
          } else {
            if(error.error.includes("DELETE statement conflicted"))
            {
              formattedError = "Cannot remove resolver as it is used in a configuration."
            } else {
              formattedError = error.error;
            }
          }
      } else {
      if(error?.error?.error) {
          formattedError = error?.error.error;
      } else {
          formattedError = 'Unknown error';
      }
      }

      this.errorMessage = formattedError;
      console.error(error);
    }
}
