<form [formGroup]="inboundParserForm" (submit)="save()">
<div class="modal-header">
    <h4 class="modal-title">Add parser</h4>
</div>
<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="errorModel">
    <b>Error when administrating parser. Error Message: {{errorModel.errorMessage}}</b>
</div>
<div class="modal-body">
    <div class="form-group row mb5">
        <label for="useDynamicParser" class="col-sm-3 col-form-label control-label">Use dynamic parser</label>
        <div class="col-sm-9">
            <input class="form-check-input" type="checkbox" value="" formControlName="useDynamicParser" name="useDynamicParser" id="useDynamicParser" (change)="useDynamicParserChangeHandler($event)">
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="messageTypesDDL != undefined && messageTypesDDL.length > 0">
        <label for="messageTypeDDL" class="col-sm-3 col-form-label control-label">Message type <span class="req-color">*</span></label>
        <div class="col-sm-9">
            <select dropdown (change)="selectChangeHandler($event)" formControlName="messageType" id="messageTypeDDL" name="messageTypeDDL" class="form-select form-select-sm control-label">
            <option value="">--- Select message type ---</option>
            <option *ngFor="let item of messageTypesDDL" [value]="item.value">
                {{item.text}}
            </option>
            </select>
            <div *ngIf="form.messageType.invalid && (form.messageType.dirty || form.messageType.touched)" class="alert alert-danger">
                <div *ngIf="form.messageType.errors?.['required']">Message type is required.</div>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="!useDynamicParser && parserClassesDDL != undefined && parserClassesDDL.length > 0">
        <label for="parserClassesDDL" class="col-sm-3 col-form-label control-label">Parser <span class="req-color">*</span></label>
        <div class="col-sm-9">
            <select dropdown (change)="selectChangeHandler($event)" formControlName="parserClassId" id="parserClassesDDL" name="parserClassesDDL" class="form-select form-select-sm control-label">
            <option value="">--- Select parser type ---</option>
            <option *ngFor="let item of parserClassesDDL" [value]="item.value">
                {{item.text}}
            </option>
            </select>
            <div *ngIf="form.parserClassId.invalid && (form.parserClassId.dirty || form.parserClassId.touched)" class="alert alert-danger">
                <div *ngIf="form.parserClassId.errors?.['required']">Parser is required.</div>
            </div>
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="senderIdPath" class="col-sm-3 col-form-label">Sender XPath <span class="req-color">*</span></label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="senderIdPath" required id="senderIdPath" name="senderIdPath" placeholder="SenderId XPath">
            <div *ngIf="form.senderIdPath.invalid && (form.senderIdPath.dirty || form.senderIdPath.touched)" class="alert alert-danger">
                <div *ngIf="form.senderIdPath.errors?.['required']">SenderId XPath is required.</div>
            </div>
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="receiverIdPath" class="col-sm-3 col-form-label">Receiver XPath <span class="req-color">*</span></label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="receiverIdPath" required id="receiverIdPath" name="receiverIdPath" placeholder="Receiver XPath">
            <div *ngIf="form.receiverIdPath.invalid && (form.receiverIdPath.dirty || form.receiverIdPath.touched)" class="alert alert-danger">
                <div *ngIf="form.receiverIdPath.errors?.['required']">ReceiverId XPath is required.</div>
            </div>
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="primaryIdPath" class="col-sm-3 col-form-label">Primary Id XPath <span class="req-color">*</span></label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="primaryIdPath" required id="primaryIdPath" name="primaryIdPath" placeholder="Primary Id XPath">
            <div *ngIf="form.primaryIdPath.invalid && (form.primaryIdPath.dirty || form.primaryIdPath.touched)" class="alert alert-danger">
                <div *ngIf="form.primaryIdPath.errors?.['required']">Primary Id XPath is required.</div>
            </div>
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="referenceIdPath" class="col-sm-3 col-form-label">Reference Id XPath <span class="req-color">*</span></label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="referenceIdPath" required id="referenceIdPath" name="referenceIdPath" placeholder="Reference Id XPath">
            <div *ngIf="form.referenceIdPath.invalid && (form.referenceIdPath.dirty || form.referenceIdPath.touched)" class="alert alert-danger">
                <div *ngIf="form.referenceIdPath.errors?.['required']">Reference Id XPath is required.</div>
            </div>
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="taskIdPath" class="col-sm-3 col-form-label">Task Id XPath</label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="taskIdPath" id="taskIdPath" name="taskIdPath" placeholder="Task Id XPath">            
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="areaPlantIdPath" class="col-sm-3 col-form-label">Area/plant Id XPath</label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="areaPlantIdPath" id="areaPlantIdPath" name="areaPlantIdPath" placeholder="Area/plant Id XPath">            
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="countryCodePath" class="col-sm-3 col-form-label">Country code XPath</label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="countryCodePath" id="countryCodePath" name="countryCodePath" placeholder="Country code XPath">            
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="customLookupIdPath" class="col-sm-3 col-form-label">Custom lookup Id XPath</label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="customLookupIdPath" id="customLookupIdPath" name="customLookupIdPath" placeholder="Custom lookup Id XPath">            
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="useDynamicParser">
        <label for="namespacesUri" class="col-sm-3 col-form-label">Namespaces Uri</label>
        <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="namespacesUri" id="namespacesUri" name="namespacesUri" placeholder="Namespaces Uri">            
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" [disabled]="!inboundParserForm.valid" (click)="save()">Add</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>

</form>