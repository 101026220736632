import { Component, Input, OnInit } from '@angular/core';
import { DestinationSharedFolderProfile } from '../../../../../models/destination/destination-shared-folder-profile';
import { FileShareProfileService } from './fileshare-profile.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
    selector: "fileshare-profile",
    templateUrl: "./fileshare-profile.component.html"
})
export class FileshareProfileComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;
    
    public destinationSharedFolderprofileForm: FormGroup;
    public sharedFolderProfiles: Array<DestinationSharedFolderProfile>;
    
    constructor(private fileShareProfileService: FileShareProfileService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.destinationSharedFolderprofileForm.controls; }

    ngOnInit(): void {
        this.destinationSharedFolderprofileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        this.fileShareProfileService.getAllFileShareProfiles().subscribe({
            next: (result) => {
                this.sharedFolderProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });
    }

    public handleDestOptionsChange(event: any) {
        this.fileShareProfileService.setupRequiredValidation(this.destinationSharedFolderprofileForm);
    }
}