import { PostFormattingClass } from './post-formatting-class';
export class PostFormatting
{
    id: number;
    formattingClass: string
    order: number;
    createTimestamp: string;
    updateTimestamp: string;
    createdBy: string;
    updatedBy: string;
    postFormattingClassId: number;
    postFormattingClass: PostFormattingClass;
    entityMapId: number;
}