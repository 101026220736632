<form [formGroup]="outboundMessageTypeForm">
  <fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound message type</span></legend>

    <div class="form-group row" *ngIf="outboundMessageTypes.length > 0">
      <label for="outboundMessageTypeDDL" class="col-sm-2 col-form-label control-label">Outbound message type <span class="req-color">*</span></label>
      <div class="col-sm-6">
        <select dropdown formControlName="outboundMessageTypeId" id="outboundMessageTypeDDL" name="outboundMessageTypeDDL" class="form-select form-select-sm control-label" (change)="selectOutboundPartnerChangeHandler($event)">
          <option value="">--- Select outbound message type ---</option>
          <option *ngFor="let item of outboundMessageTypes" [value]="item.messageTypeId.toString()">
            {{item.messageTypeDescription }} ({{item.messageName}})
          </option>
        </select>
        <div *ngIf="form.outboundMessageTypeId.invalid && (form.outboundMessageTypeId.dirty || form.outboundMessageTypeId.touched)" class="alert alert-danger">
          <div *ngIf="form.outboundMessageTypeId.errors?.['required']">Outbound message type is required.</div>
        </div>
      </div>
    </div>
  </fieldset>
</form>

  