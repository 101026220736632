<h3 id="tableLabel">Administrate resolvers</h3>

<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div>

<div>
    <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="gridView">
        <thead>
          <tr>
            <th scope="col" colSpan="9">
              <div>
                <button class="btn btn-outline-secondary btn-sm" (click)="createResolver()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
              </div>
            </th>
          </tr>
        </thead>
        <thead>
            <tr>
                <th class="vtop">
                  <div>Id</div>
                </th>
                <th>
                  <div>Resolver</div>
                  <div>
                    <input type="text" class="form-control" id="resolver" name="resolver" placeholder="Filter resolver" [(ngModel)]="filterResolver" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                  </div>
                </th>
                <th>
                  <div>Description</div>
                  <div>
                    <input type="text" class="form-control" id="resolverDescription" name="resolverDescription" placeholder="Filter description" [(ngModel)]="filterDescription" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                  </div>
                </th>                      
                <th class="vtop">
                  <div>Created by</div>
                </th>  
                <th class="vtop">
                  <div>Created at</div>
                </th>  
                <th class="vtop">
                  <div>Updated by</div>
                </th>  
                <th class="vtop">
                  <div>Updated at</div>
                </th>
                <th>                
                </th>  
            </tr>
        </thead>
        <tbody *ngIf="gridView">
            <tr *ngFor="let r of gridView.rows;let index = index;">
                <td>
                  <span>{{r.id}}</span>
                </td>
                <td>
                  <span>{{ utilService.getClassName(r.resolver) }}</span>
                </td>            
                <td>
                  <span>{{r.description}}</span>
                </td>
                <td>
                  <span>{{r.createdBy}}</span>
                </td>
                <td>
                  <span>{{r.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss' }}</span>
                </td>   
                <td>
                  <span>{{r.updatedBy}}</span>
                </td>
                <td>
                  <span>{{r.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss' }}</span>
                </td>             
                <td>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="!isAdmin" (click)="editResolver(r)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="!isAdmin" (click)="removeResolver(r)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                </td>
            </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="11">
              <div>
                <table>
                  <tr>
                    <td style="margin: auto;">
                      <nav>
                        <ul class="pagination">
                          <li class="page-item" ng-class="{disabled: currentPage == 1}">
                            <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                          </li>
                          <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(resolverPaging.currentPage, 1, resolverPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(resolverPaging.currentPage, 1, resolverPaging.totalPages), 1)">{{pService.getPageNumber(resolverPaging.currentPage, 1, resolverPaging.totalPages)}}</a></li>
                          <li class="page-item" *ngIf="resolverPaging.totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(resolverPaging.currentPage, 2, resolverPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(resolverPaging.currentPage, 2, resolverPaging.totalPages), 2)">{{pService.getPageNumber(resolverPaging.currentPage, 2, resolverPaging.totalPages)}}</a></li>
                          <li class="page-item" *ngIf="resolverPaging.totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(resolverPaging.currentPage, 3, resolverPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(resolverPaging.currentPage, 3, resolverPaging.totalPages), 3)">{{pService.getPageNumber(resolverPaging.currentPage, 3, resolverPaging.totalPages)}}</a></li>
                          <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                            <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                          </li>
                        </ul>
                        </nav>
                    </td>
                    <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                      <label>{{(((resolverPaging.currentPage) - 1) * resolverPaging.pageCount) + 1}} - {{resolverPaging.lastDisplayIndex}} of {{gridView.totalRows}}</label>
                    </td>
                    <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;"> 
                      <select dropdown (change)="selectChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="resolverPaging.pageCount">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </td>
                  </tr>                  
                </table>
              </div>
            </td>
          </tr>
        </tfoot>
    </table>
  </div>