import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { EdiConfigurationsComponent } from './edi-configurations.component';
import { EdiConfigurationsService } from './edi-configurations.service';
import { AddAgreementComponent } from './add-agreement/upsert-agreement.component';
import { UpsertPartnerComponent } from './add-agreement/upsert-partner.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports:      [ CommonModule, FormsModule, NgbModule ],
  providers:    [ EdiConfigurationsService ],
  declarations: [ EdiConfigurationsComponent, AddAgreementComponent, UpsertPartnerComponent ]
})
export class EdiConfigurationsModule { }