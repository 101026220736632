<div class="modal-header">
    <h4 class="modal-title">Delete outbound message type {{outboundMessageType.messageName}}</h4>
</div>

<div class="modal-body">
    <!-- <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div> -->
    <error-display [errorModel]="errorModel"></error-display>
    <div>
        Are you really sure you want to delete outbound message type {{outboundMessageType.messageName}}?
    </div>    
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
