import { DropDownListModel } from './dropdown-list-model';

export class NewConfigurationResponse {
  messageTypes: DropDownListModel[];
  partners: DropDownListModel[];
  inboundQueues: DropDownListModel[];
  destinations: DropDownListModel[];
  maxMessageTypeId: number;
  maxConfId: number;
  maxPartnerId: number;
}
