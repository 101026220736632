import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SourceViewTableRowComponent } from './source-view-table-data.component';

@NgModule({
  imports:      [ 
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule 
  ],  
  providers:    [  ],
  declarations: [ SourceViewTableRowComponent ],
  exports:      [ SourceViewTableRowComponent ]
})
export class SourceViewTableRowModule { }