import { Component, Input, OnInit } from '@angular/core';
import { Source } from 'src/app/models/source/source';

@Component({
  selector: 'source-view-table-data',
  templateUrl: './source-view-table-data.component.html'
})
export class SourceViewTableRowComponent implements OnInit {
    @Input() source: Source;

    constructor() {}

    ngOnInit(): void {}
}
