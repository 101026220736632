import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/common-services/util.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'resolver-info',
  templateUrl: './resolver-info.component.html',
})
export class ResolverInfoComponent implements OnInit {
    @Input() formGroupName: string;  
    public resolverForm: FormGroup;
      
    constructor(private rootFormGroup: FormGroupDirective, public utilService: UtilService) { }

    ngOnInit() : void {
      this.resolverForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    }

    get form() { return this.resolverForm.controls; }
}
