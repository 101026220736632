import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Partner } from 'src/app/models/partner/partner';
import { PartnerService } from '../partner.service';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { UtilService } from 'src/app/common-services/util.service';
import { PartnerRequestModel } from '../models/partner-request-model';
import { Resolver } from 'src/app/models/resolver/resolver';
import { TabModel } from 'src/app/models/tab-model';
import { Scheduler } from 'src/app/models/source/scheduler';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { ConfDestination } from 'src/app/models/destination/conf-destination';
import { InboundParserResponseModel } from '../models/inboundparser-response-model'
import { ParserClass } from 'src/app/models/parser/parser-class';
import { ParserClassDropDownListModel } from 'src/app/models/parser/parserclasses-dropdown-list-model';
import { InboundMessageType } from 'src/app/models/message-type';
import { CanonicalMap } from 'src/app/models/mapping/canonical-map';
import { PreStepService } from '../../services/pre-step.service';
import { PreStep } from 'src/app/models/pre-step';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'add-partner',
  templateUrl: './edit-partner.component.html'
})
export class EditPartnerComponent implements OnInit {
    @Input() partner: Partner;

    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public resolversddl: Array<DropDownListModel>;
    public preStepClassId: number = -1;
    public parserClassesddl: Array<ParserClassDropDownListModel>;
    public preStepClassesDDL: Array<DropDownListModel>;
    public resolverId: number = -1;
    public resolver: Resolver;
    public preStep: PreStep;
    public tabModel: TabModel = new TabModel();

    public schedulers: Array<Scheduler> = null;
    public destinations: Array<ConfDestination> = null;
    public existingSchedulers: Array<Scheduler>;
    public keyVaultProfiles: Array<KeyVaultProfile>;

    public parsersGridList: Array<InboundParserResponseModel> = new Array<InboundParserResponseModel>();
    public itcmcGridList: Array<CanonicalMap> = new Array<CanonicalMap>()
    public isOnboarder: boolean = false;
    public selectedMessageType: InboundMessageType;
    public selectedParserClass: ParserClass;

    constructor(public partnerService: PartnerService, public preStepService: PreStepService, private errorParserService: ErrorParserService, private utilService: UtilService, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.showSpinner = true;
        this.tabModel.activeTab = 1;
        // TODO Best practice is to avoid multiple calls to backend for populating frontend dropdowns etc.
        //      But for now this will do
        this.partnerService.getResolverClasses().subscribe({
            next: (resolvers) => {
                this.resolversddl = new Array<DropDownListModel>();
                resolvers.forEach(x => {
                    let d: DropDownListModel = {
                        value: x.id.toString(),
                        text: `${this.utilService.getClassName(x.resolver)} (${x.description})`
                    };

                    this.resolversddl.push(d);
                });

                this.getResolver(this.partner.id);
            },
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });

        this.preStepService.getPreStepClasses().subscribe({
            next: (result) => {
                this.showSpinner = false;
                this.preStepClassesDDL = new Array<DropDownListModel>();
                result.forEach(x => {
                    let d: DropDownListModel = {
                        value: x.id.toString(),
                        text: `${this.utilService.getClassName(x.preStepClass)} (${x.description})`
                    };

                    this.preStepClassesDDL.push(d);
                });

                this.getPreStep(this.partner.id);
            },
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });        

        this.getPartnerSources(this.partner.id);
    }

    private getPartnerSources(partnerId: number) {
        this.partnerService.getPartnerSources(partnerId).subscribe({
            next: (schedulers) => {
                this.showSpinner = false;
                this.schedulers = schedulers;
            },
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });

        this.partnerService.getParserClasses().subscribe({
          next: (result) => {
              result.sort((a,b) => (a.parser < b.parser ? -1:1));
              this.parserClassesddl = new Array<ParserClassDropDownListModel>
              result.forEach(x => {
                  let pc: ParserClassDropDownListModel = {
                      value: x,
                      text: `(${x.id.toString()}) ${this.utilService.getClassName(x.parser)} (${x.description})`
                  };

                  this.parserClassesddl.push(pc);
              });

          },
          error: (error) => {
              this.showSpinner = false;
              this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
          }
        });
    }

    public getResolver(partnerId: number) {
        this.partnerService.getResolver(partnerId).subscribe({
            next: (result) => {
                this.showSpinner = false;
                this.resolver = result;
                this.resolverId = this.resolver.resolverClassId;
            },
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });
    }

    public getPreStep(partnerId: number) {
        this.preStepService.getPreStep(partnerId).subscribe({
            next: (result) => {
                this.showSpinner = false;
                if(result !== null) {
                    this.preStep = result;
                    this.preStepClassId = this.preStep.preStepClassId;
                }                
            },
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });
    }

    public addParserConnection() {
        this.errorModel
        if(Object.keys(this.selectedParserClass).length !== 0 && Object.keys(this.selectedMessageType).length !== 0){
            let newparserConnection = new InboundParserResponseModel();
            newparserConnection.id = 0;
            newparserConnection.isNew = true;
            newparserConnection.inboundPartnerId = this.partner.id;
            newparserConnection.parserClassId = +this.selectedParserClass.id;
            newparserConnection.parser = this.utilService.getClassName(this.selectedParserClass.parser);
            newparserConnection.messageType = +this.selectedMessageType.inboundMessageTypeId;
            newparserConnection.messageTypeName =  this.selectedMessageType.messageName;

            let parserConExists = this.parsersGridList.find(x => x.inboundPartnerId === newparserConnection.inboundPartnerId && x.messageType === newparserConnection.messageType)
            if(parserConExists == undefined) {
            this.parsersGridList.push(newparserConnection);
            this.errorModel = null;
            }else{
                this.errorModel = new ErrorModel();
                this.errorModel.errorMessage = `Error Message: MessageType already has a parserconnections listed`;                
            }
      }else{
        let tets = 0;
    }

    }

    public save() {
        let partnerRequestModel: PartnerRequestModel = new PartnerRequestModel();
        partnerRequestModel.partner = this.partner;
        partnerRequestModel.resolverClassId = this.resolverId;
        partnerRequestModel.preStepClassId = this.preStepClassId;

        this.partnerService.updatePartner(partnerRequestModel).subscribe(
            {
                next: (result) => {
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }

    public onSourceUpdated(scheduler: Scheduler) {
        this.getPartnerSources(this.partner.id);
    }

    public onclickGoToTab(tab: number) {

        this.tabModel.activeTab = tab;

        switch(tab) {
            case 1: {
                this.showSpinner = true;
                // TODO Mode the read of source data to the ngOnInit for the child component
                this.getPartnerSources(this.partner.id);
                break;
            }
            case 2: {
                //this.showSpinner = true;
                break;
            }
            case 3: {
                //this.showSpinner = true;
                //this.getParsers(this.partner.id)
            }

        }
    }
}
