<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Pre-step</span></legend>
    <div style="margin-bottom: 10px;">
        <span class="text-muted"><small>Prestep is use for converting the incomming file's format from CSV, Flatfile etc to Xml in order to process it.</small></span>
    </div>  

    <div class="form-group row" *ngIf="preStep">
      <label for="resolverDDL" class="col-sm-2 col-form-label control-label">Prestep class name:</label>
      <div class="col-sm-6">
        {{ utilService.getClassName(preStep.preStepClasses.preStepClass ) }} <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="Resolver is used to resolve which kind of message type the incomming message is. Resolver is connected to a specific partner and is configured in the master data section."></i>
      </div>
    </div>
    
    <div class="form-group row" *ngIf="preStep">
        <label for="resolverDDL" class="col-sm-2 col-form-label control-label">Description:</label>
        <div class="col-sm-6">
            {{preStep.preStepClasses.description}}
        </div>
    </div>
</fieldset>