import { CanonicalMapXslt } from "./canonical-map-xslt";
import { InboundMessageType } from "../message-type";


export class CanonicalMap {
    id: number;
    inboundMessageTypeId: number;
    inboundMessageType : InboundMessageType
    inboundPartnerId: number;
    taskId: string;
    createdBy: string;
    createTimestamp: string;    
    updatedBy: string;
    updateTimestamp: string;
    canonicalMapXsltId: number;
    canonicalMapXslt: CanonicalMapXslt;
}
  