<fieldset class="border p-2" *ngIf="rs.getNestedFormControlValue(form, 'preFormatting', 'preFormattingClassId')">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Pre-formatting</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Class name:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ utilService.getClassName(rs.getNestedFormControlValue(form, 'preFormatting', 'preFormattingClassName'))  }}</label>
        </div>
        <div class="col-sm-2">
            <label><b>Description:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'preFormatting', 'preFormattingClassDescription')  }}</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Root tag:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'preFormatting', 'rootTag')  }}</label>
        </div>
        <div class="col-sm-2">
        </div>
        <div class="col-sm-3">
        </div>
    </div>
</fieldset>