import { Component, Input } from '@angular/core';
import { UtilService } from 'src/app/common-services/util.service';
import { Enrichment } from 'src/app/models/enrichment';

@Component({
  selector: 'review-enrichment',
  templateUrl: './review-enrichment.component.html',
})
export class ReviewEnrichmentComponent {
    @Input() enrichments: Array<Enrichment>;

    public enricherColumns = [
      { header: 'Enricher', field: 'enricher' },
      { header: 'Description', field: 'description' },
      { header: 'Order', field: 'order' }
    ];
    public enricherData: Array<any> = null;

    constructor(public utilService: UtilService) {}

    ngOnInit(): void {
       // Populate enrichement grid data
       if(this.enrichments != undefined) {
        this.enricherData = new Array<any>;
        this.enrichments.forEach(e => {
          this.enricherData.push({ enricher: this.utilService.getClassName(e.enrichmentClass.enricher), description: e.enrichmentClass.description, order: e.order })
        });
      }
    }
}
