import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MDSourceInputService } from '../md-source-input.service';

@Component({
  selector: 'source-mq-input',
  templateUrl: './source-mq.component.html',
})
export class SourceMqComponent implements OnInit {
  @Input() formGroupName: string;
  @Input() isEdit: boolean;

  public sourceMqProfileForm: FormGroup;

    constructor(private sourceInputService: MDSourceInputService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.sourceMqProfileForm.controls; }

  ngOnInit(): void {
    this.sourceMqProfileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }
}
