<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Response resolver</span></legend>
    <div style="margin-bottom: 10px;">
        <span class="text-muted"><small>Response Resolver is used if the sending party requests a specific format on the returned response. If no resolver is selected it will not be used in the configuration.</small></span>
    </div>  

    <div class="form-group row" *ngIf="responseResolver.isExisting">
        <label for="responseResolverDDL" class="col-sm-2 col-form-label control-label">Response resolver</label>
        <div class="col-sm-6">
          <select dropdown (change)="selectChangeHandler($event)" id="responseResolverDDL" name="responseResolverDDL" class="form-select form-select-sm control-label" [(ngModel)]="responseResolver.responseResolver.responseResolverClass">
            <option value="">--- Select response resolver ---</option>
            <option *ngFor="let item of responseResolversDDL" [value]="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
    </div>
</fieldset>