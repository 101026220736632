<div>
    <table class='table table-striped table-bordered table-sm' aria-labelledby="tableLabel" *ngIf="pagedCustomerConfigurations">
        <thead>
            <tr>
                <th>
                  <div>Partner</div>
                  <div>
                    <input type="text" class="form-control" id="partnerName" name="partnerName" placeholder="Filter partner" [(ngModel)]="filterPartner" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Message type</div>
                  <div>
                    <input type="text" class="form-control" id="messageTypeName" name="messageTypeName" placeholder="Filter message type" [(ngModel)]="filterMessageTypeName" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Sender</div>
                  <div>
                    <input type="text" class="form-control" id="senderName" name="senderName" placeholder="Filter sender" [(ngModel)]="filterSender" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Receiver</div>
                  <div>
                    <input type="text" class="form-control" id="receiverName" name="receiverName" placeholder="Filter receiver" [(ngModel)]="filterReceiver" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Legal entity</div>
                  <div>
                    <input type="text" class="form-control" id="legalEntity" name="legalEntity" placeholder="Filter legal entity" [(ngModel)]="filterLegalEntity" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Area plant</div>
                  <div>
                    <input type="text" class="form-control" id="areaPlant" name="areaPlant" placeholder="Filter area plant" [(ngModel)]="filterAreaPlant" (keyup)="onFilter()">
                  </div>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cc of pagedCustomerConfigurations">
                <td>{{ cc.partnerName }}</td>
                <td>{{ cc.messageName }}</td>            
                <td>{{ cc.senderName }}</td>
                <td>{{ cc.receiverName }}</td>
                <td>{{ cc.legalEntityName }}</td>
                <td>{{ cc.areaPlantName }}</td>
                <td>
                  <button class="btn btn-outline-secondary btn-sm" (click)="selectConfig(cc)" style="margin-right: 5px;">Select</button>
                </td>
            </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">
              <div class="pull-left">
                <nav>
                  <ul class="pagination">
                    <li class="page-item" ng-class="{disabled: currentPage == 1}">
                      <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#" (click)="$event.preventDefault(); onclickPage(currentPage)">{{currentPage}}</a></li>
                    <li class="page-item"><a class="page-link" href="#" (click)="$event.preventDefault(); onclickPage(currentPage + 1)">{{currentPage + 1}}</a></li>
                    <li class="page-item"><a class="page-link" href="#" (click)="$event.preventDefault(); onclickPage(currentPage + 2)">{{currentPage + 2}}</a></li>
                    <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                      <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                    </li>
                  </ul>
                </nav>
              </div>        
              <div class="pull-right">
                <label style="margin-right: 5px;">{{(((currentPage + 1) - 1) * 10) + 1}} - {{lastDisplayIndex}} of {{configurationCount}}</label>
              </div>
            </td>
          </tr>
        </tfoot>
    </table>
  </div>