import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
    selector: 'address-info',
    templateUrl: './address-info.component.html',
})
export class AddressInfoComponent implements OnInit {
    // @Input() form: FormGroup;
    // userForm : FormGroup;
    // formSubmitted : boolean = false;
    @Input() formGroupName: string;
    form: FormGroup;
    public addressLine1: string = '';
    
    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        // this.userForm = this.formBuilder.group(
        //     {
        //         firstName: new FormControl("", [Validators.required]),
        //     }            
        //   );
    }


    get formA() { return this.form.controls; }

    // get form() { return this.userForm.controls; }


}


