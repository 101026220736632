import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { WizardDuplicatesResponse } from 'src/app/models/wizard-duplicates-response';
import { WizardRequestModel } from 'src/app/models/wizard-request-model';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ReviewwInputService {
  public baseUrlLocal: string = "";

  constructor(private http: HttpClient) {
    this.baseUrlLocal = environment.apiBaseUrl;
  }

  public getEDITypeName(ediType: number) {
    let ediTypeName = '';
    switch(ediType) { 
      case 1: { 
        ediTypeName = 'EDIFACT';
         break; 
      }
      case 2: { 
        ediTypeName = 'X12';
         break; 
      }         
      default: { 
        ediTypeName = 'None'
        break; 
      } 
    }

    return ediTypeName;
  }

  getNestedFormControlValue(form: FormGroup, groupName: string, controlName: string): any {
    const group = form.get(groupName) as FormGroup;
    return group.get(controlName)?.value;
  }
}
