import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
    selector: 'entitymap-info',
    templateUrl: './entitymap-info.component.html',
})
export class EntitymapInfoComponent implements OnInit {
    // @Input() form: FormGroup;
    // userForm : FormGroup;
    // formSubmitted : boolean = false;
    // @Input() formGroupName: string;
    // form: FormGroup;

    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        //this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        // this.userForm = this.formBuilder.group(
        //     {
        //         firstName: new FormControl("", [Validators.required]),
        //     }            
        //   );
    }

    // get form() { return this.userForm.controls; }


}


