import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ViewConfigurationModelService {
    public getYesNo(property: boolean) {
        return property === true ? 'YES' : 'NO';
    }

    public getValidationType(type: number) {
      let typeName = '';
      switch(type) { 
        case 0: { 
          typeName = 'None';
           break; 
        }
        case 1: { 
          typeName = 'Xml';
           break; 
        } 
        case 2: { 
          typeName = 'Xsd';
           break; 
        }
        case 2: { 
          typeName = 'Json';
           break; 
        }      
        default: { 
           break; 
        } 
      }
  
      return typeName;
    }

    public getProtocolName(protocol: number) {
      let protocolName = '';
      switch(protocol) { 
        case 9: { 
          protocolName = 'HTTP';
           break; 
        }
        case 1: { 
          protocolName = 'Service bus';
           break; 
        } 
        case 3: { 
          protocolName = 'FTP';
          break; 
        } 
        case 2: { 
          protocolName = 'Fileshare';
           break; 
        } 
        case 7: { 
          protocolName = 'MQ';
           break; 
        }
        case 8: { 
          protocolName = 'SFTP';
           break; 
        } 
        default: { 
           break; 
        } 
      }
  
      return protocolName;
    }

    public getClassName(namespaceAndClass: string) {
      if(namespaceAndClass != undefined) {
        let splitted = namespaceAndClass.split(".");
        return splitted[splitted.length - 1];
      }
      
      return '';
    }

    public getTypeText(type: number) {
      return type == 0 ? 'PRIMARY' : 'SECONDARY';
    }

    public getFormatType(formaType: number) {
      let formatTypeName = '';
        switch(formaType) { 
          case 0: { 
            formatTypeName = 'None';
             break; 
          }
          case 1: { 
            formatTypeName = 'PlainText';
             break; 
          } 
          case 2: { 
            formatTypeName = 'Base64';
             break; 
          } 
          case 3: { 
            formatTypeName = 'Binary';
             break; 
          }  
          default: { 
             break; 
          } 
        }
    
        return formatTypeName;
    }

    public getEDITypeName(ediType: number) {
      let ediTypeName = '';
      switch(ediType) { 
        case 1: { 
          ediTypeName = 'EDIFACT';
           break; 
        }
        case 2: { 
          ediTypeName = 'X12';
           break; 
        }         
        default: { 
          ediTypeName = 'None'
          break; 
        } 
      }
  
      return ediTypeName;
    }
}
