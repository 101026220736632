
export class CanonicalMapXslt {
    id: number;
    name: string;
    description: string;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
    canonicalMapXsltId: number;
    modelType: number;
    modelTypeVersion: number;    
    mapContentLink: string;
}
