import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ReviewwInputService } from '../review-input.service';

@Component({
  selector: 'review-edi-lookup',
  templateUrl: './review-edi-lookup.component.html',
})
export class ReviewEdiLookupComponent {
  public form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, public rs: ReviewwInputService) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.form;
  }
}
