import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'review-mapping',
  templateUrl: './review-mapping.component.html',
})
export class ReviewMappingComponent implements OnInit {
    form!: FormGroup;

    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
      this.form = this.rootFormGroup.form;
    }
}

