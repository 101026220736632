<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Destinations</span></legend>
    <div class="row mb5">
        <div class="col-sm-12">
            <table class="table table-striped table-bordered">
                <thead>                    
                    <tr>
                        <th>Description</th>
                        <th style="max-width: 50px;">Protocol</th>
                        <th style="max-width: 50px;">Enabled</th>
                        <th style="max-width: 50px;">Remove BOM</th>
                        <th style="max-width: 60px;">Type</th>
                        <th>Details</th>                            
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="confDestConnections.length === 0">
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                    </tr>
                    <tr *ngFor="let cc of confDestConnections">
                        <td>{{ cc.confDestination.description }}</td> 
                        <td style="max-width: 50px;">{{ viewService.getProtocolName(cc.confDestination.protocol) }}</td>
                        <td style="max-width: 50px;">{{ viewService.getYesNo(cc.enabled) }}</td>            
                        <td style="max-width: 50px;">{{ viewService.getYesNo(cc.removeBom) }}</td>
                        <td style="max-width: 60px;">{{ viewService.getTypeText(cc.confDestination.type) }}</td>
                        <td>
                            <div *ngIf="cc.confDestination.destinationMqprofile !== null">
                                <row-display [label]="'Host:'" [value]="cc.confDestination.destinationMqprofile.host"></row-display>
                                <row-display [label]="'Port:'" [value]="cc.confDestination.destinationMqprofile.port"></row-display>
                                <row-display [label]="'Queue manager:'" [value]="cc.confDestination.destinationMqprofile.qm"></row-display>
                                <row-display [label]="'Channel:'" [value]="cc.confDestination.destinationMqprofile.channel"></row-display>
                                <row-display [label]="'Queue:'" [value]="cc.confDestination.destinationMqprofile.queue"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationMqprofile.keyVaultProfile != null" [label]="'Key vault:'" [value]="cc.confDestination.destinationMqprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationMqprofile.keyVaultProfile != null" [label]="'Secret name:'" [value]="cc.confDestination.destinationMqprofile.keyVaultProfile.secretName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationMqprofile.keyVaultProfile != null" [label]="'Secret description:'" [value]="cc.confDestination.destinationMqprofile.keyVaultProfile.name"></row-display>
                            </div>
                            <div *ngIf="cc.confDestination.destinationSbprofile !== null">
                                <row-display [label]="'Namespace:'" [value]="cc.confDestination.destinationSbprofile.namespace"></row-display>
                                <row-display [label]="'Queue:'" [value]="cc.confDestination.destinationSbprofile.queue"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationSbprofile.keyVaultProfile != null" [label]="'Key vault:'" [value]="cc.confDestination.destinationSbprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationSbprofile.keyVaultProfile != null" [label]="'Secret name:'" [value]="cc.confDestination.destinationSbprofile.keyVaultProfile.secretName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationSbprofile.keyVaultProfile != null" [label]="'Secret description:'" [value]="cc.confDestination.destinationSbprofile.keyVaultProfile.name"></row-display>
                            </div>
                            <div *ngIf="cc.confDestination.destinationHttpprofile !== null">
                                <row-display [label]="'Host:'" [value]="cc.confDestination.destinationHttpprofile.host"></row-display>
                                <row-display [label]="'Port:'" [value]="cc.confDestination.destinationHttpprofile.port"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationHttpprofile.keyVaultProfile != null" [label]="'Key vault:'" [value]="cc.confDestination.destinationHttpprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationHttpprofile.keyVaultProfile != null" [label]="'Secret name:'" [value]="cc.confDestination.destinationHttpprofile.keyVaultProfile.secretName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationHttpprofile.keyVaultProfile != null" [label]="'Secret description:'" [value]="cc.confDestination.destinationHttpprofile.keyVaultProfile.name"></row-display>
                                <row-display [label]="'Transfer queue:'" [value]="cc.confDestination.destinationHttpprofile.path"></row-display>
                            </div>
                            <div *ngIf="cc.confDestination.destinationSharedFolderProfile !== null">
                                <row-display [label]="'Server:'" [value]="cc.confDestination.destinationSharedFolderProfile.server"></row-display>
                                <row-display [label]="'Path:'" [value]="cc.confDestination.destinationSharedFolderProfile.path"></row-display>
                                <row-display [label]="'Filter:'" [value]="cc.confDestination.destinationSharedFolderProfile.filter"></row-display>
                                <row-display [label]="'Encoding:'" [value]="cc.confDestination.destinationSharedFolderProfile.encoding"></row-display>
                                <row-display [label]="'Filename:'" [value]="cc.confDestination.destinationSharedFolderProfile.filename"></row-display>
                            </div>
                            <div *ngIf="cc.confDestination.destinationSftpprofile !== null">
                                <row-display [label]="'Name:'" [value]="cc.confDestination.destinationSftpprofile.name"></row-display>
                                <row-display [label]="'Host:'" [value]="cc.confDestination.destinationSftpprofile.host"></row-display>
                                <row-display [label]="'Port:'" [value]="cc.confDestination.destinationSftpprofile.port"></row-display>
                                <row-display [label]="'Path:'" [value]="cc.confDestination.destinationSftpprofile.path"></row-display>
                                <row-display [label]="'Temporary path:'" [value]="cc.confDestination.destinationSftpprofile.tempPath"></row-display>
                                <row-display [label]="'Encoding:'" [value]="cc.confDestination.destinationSftpprofile.encoding"></row-display>
                                <row-display [label]="'Filename:'" [value]="cc.confDestination.destinationSftpprofile.filename"></row-display>
                                <row-display [label]="'Username:'" [value]="cc.confDestination.destinationSftpprofile.userName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationSftpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="cc.confDestination.destinationSftpprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationSftpprofile.keyVaultProfileId != undefined" [label]="'Secret:'" [value]="cc.confDestination.destinationSftpprofile.keyVaultProfile.secretName"></row-display>
                            </div>
                            <div *ngIf="cc.confDestination.destinationFtpprofile !== null">
                                <row-display [label]="'Name:'" [value]="cc.confDestination.destinationFtpprofile.name"></row-display>
                                <row-display [label]="'Host:'" [value]="cc.confDestination.destinationFtpprofile.host"></row-display>
                                <row-display [label]="'Port:'" [value]="cc.confDestination.destinationFtpprofile.port"></row-display>
                                <row-display [label]="'Path:'" [value]="cc.confDestination.destinationFtpprofile.path"></row-display>
                                <row-display [label]="'Temporary path:'" [value]="cc.confDestination.destinationFtpprofile.tempPath"></row-display>
                                <row-display [label]="'Encoding:'" [value]="cc.confDestination.destinationFtpprofile.encoding"></row-display>
                                <row-display [label]="'Filename:'" [value]="cc.confDestination.destinationFtpprofile.filename"></row-display>
                                <row-display [label]="'Username:'" [value]="cc.confDestination.destinationFtpprofile.userName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationFtpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="cc.confDestination.destinationFtpprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="cc.confDestination.destinationFtpprofile.keyVaultProfileId != undefined" [label]="'Secret:'" [value]="cc.confDestination.destinationFtpprofile.keyVaultProfile.secretName"></row-display>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</fieldset>