  <h3>Grid view example</h3>
  <grid-view [data]="form">
    <grid-view-column field="id" title="Id"></grid-view-column>
    <grid-view-column field="name" title="Name"></grid-view-column>
    <grid-view-column field="age" title="Age"></grid-view-column>
    <grid-view-column field="city" title="City"></grid-view-column>
    <grid-view-command-column title="Command" [defaultTemplate]="defaultCommandTemplate"></grid-view-command-column>
  </grid-view>

  <ng-template #defaultCommandTemplate let-row let-removeClicked="removeClicked" let-editClicked="editClicked">
    <button class="btn btn-outline-dark" (click)="editClicked(row)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
    <button class="btn btn-outline-dark" (click)="removeClicked(row)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
  </ng-template>


  <!-- <ng-template #defaultCommandTemplate let-row>
    <button class="btn btn-outline-dark" (click)="removeClicked(row)">Remove</button>
    
  </ng-template> -->

<!-- <button class="btn btn-outline-dark" (click)="editClicked(row)">Edit</button> -->
  <!-- <button class="btn btn-outline-dark" (click)="editClicked(row)">Edit</button> -->
  <!-- <kendo-grid-command-column title="command" [width]="220">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand [primary]="true">Edit</button>
        <button kendoGridRemoveCommand>Remove</button>
        <button kendoGridSaveCommand [disabled]="formGroup.invalid">{{ isNew ? 'Add' : 'Update' }}</button>
        <button kendoGridCancelCommand>{{ isNew ? 'Discard changes' : 'Cancel' }}</button>
    </ng-template>
</kendo-grid-command-column> -->
