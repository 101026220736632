import { Component, Input } from '@angular/core';
import { ViewConfigurationModelService } from 'src/app/common-components/view-configuration/view-configuration-modal.service';
import { Scheduler } from 'src/app/models/source/scheduler';

@Component({
  selector: 'review-source',
  templateUrl: './review-source.component.html',
})
export class ReviewSourceComponent {
  @Input() schedulers: Array<Scheduler>

  constructor(public viewService: ViewConfigurationModelService) {}
}
