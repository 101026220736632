import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Partner } from 'src/app/models/partner/partner';
import { MDDestinationService } from './md-destination.service';
import { ConfDestination } from 'src/app/models/destination/conf-destination';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'add-destination',
  templateUrl: './add-destination-model.component.html'
})
export class AddDestinationModalComponent implements OnInit {
    @Input() partner: Partner;
    public destinationForm: FormGroup;

    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;

    constructor(public activeModal: NgbActiveModal, public destinationService: MDDestinationService, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        this.destinationForm = this.destinationService.getEmptyDestinationForm(this.partner.id);
    }

    get form() { return this.destinationForm.controls; }

    public save() {
        let confDestination: ConfDestination = this.destinationService.getConfDestinationRequestModel(this.destinationForm);
        this.destinationService.setupProfileRequestModel(this.destinationForm, confDestination, false);
        
        this.showSpinner = true;

        this.destinationService.addDestination(confDestination).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
                }
        );
    }

    selectProtocolChangeHandler(event: any) {
        this.destinationService.setupDestinationFormGroup(this.destinationForm);
    }

    // TODO Remove after testing
    getValidators(control: AbstractControl): string {
        const validatorFns = control.validator ? [control.validator] : [];
    
        if (control.asyncValidator) {
          validatorFns.push(control.asyncValidator);
        }
    
        return validatorFns
          .map(validatorFn => this.mapValidatorFnToName(validatorFn))
          .filter(name => !!name)
          .join(', ');
    }
    
    private mapValidatorFnToName(validatorFn: any): string {
        if (validatorFn === Validators.required) {
          return 'required';
        }
        if (validatorFn === Validators.email) {
          return 'email';
        }
        if (validatorFn === Validators.minLength) {
          return 'minLength';
        }
        if (validatorFn === Validators.maxLength) {
          return 'maxLength';
        }
        if (validatorFn === Validators.pattern) {
          return 'pattern';
        }
        // Add more cases for other validators as needed
    
        return 'customValidator';
    }
}
