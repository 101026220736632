import { Component, OnInit, Inject } from '@angular/core';
import { Partner } from 'src/app/models/partner/partner';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataResolverService } from './md-resolver.service';
import { ResolverClass } from 'src/app/models/resolver/resolver-class';
import { GridView } from 'src/app/models/grid-view';
import { PagingService } from 'src/app/common-services/paging.service';
import { UtilService } from 'src/app/common-services/util.service';
import { AddResolverClassComponent } from './child-components/add-resolver-class.component';
import { EditResolverClassComponent } from './child-components/edit-resolver-class.component';
import { DeleteResolverClassComponent } from './child-components/delete-resolver-class.component';
import { Paging } from 'src/app/models/paging/paging';

@Component({
    selector: 'md-partners',
    templateUrl: './md-resolver.component.html',
  })
export class MasterDataResolverComponent  implements OnInit {
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = true;
    public filterResolver: string = '';
    public filterDescription: string = '';
    public gridView: GridView<ResolverClass>;
    public resolverPaging: Paging = new Paging();

    constructor(public rService: MasterDataResolverService, private authService: MsalService, public utilService: UtilService, private modalService: NgbModal, public pService: PagingService) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        this.getData();
    }

    private getData() {
        this.rService.getResolverClasses(this.resolverPaging.currentPage, this.resolverPaging.pageCount, this.filterResolver, this.filterDescription).subscribe(
        {
          next: (result) => {
            this.gridView = result;
            this.resolverPaging.setLastDisplayIndex();
            this.resolverPaging.totalPages = Math.floor(this.gridView.totalRows/this.resolverPaging.pageCount) + (this.gridView.totalRows % this.resolverPaging.pageCount > 0 ? 1 : 0);
            this.showSpinner = false; 
          },
          error: (error) => { 
            this.setErrorText(error);
            this.showSpinner = false; 
          }
        });
    }

    public onFilter() {
        this.resolverPaging.currentPage = 1;
        this.getData();
    }

    public createResolver() {
        const modalRef = this.modalService.open(AddResolverClassComponent, { size: 'size_40' });
        modalRef.result.then((result) => {            
            if(result === 'save') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public editResolver(gridRow: ResolverClass) {
        const modalRef = this.modalService.open(EditResolverClassComponent, { size: 'size_40' });
        modalRef.componentInstance.resolverClass = gridRow;
        modalRef.result.then((result) => {
            if(result === 'save') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public removeResolver(gridRow: ResolverClass) {
        const modalRef = this.modalService.open(DeleteResolverClassComponent, { size: 'size_40' });
        modalRef.componentInstance.resolverClass = gridRow;
        modalRef.result.then((result) => {            
            if(result === 'delete') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }
  
    private setErrorText(error: any) {
        this.error = true;
        let msg: string = '';
        let title: string = error?.error?.title;
        msg = (title !== undefined) ? title : error?.error;
        this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
        console.error(error); 
    }

    // --- Paging ----------------------------------------------------------------------------------  
    public onclickPrev() {
        if (this.resolverPaging.currentPage > 1) {
            this.resolverPaging.currentPage = this.resolverPaging.currentPage - 1;
            this.resolverPaging.setStartEndIndex();
            this.getData();      
        }
    }

    public onclickNext() {
        if ((this.resolverPaging.currentPage) * this.resolverPaging.pageCount < this.gridView.totalRows) {
            this.resolverPaging.nextButtonDisabled = false;
            this.resolverPaging.currentPage = this.resolverPaging.currentPage + 1;
            this.resolverPaging.setStartEndIndex();
            this.getData();
        } else {
            this.resolverPaging.nextButtonDisabled = true;
        }
    }

    public onclickPage(page: number, index: number) {
        this.resolverPaging.currentPage = this.resolverPaging.currentPage === page ? this.resolverPaging.currentPage : page;
        this.resolverPaging.setStartEndIndex();
        this.getData();
    }
    
    selectChangeHandler(event: any) {
        this.resolverPaging.currentPage = 1;
        this.resolverPaging.setStartEndIndex();      
        this.getData();
    }
}
