<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div>
    <fieldset class="border p-2">
        <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Canonical => Outbound Mapping Connections</span></legend>

        <div style="margin-bottom: 10px;">
            <span class="text-muted"><small>Canonical => Outbound-documents are used to transform incomming data to a canonical format</small></span>
        </div>

          <hr />

        <div><fieldset class="border p-2 mb10 mt10" *ngIf="ctomcGridList">
  <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Connected Canonical => Outbound-documents</span></legend>
  <div>
  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="ctomcGridList">
    <thead>
      <tr>
        <th scope="col" colSpan="8">
          <div>
            <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="addPartnerCanonicalToOutboundMappingConnection()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
        </th>
      </tr>
    </thead>
    <thead>
        <tr>
            <th>
              <div>Canonical => Outbound Mapping Document</div>
            </th>
            <th>
              <div>Created by</div>
            </th>
            <th>
              <div>Created at</div>
            </th>
            <th>
              <div>Updated by</div>
            </th>
            <th>
              <div>Updated at</div>
            </th>
            <th>
              <div></div>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="ctomcGridList != undefined && ctomcGridList.length === 0">
        <tr>
            <td scope="col" colSpan="7">No Canonical => Outbound mapping-documents configured</td>
        </tr>
    </tbody>
    <tbody *ngIf="ctomcGridList!= undefined && ctomcGridList.length > 0">
        <tr *ngFor="let ctomc of ctomcGridList;let index = index;">
            <td>
              <span>{{ctomc.outboundMapXslt.name}}</span>
            </td>
            <td>
              <span>{{ctomc.createdBy}}</span>
            </td>
            <td>
              <span>{{ctomc.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
            </td>
            <td>
              <span>{{ctomc.updatedBy}}</span>
            </td>
            <td>
              <span>{{ctomc.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
            </td>
            <td>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="editPartnerCanonicalToOutboundMappingConnection(ctomc)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="removePartnerCanonicalToOutboundMappingConnection(ctomc)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
            </td>
        </tr>
    </tbody>
    <tfoot>
    </tfoot>
</table>
</div>
</fieldset>
<br />
</div>
</fieldset>
</div>
