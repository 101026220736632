import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InboundMessageType } from 'src/app/models/message-type';
import { MessageTypeService } from '../message-type.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'edit-inbound-message-type',
  templateUrl: './edit-inbound-message-type.component.html',
})
export class EditInboundMessageTypeComponent implements OnInit {
    @Input() inboundMessageType: InboundMessageType;
    public inboundMessageTypeForm: FormGroup;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;

    constructor(public mtService: MessageTypeService, public activeModal: NgbActiveModal, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        this.inboundMessageTypeForm = this.mtService.getInboundMessageTypeForm(this.inboundMessageType);

        // Subscribe on value changes for messageName input control to force to uppercase
        this.inboundMessageTypeForm.get('messageName').valueChanges.subscribe((value: string) => {
            const formattedValue = this.mtService.formatValue(value);
            this.inboundMessageTypeForm.get('messageName').setValue(formattedValue, { emitEvent: false });
        });
    }

    get form() { return this.inboundMessageTypeForm.controls; }

    public save() {
        let inboundMessageType = this.mtService.getInboundMessageTypeModel(this.inboundMessageTypeForm);

        if(this.inboundMessageTypeForm.valid){
            this.mtService.updateInboundMessageType(inboundMessageType).subscribe(
                {
                    next: (result) => {
                        this.activeModal.close('save');
                    },
                    error: (error) => {
                        this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                    }
                 }
            );
        }        
    }
}
