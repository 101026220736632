import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
// import { EntityMap } from 'src/app/models/entity-map';

@Component({
  selector: 'review-entitymap',
  templateUrl: './review-entitymap.component.html',
})
export class ReviewEntitymapComponent implements OnInit {
  //@Input() entityMap: EntityMap;
  form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.form;
  }
}
