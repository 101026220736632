import { Component, Input, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { AS2ConfIn } from '../../../../models/edi/as2-conf-In';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { DropDownListModelNumber } from 'src/app/models/dropdown-list-model';

@Component({
  selector: 'as2-inbound-input',
  templateUrl: './as2-inbound-input.component.html',
})
export class As2InboundInputComponent implements OnInit {
    @Input() formGroupName: string;
    public aS2ConfInForm: FormGroup;
  
    public as2formats: Array<DropDownListModelNumber> = [    
        { value: 0, text: "None" },
        { value: 1, text: "Plain text" },
        { value: 2, text: "Base64" },
        { value: 3, text: "Binary" },        
    ];

    constructor(private rootFormGroup: FormGroupDirective) { }

    public aS2ConfIn: AS2ConfIn = new AS2ConfIn();
    public requestFormat: string = '';
    public mdnformat: string = '';
    public ackFormat: string = '';
    public ackEndpoint: string = '';
    public ackEnabled: boolean = false;

    ngOnInit(): void {
        this.aS2ConfInForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        let requestFormatId: number = +this.aS2ConfInForm.get('requestFormat')?.value;
        let mdnformatId: number = +this.aS2ConfInForm.get('mdnformat')?.value;
        let ackFormatId: number = +this.aS2ConfInForm.get('ackFormat')?.value;
        this.ackEndpoint = this.aS2ConfInForm.get('ackEndpoint')?.value;
        this.ackEnabled = this.aS2ConfInForm.get('ackEnabled')?.value;

        this.requestFormat = this.as2formats.find(x => x.value === requestFormatId)?.text;
        this.mdnformat = this.as2formats.find(x => x.value === mdnformatId)?.text;
        this.ackFormat = this.as2formats.find(x => x.value === ackFormatId)?.text;
    }
}
