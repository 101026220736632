<div class="modal-header">
    <h4 class="modal-title">Delete this Edi Pre-formattera for partner: {{partner.partnerId}}</h4>
</div>

<div class="modal-body">
    Are you really sure you want to delete the Edi Pre-formatter for Partner {{partner.partnerId}}, with regex
    {{ediPreFormatter.regEx}}?
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>