import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { DestinationSbprofile } from 'src/app/models/destination/destination-sb-profile';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class SbProfileService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getAllSbProfiles(): Observable<Array<DestinationSbprofile>> {
        return this._http.get<Array<DestinationSbprofile>>(`${this.baseUrlLocal}confdestinations/sbprofiles`);
    }

    public setupRequiredValidation(destinationSbprofileForm: FormGroup) {
        const id = destinationSbprofileForm.get('id');
        const namespace = destinationSbprofileForm.get('namespace');
        const queue = destinationSbprofileForm.get('queue');
        const sharedAccessKeyName = destinationSbprofileForm.get('sharedAccessKeyName');

        if(destinationSbprofileForm.value.useExistingDestination === true) {
            id.setValue('');
            id.setValidators([Validators.required]);
            namespace.setValidators(null);
            queue.setValidators(null);
            sharedAccessKeyName.setValidators(null);
            
        } else {
            id.setValue(0);
            id.setValidators(null); // We could have required here as we set the id to 0 that is a value, but as we hard code...naahh
            namespace.setValidators([Validators.required]);            
            queue.setValidators([Validators.required]);            
            sharedAccessKeyName.setValidators([Validators.required]);            
        }
        id.updateValueAndValidity();
        namespace.updateValueAndValidity();
        queue.updateValueAndValidity();
        sharedAccessKeyName.updateValueAndValidity();
    }
}
