import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataMappingDocumentsComponent } from './md-mapping-documents.component';
import { MasterDataInboundToCanonicalMappingDocumentsComponent } from './child-components/md-inbound-to-canonical-mapping-documents.component';
import { MappingDocumentService } from './mapping-document.service';
import { MasterDataCanonicalToOutboundMappingDocumentsComponent } from './child-components/md-canonical-to-outbound-mapping-documents.component';
import { AppCanonicalToOutboundMappingDocumentComponent } from './child-components/add-canonical-to-outbound-mapping-documents.component';
import { DeleteCanonicalToOutboundMappingDocumentComponent } from './child-components/delete-canonical-to-outbound-mapping-documents.component';
import { EditCanonicalToOutboundMappingDocumentComponent } from './child-components/edit-canonical-to-outbound-mapping-documents.component';
import { AppInboundToCanonicalMappingDocumentComponent } from './child-components/add-inbound-to-canonical-mapping-documents.component';
import { EditInboundToCanonicalMappingDocumentComponent } from './child-components/edit-inbound-to-canonical-mapping-documents.component';
import { ErrorDisplayModule } from 'src/app/common-components/error-display/error-display.module';
import { DeleteInboundToCanonicalMappingDocumentComponent } from './child-components/delete-inbound-to-canonical-mapping-documents.component';

@NgModule({
  imports:      [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule,
    ErrorDisplayModule
  ],
  providers:    [ MappingDocumentService ],
  declarations: [
    MasterDataMappingDocumentsComponent,
    MasterDataInboundToCanonicalMappingDocumentsComponent,
    MasterDataCanonicalToOutboundMappingDocumentsComponent,
    AppInboundToCanonicalMappingDocumentComponent,
    AppCanonicalToOutboundMappingDocumentComponent,
    DeleteCanonicalToOutboundMappingDocumentComponent,
    DeleteInboundToCanonicalMappingDocumentComponent,
    EditCanonicalToOutboundMappingDocumentComponent,
    EditInboundToCanonicalMappingDocumentComponent
  ]
})
export class MasterDataMappingDocumentsModule { }
