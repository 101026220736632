import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'source-fileshare-input',
  templateUrl: './source-fileshare.component.html',  
})
export class SourceFileshareComponent implements OnInit{
  @Input() formGroupName: string;
    @Input() isEdit: boolean;

    
    public sourceSharedFolderProfileForm: FormGroup;

    constructor(private rootFormGroup: FormGroupDirective) {}

    get form() { return this.sourceSharedFolderProfileForm.controls; }

    ngOnInit(): void {
      this.sourceSharedFolderProfileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;      
  }
}
