import { Component, Input, OnInit} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { MDSourceInputService } from '../md-source-input.service';


@Component({
  selector: 'source-sb-input',
  templateUrl: './source-sb.component.html'
})
export class SourceSbComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;

    public sourceSbProfileForm: FormGroup;
    public keyVaultProfiles: Array<KeyVaultProfile>;

    constructor(private keyVaultService: KeyVaultService, private sourceInputService: MDSourceInputService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.sourceSbProfileForm.controls; }
    
    ngOnInit(): void {
        this.sourceSbProfileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        // Subscribe on value changes for systemName input control to only allow a-z and 0-9
        this.sourceSbProfileForm.get('systemName').valueChanges.subscribe((value: string) => {
            const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
            if (sanitizedValue !== value) {
                this.sourceSbProfileForm.get('systemName').setValue(sanitizedValue, { emitEvent: false });
            }
        });

        this.keyVaultService.getKeyVaultProfiles().subscribe({
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            error: (error) => {
                console.error(error);
            }
        });
    }
}
