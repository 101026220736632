import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataResolverService } from '../md-resolver.service';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'add-resolver-class',
  templateUrl: './add-resolver-class.component.html'
})
export class AddResolverClassComponent implements OnInit {
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public resolverClassForm: FormGroup;
    
    constructor(public rService: MasterDataResolverService, public activeModal: NgbActiveModal, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        this.resolverClassForm = this.rService.getEmptyResolverClassForm();
    }

    get form() { return this.resolverClassForm.controls; }

    public save() {
        this.showSpinner = true;
        let model = this.rService.getResolverClassModel(this.resolverClassForm);
        
        this.rService.addResolverClass(model).subscribe(
            {
                next: (result) => {
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }
}
