export class InboundParserResponseModel {
    id: number;
    parser: string;
    parserClassId: number;
    inboundPartnerId: number;
    messageType: number;
    messageTypeName: string;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
    editMode: boolean;
    isNew: boolean;
    isDeleted: boolean;
    type: number;
    senderIdPath: string;
    receiverIdPath: string;
    primaryIdPath: string;
    referenceIdPath: string;
    taskIdPath: string;
    countryCodePath: string;
    customLookupIdPath: string;
    areaPlantIdPath: string;
    namespacesUri: string;
}
