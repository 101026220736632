export class OutboundMapXslt {
    id: number;
    name: string;
    description: string;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
    mapContentLink: string;
}
