export class SourceSharedFolderProfile {
    id: number;
    name: string;
    server: string;
    path: string;
    filter: string;
    createdBy: string;
    createTimestamp: string;
    fileName: string;
    inputEncoding: string;
    outputEncoding: string
}