import { Component, OnInit } from '@angular/core';
import { MappingDocumentModel } from './mapping-document-model';
import { MappingDocumentService } from './mapping-document.service';
import { TabModel } from 'src/app/models/tab-model';
@Component({
    selector: 'md-mapping-documents',
    templateUrl: './md-mapping-documents.component.html',
  })
export class MasterDataMappingDocumentsComponent  implements OnInit {
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = true;
    public mappingDocumentGridList: Array<MappingDocumentModel> = new Array<MappingDocumentModel>();
    public tabModel: TabModel = new TabModel();

    constructor(public mappingDocumentService: MappingDocumentService) {}

    ngOnInit(): void {

    }

    public onclickGoToTab(tab: number) {

        this.tabModel.activeTab = tab;
    }
}
