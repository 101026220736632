export class EntityMap {
    id: number;
    inboundMessageTypeId: number;
    outboundMessageTypeId: number;
    senderId: string;
    senderName: string;
    receiverId: string;
    receiverName: string;
    legalEntityId: string;
    legalEntityName: string;
    areaPlantId: string;
    areaPlantName: string;
    inboundPartnerId: number;
    outboundPartnerId: number;
    customLookupId: string;
    country: string;
    createdBy: string;
    updatedBy: string;
    createTimestamp: string;
    updateTimestamp: string;
    largeFilelimitKB: number;
    outboundMapXsltId: number;
}
