import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TrackingView } from '../models/tracking-view';
import { Observable } from 'rxjs';
import { Tracking } from '../models/tracking';
import { BlobRequest } from '../models/blob-request';
import { BlobResponse } from '../models/blob-response';

@Injectable({
  providedIn: 'root',
})
export class CorrelatedMessageService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getTrackingData(correlationId: string) : Observable<Array<TrackingView>> {
        let queryStart = '';
        let queryString = '';
    
        return this.http.get<Array<TrackingView>>(this.baseUrlLocal + `tracking/correlated/${correlationId}`);
    }

    // public getTrackingDataForFlowId(flowid: string, isArchive: boolean) : Observable<Array<Tracking>> {
    //     return this.http.get<Array<Tracking>>(this.baseUrlLocal + `tracking/${flowid}/${isArchive}`);
    // }

    // public getBlobData(blobRequest: BlobRequest) {
    //     return this.http.post<BlobResponse>(this.baseUrlLocal + 'blob', blobRequest);
    // }

    // private getQueryStart(queryStart: string) {
    //     if(queryStart === '') {
    //         return  '?';
    //     } else {
    //         return '&';
    //     }
    // }
}


