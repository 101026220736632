import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataResolverComponent } from './md-resolver.component';
import { MasterDataResolverService } from './md-resolver.service';
import { AddResolverClassComponent } from './child-components/add-resolver-class.component';
import { EditResolverClassComponent } from './child-components/edit-resolver-class.component';
import { DeleteResolverClassComponent } from './child-components/delete-resolver-class.component';
import { ErrorDisplayModule } from 'src/app/common-components/error-display/error-display.module';

@NgModule({
  imports:      [ 
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule,
    ErrorDisplayModule
  ],
  providers:    [ MasterDataResolverService ],
  declarations: [ MasterDataResolverComponent, AddResolverClassComponent, EditResolverClassComponent, DeleteResolverClassComponent ]
})
export class MasterDataResolverModule { }