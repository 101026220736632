import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { CanonicalMap } from 'src/app/models/mapping/canonical-map';
import { CanonicalMapXslt } from 'src/app/models/mapping/canonical-map-xslt';
import { Observable } from 'rxjs';
import { InboundMessageType } from 'src/app/models/message-type'

@Injectable({
  providedIn: 'root',
})
export class MDInboundMappingConnectionInputService {
  public baseUrlLocal: string = "";
  constructor(private http: HttpClient) {
    this.baseUrlLocal = environment.apiBaseUrl;
  }


  public getAllInboundToCanonicalDocuments(): Observable<Array<CanonicalMapXslt>> {
    return this.http.get<Array<CanonicalMapXslt>>(this.baseUrlLocal + 'inboundmappingsxslt');
  }

  public getPartnerInboundToCanonicalMappingConnections(inboundpartnerId: number): Observable<Array<CanonicalMap>> {
    return this.http.get<Array<CanonicalMap>>(`${this.baseUrlLocal}inboundmappings/${inboundpartnerId}`);
  }

  public addPartnerInboundToCanonicalMappingConnection(canonicalMap: CanonicalMap) {
    return this.http.post(this.baseUrlLocal + 'inboundmappings', canonicalMap);
  }

  public updatePartnerInboundToCanonicalMappingConnection(canonicalMap: CanonicalMap) {
    return this.http.put(this.baseUrlLocal + 'inboundmappings', canonicalMap);
  }

  public getAllInboundMessageTypes(): Observable<Array<InboundMessageType>> {
    return this.http.get<Array<InboundMessageType>>(this.baseUrlLocal + 'inboundmessagetypes');
  }

  public deletePartnerInboundToCanonicalMappingConnection(canonicalMap: CanonicalMap) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: canonicalMap
    };
    return this.http.delete(this.baseUrlLocal + 'inboundmappings', options);
  }
}
