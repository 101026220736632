import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataPrestepClassesComponent } from './md-prestep-classes.component';
import { MasterDataPrestepClassesService } from './md-prestep-classes.service';
import { AddPrestepClassComponent } from './child-components/add-prestep-class.component';
import { DeletePrestepClassComponent } from './child-components/delete-prestep-class.component';
import { EditPrestepClassComponent } from './child-components/edit-prestep-class.component';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@NgModule({
  imports:      [ 
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule 
  ],
  providers:    [ MasterDataPrestepClassesService, MasterDataPrestepClassesService, ErrorParserService ],
  declarations: [ MasterDataPrestepClassesComponent, AddPrestepClassComponent, AddPrestepClassComponent, DeletePrestepClassComponent, EditPrestepClassComponent ]
})
export class MasterDataPrestepClassesModule { }