<form [formGroup]="preFormattingForm">
  <fieldset class="border p-2">
      <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Pre-formatting</span></legend>
      <div style="margin-bottom: 10px;">
          <span class="text-muted"><small>Pre-formatter is used for transforming incomming messages from JSON to XML, or CSV to XML etc. If no formatter is selected it will not be used in the configuration.</small></span>
      </div>  

      <div class="form-group row" *ngIf="preFormatters">
          <label for="preFormattingDDL" class="col-sm-2 col-form-label control-label">Pre-formatter</label>
          <div class="col-sm-6">
            <select dropdown formControlName="preFormattingClassId" (change)="selectChangeHandler($event)" id="preFormattingDDL" name="preFormattingDDL" class="form-select form-select-sm control-label">
              <option value="">--- Select pre-formatter ---</option>
              <option *ngFor="let item of preFormatters" [value]="item.id.toString()">
                {{item.description}}
              </option>
            </select>
          </div>
      </div>

      <!-- rootTag -->
      <div class="form-group row mb5" *ngIf="preFormatters && form.preFormattingClassId.value != ''">
        <label for="country" class="col-sm-2 col-form-label">Root tag</label>
        <div class="col-sm-6">
            <input type="text" formControlName="rootTag" class="form-control" id="rootTag" name="rootTag" placeholder="rootTag" (change)="onRootTagChange($event)">
        </div>
      </div>
  </fieldset>
</form>