import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ResolverTesterService } from './resolver-tester.service';
import { ResolverTesterComponent } from './resolver-tester.component';

@NgModule({
  imports:      [ CommonModule, FormsModule ],
  providers:    [ ResolverTesterService ],
  declarations: [ ResolverTesterComponent ]
})
export class ResolverTesterModule { }