import { Component, Input, OnInit } from '@angular/core';
import { DestinationMqprofile } from '../../../../../models/destination/destination-mq-profile';
import { MqProfileService } from './mq-profile.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
    selector: "mq-profile",
    templateUrl: "./mq-profile.component.html"
})
export class MqProfileComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;
    
    public destinationMqprofileForm: FormGroup;
    public mqProfiles: Array<DestinationMqprofile>;

    constructor(private mqProfileService: MqProfileService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.destinationMqprofileForm.controls; }

    ngOnInit(): void {
        this.destinationMqprofileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        this.mqProfileService.getAllMqProfiles().subscribe({
            next: (result) => {
                this.mqProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });
    }

    public handleDestOptionsChange(event: any) {
        this.mqProfileService.setupRequiredValidation(this.destinationMqprofileForm);
    }
}