<h3 id="tableLabel">Track messages</h3>
<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="errorModel">
  <b>Error when reading tracking data. {{errorModel.errorMessage}}</b>
</div>

<div style="margin-bottom: 10px;">
  
</div>

<div class="mb10">
  <p>Search span is set to 90 days back. Use the filters to find the message you are looking for.</p>
</div>

<div class="row">
    <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="gridView.rows">
      <thead>
        <tr>
          <th scope="col" colSpan="11">
            <div>
              <button class="btn btn-outline-secondary btn-sm" (click)="refresh()" style="float: right;"><i class="fa-solid fa-rotate-right"></i></button>
            </div>
          </th>
        </tr>
      </thead>
         <thead>
            <tr>
                <th>
                  <div class="d-flex align-items-center">
                    <div class="row fixed80" style="margin-left: 1px; width: 110px;">Timestamp (utc)</div>
                    <div clss="row">
                      <button class="btn btn-outline-secondary btn-sm" (click)="showTimestampModal()"><i class="fa-solid fa-filter"></i></button>                    
                    </div>
                  </div>
                  <div style="height: 29px; font-weight: normal;" *ngIf="!filterStartDateTime">

                  </div>
                  <div style="height: 29px; font-weight: normal;" *ngIf="filterStartDateTime">
                    {{filterStartDateTime | date: 'yyyy-MM-dd HH:mm:ss'}} to {{filterEndDateTime | date: 'yyyy-MM-dd HH:mm:ss'}}
                  </div>
                </th>
                <th>
                  <div>Sender</div>
                  <div>
                    <input type="text" class="form-control" id="filterSender" name="filterSender" placeholder="Filter sender" [(ngModel)]="filterSender" (change)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Sender Id</div>
                  <div>
                    <input type="text" class="form-control" id="filterSenderId" name="filterSenderId" placeholder="Filter sender" [(ngModel)]="filterSenderId" (change)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Receiver</div>
                  <div>
                    <input type="text" class="form-control" id="filterReceiver" name="filterReceiver" placeholder="Filter receiver" [(ngModel)]="filterReceiver" (change)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Receiver Id</div>
                  <div>
                    <input type="text" class="form-control" id="filterReceiverId" name="filterReceiverId" placeholder="Filter receiver" [(ngModel)]="filterReceiverId" (change)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Message type</div>
                  <div>
                    <input type="text" class="form-control" id="messageType" name="messageType" placeholder="Filter message type" [(ngModel)]="filterMessageType" (change)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Flow Id</div>
                  <div>
                    <input type="text" class="form-control" id="flowId" name="flowId" placeholder="Filter flow Id" [(ngModel)]="filterFlowID" (change)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Primary Id</div>
                  <div>
                    <input type="text" class="form-control" id="filterPrimaryId" name="filterPrimaryId" placeholder="Filter primary Id" [(ngModel)]="filterPrimaryID" (change)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Inbound Partner Id</div>
                  <div>
                    <input type="text" class="form-control" id="filterPartner" name="filterPartner" placeholder="Filter inbound partner Id" [(ngModel)]="filterPartner" (change)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Status</div>
                  <div>
                    <select dropdown (change)="selectStatusChangeHandler($event)" id="statusDDL" name="statusDDL" class="form-select form-select-sm control-label" [(ngModel)]="filterStatus">
                        <option value="-1">--- Select status ---</option>
                        <option [value]="998">CANCELLED</option>
                        <option [value]="999">FAILED</option>
                        <option [value]="4">PARTIAL DONE</option>
                        <option [value]="2">PROCESSING</option>
                        <option [value]="3">SUCCESS</option>
                      </select>
                  </div>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let tv of gridView.rows">
                <td>{{ tv.timestamp | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                <td>{{ tv.sender }}</td>
                <td>{{ tv.senderId }}</td>
                <td>{{ tv.receiver }}</td>
                <td>{{ tv.receiverId }}</td>
                <td>{{ tv.messageType }}</td>
                <td>{{ tv.flowID }}</td>
                <td>{{ tv.primaryID }}</td>
                <td>{{ tv.partner }}</td>
                <td>
                    <span style="color: green;" *ngIf="tv.status === 'SUCCESS'" ngbTooltip="SUCCESS">
                        <i class="fa-solid fa-circle"></i>
                    </span>                    
                    <span style="color: red;" *ngIf="tv.status === 'FAILED'" ngbTooltip="FAILED">
                        <i class="fa-solid fa-circle"></i>
                    </span>
                    <span style="color: gray;" *ngIf="tv.status === 'CANCELLED'" ngbTooltip="CANCELLED">
                      <i class="fa-solid fa-circle"></i>
                  </span>
                  <span style="color: orange;" *ngIf="tv.status === 'PARTIAL'" ngbTooltip="PARTIAL">
                    <i class="fa-solid fa-circle"></i>
                  </span>  
                  <span style="color: lightblue;" *ngIf="tv.status === 'PROCESSING'" ngbTooltip="PROCESSING">
                    <i class="fa-solid fa-circle"></i>
                  </span>  
                </td>
                <td class="fixed80">
                  <button class="btn btn-outline-secondary btn-sm" (click)="openViewConfig(tv)" style="margin-right: 5px;"><i class="fa fa-magnifying-glass"></i></button>
                  <button class="btn btn-outline-secondary btn-sm" (click)="viewCorrelatedMessage(tv)" style="margin-right: 5px;"><i class="fa-solid fa-link"></i></button>
                </td>
            </tr>         
        </tbody>
        <tfoot>
          <tr>
            <td colspan="11">
              <div>
                <table>
                  <tr>
                    <td style="margin: auto;">
                      <nav>
                        <ul class="pagination">
                          <li class="page-item" ng-class="{disabled: currentPage == 1}">
                            <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                          </li>
                          <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(trackingPaging.currentPage, 1, trackingPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(trackingPaging.currentPage, 1, trackingPaging.totalPages), 1)">{{pService.getPageNumber(trackingPaging.currentPage, 1, trackingPaging.totalPages)}}</a></li>
                          <li class="page-item" *ngIf="trackingPaging.totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(trackingPaging.currentPage, 2, trackingPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(trackingPaging.currentPage, 2, trackingPaging.totalPages), 2)">{{pService.getPageNumber(trackingPaging.currentPage, 2, trackingPaging.totalPages)}}</a></li>
                          <li class="page-item" *ngIf="trackingPaging.totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(trackingPaging.currentPage, 3, trackingPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(trackingPaging.currentPage, 3, trackingPaging.totalPages), 3)">{{pService.getPageNumber(trackingPaging.currentPage, 3, trackingPaging.totalPages)}}</a></li>
                          <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                            <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                          </li>
                        </ul>
                        </nav>
                    </td>
                    <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                      <label>{{(((trackingPaging.currentPage) - 1) * trackingPaging.pageCount) + 1}} - {{trackingPaging.lastDisplayIndex}} of {{gridView.totalRows}}</label>
                    </td>
                    <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;"> 
                      <select dropdown (change)="selectChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="trackingPaging.pageCount">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </td>
                  </tr>                  
                </table>
              </div>
              <!-- <div class="row">
                <div style="display: inline; float: left;">
                  
                </div>
                <div style="display: inline; float: right;">
                  
                </div>
              </div> -->
              
              
  
            </td>
          </tr>
        </tfoot>
    </table>    
  </div>
  <!-- Include the custom modal -->
<!-- <app-timestamp-filter-modal
[isVisible]="isTimestampModalVisible"
(close)="hideTimestampModal()"
(apply)="applyTimestampFilter($event)">
</app-timestamp-filter-modal> -->