export class ParserClass {
    id: number;
    parser: string;
    description: string;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
    type: number;
}
