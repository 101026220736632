import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DropDownListModel } from '../../../../models/dropdown-list-model';
import { ResponseResolverClass } from 'src/app/models/response-resolver/response-resolver-class';
import { ResponseResolverUpdateData } from '../../../../models/response-resolver/response-resolver-update-data';

@Component({
  selector: 'response-resolver-input',
  templateUrl: './response-resolver-input.component.html',
})
export class ResponseResolverInputComponent implements OnInit {
  @Input() responseResolver: ResponseResolverUpdateData;
  @Input() responseResolvers: Array<ResponseResolverClass>;  
  @Input() responseResolversDDL: Array<DropDownListModel>;
  @Output() PreFormatterUpdated = new EventEmitter<any>();
  
  public selectedResponseResolver: string;
  public selectedResponseResolverValue: string;
  public selectedResponseResolverText: string;
  public selectedResponseResolverData: ResponseResolverClass;

  ngOnInit(): void {
    this.responseResolversDDL = new Array<DropDownListModel>();

    this.responseResolvers.forEach(x => {
      let d: DropDownListModel = {
          value: x.responseResolverClass.toString(),
          text: x.description
        };
      this.responseResolversDDL.push(d);
    });
  }

  selectChangeHandler(event: any) {
    this.selectedResponseResolverValue = event.target.value;
    this.selectedResponseResolverText = event.target[event.target.selectedIndex].text;
    this.selectedResponseResolverData = this.responseResolvers.find(x => x.responseResolverClass === event.target.value);

    let resolverData = this.getPreFormatterUpdateData();
    this.PreFormatterUpdated.emit(resolverData);
  }

  getPreFormatterUpdateData() {
    let responseResolverUpdateData = Object.assign({}, this.responseResolver);

    if(this.responseResolver.isExisting) {  
        responseResolverUpdateData.responseResolver.id = this.selectedResponseResolverData.id;
        responseResolverUpdateData.responseResolver.responseResolverClass = this.selectedResponseResolverData.responseResolverClass;
        responseResolverUpdateData.responseResolver.description = this.selectedResponseResolverText;
    }

    return responseResolverUpdateData;
  }
}
