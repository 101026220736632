<table class="{{tableClass}}">
    <thead>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </thead>
    <tbody *ngIf="data.length > 0">
      <tr *ngFor="let row of data" [ngClass]="rowClass">
        <td *ngFor="let col of columns" [ngClass]="col.class">
            {{ applyPipe(row[col.field], col.pipe) }}
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="useMissingDataMessage === true && data != undefined && data.length === 0">
        <tr>
          <td [attr.colspan]="colspan">{{missingDataMessage}}</td>
        </tr>         
    </tbody>   
  </table>