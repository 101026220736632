<form [formGroup]="outboundEdiForm">
    <div class="row">
        <div class="col-12">
              <div class="form-check">
                <input class="form-check-input" formControlName="configureEdifact" type="checkbox" value="" name="configureEdifact" id="configureEdifact" (change)="onChangeConfigureEdifact()">
                <label class="form-check-label" for="configureEdifact">
                    Configure outbound Edifact
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" formControlName="configureX12" type="checkbox" value="" name="configureX12" id="configureX12" (change)="onChangeConfigureX12()">
                <label class="form-check-label" for="configureX12">
                    Configure outbound X12
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" formControlName="configureAS2" type="checkbox" value="" name="configureAS2" id="configureAS2" (change)="onChangeConfigureAS2()">
                <label class="form-check-label" for="configureAS2">
                    Configure outbound AS2
                </label>
              </div>
        </div>
    </div>
    
    <div formGroupName="ediLookup" *ngIf="this.outboundEdiForm.get('ediLookup')">
        <fieldset class="border p-2" >
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Edifact outbound</span></legend>
        
            <div class="form-group row">
                <label for="senderQualifier1" class="col-sm-2 col-form-label control-label">Host identity qualifier <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <select dropdown formControlName="hostidentityQualifier" id="senderQualifier1" name="senderQualifier1" class="form-select form-select-sm control-label">
                    <option value="-1">--- Select host identity qualifier ---</option>
                    <option *ngFor="let item of qualifierDDL" [value]="item.value">
                        {{item.text}}
                    </option>
                    </select>
                </div>
            </div>
        
            <!-- Sender -->
            <div class="form-group row mb5">
                <label for="senderId1" class="col-sm-2 col-form-label">Host identity<span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" formControlName="hostidentity"  class="form-control" id="senderId1" name="senderId1" placeholder="EDI Sender">
                </div>
            </div>
        
            <div class="form-group row">
                <label for="receiverQualifier1" class="col-sm-2 col-form-label control-label">Guest identity qualifier <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <select dropdown formControlName="guestidentityQualifier" id="receiverQualifier1" name="receiverQualifier1" class="form-select form-select-sm control-label">
                    <option value="-1">--- Select guest identity qualifier ---</option>
                    <option *ngFor="let item of qualifierDDL" [value]="item.value">
                        {{item.text}}
                    </option>
                    </select>
                </div>
            </div>
        
            <!-- Receiver -->
            <div class="form-group row mb5">
                <label for="receiverId" class="col-sm-2 col-form-label">Guest identity<span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" formControlName="guestidentity"  class="form-control" id="receiverId" name="receiverId" placeholder="EDI Receiver">
                </div>
            </div>
        </fieldset>
    </div>

    <div formGroupName="x12Lookup" *ngIf="this.outboundEdiForm.get('x12Lookup')">
        <fieldset class="border p-2">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">X12 outbound</span></legend>
        
            <div class="form-group row">
                <label for="senderQualifier2" class="col-sm-2 col-form-label control-label">Host identity qualifier <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <select dropdown formControlName="hostidentityQualifier" id="senderQualifier2" name="senderQualifier2" class="form-select form-select-sm control-label">
                    <option value="-1">--- Select host identity qualifier ---</option>
                    <option *ngFor="let item of qualifierDDL" [value]="item.value">
                        {{item.text}}
                    </option>
                    </select>
                </div>
            </div>
        
            <!-- Sender -->
            <div class="form-group row mb5">
                <label for="senderId2" class="col-sm-2 col-form-label">Host identity<span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" formControlName="hostidentity" class="form-control" id="senderId2" name="senderId2" placeholder="EDI Sender">
                </div>
            </div>
        
            <div class="form-group row">
                <label for="receiverQualifier2" class="col-sm-2 col-form-label control-label">Guest identity qualifier <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <select dropdown formControlName="guestidentityQualifier" id="receiverQualifier2" name="receiverQualifier2" class="form-select form-select-sm control-label">
                    <option value="-1">--- Select guest identity qualifier ---</option>
                    <option *ngFor="let item of qualifierDDL" [value]="item.value">
                        {{item.text}}
                    </option>
                    </select>
                </div>
            </div>
        
            <!-- Receiver -->
            <div class="form-group row mb5">
                <label for="ediReceiver" class="col-sm-2 col-form-label">Guest identity<span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" formControlName="guestidentity" class="form-control" id="ediReceiver" name="ediReceiver" placeholder="EDI Receiver">
                </div>
            </div>
        </fieldset>
    </div>
    
    <div formGroupName="as2ConfOut" *ngIf="this.outboundEdiForm.get('as2ConfOut')">
        <fieldset class="border p-2" >
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">AS2 outbound</span></legend>
        
            <!-- Sender -->
            <div class="form-group row mb5">
                <label for="as2Sender" class="col-sm-2 col-form-label">AS2 Sender<span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="as2Sender" id="as2Sender" name="as2Sender" placeholder="AS2 Sender">
                </div>
            </div>
        
            <!-- Receiver -->
            <div class="form-group row mb5">
                <label for="as2Receiver" class="col-sm-2 col-form-label">AS2 Receiver<span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="as2Receiver" id="as2Receiver" name="as2Receiver" placeholder="AS2 Receiver">
                </div>
            </div>
        
             <!-- as2Agreement -->
             <div class="form-group row mb5">
                <label for="receiverName" class="col-sm-2 col-form-label">AS2 agreement<span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="as2Agreement" id="as2Agreement" name="as2Agreement" placeholder="AS2 Agreement">
                </div>
            </div>
        
            <div class="form-group row mb5">
                <label for="as2RequestFormatDDL" class="col-sm-2 col-form-label control-label">RequestFormat <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <select dropdown formControlName="responseFormat" id="as2RequestFormatDDL" name="as2RequestFormatDDL" class="form-select form-select-sm control-label">
                    <option value="0">None</option>
                    <option value="1">Plain text</option>
                    <option value="2">Base64</option>
                    <option value="3">Binary</option>
                    </select>
                </div>
            </div>
              
            <div class="form-group row">
                <label for="as2MDNformatDDL" class="col-sm-2 col-form-label control-label">MDN format <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <select dropdown formControlName="mdnformat" id="as2MDNformatDDL" name="as2MDNformatDDL" class="form-select form-select-sm control-label">
                    <option value="0">None</option>
                    <option value="1">Plain text</option>
                    <option value="2">Base64</option>
                    <option value="3">Binary</option>
                    </select>
                </div>
            </div>
        </fieldset>
    </div>
</form>


