import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InboundMessageType } from 'src/app/models/message-type';
import { MessageTypeService } from '../message-type.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'delete-inbound-message-type', // TODO: Is selector needed for modal?
  templateUrl: './delete-inbound-message-type.component.html',
})
export class DeleteInboundMessageTypeComponent implements OnInit {
    @Input() inboundMessageType: InboundMessageType;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;

    constructor(public activeModal: NgbActiveModal, public mtService: MessageTypeService, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
    }

    public delete() {
      this.mtService.deleteInboundMessageType(this.inboundMessageType).subscribe(
            {
                next: (result) => {
                      this.activeModal.close('delete');
                },
                error: (error) => {
                  this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
              }
        );
    }
}
