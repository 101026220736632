import { EnrichmentClass } from './enrichment-class';

export class Enrichment {
    id: number;
    createTimestamp: string;
    updateTimestamp: string;
    createdBy: string;
    updatedBy: string;
    enrichmentClassId: number;    
    enrichmentClass: EnrichmentClass;
    entityMapId: number;
    order: number;
}