<form [formGroup]="sourceSbProfileForm">
    <hr>
    <div class="form-group row mb5">
    <label for="sourceSbProfileNamespace" class="col-sm-3 col-form-label">Hostname<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="namespace" class="form-control" id="sourceSbProfileNamespace" name="sourceSbProfileNamespace" placeholder="Hostname">
        <div *ngIf="form.namespace.invalid && (form.namespace.dirty || form.namespace.touched)" class="alert alert-danger">
            <div *ngIf="form.namespace.errors?.['required']">Hostname is required.</div>
        </div>
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSbProfileQueue" class="col-sm-3 col-form-label">Queue<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="queue" class="form-control" id="sourceSbProfileQueue" name="sourceSbProfileQueue" placeholder="Queue">
        <div *ngIf="form.queue.invalid && (form.queue.dirty || form.queue.touched)" class="alert alert-danger">
            <div *ngIf="form.queue.errors?.['required']">Queue is required.</div>
        </div>
    </div>
</div>
<!-- Use RegEx -->
<div class="form-group row mb5">
    <label for="sourceSbProfileUseManagedIdentity" class="col-sm-3 col-form-label">Use ManagedIdentity</label>
    <div class="col-sm-6">
        <div class="form-check">
            <input type="checkbox" formControlName="useManagedIdentity" class="form-check-input" id="sourceSbProfileUseManagedIdentity" value="" name="sourceSbProfileUseManagedIdentity" placeholder="UseManagedIdentity">
        </div>
    </div>
</div>

<!-- Selection -->
<div *ngIf="sourceSbProfileForm.value.useManagedIdentity == false">
    <div class="row" *ngIf="sourceSbProfileForm.value.useManagedIdentity == false">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
            <div class="form-check">
                <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio" name="useExistingKeyVaultValue" [value]="1" id="useExistingKeyVaultValue">
                <label class="form-check-label" for="useExistingKeyVaultValue">
                    Use existing key vault secret
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio" name="useExistingKeyVaultValue" [value]="2" id="useExistingKeyVaultValue" checked>
                <label class="form-check-label" for="useExistingKeyVaultValue">
                    Add key vault secret
                </label>
            </div>
            <div class="form-check" *ngIf="sourceSbProfileForm.value.keyVaultProfileId != -1 && this.isEdit != false">
                <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio" name="useExistingKeyVaultValue" [value]="3" id="useExistingKeyVaultValue">
                <label class="form-check-label" for="useExistingKeyVaultValue">
                    Update key vault secret
                </label>
            </div>
        </div>
    </div>  

    <div class="form-group row mb5">
        <label for="sourceSbProfileSharedAccessKeyName" class="col-sm-3 col-form-label">SharedAccessKeyName<span class="req-color">*</span></label>
        <div class="col-sm-6">
            <input type="text" formControlName="sharedAccessKeyName" class="form-control" id="sourceSbProfileSharedAccessKeyName" name="sourceSbProfileSharedAccessKeyName" placeholder="SharedAccessKeyName">
            <div *ngIf="form.sharedAccessKeyName.invalid && (form.sharedAccessKeyName.dirty || form.sharedAccessKeyName.touched)" class="alert alert-danger">
                <div *ngIf="form.sharedAccessKeyName.errors?.['required']">SharedAccessKeyName is required.</div>
            </div>
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="sourceSbProfileForm.value.useExistingKeyVaultValue === 2">
        <label for="systemName" class="col-sm-3 col-form-label">System name<span class="req-color">*</span> <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="The secret that will store the ServiceBus key has the form of al-goh-servicebus-auth-in-{system name}-key. The value you provide in the System name input will be used to construct the secret name."></i></label>
        <div class="col-sm-6">
            <input type="text" formControlName="systemName" class="form-control" id="systemName" name="systemName" placeholder="System name">
            <div *ngIf="form.systemName.invalid && (form.systemName.dirty || form.systemName.touched)" class="alert alert-danger">
                <div *ngIf="form.systemName.errors?.['required']">System name is required.</div>
            </div>
        </div>
    </div>
    <!-- sourceAccesskey -->
    <div class="form-group row mb5" *ngIf="sourceSbProfileForm.value.useExistingKeyVaultValue === 2 || sourceSbProfileForm.value.useExistingKeyVaultValue === 3">
        <label for="destinationPassword" class="col-sm-3 col-form-label">Access key</label>
        <div class="col-sm-6">
            <input type="password" formControlName="sharedAccessKey" class="form-control" id="sourceAccesskey" name="sourceAccesskey" placeholder="Access key">
        </div>
    </div>
    <!-- Key vault secret -->
    <div class="form-group row" *ngIf="keyVaultProfiles && (sourceSbProfileForm.value.useExistingKeyVaultValue === 1 || sourceSbProfileForm.value.useExistingKeyVaultValue === 3)">
        <label for="keyVaultSecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
        <div class="col-sm-6">
            <select dropdown formControlName="keyVaultProfileId" id="keyVaultSecretDDL" name="keyVaultSecretDDL" class="form-select form-select-sm control-label">
                <option value="-1">--- Select secret ---</option>
                <option *ngFor="let item of keyVaultProfiles" [value]="item.id">
                    {{item.name}}
                </option>
            </select>
        </div>
    </div>
</div>
</form>