import { Component, Input } from '@angular/core';
import { ViewConfigurationModelService } from 'src/app/common-components/view-configuration/view-configuration-modal.service';
import { ConfDestConnection } from 'src/app/models/destination/conf-dest-connection';

@Component({
  selector: 'review-destinations',
  templateUrl: './review-destinations.component.html',
})
export class ReviewDestinationsComponent {
    @Input() confDestConnections: Array<ConfDestConnection>

    constructor(public viewService: ViewConfigurationModelService) {}
}
