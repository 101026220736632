import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataMessageTypesComponent } from './md-message-types.component';
import { MasterDataInboundMessageTypesComponent } from './child-components/md-inbound-message-types.component';
import { MessageTypeService } from './message-type.service';
import { MasterDataOutboundMessageTypesComponent } from './child-components/md-outbound-message-types.component';
import { AppOutboundMessageTypeComponent } from './child-components/add-outbound-message-type.component';
import { DeleteOutboundMessageTypeComponent } from './child-components/delete-outbound-message-type.component';
import { EditOutboundMessageTypeComponent } from './child-components/edit-outbound-message-type.component';
import { AppInboundMessageTypeComponent } from './child-components/add-inbound-message-type.component';
import { EditInboundMessageTypeComponent } from './child-components/edit-inbound-message-type.component';
import { ErrorDisplayModule } from 'src/app/common-components/error-display/error-display.module';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { DeleteInboundMessageTypeComponent } from './child-components/delete-inbound-message-type.component';

@NgModule({
  imports:      [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule,
    ErrorDisplayModule
  ],
  providers:    [ MessageTypeService, ErrorParserService ],
  declarations: [    
    MasterDataMessageTypesComponent,
    MasterDataInboundMessageTypesComponent,
    MasterDataOutboundMessageTypesComponent,
    AppOutboundMessageTypeComponent,
    DeleteInboundMessageTypeComponent,
    DeleteOutboundMessageTypeComponent,
    EditOutboundMessageTypeComponent,
    AppInboundMessageTypeComponent,
    EditInboundMessageTypeComponent
  ]
})
export class MasterDataMessageTypesModule { }
