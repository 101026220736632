import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { MDInboundParsersInputService } from './md-inboundparsers-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { UtilService } from 'src/app/common-services/util.service';
import { InboundParserResponseModel } from '../../models/inboundparser-response-model';
import { InboundParser } from 'src/app/models/parser/parser';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'edit-parser',
  templateUrl: './edit-parser-modal.component.html'
})
export class EditParserModalComponent implements OnInit {
  @Input() partner: Partner;
  @Input() parsersGridList: Array<InboundParserResponseModel>;
  @Input() inboundParser: InboundParser;

  public inboundParserForm: FormGroup;
  public errorModel: ErrorModel = null;
  public showSpinner: boolean = false;
  public requiredInputSet: boolean = false; // TODO Add validation of required input // TODO do for all!!!!!
  public messageTypesDDL: DropDownListModel[];
  public parserClassesDDL: DropDownListModel[];
  public useDynamicParser: boolean = false;
  public xPathParserId: number;

  constructor(public activeModal: NgbActiveModal, private inboundParsersInputService: MDInboundParsersInputService, public utilService: UtilService, private errorParserService: ErrorParserService) {}

  ngOnInit(): void {
    this.showSpinner = true;
    this.inboundParserForm = this.inboundParsersInputService.getEmptyInboundParserFormNoDynamicParser(this.partner.id);

    this.inboundParsersInputService.getParserBaseData().subscribe(
      {
          next: (result) => {
            this.messageTypesDDL = new Array<DropDownListModel>();
            result.inboundMessageTypes.forEach(x => {
                let d: DropDownListModel = {
                    value: x.inboundMessageTypeId.toString(),
                    text: `${x.messageTypeDescription} (${x.messageName})`
                  };
                  this.messageTypesDDL.push(d);
            });

            this.parserClassesDDL = new Array<DropDownListModel>();
            result.parserClasses.forEach(x => {
              let d: DropDownListModel = {
                  value: x.id.toString(),
                  text: `${this.utilService.getClassName(x.parser)} (${x.description})`
                };
                this.parserClassesDDL.push(d);
            });

            // This is really ugly! I have created story for next sprint to move type to tblParserClasses so we do not have to search on name
            let xPathParser = result.parserClasses.find(x => x.parser === "al.goh.common.utilities.parsers.XPathParser")
            if(xPathParser != undefined) {
              this.xPathParserId = xPathParser.id;
              if(this.inboundParser.parserClassId == xPathParser.id) {
                this.useDynamicParser = true;
                this.inboundParserForm = this.inboundParsersInputService.getInboundParserFormWithDynamicParser(this.inboundParser)
              } else {
                this.useDynamicParser = false;
                this.inboundParserForm = this.inboundParsersInputService.getInboundParserFormNoDynamicParser(this.inboundParser);
              }
            } else {
              this.useDynamicParser = false;
              this.inboundParserForm = this.inboundParsersInputService.getInboundParserFormNoDynamicParser(this.inboundParser);
            }

            this.showSpinner = false;
          },
          // TODO Add better error hanlding to present the issue!
          error: (error) => {
            this.showSpinner = false;
            this.errorModel = this.errorParserService.extractErrors(error);
            console.error(error);
          }
        }
    );    
  }

  get form() { return this.inboundParserForm.controls; }

  public save() {

    let model = this.inboundParsersInputService.getInboundParserRequestModel(this.inboundParserForm, this.useDynamicParser, this.xPathParserId);

    this.inboundParsersInputService.updateParserPartnerConnection(model).subscribe(
        {
            next: (result) => {
                this.activeModal.close('save');
            },
            error: (error) => {
              this.showSpinner = false;
              this.errorModel = this.errorParserService.extractErrors(error);
              console.error(error);
            }
        }
    );
  }
  
  useDynamicParserChangeHandler(event: any) {
    this.useDynamicParser = this.inboundParserForm.get('useDynamicParser').value;
    this.inboundParserForm = this.useDynamicParser ? this.inboundParsersInputService.getInboundParserFormWithDynamicParser(this.inboundParser) : this.inboundParsersInputService.getInboundParserFormNoDynamicParser(this.inboundParser);
  }
}