import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { InboundMessageType } from 'src/app/models/message-type';
import { ReviewwInputService } from '../review-input.service';

@Component({
  selector: 'review-message-type',
  templateUrl: './review-message-type.component.html',
})
export class ReviewMessageTypeComponent implements OnInit {
  form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, public rs: ReviewwInputService) {}
  
  ngOnInit(): void {
    this.form = this.rootFormGroup.form;
  }
}
