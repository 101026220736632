import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'test-comp',
  templateUrl: './test.component.html',
})
export class TestComponent implements OnInit  {
  public _http: HttpClient;
  public baseUrlLocal: string = "";
  public resultString: string = "";
  public showSpinner: boolean = true;
  public closeResult: string;
  
  constructor(private http: HttpClient, private modalService: NgbModal) { 
    this._http = http;
    this.baseUrlLocal = environment.apiBaseUrl; // 'https://localhost:44376/api/';
  }

  ngOnInit(): void {
    this.showSpinner = false;
  }
    
  open(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      
      return 'by clicking on a backdrop';
    } else {
      
      return  `with: ${reason}`;
    }
  }

  // public getTestEdi(): Observable<any> {
  //   return this._http.get<any>(this.baseUrlLocal + `testedi`);
  // }

}
