<div *ngIf="source?.sourceSharedFolderProfile !== null">
    <div class="row">
        <div class="col-sm-3"><b>Server:</b></div>
        <div class="col-sm-9">{{source?.sourceSharedFolderProfile?.server}}</div>
    </div>
    <div class="row">
        <div class="col-sm-3"><b>Path:</b></div>
        <div class="col-sm-9">{{source?.sourceSharedFolderProfile?.path}}</div>
    </div>
    <div class="row">
        <div class="col-sm-3"><b>Filter:</b></div>
        <div class="col-sm-9">{{source?.sourceSharedFolderProfile?.filter}}</div>
    </div>
    <div class="row">
        <div class="col-sm-3"><b>Input encoding:</b></div>
        <div class="col-sm-9">{{source?.sourceSharedFolderProfile?.inputEncoding}}</div>
    </div>
    <div class="row">
        <div class="col-sm-3"><b>output encoding:</b></div>
        <div class="col-sm-9">{{source?.sourceSharedFolderProfile?.outputEncoding}}</div>
    </div>
</div>
<div *ngIf="source?.sourceMQProfile !== null">
    <div class="row">
        <div class="col-sm-4"><b>Host:</b></div>
        <div class="col-sm-8">{{source?.sourceMQProfile?.host}}</div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Port:</b></div>
        <div class="col-sm-8">{{source?.sourceMQProfile?.port}}</div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Queue manager:</b></div>
        <div class="col-sm-8">{{source?.sourceMQProfile?.qm}}</div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Channel:</b></div>
        <div class="col-sm-8">{{source?.sourceMQProfile?.channel}}</div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Queue:</b></div>
        <div class="col-sm-8">{{source?.sourceMQProfile?.queue}}</div>
    </div>
</div>
<div *ngIf="source.sourceSBProfile !== null">
    <div class="row">
        <div class="col-sm-4"><b>Namespace:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSBProfile?.namespace }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Queue:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSBProfile?.queue }}</label>
        </div>
    </div>
    <div *ngIf="source?.sourceSBProfile?.useManagedIdentity == false">
        <div class="row">
            <div class="col-sm-4"><b>Shared access keyName:</b></div>
            <div class="col-sm-8">
                <label>{{ source?.sourceSBProfile?.sharedAccessKeyName }}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4"><b>Secret name:</b></div>
            <div class="col-sm-8">
                <label>{{ source?.sourceSBProfile?.keyVaultProfile?.secretName }}</label>
            </div>
        </div>
    </div>
    <div *ngIf="source?.sourceSBProfile?.useManagedIdentity == true">
        <div class="row">
            <div class="col-sm-4"><b>Use ManagedIdentity:</b></div>
            <div class="col-sm-8">
                <label>{{ source?.sourceSBProfile?.useManagedIdentity }}</label>
            </div>
        </div>
    </div>
</div>
<div *ngIf="source.sourceSftpProfile !== null">
    <div class="row">
        <div class="col-sm-4"><b>Host:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.host }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Port:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.port }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Path:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.path }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>ArchivePath:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.archivePath }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Username:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.userName }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Encoding:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.encoding }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Use RegEx:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.useRegEx }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Filter or RegEx:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.filterOrRegEx }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Delete:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.delete }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Secret name:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceSftpProfile?.keyVaultProfile?.secretName }}</label>
        </div>
    </div>
</div>
<div *ngIf="source.sourceFtpProfile !== null">
    <div class="row">
        <div class="col-sm-4"><b>Host:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.host }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Port:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.port }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Path:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.path }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>ArchivePath:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.archivePath }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Username:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.userName }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Encoding:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.encoding }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Use RegEx:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.useRegEx }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Filter or RegEx:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.filterOrRegEx }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Delete:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.delete }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"><b>Secret name:</b></div>
        <div class="col-sm-8">
            <label>{{ source?.sourceFtpProfile?.keyVaultProfile?.secretName }}</label>
        </div>
    </div>
</div>