import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GridData } from 'src/app/common-components/grid-view/models/grid-data.model';
import { Product } from './products';

@Component({
  selector: 'app-test-grid-view',
  templateUrl: './test-grid-view.component.html',
})
export class TestGridViewComponent implements OnInit {
  // title = 'grid-app';
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    // Initialize the FormGroup with some data
    this.form = this.fb.group({
      rows: this.fb.array([
        this.createRow(1, 'John', 25, 'New York'),
        this.createRow(2, 'Alice', 30, 'San Francisco'),
        this.createRow(3, 'Bob', 22, 'Chicago'),
        this.createRow(4, 'Tommy', 51, 'Kvesarum'),
        this.createRow(5, 'Lennart', 50, 'Stockholm')
        // Add more rows as needed
      ])
    });
  }

  ngOnInit(): void {
  }

  private createRow(id: number, name: string, age: number, city: string): FormGroup {
    return this.fb.group({
      id: [id, Validators.required],
      name: [name, Validators.required],
      age: [age, Validators.required],
      city: [city, Validators.required]
    });
  }


}
