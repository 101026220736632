import { Component, Input, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { UtilService } from 'src/app/common-services/util.service';
import { Partner } from 'src/app/models/partner/partner';
import { ConfDestination } from 'src/app/models/destination/conf-destination';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddDestinationModalComponent } from './add-destination-model.component';
import { MDDestinationService } from './md-destination.service';
import { DeleteDestinationComponent } from './delete-destination.component';
import { EditDestinationModalComponent } from './edit-destination-model.component';

@Component({
  selector: 'destination-input',
  templateUrl: './md-destination-input.component.html'
})
export class MDDestinationInputComponent implements OnInit {
    @Input() partner: Partner;
    public destinations: Array<ConfDestination>;
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public showSpinner: boolean = true;

    constructor(private authService: MsalService, public utilService: UtilService, public destinationService: MDDestinationService, private modalService: NgbModal) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        this.getPartnerDestinations(this.partner.id);
    }

    private getPartnerDestinations(partnerId: number) {
        this.showSpinner = true;
        this.destinationService.getPartnerDestinations(partnerId).subscribe({
            next: (destinations) => {
                this.showSpinner = false;
                this.destinations = destinations;
            },
            error: (error) => {
                this.showSpinner = false;
                // this.displayErrorMessage(error);
            }
        });
    }

    public openAddDestinationModal() {
        const modalRef = this.modalService.open(AddDestinationModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        let confDestination: ConfDestination = new ConfDestination();
        confDestination.id = 0;
        modalRef.componentInstance.confDestination = confDestination;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                //this.sourceUpdated.emit(this.schedulers);
                this.getPartnerDestinations(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }
  
    public editDestination(destination: ConfDestination) {
        const modalRef = this.modalService.open(EditDestinationModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.confDestination = destination;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                //this.sourceUpdated.emit(this.schedulers);
                this.getPartnerDestinations(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });       
    }


    public removeDestinaton(destination: ConfDestination) {
        const modalRef = this.modalService.open(DeleteDestinationComponent, { size: 'size_40' });
        modalRef.componentInstance.confDestination = destination;
        modalRef.result.then((result) => {            
            if(result === 'delete') {
                // Refresh grid after save
                this.getPartnerDestinations(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }
}
