import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Scheduler } from 'src/app/models/source/scheduler';
// import { SchedulerUpdateData } from 'src/app/models/source/scheduler-update-data';
import { Source } from 'src/app/models/source/source';
// import { DropDownListSource } from 'src/app/models/wizard-response-model';
import { UtilService } from 'src/app/common-services/util.service';
import { SourceSbProfile } from 'src/app/models/source/source-sb-profile';
import { SourceMqProfile } from 'src/app/models/source/source-mq-profile';
import { MDInboundParsersInputService } from './md-inboundparsers-input.service';
import { SourceSharedFolderProfile } from 'src/app/models/source/source-shared-folder-profile';
import { MessageTypeUpdateData } from 'src/app/models/message-type-update-data';
import { Partner } from 'src/app/models/partner/partner';
import { ConfDestination } from 'src/app/models/destination/conf-destination';
import { InboundParserResponseModel } from '../../models/inboundparser-response-model';
import { PartnerService } from '../../partner.service';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddParserModalComponent } from './add-parser-modal.component';
import { InboundParser } from 'src/app/models/parser/parser';
import { DeleteParserConnectionModalComponent } from './delete-parser-connection-modal.component';
import { EditParserModalComponent } from './edit-parser-modal.component';

@Component({
  selector: 'inboundparsers-input',
  templateUrl: './md-inboundparsers-input.component.html',
})
export class MDInboundParsersComponent implements OnInit {
    @Input() partner: Partner;
    public parsersGridList: Array<InboundParserResponseModel>;

    //public parsersGridList: Array<InboundParserResponseModel> = new Array<InboundParserResponseModel>();
    public showSpinner: boolean = false;
    public error: boolean = false;
    public errorMessage: string = '';
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;

    constructor(public partnerService: PartnerService, private authService: MsalService, public utilService: UtilService, public inboundParsersInputService: MDInboundParsersInputService, private modalService: NgbModal) {}

    ngOnInit(): void {
      // this.parsersGridList = this.inboundparsers;
      // this.parsersGridList.forEach(p => {
      //   p.parser = this.utilService.getClassName(p.parser);})

        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }
        this.getParsers(this.partner.id);
    }

    public getParsers(partnerId: number) {
      this.partnerService.getParsers(partnerId).subscribe({
          next: (inboundparsers) => {
              this.showSpinner = false;
              this.parsersGridList = inboundparsers;
              this.parsersGridList.forEach(p => {
                p.parser = this.utilService.getClassName(p.parser);})
          },
          error: (error) => {
              this.showSpinner = false;
              this.displayErrorMessage(error);
          }
      });
    }
    
    public addParserConnection() {
        const modalRef = this.modalService.open(AddParserModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.parsersGridList = this.parsersGridList;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getParsers(this.partner.id);
                //this.sourceUpdated.emit(this.schedulers);
                //this.getData(1, 10);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public editParser(inboundParserResponseModal: InboundParserResponseModel) {
        const modalRef = this.modalService.open(EditParserModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.parsersGridList = this.parsersGridList;
        let inboundParser: InboundParser = new InboundParser();
        inboundParser.id = inboundParserResponseModal.id;
        inboundParser.partner = this.partner.id;
        inboundParser.messageType = inboundParserResponseModal.messageType;
        inboundParser.parserClassId = inboundParserResponseModal.parserClassId;
        inboundParser.senderIdPath = inboundParserResponseModal.senderIdPath;
        inboundParser.receiverIdPath = inboundParserResponseModal.receiverIdPath;
        inboundParser.primaryIdPath = inboundParserResponseModal.primaryIdPath;
        inboundParser.referenceIdPath = inboundParserResponseModal.referenceIdPath;
        inboundParser.taskIdPath = inboundParserResponseModal.taskIdPath;
        inboundParser.countryCodePath = inboundParserResponseModal.countryCodePath;
        inboundParser.customLookupIdPath = inboundParserResponseModal.customLookupIdPath;
        inboundParser.areaPlantIdPath = inboundParserResponseModal.areaPlantIdPath;
        inboundParser.namespacesUri = inboundParserResponseModal.namespacesUri;

        modalRef.componentInstance.inboundParser = inboundParser;
        
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getParsers(this.partner.id);
                //this.sourceUpdated.emit(this.schedulers);
                //this.getData(1, 10);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

  

    public removeParserPartnerConnection(inboundParserResponseModal: InboundParserResponseModel) {
        let inboundParser: InboundParser = new InboundParser();
        inboundParser.id = inboundParserResponseModal.id;
        inboundParser.partner = this.partner.id;
        inboundParser.messageType = inboundParserResponseModal.messageType;
        inboundParser.parserClassId = inboundParserResponseModal.parserClassId;

        const modalRef = this.modalService.open(DeleteParserConnectionModalComponent, { size: 'size_40' });
        modalRef.componentInstance.inboundParser = inboundParser;
        modalRef.componentInstance.partner = this.partner;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'delete') {
                this.getParsers(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    private displayErrorMessage(error: any) {
      let formattedError = '';
      if(error?.error && error?.error.error == undefined) {
      if(error.error.includes('at al.goh.admin.tool.api')) {
          formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
      } else {
          formattedError = error.error;
      }
      } else {
      if(error?.error?.error) {
          formattedError = error?.error.error;
      } else {
          formattedError = 'Unknown eror';
      }
      }

      this.errorMessage = `Error when decoding message. Error Message ${formattedError}`;
      this.error = true;
      console.error(error);
  }

}
