<div class="row mb5">
    <div class="{{labelClass}}">
        <b>{{firstLabel}}</b>
    </div>
    <div class="{{valueClass}}">
        {{firstValue}}
    </div>
    <div class="{{labelClass}}">
        <b>{{secondLabel}}</b>
    </div>
    <div class="{{valueClass}}">
        {{secondValue}}
    </div>
</div>