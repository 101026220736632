import { Component, OnInit } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { HomeService } from './home.service';
import { ChartModel } from './models/chart-model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit  {
  public error: boolean = false;
  public errorMessage: string = '';
  public showSpinner: boolean = false;
  public ordersPerSender: Array<ChartModel>;
  public messagesPerSender: Array<ChartModel>;
  public orderLinesPerDay: Array<ChartModel>;
  public messagesPerDay: Array<ChartModel>;
  public orderSenderDaysCount: number = 30;  
  public messageSenderDaysCount: number = 30;
  public orderLinesPerDayCount: number = 30;
  public messagesPerDaysCount: number = 30;
  public view: [number, number] = [600, 450];

  colorScheme: Color = { 
      domain: ['#11387F'], 
      group: ScaleType.Ordinal, 
      selectable: true, 
      name: 'Customer Usage', 
  };

  onSelect(event: any) {
      console.log(event);
  }

  constructor(private service: HomeService) {}

  ngOnInit(): void {
    this.showSpinner = true; 
    this.getOrdersPerSender();
    this.getMessagesPerSender();
    this.getOrderLinesPerDay();
    this.getMessagesPerDay();
  }
 
  private getOrdersPerSender() {
    this.service.getOrdersPerSender(`${this.orderSenderDaysCount}d`, 30).subscribe({
      next: (result) => {
        this.ordersPerSender = new Array<ChartModel>();

        result.forEach(x => {
          let s: ChartModel = {
            value: x.messageCount,
            name: x.senderName
          };
          this.ordersPerSender.push(s);
        });

        this.showSpinner = false; 
      },
      error: (error) => { 
        // this.setErrorText(error);
        this.showSpinner = false; 
        console.error(error);
      }
    });
  }

  private getMessagesPerSender() {
    this.service.getMessagesPerSender(`${this.messageSenderDaysCount}d`, 30).subscribe({
      next: (result) => {
        this.messagesPerSender = new Array<ChartModel>();

        result.forEach(x => {
          let s: ChartModel = {
            value: x.messageCount,
            name: x.senderName
          };
          this.messagesPerSender.push(s);
        });

        this.showSpinner = false; 
      },
      error: (error) => { 
        // this.setErrorText(error);
        this.showSpinner = false; 
        console.error(error);
      }
    });
  }

  private getOrderLinesPerDay() {
    this.service.getOrderLinesPerDay(`${this.orderLinesPerDayCount}d`, '1d', 0).subscribe({
      next: (result) => {
        this.orderLinesPerDay = new Array<ChartModel>();

          result.forEach(x => {
            var datePipe = new DatePipe("en-US"); // Works with en-US but shold perhaps be swidish if I fugure out to include the locale
            let s: ChartModel = {
              value: x.orderLinesCount,
              name: datePipe.transform(x.dayGenerated, 'yyyy-MM-dd')
            };
            this.orderLinesPerDay.push(s);
          });

          this.showSpinner = false; 
      },
      error: (error) => { 
        // this.setErrorText(error);
        this.showSpinner = false; 
        console.error(error);
      }
    });
  }

  private getMessagesPerDay() {
    this.service.getMessagesPerDay(`${this.messagesPerDaysCount}d`, '1d', 0).subscribe({
      next: (result) => {
        this.messagesPerDay = new Array<ChartModel>();
        
        result.forEach(x => {
          var datePipe = new DatePipe("en-US"); // Works with en-US but shold perhaps be swidish if I fugure out to include the locale
          let s: ChartModel = {
            value: x.messageCount,
            name: datePipe.transform(x.dayGenerated, 'yyyy-MM-dd')
          };
          this.messagesPerDay.push(s);
        });

        this.showSpinner = false; 
      },
      error: (error) => { 
        // this.setErrorText(error);
        this.showSpinner = false; 
        console.error(error);
      }
    });
  }

  public selectOrderSenderDaysChangeHandler() {
    this.showSpinner = true; 
    this.getOrdersPerSender();
  }

  public selectMessageSenderDaysChangeHandler() {
    this.showSpinner = true; 
    this.getMessagesPerSender();
  }

  public selectOrderLinesPerDaysChangeHandler() {
    this.showSpinner = true; 
    this.getOrderLinesPerDay();
  }

  public selectMessagesPerDayChangeHandler() {
    this.showSpinner = true; 
    this.getMessagesPerDay();
  }
}
