import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InboundMapping } from './models/inbound-mapping';

@Injectable({
  providedIn: 'root',
})
export class MappingExplorerService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getInboundMappings() {
        return this.http.get<Array<InboundMapping>>(this.baseUrlLocal + 'inboundmappings/mappingresponse');
    }

    public getOutboundMappings() {
        return this.http.get<Array<InboundMapping>>(this.baseUrlLocal + 'outboundmappings/mappingresponse');
    }
}


