import { Component, Input, OnInit } from '@angular/core';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { FtpProfileService } from './ftp-profile.service';
import { DestinationFtpprofile } from 'src/app/models/destination/destination-ftp-profile';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: "ftp-profile",
    templateUrl: "./ftp-profile.component.html"
})
export class FtpProfileComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;

    public destinationFtpprofileForm: FormGroup;
    public keyVaultProfiles: Array<KeyVaultProfile>;
    public ftpprofiles: Array<DestinationFtpprofile>;
    private systemNameSubscription: Subscription;
    
    constructor(private keyVaultService: KeyVaultService, private ftpProfileService: FtpProfileService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.destinationFtpprofileForm.controls; }

    ngOnInit(): void {
        this.destinationFtpprofileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        // Subscribe on value changes for systemName input control to only allow a-z and 0-9
        // Only setup the subscribe on systemName if we add a new secret
        if(this.destinationFtpprofileForm.value.useExistingKeyVaultValue === false) {
            this.destinationFtpprofileForm.get('systemName').valueChanges.subscribe((value: string) => {
                const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
                if (sanitizedValue !== value) {
                    this.destinationFtpprofileForm.get('systemName').setValue(sanitizedValue, { emitEvent: false });
                }
            });
        }

        this.ftpProfileService.getAllFtpProfiles().subscribe({
            next: (result) => {
                this.ftpprofiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });

        this.keyVaultService.getKeyVaultProfiles().subscribe({
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });
    }

    public handleDestOptionsChange(event: any) {
        this.ftpProfileService.setupRequiredValidation(this.destinationFtpprofileForm);        
    }

    public onchangeUseExistingKeyVaultValue(event: any) {
        if(this.destinationFtpprofileForm.value.useExistingKeyVaultValue === false) {
            // If we want to add a new keyvault secret we can't send down an existing
            this.destinationFtpprofileForm.get('keyVaultProfileId').setValue(-1);
            // When showing systemName we need to add the FormControl and add the subscribe
            this.destinationFtpprofileForm.addControl('systemName', new FormControl(null));
            this.systemNameSubscription = this.destinationFtpprofileForm.get('systemName').valueChanges.subscribe((value: string) => {
                const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
                if (sanitizedValue !== value) {
                    this.destinationFtpprofileForm.get('systemName').setValue(sanitizedValue, { emitEvent: false });
                }
            });
        } else {
            // Remove the subscription before removing the control
            this.systemNameSubscription.unsubscribe();
            this.destinationFtpprofileForm.removeControl('systemName');
        }
    }
}