<div class="modal-header">
    <h4 class="modal-title">Delete prestep class {{ utilService.getClassName(preStepClass.preStepClass) }}</h4>
</div>

<div class="modal-body">
    <!-- <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div> -->
    <div class="alert alert-danger" role="alert" *ngIf="errorModel">
        <b>Error when deleting prestep class. Error Message: {{errorModel.errorMessage}}</b>
    </div>
    <div>
        Are you really sure you want to delete prestep class {{ utilService.getClassName(preStepClass.preStepClass) }}?
    </div>    
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
