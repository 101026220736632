<div class="modal-header">
    <h4 class="modal-title" *ngIf="configuration">Configuration for inbound partner {{configuration.inboundPartner.name}} ({{configuration.inboundPartner.partnerId}})</h4>
</div>
<div class="modal-body">
    <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
    <div class="alert alert-danger" role="alert" *ngIf="errorModel">
        <b>Error in edit simplified flow configuration form. Error Message: {{errorModel.errorMessage}}</b>
    </div>
    <div *ngIf="configuration">
        <div *ngIf="configuration && configuration.basicAuthUsername != undefined">
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Basic authentication</span></legend>
                <div class="row mb5">
                    <label for="receiverName" class="col-sm-3"><b>Username:</b></label>
                    <div class="col-sm-9">
                        <label for="receiverName" class="col-sm-6">{{configuration.basicAuthUsername}}</label>
                    </div>
                </div>
                <div class="row mb5">
                    <label for="receiverName" class="col-sm-3"><b>Password:</b></label>
                    <div class="col-sm-9">
                        <label for="receiverName" class="col-sm-6">********** <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="Password is not show due to security reasons."></i></label>
                    </div>
                </div>
            </fieldset>
        </div>
        <fieldset class="border p-2">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Inbound message type</span></legend>
            <div class="row mb5">
                <label for="receiverName" class="col-sm-3"><b>Message type id:</b></label>
                <div class="col-sm-9">
                    <label for="receiverName" class="col-sm-6">{{configuration.inboundMessageType.messageName}}</label>
                </div>
            </div>
            <div class="row mb5">
                <label for="receiverName" class="col-sm-3"><b>Message type description:</b></label>
                <div class="col-sm-9">
                    <label for="receiverName" class="col-sm-6">{{configuration.inboundMessageType.messageTypeDescription}}</label>
                </div>
            </div>
        </fieldset>       
        <fieldset class="border p-2">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound partner</span></legend>
            <div class="row mb5">
                <div class="col-sm-2">
                    <label for="receiverName"><b>Id:</b></label>
                </div>
                <div class="col-sm-3">
                    <label for="receiverName">{{ configuration.outboundPartner.partnerId }}</label>
                </div>
                <div class="col-sm-2">
                    <label for="receiverName"><b>Name:</b></label>
                </div>
                <div class="col-sm-3">
                    <label for="receiverName">{{configuration.outboundPartner.name}}</label>
                </div>
            </div>
        </fieldset>
        <fieldset class="border p-2">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound message type</span></legend>
            <div class="row mb5">
                <label for="receiverName" class="col-sm-3"><b>Message type id:</b></label>
                <div class="col-sm-9">
                    <label for="receiverName" class="col-sm-6">{{configuration.outboundMessageType.messageName}}</label>
                </div>
            </div>
            <div class="row mb5">
                <label for="receiverName" class="col-sm-3"><b>Message type description:</b></label>
                <div class="col-sm-9">
                    <label for="receiverName" class="col-sm-6">{{configuration.outboundMessageType.messageTypeDescription}}</label>
                </div>
            </div>
        </fieldset>       
        
        <div *ngIf="configuration.schedulers !== null && configuration.schedulers.length > 0">
        <fieldset class="border p-2">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Source</span></legend>
            <div class="row mb5">
                <div class="col-sm-12">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th style="max-width: 50px;">Protocol</th>
                                <th style="max-width: 50px;">Enabled</th>
                                <th style="max-width: 50px;">Encodings</th>
                                <th style="max-width: 50px;">Validations</th>
                                <th style="max-width: 50px;">EDI type</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of configuration.schedulers">
                                <td>{{ s.name }}</td>
                                <td>{{ viewService.getProtocolName(s.source.protocolType) }}</td>
                                <td>{{ viewService.getYesNo(s.enabled) }}</td>
                                <td>
                                    <div class="row">
                                        <div><b>Input:</b>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div><b>Output:</b>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ viewService.getValidationType(s.validationType) }}</td>
                                <td>{{ viewService.getEDITypeName(s.source.ediType) }} </td>
                                <td>
                                    <source-view-table-data [source]="s.source"></source-view-table-data>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </fieldset>
        </div>
        <fieldset class="border p-2">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Destinations</span></legend>
            <div class="row mb5">
                <div class="col-sm-12">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th style="max-width: 50px;">Protocol</th>
                                <!-- <th style="max-width: 50px;">Enabled</th>
                                <th style="max-width: 50px;">Remove BOM</th> -->
                                <th style="max-width: 60px;">Type</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cc of configuration.destinations">
                                <td>{{ cc.confDestination.description }}</td>
                                <td style="max-width: 50px;">{{ viewService.getProtocolName(cc.confDestination.protocol) }}</td>
                                <!-- <td style="max-width: 50px;">{{ viewService.getYesNo(cc.enabled) }}</td>
                                <td style="max-width: 50px;">{{ viewService.getYesNo(cc.removeBom) }}</td> -->
                                <td style="max-width: 60px;">{{ viewService.getTypeText(cc.confDestination.type) }}</td>
                                <td>
                                    <div *ngIf="cc.confDestination.destinationMqprofile !== null">
                                        <row-display [label]="'Host:'" [value]="cc.confDestination.destinationMqprofile.host"></row-display>
                                        <row-display [label]="'Port:'" [value]="cc.confDestination.destinationMqprofile.port"></row-display>
                                        <row-display [label]="'Queue manager:'" [value]="cc.confDestination.destinationMqprofile.qm"></row-display>
                                        <row-display [label]="'Channel:'" [value]="cc.confDestination.destinationMqprofile.channel"></row-display>
                                        <row-display [label]="'Queue:'" [value]="cc.confDestination.destinationMqprofile.queue"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationMqprofile.keyVaultProfile != null" [label]="'Key vault:'" [value]="cc.confDestination.destinationMqprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationMqprofile.keyVaultProfile != null" [label]="'Secret name:'" [value]="cc.confDestination.destinationMqprofile.keyVaultProfile.secretName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationMqprofile.keyVaultProfile != null" [label]="'Secret description:'" [value]="cc.confDestination.destinationMqprofile.keyVaultProfile.name"></row-display>
                                    </div>
                                    <div *ngIf="cc.confDestination.destinationSbprofile !== null">
                                        <row-display [label]="'Namespace:'" [value]="cc.confDestination.destinationSbprofile.namespace"></row-display>
                                        <row-display [label]="'Queue:'" [value]="cc.confDestination.destinationSbprofile.queue"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationSbprofile.keyVaultProfile != null" [label]="'Key vault:'" [value]="cc.confDestination.destinationSbprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationSbprofile.keyVaultProfile != null" [label]="'Secret name:'" [value]="cc.confDestination.destinationSbprofile.keyVaultProfile.secretName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationSbprofile.keyVaultProfile != null" [label]="'Secret description:'" [value]="cc.confDestination.destinationSbprofile.keyVaultProfile.name"></row-display>
                                    </div>
                                    <div *ngIf="cc.confDestination.destinationHttpprofile !== null">
                                        <row-display [label]="'Host:'" [value]="cc.confDestination.destinationHttpprofile.host"></row-display>
                                        <row-display [label]="'Port:'" [value]="cc.confDestination.destinationHttpprofile.port"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationHttpprofile.keyVaultProfile != null" [label]="'Key vault:'" [value]="cc.confDestination.destinationHttpprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationHttpprofile.keyVaultProfile != null" [label]="'Secret name:'" [value]="cc.confDestination.destinationHttpprofile.keyVaultProfile.secretName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationHttpprofile.keyVaultProfile != null" [label]="'Secret description:'" [value]="cc.confDestination.destinationHttpprofile.keyVaultProfile.name"></row-display>
                                        <row-display [label]="'Transfer queue:'" [value]="cc.confDestination.destinationHttpprofile.path"></row-display>
                                    </div>
                                    <div *ngIf="cc.confDestination.destinationSharedFolderProfile !== null">
                                        <row-display [label]="'Server:'" [value]="cc.confDestination.destinationSharedFolderProfile.server"></row-display>
                                        <row-display [label]="'Path:'" [value]="cc.confDestination.destinationSharedFolderProfile.path"></row-display>
                                        <row-display [label]="'Filter:'" [value]="cc.confDestination.destinationSharedFolderProfile.filter"></row-display>
                                        <row-display [label]="'Encoding:'" [value]="cc.confDestination.destinationSharedFolderProfile.encoding"></row-display>
                                        <row-display [label]="'Filename:'" [value]="cc.confDestination.destinationSharedFolderProfile.filename"></row-display>
                                    </div>
                                    <div *ngIf="cc.confDestination.destinationSftpprofile !== null">
                                        <row-display [label]="'Name:'" [value]="cc.confDestination.destinationSftpprofile.name"></row-display>
                                        <row-display [label]="'Host:'" [value]="cc.confDestination.destinationSftpprofile.host"></row-display>
                                        <row-display [label]="'Port:'" [value]="cc.confDestination.destinationSftpprofile.port"></row-display>
                                        <row-display [label]="'Path:'" [value]="cc.confDestination.destinationSftpprofile.path"></row-display>
                                        <row-display [label]="'Temporary path:'" [value]="cc.confDestination.destinationSftpprofile.tempPath"></row-display>
                                        <row-display [label]="'Encoding:'" [value]="cc.confDestination.destinationSftpprofile.encoding"></row-display>
                                        <row-display [label]="'Filename:'" [value]="cc.confDestination.destinationSftpprofile.filename"></row-display>
                                        <row-display [label]="'Username:'" [value]="cc.confDestination.destinationSftpprofile.userName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationSftpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="cc.confDestination.destinationSftpprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationSftpprofile.keyVaultProfileId != undefined" [label]="'Secret:'" [value]="cc.confDestination.destinationSftpprofile.keyVaultProfile.secretName"></row-display>
                                    </div>
                                    <div *ngIf="cc.confDestination.destinationFtpprofile !== null">                                        
                                        <row-display [label]="'Name:'" [value]="cc.confDestination.destinationFtpprofile.name"></row-display>
                                        <row-display [label]="'Host:'" [value]="cc.confDestination.destinationFtpprofile.host"></row-display>
                                        <row-display [label]="'Port:'" [value]="cc.confDestination.destinationFtpprofile.port"></row-display>
                                        <row-display [label]="'Path:'" [value]="cc.confDestination.destinationFtpprofile.path"></row-display>
                                        <row-display [label]="'Temporary path:'" [value]="cc.confDestination.destinationFtpprofile.tempPath"></row-display>
                                        <row-display [label]="'Encoding:'" [value]="cc.confDestination.destinationFtpprofile.encoding"></row-display>
                                        <row-display [label]="'Filename:'" [value]="cc.confDestination.destinationFtpprofile.filename"></row-display>
                                        <row-display [label]="'Username:'" [value]="cc.confDestination.destinationFtpprofile.userName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationFtpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="cc.confDestination.destinationFtpprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="cc.confDestination.destinationFtpprofile.keyVaultProfileId != undefined" [label]="'Secret:'" [value]="cc.confDestination.destinationFtpprofile.keyVaultProfile.secretName"></row-display>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </fieldset>
    </div>
</div>
<div class="modal-footer">
    <span *ngIf="configuration && configuration.simpleEntityMap.createTimestamp">Created at {{configuration.simpleEntityMap.createTimestamp | date: 'yyyy-MM-dd HH:mm:ss'}}</span>
    <span *ngIf="configuration && configuration.simpleEntityMap.createdBy">by {{configuration.simpleEntityMap.createdBy}}</span>
    <span *ngIf="configuration && configuration.simpleEntityMap.updateTimestamp">, Updated at {{configuration.simpleEntityMap.updateTimestamp | date: 'yyyy-MM-dd HH:mm:ss'}}</span>
    <span style="margin-right: 10px;" *ngIf="configuration && configuration.simpleEntityMap.updatedBy">by {{configuration.simpleEntityMap.updatedBy}}</span>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>