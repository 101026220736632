import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { MessagesPerSenderModel } from './models/messages-per-sender-model';
import { OrdersPerSenderModel } from './models/orders-per-sender-model';
import { OrderLinesPerDayModel } from './models/order-lines-per-day-model';
import { MessagesPerDayModel } from './models/messages-per-day-model';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getOrderLinesPerDay(timeSpan: string, binSize: string, count: number): Observable<Array<OrderLinesPerDayModel>> {
        return this.http.get<Array<OrderLinesPerDayModel>>(this.baseUrlLocal + `statistics/orderlinesperday?timeSpan=${timeSpan}&binSize=${binSize}&count=${count}`);
    }

    public getOrdersPerSender(timeSpan: string, count: number): Observable<Array<OrdersPerSenderModel>> {
        return this.http.get<Array<OrdersPerSenderModel>>(this.baseUrlLocal + `statistics/orderspersender?timeSpan=${timeSpan}&count=${count}`);
    }

    public getMessagesPerSender(timeSpan: string, count: number): Observable<Array<MessagesPerSenderModel>> {
        return this.http.get<Array<MessagesPerSenderModel>>(this.baseUrlLocal + `statistics/messagespersender?timeSpan=${timeSpan}&count=${count}`);
    }
    
    public getMessagesPerDay(timeSpan: string, binSize: string, count: number): Observable<Array<MessagesPerDayModel>> {
        return this.http.get<Array<MessagesPerDayModel>>(this.baseUrlLocal + `statistics/messagesperday?timeSpan=${timeSpan}&binSize=${binSize}&count=${count}`);
    }
}