import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TestGridViewComponent } from './test-grid-view.component';
import { GridViewModule } from 'src/app/common-components/grid-view/grid-view.module';


@NgModule({
  declarations: [
    TestGridViewComponent
  ],
  imports: [ GridViewModule
  ],
  providers: []
})
export class TestGridViewModule { }
