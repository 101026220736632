import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { Scheduler } from 'src/app/models/source/scheduler';
import { UtilService } from 'src/app/common-services/util.service';
import { MDSourceInputService } from './md-source-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 import { AddSourceModalComponent } from './add-source-model.component';
import { DeleteSourceModalComponent } from './delete-source-modal.component';
import { EditSourceModalComponent } from './edit-source-model.component';

@Component({
  selector: 'source-input',
  templateUrl: './md-source-input.component.html'
})
export class MDSourceInputComponent implements OnInit {
    @Input() partner: Partner;
    public sourceProtocol: string;

    public schedulers: Array<Scheduler> = new  Array<Scheduler>()
    public sbSecretDdl: DropDownListModel;
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public showSpinner: boolean = true;

    constructor(public utilService: UtilService, public sourceInputService: MDSourceInputService, private authService: MsalService, private modalService: NgbModal) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        this.getPartnerSource(this.partner.id);
    }

    private getPartnerSource(partnerId: number) {
        this.showSpinner = true;
        this.sourceInputService.getPartnerSources(partnerId).subscribe({
            next: (scheduler) => {
                this.showSpinner = false;
                this.schedulers = scheduler;
            },
            error: (error) => {
                this.showSpinner = false;
            }
        });
    }


    public openAddSourceModal() {
        const modalRef = this.modalService.open(AddSourceModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        let scheduler: Scheduler = new Scheduler();
        scheduler.id = 0
   
        modalRef.componentInstance.scheduler = scheduler;
        
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getPartnerSource(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    
         
    public removeScheduler(scheduler: Scheduler) {
        const modalRef = this.modalService.open(DeleteSourceModalComponent, { size: 'size_40' });
        modalRef.componentInstance.scheduler = scheduler;
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'delete') {
                this.getPartnerSource(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });

        
    }

    public editSource(scheduler: Scheduler) {
        // Only need to update profile table in this case!!!
        const modalRef = this.modalService.open(EditSourceModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.scheduler = scheduler;
        modalRef.componentInstance.sourceProtocol = scheduler.source.protocolType.toString();

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getPartnerSource(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }
}
