import { Component, Input, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { UtilService } from 'src/app/common-services/util.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'parser-info',
  templateUrl: './parser-info.component.html',
})
export class ParserInfoComponent implements OnInit {
  @Input() formGroupName: string;  
  public parserForm: FormGroup;
      
  constructor(private rootFormGroup: FormGroupDirective, public utilService: UtilService) { }

  ngOnInit() : void {
    this.parserForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  get form() { return this.parserForm.controls; }
}
