<fieldset class="border p-2" *ngIf="rs.getNestedFormControlValue(form, 'postFormatting', 'postFormattingClassId')">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Post-formatting</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Class name: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ utilService.getClassName(rs.getNestedFormControlValue(form, 'postFormatting', 'postFormattingClassName'))  }}</label>
        </div>
        <div class="col-sm-2">
            <label><b>Description: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'postFormatting', 'postFormattingClassDescription')  }}</label>
        </div>
    </div>
</fieldset>