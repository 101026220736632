// export class MessageType {
//     inboundMessageTypeId: number;
//     messageName: string;
//     messageTypeDescription: string;
//     createdBy: string;
//     createTimestamp: string;
// }

export class MessageTypeEditable {
    messageTypeId: number;
    messageName: string;
    messageTypeDescription: string;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
    isEdit: boolean;
}

export class InboundMessageType {
    inboundMessageTypeId: number;
    messageName: string;
    messageTypeDescription: string;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
}

export class OutboundMessageType {
    messageTypeId: number;
    messageName: string;
    messageTypeDescription: string;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
}
