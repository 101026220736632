import { AfterContentInit, AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// import { GridViewColumnComponent } from './grid-view-column.component';
// import { GridViewCommandColumnComponent, GridViewRemoveCommandDirective } from './grid-view-command-column.component';
import { GridViewColumnDirective } from './directives/grid-view-column.directive';
import { GridViewCommandColumnDirective } from './directives/grid-view-command-column.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { GenericEditDialogComponent } from './generic-edit-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { GridViewCommandColumnDirective } from './directives/grid-view-command-column.directive';

@Component({
  selector: 'grid-view',
  templateUrl: './grid-view.component.html',
})
export class GridViewComponent implements AfterViewInit, AfterContentInit {
    @Input() data: FormGroup = new FormGroup({});
    @Input() editFormTemplate: FormGroup = new FormGroup({});
    // @Input() editFormTemplate: FormGroup | null = null;
  
    @ContentChildren(GridViewColumnDirective) columnsDirectives: QueryList<GridViewColumnDirective> = new QueryList<GridViewColumnDirective>();
    @ContentChildren(GridViewCommandColumnDirective) commandColumns: QueryList<GridViewCommandColumnDirective> = new QueryList<GridViewCommandColumnDirective>();
  
    columns: any[] = [];
  
    constructor(private modalService: NgbModal) {
      //console.log('Constructor - data:', this.data);
    }
  
    ngOnInit() {
      //console.log('ngOnInit - data:', this.data);
    }
  
    ngAfterContentInit(): void {
      this.handleCommandColumnEvents();
    }
  
    public ngAfterViewInit(): void {
      // Set the columns on each regular column directive
      this.columnsDirectives.toArray().forEach(columnDirective => {
        this.columns.push({
          title: columnDirective.title,
          field: columnDirective.field,
          isCommandColumn: false,
          template: columnDirective.template
        });
      });
  
      // Set the columns on each command column directive
      this.commandColumns.toArray().forEach(commandDirective => {
        this.columns.push({
          title: commandDirective.title,
          isCommandColumn: true,
          template: commandDirective.template
        });
      });
    }
  
    private handleCommandColumnEvents(): void {
      this.commandColumns.forEach((commandColumn) => {
        commandColumn.removeClicked.subscribe((row) => this.showConfirmationDialog(row));
        commandColumn.editClicked.subscribe((row) => this.editRow(row));
      });
    }
  
  
    // editRow(row: AbstractControl): void {
    //   // Implement edit row logic here
    //   console.log('Edit row:', row);
    // }
  
    showConfirmationDialog(row: AbstractControl): void {
      const modalRef = this.modalService.open(ConfirmationDialogComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
  
      modalRef.componentInstance.title = 'Confirmation';
      modalRef.componentInstance.message = 'Are you sure you want to remove this item?';
  
      modalRef.result.then((result) => {
        if (result) {
          this.removeRow(row);
        }
      });
    }
  
    public editRow = (row: AbstractControl): void => {
      if (!this.editFormTemplate) {
        console.error('No editFormTemplate provided.');
        return;
      }
  
      const modalRef = this.modalService.open(GenericEditDialogComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
  
      modalRef.componentInstance.title = 'Edit Item';
      modalRef.componentInstance.editForm = this.createEditForm(row);
  
      modalRef.result.then((result) => {
        if (result) {
          // Implement logic to handle edited data
          console.log('Edited data:', modalRef.componentInstance.editForm.value);
        }
      });
    }
  
    createEditForm(row: AbstractControl): FormGroup {
      // Implement logic to create edit form based on the provided row
      // Use editFormTemplate as a template for creating the form
  
      const editForm = this.editFormTemplate;
  
      // Set initial values from the provided row
      editForm?.patchValue(row.value);
  
      return editForm;
    }

    // We use Arrow Function in the Method Definition to be able to access this
    // Note: id is mandatory for the FormArray!  
    public removeRow = (row: any): void => {
      const modalRef = this.modalService.open(ConfirmationDialogComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
    
      modalRef.componentInstance.title = 'Confirmation';
      modalRef.componentInstance.message = 'Are you sure you want to remove this item?';
    
      modalRef.result.then((result) => {
        if (result) {
          // User clicked 'Yes', proceed with removal
          const rowsArray = this.data.get('rows') as FormArray;
          const id = row.id;
          // Find the index of the row based on the "id" column value
          const index = rowsArray.controls.findIndex((row) => {
            const idControl = (row as FormGroup).get('id');
            return idControl && idControl.value === id;
          });
  
          if (index !== -1) {
            rowsArray.removeAt(index);
  
            // TODO trigger function on calling Component to save data!
          }
        }
      });
    }
  

  
    public toArray(formArray: any): any[] {
      //console.log('toArray - data:', this.data);
      return Object.keys(formArray).map((index) => formArray[index]);
    }
  
    // Utility methods to handle types
    public isFormArray(control: AbstractControl | null): control is FormArray {
      //console.log('isFormArray - data:', this.data);
      return control instanceof FormArray;
    }
  
    public getFormArray(control: AbstractControl | null): FormArray | null {
      //console.log('getFormArray - data:', this.data);
      return this.isFormArray(control) ? control : null;
    }
  
    public getFormControl(formGroup: AbstractControl | null, field: string): AbstractControl | null {
      //console.log('getFormControl - data:', this.data);
      return formGroup instanceof FormGroup ? formGroup.get(field) : null;
    }
}
