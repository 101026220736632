import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/common-services/util.service';
import { MasterDataPrestepClassesService } from '../md-prestep-classes.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';
import { PreStepClass } from 'src/app/models/pre-step-class';

@Component({
  selector: 'delete-prestep-class', // TODO: Is selector needed for modal?
  templateUrl: './delete-prestep-class.component.html',
})
export class DeletePrestepClassComponent implements OnInit {
    @Input() preStepClass: PreStepClass;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;

    constructor(public activeModal: NgbActiveModal, public service: MasterDataPrestepClassesService, private errorParserService: ErrorParserService, public utilService: UtilService) {}

    ngOnInit(): void {
    }

    public delete() {
        this.showSpinner = true; 
        this.service.deletePrestepClass(this.preStepClass).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('delete');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
              }
        );
    }
}
