export class DestinationSharedFolderProfile {
    id: number;
    name: string;
    server: string;
    path: string;
    filter: string;
    encoding: string;
    filename: string;
    createdBy: string;
    createTimestamp: string;
}

