<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound message type</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Type: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-6">
            <label *ngIf="rs.getNestedFormControlValue(form, 'outboundMessageType', 'messageName')">{{ rs.getNestedFormControlValue(form, 'outboundMessageType', 'messageName') }}</label>
            <label *ngIf="!rs.getNestedFormControlValue(form, 'outboundMessageType', 'messageName')" class="text-red">MISSING DATA</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Description: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-6">
            <label *ngIf="rs.getNestedFormControlValue(form, 'outboundMessageType', 'messageTypeDescription')">{{ rs.getNestedFormControlValue(form, 'outboundMessageType', 'messageTypeDescription') }}</label>
            <label *ngIf="!rs.getNestedFormControlValue(form, 'outboundMessageType', 'messageTypeDescription')" class="text-red">MISSING DATA</label>
        </div>
    </div>
</fieldset>