import { ParserClass } from "./parser-class";

export class InboundParser {
    id: number;
    partner: number;
    messageType: number;
    //parser: string;
    enabled: boolean;
    //description: string;
    metaData: string;
    createdBy: string;
    createTimestamp: string;
    parserClassId: number;
    parserClass: ParserClass;    
    senderIdPath: string;
    receiverIdPath: string;
    primaryIdPath: string;
    referenceIdPath: string;
    taskIdPath: string;
    countryCodePath: string;
    customLookupIdPath: string;
    areaPlantIdPath: string;
    namespacesUri: string;

}
