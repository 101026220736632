import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MDEdiPreFormatterInputService } from './md-ediPreFormatter-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { UtilService } from 'src/app/common-services/util.service';
import { EDIPreFormatter } from 'src/app/models/edi-preformatter/edi-preformatter';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'edit-ediPreFormatter',
  templateUrl: './edit-ediPreFormatter-modal.component.html'
})
export class EditEdiPreFormatterModalComponent implements OnInit {
  @Input() partner: Partner;
  @Input() ediPreFormatter: EDIPreFormatter;
  public ediPreFormatterForm: FormGroup;
  public errorModel: ErrorModel = null;
  public showSpinner: boolean = false;

  constructor(public activeModal: NgbActiveModal, private ediPreFormatterInputService: MDEdiPreFormatterInputService, public utilService: UtilService, private errorParserService: ErrorParserService) { }

  ngOnInit(): void {
    this.showSpinner = true;
    this.ediPreFormatterForm = this.ediPreFormatterInputService.getInboundPartnerEdiPreFormatterForm(this.ediPreFormatter);
    this.showSpinner = false;
  }

  get form() { return this.ediPreFormatterForm.controls; }

  public save() {
    this.showSpinner = true;
    let ediPreFormatter = this.ediPreFormatterInputService.getInboundPartnerEdiPreFormatterModel(this.ediPreFormatterForm)

    this.ediPreFormatterInputService.updateInboundPartnerEdiPreFormatter(ediPreFormatter).subscribe(
      {
        next: (result) => {
          this.showSpinner = false;
          this.activeModal.close('save');
        },
        error: (error) => {
          this.showSpinner = false;
          this.errorModel = this.errorParserService.extractErrors(error);
          console.error(error);
        }
      }
    );
  }
}
