import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorDisplayComponent } from './error-display.component';

@NgModule({
  imports:      [ 
    CommonModule    
  ],  
  providers:    [  ],
  declarations: [ ErrorDisplayComponent ],
  exports:      [ ErrorDisplayComponent ]
})
export class ErrorDisplayModule { }