<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Source</span></legend>
    <div class="row mb5">
        <div class="col-sm-12">
            <table class="table table-striped table-bordered">
                <thead>                    
                    <tr>
                        <th>Description</th>
                        <th style="max-width: 50px;">Protocol</th>
                        <th style="max-width: 50px;">Enabled</th>
                        <th style="max-width: 50px;">Encodings</th>
                        <th style="max-width: 50px;">Validations</th>
                        <th style="max-width: 50px;">EDI type</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody *ngIf="schedulers == undefined">
                    <tr>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                        <td><label class="text-red">MISSING DATA</label></td>
                    </tr>
                </tbody>
                <tbody *ngIf="schedulers != undefined">
                    <tr *ngFor="let s of schedulers">
                        <td>
                            <label *ngIf="s.source.name">{{ s.source.name }}</label>
                            <label *ngIf="!s.source.name" class="text-red">MISSING DATA</label>
                        </td> 
                        <td>{{ viewService.getProtocolName(s.source.protocolType) }}</td>
                        <td>{{ viewService.getYesNo(s.enabled) }}</td>
                        <td>
                            <div class="row">
                                <div><b>Input:</b> 
                                    <!-- {{s.inputEncoding === null ? '-' : s.inputEncoding}} -->
                                </div>
                            </div>
                            <div class="row">
                                <div><b>Output:</b> 
                                    <!-- {{s.outputEncoding === null ? '-' : s.outputEncoding}} -->
                                </div>
                            </div>
                        </td>
                        <td>{{ viewService.getValidationType(s.validationType) }}</td> 
                        <td>{{ viewService.getEDITypeName(s.source.ediType) }} </td>
                        <td>
                            <div *ngIf="s.source.sourceMQProfile !== null">
                                <div class="row">
                                    <div class="col-sm-3"><b>Host:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceMQProfile.host}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Port:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceMQProfile.port}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Queue manager:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceMQProfile.qm}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Channel:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceMQProfile.channel}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Queue:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceMQProfile.queue}}</div>
                                </div>     
                            </div>
                            <div *ngIf="s.source.sourceSBProfile !== null">
                                <div class="row">
                                    <div class="col-sm-3"><b>Namespace:</b></div>
                                    <div class="col-sm-9">
                                        <label *ngIf="s.source.sourceSBProfile.namespace">{{ s.source.sourceSBProfile.namespace }}</label>
                                        <label *ngIf="!s.source.sourceSBProfile.namespace" class="text-red">MISSING DATA</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Queue:</b></div>
                                    <div class="col-sm-9">
                                        <label *ngIf="s.source.sourceSBProfile.queue">{{ s.source.sourceSBProfile.queue }}</label>
                                        <label *ngIf="!s.source.sourceSBProfile.queue" class="text-red">MISSING DATA</label>
                                    </div>
                                </div>
                                <div *ngIf="s.source.sourceSBProfile.useManagedIdentity == false">
                                    <div class="row">
                                        <div class="col-sm-3"><b>Shared access keyName:</b></div>
                                        <div class="col-sm-9">
                                            <label *ngIf="s.source.sourceSBProfile.sharedAccessKeyName && !s.source.sourceSBProfile.useManagedIdentity">{{ s.source.sourceSBProfile.sharedAccessKeyName }}</label>
                                            <label *ngIf="!s.source.sourceSBProfile.sharedAccessKeyName && !s.source.sourceSBProfile.useManagedIdentity" class="text-red">MISSING DATA</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="s.source.sourceSBProfile.useManagedIdentity == true">
                                    <div class="row">
                                        <div class="col-sm-3"><b>Use ManagedIdentity:</b></div>
                                        <div class="col-sm-9">
                                            <label>{{ s.source.sourceSBProfile.useManagedIdentity }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="s.source.sourceSharedFolderProfile !== null">
                                <div class="row">
                                    <div class="col-sm-3"><b>Server:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceSharedFolderProfile.server}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Path:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceSharedFolderProfile.path}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Filter:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceSharedFolderProfile.filter}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Input encoding:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceSharedFolderProfile.inputEncoding}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3"><b>Output encoding:</b></div>
                                    <div class="col-sm-9">{{s.source.sourceSharedFolderProfile.outputEncoding}}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</fieldset>