<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}" >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ isNew === true ? 'Add ' : 'Edit ' }}partner</h4>          
        </div>
            <div class="modal-body">
              <div class="alert alert-danger" role="alert" *ngIf="error">
                <b>{{errorMessage}}</b>
              </div>
              <fieldset class="border p-2">
                <div class="form-group row mb5">
                  <label for="name required" class="col-sm-3 col-form-label">Name <span class="req-color">*</span></label>
                  <div class="col-sm-6">
                      <input type="text" class="form-control" required id="name" name="name" placeholder="Name" [(ngModel)]="partnerRequest.name">
                  </div>
                </div>

                <fieldset class="border p-2">
                    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Sender schemas</span></legend>
  
                    <div class="form-group row ">
                      <label for="schemasEdifactDDL" class="col-sm-3 col-form-label control-label">Qualifier <span class="req-color">*</span></label>
                      <div class="col-sm-6">
                        <select dropdown (change)="selectQualifierChangeHandler($event)" id="schemasEdifactDDL" name="schemasEdifactDDL" class="form-select form-select-sm control-label" [(ngModel)]="selectedQualifier">
                          <option value="">--- Select qualifier ---</option>
                          <option *ngFor="let item of qualifierDDL" [value]="item.value">
                            {{item.text}}
                          </option>
                        </select>                        
                        
                      </div>
                    </div>

                    <div class="form-group row mb5">
                      <label for="name" class="col-sm-3 col-form-label">Value <span class="req-color">*</span></label>
                      <div class="col-sm-6">
                        <table>
                          <tr>
                            <td>
                              <input type="text" class="form-control col-sm-6" required id="name" name="name" placeholder="Name" [(ngModel)]="businessIdentityValue">
                            </td>
                            <td><button class="btn btn-outline-secondary btn-sm" (click)="addBusinessIdentity()">Add</button></td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="partnerRequest">
                      <thead>
                          <tr>
                              <th><div>Qualifier</div></th>
                              <th><div>Value</div></th>
                              <th></th>
                              </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let bi of partnerRequest.businessIdentities">
                              <td>{{ bi.qualifier }}</td>
                              <td>{{ bi.value }}</td>                                          
                              <td>
                                <button class="btn btn-outline-secondary btn-sm" (click)="removeBusinessIdentity(bi)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  </fieldset>

              </fieldset>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary btn-sm" (click)="upsertPartner()">Save</button>
          <button type="button" class="btn btn-outline-secondary btn-sm" (click)="closePopup()">Cancel</button>
        </div>
      </div>
    </div>
  </div>