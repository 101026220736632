<h3>Login</h3>

<button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>
<button mat-raised-button color="accent" *ngIf="loginDisplay" (click)="logout()">Logout</button>

<p class="text-left" *ngIf="loginDisplay">
  See below the claims in your <strong> ID token </strong>. For more information, visit:
  <span>
      <a href="https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token">
          docs.microsoft.com
      </a>
  </span>
</p>

<div>
  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="dataSource">
    <thead>
        <tr>
            <th>Claim</th>
            <th>Value</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let ds of dataSource">
            <td>{{ ds.claim }}</td>
            <td>{{ ds.value }}</td>
        </tr>
    </tbody>
  </table>
</div>