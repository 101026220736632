import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { DestinationFtpprofile } from 'src/app/models/destination/destination-ftp-profile';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FtpProfileService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getAllFtpProfiles(): Observable<Array<DestinationFtpprofile>> {
        return this._http.get<Array<DestinationFtpprofile>>(`${this.baseUrlLocal}confdestinations/ftpprofiles`);
    }

    public setupRequiredValidation(destinationFtpprofileForm: FormGroup) {
        const id = destinationFtpprofileForm.get('id');
        
        const host = destinationFtpprofileForm.get('host');

        if(destinationFtpprofileForm.value.useExistingDestination === true) {
            id.setValue('');
            id.setValidators([Validators.required]);
            host.setValidators(null);
        } else {
            id.setValue(0);
            id.setValidators(null); // We could have required here as we set the id to 0 that is a value, but as we hard code...naahh
            host.setValidators([Validators.required]);            
        }
        id.updateValueAndValidity();
        host.updateValueAndValidity();
    }
}
