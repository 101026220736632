<fieldset class="border p-2" *ngIf="responseResolver">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Response resolver</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Class name:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ utilService.getClassName(responseResolver.responseResolver.responseResolverClass) }}</label>
        </div>
        <div class="col-sm-2">
            <label><b>Description:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ responseResolver.responseResolver.description }}</label>
        </div>
    </div>
</fieldset>