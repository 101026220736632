import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { generate } from 'rxjs';
import { Partner } from 'src/app/models/partner/partner';
import { ConfigurationWizardService } from '../../configuration-wizard.service';

@Component({
  selector: 'outbound-partner-input',
  templateUrl: './outbound-partner-input.component.html'
})
export class OutboundPartnerInputComponent implements OnInit {
  @Input() partners: Array<Partner>;
  @Input() formGroupName: string;  
  @Output() partnerUpdated = new EventEmitter<any>();

  public outboundPartnerForm: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, private wService: ConfigurationWizardService) {}

  ngOnInit(): void {
    this.outboundPartnerForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  get form() { return this.outboundPartnerForm.controls; }

  selectPartnerIdChangeHandler(event: any) {
    let selectedOutboundPartner = this.partners.find(x => x.id === +event.target.value);
    this.outboundPartnerForm.controls['partnerId'].setValue(selectedOutboundPartner.partnerId);
    this.outboundPartnerForm.controls['name'].setValue(selectedOutboundPartner.name);
    this.outboundPartnerForm.controls['description'].setValue(selectedOutboundPartner.description);
    this.partnerUpdated.emit(selectedOutboundPartner);
  }
}
