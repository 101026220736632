<form [formGroup]="outboundPartnerForm">
  <fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound partner</span></legend>
  
    <div class="form-group row" *ngIf="partners">
      <label for="partnerDDL" class="col-sm-2 col-form-label control-label">Outbound partner <span class="req-color">*</span></label>
      <div class="col-sm-6">
        <select dropdown formControlName="outboundPartnerId" (change)="selectPartnerIdChangeHandler($event)" *ngIf="partners" id="outboundPartnersDDL" name="outboundPartnersDDL" class="form-select form-select-sm control-label">
          <option value="">--- Select partner ---</option>
          <option *ngFor="let item of partners" [value]="item.id.toString()">
            {{item.name}} ({{item.partnerId}})
          </option>
        </select>
        <div *ngIf="form.outboundPartnerId.invalid && (form.outboundPartnerId.dirty || form.outboundPartnerId.touched)" class="alert alert-danger">
          <div *ngIf="form.outboundPartnerId.errors?.['required']">Outbound partner is required.</div>
        </div>
      </div>
    </div>
  </fieldset>
</form>

  