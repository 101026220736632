import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { ParserClassService } from '../md-parser-class.service';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'add-parser-class',
  templateUrl: './add-parser-class.component.html'
})
export class AppParserClassComponent implements OnInit {
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public parserClassForm: FormGroup;

    constructor(public parserClassService: ParserClassService, public activeModal: NgbActiveModal, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        this.parserClassForm = this.parserClassService.getEmptyParserClassForm();
    }

    get form() { return this.parserClassForm.controls; }

    public save() {
        this.showSpinner = true;
        let model = this.parserClassService.getParserClassModel(this.parserClassForm);
        this.parserClassService.addParsers(model).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }
}
