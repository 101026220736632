import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TrackingView } from '../models/tracking-view';
import { Observable } from 'rxjs';
import { Tracking } from '../models/tracking';
import { BlobRequest } from '../models/blob-request';
import { BlobResponse } from '../models/blob-response';
import { GridView } from '../models/grid-view';
import { TrackingEventDetails } from '../models/tracking-event-details';
import { TrackingEventDelivery } from '../models/tracking-event-delivery';
import { CancelRequestModel } from '../models/cancel-request-model';

@Injectable({
  providedIn: 'root',
})
export class MessageTrackerService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    public trackingBaseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
        this.trackingBaseUrlLocal = environment.apiTrackingBaseUrl;
    }

    public getTrackingData(startDate: string, endDate: string, sender: string, receiver: string, messagetype: string, primaryID: string, status: string, flowid: string, partnerid: string, senderId: string, receiverId: string, pageindex: number, pagesize: number, isArchive: boolean) : Observable<GridView> {
        let queryStart = '';
        let queryString = '';

        queryStart = this.getQueryStart(queryStart);
        let isarchiveString = isArchive === false ? 'false' : 'true';
        queryString = queryString.concat(`${queryStart}isarchive=${isarchiveString}`)

        if(messagetype != undefined && messagetype.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}messagetype=${messagetype}`)
        }
        if(primaryID != undefined) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}primarymessageid=${primaryID}`)
        }
        if(status != '') {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}status=${status}`)
        }
        if(sender != undefined && sender.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}sendername=${sender}`)
        }
        if(senderId != undefined && senderId.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}senderid=${senderId}`)
        }
        if(receiver != undefined && receiver.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}receivername=${receiver}`)
        }
        if(receiverId != undefined && receiverId.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}receiverid=${receiverId}`)
        }
        if(flowid != undefined && flowid.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}flowid=${flowid}`)
        }
        if(partnerid != undefined && partnerid.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}partnerid=${partnerid}`)
        }
        if(startDate != undefined && startDate.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}startDate=${startDate}`)
        }
        if(endDate != undefined && endDate.length > 2) {
            queryStart = this.getQueryStart(queryStart);
            queryString = queryString.concat(`${queryStart}endDate=${endDate}`)
        }
        
        queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
        queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)

        return this.http.get<GridView>(this.trackingBaseUrlLocal + `tracking` + queryString);
    }

    public getTrackingDataForFlowId(flowid: string, isArchive: boolean) : Observable<TrackingEventDetails> {
        return this.http.get<TrackingEventDetails>(this.baseUrlLocal + `tracking/${flowid}/${isArchive}`);
    }

    public getBlobData(blobRequest: BlobRequest) {
        return this.http.post<BlobResponse>(this.baseUrlLocal + 'blob', blobRequest);
    }

    public addCancelMessage(cancelRequestModel: CancelRequestModel) {
        return this.http.post(this.baseUrlLocal + 'tracking/cancel', cancelRequestModel);
    }

    private getQueryStart(queryStart: string) {
        if(queryStart === '') {
            return  '?';
        } else {
            return '&';
        }
    }

    public getSentMessage(trackingEventDelivery: TrackingEventDelivery) : string {
        let sentMessage = '';
        if(trackingEventDelivery.success === true) {
            switch (trackingEventDelivery.confDestination.protocol) {
                case 1: // SB
                    sentMessage = `File sent to Service Bus queue ${trackingEventDelivery.confDestination.destinationSbprofile.queue} on namespace ${trackingEventDelivery.confDestination.destinationSbprofile.namespace}`;
                    break;
                case 2: // FILESHARE
                    sentMessage = `File ${trackingEventDelivery.filename} sent to ${trackingEventDelivery.confDestination.destinationSharedFolderProfile.server}${trackingEventDelivery.confDestination.destinationSharedFolderProfile.path}}.`;
                    break;
                case 3: // FTP
                    sentMessage = `File ${trackingEventDelivery.filename} sent to host: ${trackingEventDelivery.confDestination.destinationFtpprofile.host}, path: ${trackingEventDelivery.confDestination.destinationFtpprofile.path}.`;
                    break;
                case 7: // MQ
                    sentMessage = `File sent to IBM MQ queue ${trackingEventDelivery.confDestination.destinationMqprofile.queue} on Host ${trackingEventDelivery.confDestination.destinationMqprofile.host}, Queue Manager ${trackingEventDelivery.confDestination.destinationMqprofile.qm}, and Channel ${trackingEventDelivery.confDestination.destinationMqprofile.channel}.`;
                    break;
                case 8: // SFTP
                    sentMessage = `File ${trackingEventDelivery.filename} sent to host: ${trackingEventDelivery.confDestination.destinationSftpprofile.host}, path: ${trackingEventDelivery.confDestination.destinationSftpprofile.path}.`;
                    break;
                case 9: // HTTP
                    sentMessage = `File sent to HTTP endpoint ${trackingEventDelivery.confDestination.destinationHttpprofile.host}.`;
                    break;            
                default:
                    sentMessage = 'No send information';
                    break;
            }
        } else {
            sentMessage = trackingEventDelivery.message;
        }

        return sentMessage;
    }
}


