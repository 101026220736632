// import { Time } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timestamp-filter-modal',
  templateUrl: './timestamp-filter-modal.component.html'
  //styleUrls: ['./timestamp-filter-modal.component.css']
})
export class TimestampFilterModalComponent implements OnInit {
    @Input() isVisible = false;
    @Input() startDate!: string;
    @Input() endDate!: string;
    @Input() startTime!: string;
    @Input() endTime!: string;

    public startdatemodel: NgbDateStruct;
    public enddatemodel: NgbDateStruct;
    
    constructor(public activeModal: NgbActiveModal) {}

    public ngOnInit(): void {
      if(this.startDate === null) {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        this.startTime = `${hours}:${minutes}:${seconds}`;
        this.endTime = `${hours}:${minutes}:${seconds}`;
      } else {
        // Set saved dates to NgbDateStruct
        this.startdatemodel = {
          year: +this.startDate.substring(0, 4),
          month: +this.startDate.substring(5, 7),
          day: +this.startDate.substring(8, 10)
        };

        this.enddatemodel = {
          year: +this.endDate.substring(0, 4),
          month: +this.endDate.substring(5, 7),
          day: +this.endDate.substring(8, 10)
        };
      }

    }

    public closeModal() {
      this.activeModal.close({ startDate: null, startTime: null, endDate: null, endTime: null });
    }

    public applyFilter() {
        this.startDate = `${this.startdatemodel.year}-${this.startdatemodel.month.toString().padStart(2, '0')}-${this.startdatemodel.day.toString().padStart(2, '0')}`;
        this.endDate = `${this.enddatemodel.year}-${this.enddatemodel.month.toString().padStart(2, '0')}-${this.enddatemodel.day.toString().padStart(2, '0')}`;

        this.activeModal.close({ startDate: this.startDate, startTime: this.startTime, endDate: this.endDate, endTime: this.endTime });
    }

    public onTimeInput(event: Event) {
        const input = event.target as HTMLInputElement;
        let value = input.value;
    
        // Allow only digits and colons
        value = value.replace(/[^0-9:]/g, '');
    
        // Automatically enforce the hh:mm:ss format
        if (value.length > 2 && value[2] !== ':') {
          value = value.substring(0, 2) + ':' + value.substring(2);
        }
        if (value.length > 5 && value[5] !== ':') {
          value = value.substring(0, 5) + ':' + value.substring(5);
        }
    
        // Ensure value doesn't exceed hh:mm:ss
        value = value.substring(0, 8);
    
        // Set the formatted value back to the input
        input.value = value;
    }
}
