<div>
    <fieldset class="border p-2">
        <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Destinations</span></legend>
        <div style="margin-bottom: 10px;">
            <span class="text-muted"><small>A message can be sent to one or more destinations. Destinations protocals can be HTTP endpoint, Service Bus, MQ, SFTP or File Share.</small></span>
        </div>

        <div class="form-group row form-inline mb10">
            <label for="protocolDDL" class="col-sm-2 col-form-label control-label">Destinations</label>
            <div class="col-sm-8">
                <select dropdown (change)="selectChangeHandler($event)" id="confDestinationDDL" name="confDestinationDDL" class="form-select form-select-sm control-label" [(ngModel)]="selectedDestinationValue">
                    <option value="-1">--- Select destination ---</option>
                    <option *ngFor="let item of outboundPartnerDestinations" [value]="item.id">
                        {{item.description}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row mb5">
            <div class="col-sm-2 col-form-label"></div>
            <div class="col-sm-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="checkboxEnabled" value="" aria-label="..." [(ngModel)]="confDestConnectionEnabled">
                    <label class="form-check-label" for="checkboxEnabled">
                        Enabled
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group row mb5">
            <div class="col-sm-2 col-form-label"></div>
            <div class="col-sm-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="checkboxRemoveBOM" value="" aria-label="..." [(ngModel)]="removeBom">
                    <label class="form-check-label" for="checkboxRemoveBOM">
                        Remove BOM
                    </label>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-outline-secondary btn-sm" [disabled]="selectedDestinationValue == '-1'" (click)="addDestination()">Add</button>

        <hr />

        <div>
            <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="confDestConnections.length > 0">
                <thead>
                    <tr>                        
                        <th><div>Protocol</div></th>
                        <th><div>Endpoint type</div></th>
                        <th><div>Enabled</div></th>
                        <th><div>Remove BOM</div></th>
                        <th><div>Existing</div></th>
                        <th><div>Destination</div></th>                        
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let d of confDestConnections">
                        <td>{{ utilService.getProtocolName(d.confDestination?.protocol) }}</td>
                        <td>{{ utilService.getTypeText(d.confDestination?.type) }}</td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="confDestConnectionEnabled" (change)="destinationEnabledOnChange(d)" value="" aria-label="..." [(ngModel)]="d.enabled">
                        </td>
                        <td>{{ utilService.getYesNo(d.removeBom) }}</td>
                        <td>-</td>
                        <td>
                            <div *ngIf="d.confDestination.destinationHttpprofile !== null">
                                <row-display [label]="'Name:'" [value]="d.confDestination.destinationHttpprofile.name"></row-display>
                                <row-display [label]="'Host:'" [value]="d.confDestination.destinationHttpprofile.host"></row-display>
                                <row-display [label]="'Port:'" [value]="d.confDestination.destinationHttpprofile.port"></row-display>
                                <row-display [label]="'Path:'" [value]="d.confDestination.destinationHttpprofile.path"></row-display>
                                <row-display [label]="'Authentication:'" [value]="utilService.getAuthenticationName(d.confDestination.destinationHttpprofile.authentication)"></row-display>
                                <row-display [label]="'Username:'" [value]="d.confDestination.destinationHttpprofile.userName"></row-display>
                                <row-display *ngIf="d.confDestination.destinationHttpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="d.confDestination.destinationHttpprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="d.confDestination.destinationHttpprofile.keyVaultProfileId != undefined" [label]="'Secret:'" [value]="d.confDestination.destinationHttpprofile.keyVaultProfile.secretName"></row-display>
                            </div>
                            <div *ngIf="d.confDestination.destinationMqprofile !== null">
                                <!-- 
                                password: string;
                                keyVaultProfile: KeyVaultProfile; -->

                                <row-display [label]="'Name:'" [value]="d.confDestination.destinationMqprofile.name"></row-display>
                                <row-display [label]="'Host:'" [value]="d.confDestination.destinationMqprofile.host"></row-display>
                                <row-display [label]="'Port:'" [value]="d.confDestination.destinationMqprofile.port"></row-display>
                                <row-display [label]="'Channel:'" [value]="d.confDestination.destinationMqprofile.channel"></row-display>
                                <row-display [label]="'Queue manager:'" [value]="d.confDestination.destinationMqprofile.qm"></row-display>
                                <row-display [label]="'Queue:'" [value]="d.confDestination.destinationMqprofile.queue"></row-display>
                                <row-display [label]="'Username:'" [value]="d.confDestination.destinationMqprofile.userName"></row-display>
                          
                            </div>
                            <div *ngIf="d.confDestination.destinationSbprofile !== null">
                                <!-- 
                                authentication: number;
                                keyVaultProfile: KeyVaultProfile;                                
                                -->
                                <row-display [label]="'Name:'" [value]="d.confDestination.destinationSbprofile.name"></row-display>
                                <row-display [label]="'Namespace:'" [value]="d.confDestination.destinationSbprofile.namespace"></row-display>
                                <row-display [label]="'Queue:'" [value]="d.confDestination.destinationSbprofile.queue"></row-display>
                                <row-display [label]="'Shared access key:'" [value]="d.confDestination.destinationSbprofile.sharedAccessKeyName"></row-display>
                                <row-display [label]="'Use session:'" [value]="d.confDestination.destinationSbprofile.useSession === false ? 'NO' : 'YES'"></row-display>
                                <row-display [label]="'Name:'" [value]="d.confDestination.destinationSbprofile.name"></row-display>
                                <row-display *ngIf="d.confDestination.destinationSbprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="d.confDestination.destinationSbprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="d.confDestination.destinationSbprofile.keyVaultProfileId != undefined" [label]="'Secret:'" [value]="d.confDestination.destinationSbprofile.keyVaultProfile.secretName"></row-display>
                            </div>
                            <div *ngIf="d.confDestination.destinationSharedFolderProfile !== null">
                                <row-display [label]="'Name:'" [value]="d.confDestination.destinationSharedFolderProfile.name"></row-display>
                                <row-display [label]="'Server:'" [value]="d.confDestination.destinationSharedFolderProfile.server"></row-display>
                                <row-display [label]="'Path:'" [value]="d.confDestination.destinationSharedFolderProfile.path"></row-display>
                                <row-display [label]="'Filter:'" [value]="d.confDestination.destinationSharedFolderProfile.filter"></row-display>
                                <row-display [label]="'Encoding:'" [value]="d.confDestination.destinationSharedFolderProfile.encoding"></row-display>
                                <row-display [label]="'Filename:'" [value]="d.confDestination.destinationSharedFolderProfile.filename"></row-display>
                            </div>
                            <div *ngIf="d.confDestination.destinationSftpprofile !== null">
                                <row-display [label]="'Name:'" [value]="d.confDestination.destinationSftpprofile.name"></row-display>
                                <row-display [label]="'Host:'" [value]="d.confDestination.destinationSftpprofile.host"></row-display>
                                <row-display [label]="'Port:'" [value]="d.confDestination.destinationSftpprofile.port"></row-display>
                                <row-display [label]="'Path:'" [value]="d.confDestination.destinationSftpprofile.path"></row-display>
                                <row-display [label]="'Temporary path:'" [value]="d.confDestination.destinationSftpprofile.tempPath"></row-display>
                                <row-display [label]="'Encoding:'" [value]="d.confDestination.destinationSftpprofile.encoding"></row-display>
                                <row-display [label]="'Filename:'" [value]="d.confDestination.destinationSftpprofile.filename"></row-display>
                                <row-display [label]="'Username:'" [value]="d.confDestination.destinationSftpprofile.userName"></row-display>
                                <row-display *ngIf="d.confDestination.destinationSftpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="d.confDestination.destinationSftpprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="d.confDestination.destinationSftpprofile.keyVaultProfileId != undefined" [label]="'Secret:'" [value]="d.confDestination.destinationSftpprofile.keyVaultProfile.secretName"></row-display>
                            </div>
                            <div *ngIf="d.confDestination.destinationFtpprofile !== null">
                                <row-display [label]="'Name:'" [value]="d.confDestination.destinationFtpprofile.name"></row-display>
                                <row-display [label]="'Host:'" [value]="d.confDestination.destinationFtpprofile.host"></row-display>
                                <row-display [label]="'Port:'" [value]="d.confDestination.destinationFtpprofile.port"></row-display>
                                <row-display [label]="'Path:'" [value]="d.confDestination.destinationFtpprofile.path"></row-display>
                                <row-display [label]="'Temporary path:'" [value]="d.confDestination.destinationFtpprofile.tempPath"></row-display>
                                <row-display [label]="'Encoding:'" [value]="d.confDestination.destinationFtpprofile.encoding"></row-display>
                                <row-display [label]="'Filename:'" [value]="d.confDestination.destinationFtpprofile.filename"></row-display>
                                <row-display [label]="'Username:'" [value]="d.confDestination.destinationFtpprofile.userName"></row-display>
                                <row-display *ngIf="d.confDestination.destinationFtpprofile.keyVaultProfileId != undefined" [label]="'Key vault:'" [value]="d.confDestination.destinationFtpprofile.keyVaultProfile.keyVaultName"></row-display>
                                <row-display *ngIf="d.confDestination.destinationFtpprofile.keyVaultProfileId != undefined" [label]="'Secret:'" [value]="d.confDestination.destinationFtpprofile.keyVaultProfile.secretName"></row-display>
                            </div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-outline-secondary btn-sm" (click)="removeConfDestConnection(d)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>                
            </table>
          </div>
    </fieldset>
</div>