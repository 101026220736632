export class TabModel {
    activeTab: number;
    basicInfoNextText: string;
    basicInfoNextTab: number;
    sourceNextTab: number;
    sourceNextText: string;
    as2InboundPrevTab: number;
    as2InboundPrevText: string;
    resolverNextTab: number;
    resolverPrevTab: number;
    resolverPrevText: string;
    resolverNextText: string;
    mappingPrevTab: number;
    mappingPrevText: string;
    destinationsNextTab: number;
    destinationsNextText: string;
    reviewPrevTab: number;
    reviewPrevText: string;
}