import { KeyVaultProfile } from "../key-vault-profile";

export class DestinationSbprofile {
    id: number;
    name: string;
    namespace: string;
    queue: string;
    authentication: number;
    keyVaultProfileId: number;
    keyVaultProfile: KeyVaultProfile;
    createdBy: string;
    createTimestamp: string;
    sharedAccessKeyName: string;
    useSession: boolean;
    connectionString: string;
}
