import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerConfiguration } from 'src/app/models/customer-configuration';
import { CustomerConfigurationsService } from '../customer-configurations.service';
// import { CustomerConfigurationsService } from './customer-configurations.service';

@Component({
  selector: 'configuration-selection-grid',
  templateUrl: './configuration-selection-grid.component.html'
})
export class ConfigurationSelectionGridComponent implements OnInit {
    @Input() customerConfigurations!: CustomerConfiguration[];
    @Output() selectedCustomerConfiguration = new EventEmitter<any>();
    
    public originalCustomerConfigurations: CustomerConfiguration[] = [];
    public allCustomerConfigurations: CustomerConfiguration[] = [];
    public pagedCustomerConfigurations: CustomerConfiguration[] = [];
    public ccLength: number = 0;
    public error: boolean = false;
    public showSpinner: boolean = true;
    public currentPage: number = 0;
    public startIndex: number = 0;
    public endIndex: number = 10;
    public pageCount = 10;
    public configurationCount: number = 0;
    public firstDisplayIndex: number = 0;
    public lastDisplayIndex: number = 0;
    public nextButtonDisabled: boolean = false;

    public filterPartner: string;
    public filterSender: string;
    public filterMessageTypeName: string;
    public filterReceiver: string;
    public filterLegalEntity: string;
    public filterAreaPlant: string;
  
    // --- View config -------------------------------------------------------------------
    public displayStyle: string = "none";
    public viewConfigData: CustomerConfiguration;

    constructor(
      private ccService: CustomerConfigurationsService,
      // private router: Router,
      // private route: ActivatedRoute
    ) {
      // this.route.queryParams.subscribe(params => {
      //   this.filterSender = params['sender'];
      //   this.filterReceiver = params['receiver'];
      // });
    }

    public selectConfig(cc: CustomerConfiguration) {
        this.selectedCustomerConfiguration.emit(cc);
    }

    ngOnInit(): void {
        // TODO Search data from preprod!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        this.ccService.getData(true, 1, 10).subscribe(
          {
            next: (result) => {
              this.configurationCount = result.length;
              this.allCustomerConfigurations = result;
              this.originalCustomerConfigurations = result;
              this.pagedCustomerConfigurations = result.slice(this.startIndex, this.endIndex);
              this.lastDisplayIndex = (this.currentPage + 1) * this.pageCount;
              this.onFilter();
              this.showSpinner = false; 
            },
            error: (error) => { 
                // TODO pick up error message and display.
                console.error(error);
                this.showSpinner = false;
            }
          });


        // this.configurationCount = this.customerConfigurations.length;
        // this.allCustomerConfigurations = this.customerConfigurations;
        // this.originalCustomerConfigurations = this.customerConfigurations;
        // this.pagedCustomerConfigurations = this.customerConfigurations.slice(this.startIndex, this.endIndex);
        // this.lastDisplayIndex = (this.currentPage + 1) * this.pageCount;
        // this.onFilter();
    }

  public onclickPrev() {
    if (this.currentPage > 0) {
      this.currentPage = this.currentPage - 1;
      this.startIndex = (this.currentPage * 10);
      this.endIndex = (this.currentPage * 10) + 10;

      this.lastDisplayIndex = (this.currentPage + 1) * this.pageCount;

      this.pagedCustomerConfigurations = this.allCustomerConfigurations.slice(this.startIndex, this.endIndex);
    }
  }

  public onclickNext() {
    // TODO fix blocking of paging when reached end... The below if is wrong!
    // if (this.currentPage * 10 < this.configurationCount) {
    if ((this.currentPage + 1) * this.pageCount < this.configurationCount) {
      this.nextButtonDisabled = false;
      this.currentPage = this.currentPage + 1;
      this.startIndex = (this.currentPage * 10);
      this.endIndex = (this.currentPage * 10) + 10;

      if((this.currentPage + 1) * this.pageCount > this.configurationCount) {
        this.lastDisplayIndex = this.configurationCount;
        
      } else {
        this.lastDisplayIndex = (this.currentPage + 1) * this.pageCount;
      }

      this.pagedCustomerConfigurations = this.allCustomerConfigurations.slice(this.startIndex, this.endIndex);
    } else {
      this.nextButtonDisabled = true;
    }
  }

  public onclickPage(page: number) {
    this.currentPage = page;
    this.startIndex = (this.currentPage * 10);
    this.endIndex = (this.currentPage * 10) + 10;

    this.pagedCustomerConfigurations = this.allCustomerConfigurations.slice(this.startIndex, this.endIndex);
  }

  public onFilter() {
    this.allCustomerConfigurations = this.originalCustomerConfigurations;      

    if(this.filterPartner !== undefined && this.filterPartner.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.partnerName.toLowerCase().includes(this.filterPartner.toLowerCase()));
    }
    if(this.filterSender !== undefined && this.filterSender.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.senderName.toLowerCase().includes(this.filterSender.toLowerCase()));
    }
    if(this.filterReceiver !== undefined && this.filterReceiver.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.receiverName.toLowerCase().includes(this.filterReceiver.toLowerCase()));
    }
    if(this.filterMessageTypeName !== undefined && this.filterMessageTypeName.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.messageName.toLowerCase().includes(this.filterMessageTypeName.toLowerCase()));
    }
    if(this.filterLegalEntity !== undefined && this.filterLegalEntity.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.legalEntityName.toLowerCase().includes(this.filterLegalEntity.toLowerCase()));
    }
    if(this.filterAreaPlant !== undefined && this.filterAreaPlant.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.areaPlantName.toLowerCase().includes(this.filterAreaPlant.toLowerCase()));
    }
    if((this.filterPartner === undefined || this.filterPartner === "") && 
      (this.filterSender === undefined || this.filterSender === "") && 
      (this.filterReceiver === undefined || this.filterReceiver === "") && 
      (this.filterMessageTypeName === undefined || this.filterMessageTypeName === "") &&
      (this.filterLegalEntity === undefined || this.filterLegalEntity === "") &&
      (this.filterAreaPlant === undefined || this.filterAreaPlant === "")) {
      this.allCustomerConfigurations = this.originalCustomerConfigurations;      
    }

    this.pagedCustomerConfigurations = this.allCustomerConfigurations.slice(this.startIndex, this.endIndex);
    this.lastDisplayIndex = (this.currentPage + 1) * this.pageCount;
    this.configurationCount = this.allCustomerConfigurations.length;   
  }
}
