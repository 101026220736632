import { Component, Input } from '@angular/core';
import { DynamicPipeService } from './dynamic-pipe.service';

@Component({
  selector: 'simple-grid',
  templateUrl: './simple-grid.component.html'
})
export class SimpleGridComponent {
    @Input() columns: Array<{ 
        header: string, 
        field: string, 
        pipe?: { name: any, args?: any[] },
        class?: string
    }> = [];
    @Input() data: Array<any> = [];
    @Input() tableClass: string;
    @Input() missingDataMessage: string;
    @Input() useMissingDataMessage: boolean = false;
    @Input() colspan: number = 1;
    @Input() rowClass: string;

    constructor(public dynamicPipeService: DynamicPipeService) {}

    applyPipe(value: any, pipe?: { name: any, args?: any[] }) {
        if (!pipe) {
        return value;
        }
        return this.dynamicPipeService.transform(value, pipe.name, pipe.args);
    }
}