import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { BusinessIdentity } from 'src/app/models/integration-account/partner/business-identity';
import { PartnerRequest } from 'src/app/models/integration-account/partner/partner-request';
import { EdiConfigurationsService } from '../edi-configurations.service';

@Component({
  selector: 'upsert-partner',
  templateUrl: './upsert-partner.component.html'
})
export class UpsertPartnerComponent implements OnInit {
    @Input() displayStyle: string;
    @Input() partnerRequest: PartnerRequest;
    @Input() isNew: boolean;
    @Output() returnedDisplayStyle = new EventEmitter<any>();
  
    public error: boolean = false;
    public errorMessage: string = '';
    public selectedQualifier: string = '';
    public businessIdentityValue: string = '';
    public qualifierDDL: Array<DropDownListModel> = [    
        { value: "", text: "--- No qualifier ---" },
        { value: "1", text: "1 - D-U-N-S (Dun & Bradstreet)" },
        { value: "2", text: "2 - SCAC (Standard Carrier Alpha Code) (X12)" },
        { value: "3", text: "3 - Federal Maritime Commission" },
        { value: "4", text: "4 - IATA (International Air Transport Association)" },
        { value: "7", text: "7 - Global Location Number (GLN)" },
        { value: "8", text: "8 - UCC Communications ID (Uniform Code Council Communications)" },
        { value: "9", text: "9 - X.121 (CCITT) / D-U-N-S (Dun & Bradstreet) with 4 digit suffix" },
        { value: "10", text: "10 - Department of Defense" },
        { value: "11", text: "11 - Drug Enforcement Administration" },
        { value: "12", text: "12 - Telephone Number" },
        { value: "13", text: "13 - UCS Code" },
        { value: "14", text: "14 - D-U-N-S Plus Suffix / EAN (European Article Numbering Association)" },
        { value: "15", text: "15 - Petroleum Accountants Society of Canada Company Code" },
        { value: "16", text: "16 - D&B D-U-N-S Number plus 4-character suffix" },
        { value: "17", text: "17 - American Bankers Association" },
        { value: "18", text: "18 - Association of American Railroads (AAR) Standard Distribution Code / AIAG(Automotive Industry Action Group)" },
        { value: "19", text: "19 - EDI Council of Australia (EDICA) Communications ID Number (COMM ID)" },
        { value: "20", text: "20 - Health Industry Number" },
        { value: "21", text: "21 - Integrated Postsecondary Education Data System, or (IPEDS)" },
        { value: "22", text: "22 - Federal Interagency Commission on Education, or FICE / INSEE (Institut National de la Statistique et des Etudes Economiques) - SIREN" },
        { value: "23", text: "23 - National Center for Education Statistics Common Core of Data 12-Digit Number for Pre-K-Grade 12 Institutes, or NCES" },
        { value: "24", text: "24 - The College Board's Admission Testing Program 4-Digit Code of Postsecondary Institutes, or ATP" },
        { value: "25", text: "25 - ACT, Inc. 4-Digit Code of Postsecondary Institutions" },
        { value: "26", text: "26 - Statistics of Canada List of Postsecondary Institutions" },
        { value: "27", text: "27 - Health Care Financing Administration Carrier ID" },
        { value: "28", text: "28 - Health Care Financing Administration Fiscal Intermediary" },
        { value: "29", text: "29 - Health Care Financing Administration Medicare Provider" },
        { value: "30", text: "30 - U.S. Federal Tax Identification Number / ISO 6523: Organization Identification" },
        { value: "31", text: "31 - Jurisdiction Identification Number Plus 4 as assigned by the International Association of Industrial Accident Boards and Commissions (IAIABC)/ DIN (Deutsches Institut fuer Normung)" },
        { value: "32", text: "32 - U.S. Federal Employer Identification Number" },
        { value: "33", text: "33 - National Association of Insurance Commissioners Company Code (NAIC) / BfA (Bundesversicherungsanstalt fuer Angestellte)" },
        { value: "34", text: "34 - Medicaid Provider and Supplier Identification Number as assigned by individual State Medicaid Agencies in conjunction with Health Care Financing Administration (HCFA) / National Statistical Agency" },
        { value: "35", text: "35 - Statistics Canada Canadian College Student Information System Institution Codes" },
        { value: "36", text: "36 - Statistics Canada University Student Information System Institution Codes" },
        { value: "37", text: "37 - Society of Property Information Compilers and Analysts" },
        { value: "38", text: "38 - The College Board and ACT, Inc. 6-Digit Code List of Secondary Institutions" },
        { value: "AM", text: "AM - Association Mexicana del Codigo de Producto (AMECOP) Communication ID" },
        { value: "NR", text: "NR - National Retail Merchants Association" },
        { value: "SA", text: "SA - User Identification Number as assigned by the Safety and Fitness Electronic Records (SAFER) System" },
        { value: "SN", text: "SN - Standard Address Number" },
        { value: "ZZ", text: "ZZ - Mutually Defined" },
        { value: "BT", text: "BT" },
        { value: "AS2Identity", text: "AS2Identity" },
        { value: "5", text: "5 - INSEE (Institut National de la Statistique et des Etudes Economiques)" },
        { value: "51", text: "51 - GEIS (General Electric Information Services)" },
        { value: "52", text: "52 - INS (IBM Network Services)" },
        { value: "53", text: "53 - Datenzentrale des Einzelhandels" },
        { value: "54", text: "54 - Bundesverband der Deutschen Baustoffhaendler" },
        { value: "55", text: "55 - Bank Identifier Code" },
        { value: "57", text: "57 - KTNet (Korea Trade Network Services)" },
        { value: "58", text: "58 - UPU (Universal Postal Union)" },
        { value: "59", text: "59 - ODETTE (Organization for Data Exchange through Tele-Transmission in Europe)" },
        { value: "61", text: "61 - SCAC (Standard Carrier Alpha Code)" },
        { value: "63", text: "63 - ECA (Electronic Commerce Australia)" },
        { value: "65", text: "65 - TELEBOX 400 (Deutsche Telekom)" },
        { value: "80", text: "80 - NHS (National Health Service)" },
        { value: "82", text: "82 - Statens Teleforvaltning" },
        { value: "84", text: "84 - Athens Chamber of Commerce" },
        { value: "85", text: "85 - Swiss Chamber of Commerce" },
        { value: "86", text: "86 - US Council for International Business" },
        { value: "87", text: "87 - National Federation of Chambers of Commerce and Industry" },
        { value: "89", text: "89 - Association of British Chambers of Commerce" },
        { value: "90", text: "90 - SITA (Societe Internationale de Telecommunications)" },
        { value: "91", text: "91 - Assigned by seller or seller's agent" },
        { value: "92", text: "92 - Assigned by buyer or buyer's agent" },
        { value: "103", text: "103 - TW, Trade-van" },
        { value: "128", text: "128 - CH, BCNR (Swiss Clearing Bank Number)" },
        { value: "129", text: "129 - CH, BPI (Swiss Business Partner Identification)" },
        { value: "144", text: "144 - US, DoDAAC (Department of Defense Activity Address Code)" },
        { value: "145", text: "145 - FR, DGCP (Direction Generale de la Comptabilite Publique)" },
        { value: "146", text: "146 - FR, DGI (Direction Generale des Impots)" },
        { value: "147", text: "147 - JP, JIPDEC/ECPC (Japan Information Processing Development Corporation / Electronic Commerce Promotion Center)" },
        { value: "148", text: "148 - ITU (International Telecommunications Union) Data Network Identification Code (DNIC)" },
        { value: "Z01", text: "Z01 - Vehicle registration number" },
        { value: "ZZZ", text: "ZZZ - Mutually Defined" },
        { value: "DUNS", text: "DUNS" },
    ];

  constructor(public ediService: EdiConfigurationsService) {}

  ngOnInit(): void {
  }

  public selectQualifierChangeHandler(event: any) {
    // this.selectedSenderSigningCertificateName = event.target.value;
    // this.agreementRequest.senderSigningCertificateName = event.target.value;      
  }

  // Cancel
  public closePopup() {
    this.displayStyle = "none";
    this.error = false;
    this.errorMessage = '';
    this.returnedDisplayStyle.emit(this.displayStyle);
  }

  public removeBusinessIdentity(businessIdentity: BusinessIdentity){
    const objWithIdIndex = this.partnerRequest.businessIdentities.findIndex((x) => x.qualifier === businessIdentity.qualifier && x.value === businessIdentity.value);

    if (objWithIdIndex > -1) {
        this.partnerRequest.businessIdentities.splice(objWithIdIndex, 1);
    }
  }


  public addBusinessIdentity() {
    let businessIdentity = new BusinessIdentity();
    businessIdentity.qualifier = this.selectedQualifier;
    businessIdentity.value = this.businessIdentityValue;
    
    this.partnerRequest.businessIdentities.push(businessIdentity);
  }

  public upsertPartner() {
    this.ediService.upsertPartner(this.partnerRequest).subscribe(
      {
          next: (result) => {      
              this.displayStyle = "none";
              this.error = false;
              this.errorMessage = '';
              this.returnedDisplayStyle.emit(this.displayStyle);
          },
          error: (error) => { 
              // TODO Add better error hanlding to present the issue!
              this.error = true;
              let msg: string = '';
              let title: string = error?.error?.title;
              msg = (title !== undefined) ? title : error?.error;
              let errorArray = error.error.error !== undefined && error.error.error.details !== undefined ? error.error.error.details : undefined;
              if(errorArray !== undefined) {
                msg = '';
                errorArray.forEach((e: any) => {
                  let errorMessage = e.message;
                  msg = msg + '[' + errorMessage + '] '
                });
                
              }
              
              let foundErrorMessage = error.error.error !== undefined && error.error.error.code !== undefined ? error.error.error.code : undefined;
              if(foundErrorMessage !== undefined) {
                  msg = error.error.error.code + ': ' + error.error.error.message;
              }

              this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
              console.error(error); 
          }
       }
    );    
  }
}
