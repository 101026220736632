import { Component, OnInit, Inject } from '@angular/core';
import { ResolverTesterService } from './resolver-tester.service';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { ResolverResult } from 'src/app/models/resolver/resolver-result';

@Component({
  selector: 'resolver-tester',
  templateUrl: './resolver-tester.component.html'
})
export class ResolverTesterComponent implements OnInit {
    public originalMessage: string = '';
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = false;
    public partnersDDL: Array<DropDownListModel>;
    public partnerId: string = '';
    public resolverResult: ResolverResult;

    constructor(private rtService: ResolverTesterService) {}

    ngOnInit(): void {
        this.showSpinner = true;
        this.rtService.getPartners().subscribe(
        {
            next: (partners) => {      
                partners = partners.sort((a, b) => (a.name < b.name ? -1 : 1));
                this.partnersDDL = new Array<DropDownListModel>();
                partners.forEach(x => {
                    let d: DropDownListModel = {
                        value: x.partnerId,
                        text: `${x.name} (${x.partnerId})`
                      };
                    this.partnersDDL.push(d);
                });
                this.showSpinner = false;
            },
            error: (error) => {
              this.showSpinner = false; 
              this.displayErrorMessage(error);
            }
          }
      );
  }
 
  public onclickResolve() {
    this.error = false;
    this.errorMessage = '';
    this.resolverResult = null;
    this.rtService.resolve(this.partnerId, this.originalMessage).subscribe(
        {
            next: (resolverResult) => {    
                this.resolverResult = resolverResult;
                this.showSpinner = false;
            },
            error: (error) => {
              this.showSpinner = false; 
              this.displayErrorMessage(error);
            }
          }
      );
  }

  private displayErrorMessage(error: any) {
    let formattedError = '';
    if(error?.error && error?.error.error == undefined) {
      if(error.error.includes('at al.goh.admin.tool.api')) {
        formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
      } else {
        formattedError = error.error;
      }
    } else {
      if(error?.error?.error) { 
        formattedError = error?.error.error;
      } else {
        formattedError = 'Unknown eror';
      }      
    }

    this.errorMessage = `Error when resolving message. Error Message ${formattedError}`;
    this.error = true;
    console.error(error); 
  }
}
