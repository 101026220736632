import { Component, Input, OnInit } from '@angular/core';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { DestinationSbprofile } from '../../../../../models/destination/destination-sb-profile';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { SbProfileService } from './sb-profile.service';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: "sb-profile",
    templateUrl: "./sb-profile.component.html"
})
export class SbProfileComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;

    public destinationSbprofileForm: FormGroup;
    public keyVaultProfiles: Array<KeyVaultProfile>;
    public sbProfiles: Array<DestinationSbprofile>;
    private systemNameSubscription: Subscription;
    
    constructor(private keyVaultService: KeyVaultService, private sbProfileService: SbProfileService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.destinationSbprofileForm.controls; }

    ngOnInit(): void {
        this.destinationSbprofileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        // Subscribe on value changes for systemName input control to only allow a-z and 0-9
        // Only setup the subscribe on systemName if we add a new secret
        if(this.destinationSbprofileForm.value.useExistingKeyVaultValue === false) {
            this.systemNameSubscription = this.destinationSbprofileForm.get('systemName').valueChanges.subscribe((value: string) => {
                const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
                if (sanitizedValue !== value) {
                    this.destinationSbprofileForm.get('systemName').setValue(sanitizedValue, { emitEvent: false });
                }
            });
        }

        this.sbProfileService.getAllSbProfiles().subscribe({
            next: (result) => {
                this.sbProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });

        this.keyVaultService.getKeyVaultProfiles().subscribe({
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });
    }

    public handleDestOptionsChange(event: any) {
        this.sbProfileService.setupRequiredValidation(this.destinationSbprofileForm);
    }

    public onchangeUseExistingKeyVaultValue(event: any) {
        if(this.destinationSbprofileForm.value.useExistingKeyVaultValue === false) {
            // If we want to add a new keyvault secret we can't send down an existing
            this.destinationSbprofileForm.get('keyVaultProfileId').setValue(-1);
            // When showing systemName we need to add the FormControl and add the subscribe
            this.destinationSbprofileForm.addControl('systemName', new FormControl(null));
            this.systemNameSubscription = this.destinationSbprofileForm.get('systemName').valueChanges.subscribe((value: string) => {
                const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
                if (sanitizedValue !== value) {
                    this.destinationSbprofileForm.get('systemName').setValue(sanitizedValue, { emitEvent: false });
                }
            });
        } else {
            // Remove the subscription before removing the control
            this.systemNameSubscription.unsubscribe();
            this.destinationSbprofileForm.removeControl('systemName');
        }
    }
}