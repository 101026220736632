import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { MDInboundMappingConnectionInputService } from './md-inboundToCanonicalMappingConnection-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { UtilService } from 'src/app/common-services/util.service';
import { CanonicalMap } from 'src/app/models/mapping/canonical-map';

export function controlContainerFactory(controlContainer?: ControlContainer) {
  return controlContainer;
}

@Component({
  selector: 'add-inboundToCanonicalMappingConnection',
  templateUrl: './add-inboundToCanonicalMappingConnection-modal.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: controlContainerFactory,
      deps: [[new Optional(), NgForm]]
    }
  ]
})
export class AddInboundMappingConnectionModalComponent implements OnInit {
  @Input() partner: Partner;
  public itcmcGridList: Array<CanonicalMap>;

  public error: boolean = false;
  public errorMessage: string = '';
  public showSpinner: boolean = false;
  public requiredInputSet: boolean = false; // TODO Add validation of required input // TODO do for all!!!!!
  public messageTypeId: number = -1;
  public inboundToCanonicalDocumentsId: number = -1;
  public taskId: string = '';
  public messageTypesDDL: DropDownListModel[];
  public inboundToCanonicalDocumentsDDL: DropDownListModel[];
  public addOrEdit: string = '';
  public isEdit: boolean = false;

  constructor(public activeModal: NgbActiveModal, private inboundMappingConnectionInputService: MDInboundMappingConnectionInputService, public utilService: UtilService) {}

  ngOnInit(): void {
    this.inboundMappingConnectionInputService.getAllInboundToCanonicalDocuments().subscribe(
      {
          next: (result) => {
            this.inboundToCanonicalDocumentsDDL = new Array<DropDownListModel>();
            result.forEach(x => {
                let d: DropDownListModel = {
                    value: x.id.toString(),
                    text: x.name
                  };
                  this.inboundToCanonicalDocumentsDDL.push(d);
            });
          },
          // TODO Add better error hanlding to present the issue!
          error: (error) => {
              this.showSpinner = false;
              console.error(error);
          }
        }
    );
    this.inboundMappingConnectionInputService.getAllInboundMessageTypes().subscribe(
      {
          next: (result) => {
            this.messageTypesDDL = new Array<DropDownListModel>();
            result.forEach(x => {
                let d: DropDownListModel = {
                    value: x.inboundMessageTypeId.toString(),
                    text: x.messageName
                  };
                  this.messageTypesDDL.push(d);
            });
          },
          // TODO Add better error hanlding to present the issue!
          error: (error) => {
              this.showSpinner = false;
              console.error(error);
          }
        }
    );
  }

  public save() {
    if(this.messageTypeId > 0 || this.inboundToCanonicalDocumentsId > 0){
      let inboundToCanonicalMap: CanonicalMap = new CanonicalMap();
      inboundToCanonicalMap.id = 0;
      inboundToCanonicalMap.inboundPartnerId = this.partner.id;
      inboundToCanonicalMap.inboundMessageTypeId = this.messageTypeId;
      inboundToCanonicalMap.canonicalMapXsltId = this.inboundToCanonicalDocumentsId;
      inboundToCanonicalMap.taskId = this.taskId;
        this.inboundMappingConnectionInputService.addPartnerInboundToCanonicalMappingConnection(inboundToCanonicalMap).subscribe(
            {
                next: (result) => {
                      this.activeModal.close('save');
                },
                error: (error) => {
                    this.displayErrorMessage(error);
                }
              }
        );
    }
  }

  selectChangeHandler(event: any) {

  }

  private displayErrorMessage(error: any) {
      let formattedError = '';
      if(error?.error && error?.error.errors) {
        formattedError = error?.error.errors;
      }

      if(error?.error && error?.error.error == undefined) {
      if(error.error.includes('at al.goh.admin.tool.api')) {
          formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
      } else {
          formattedError = error.error;
      }
      } else {
      if(error?.error?.error) {
          formattedError = error?.error.error;
      } else {
          formattedError = 'Unknown error';
      }
      }

      this.errorMessage = `Error Message: ${formattedError}`;
      this.error = true;
      console.error(error);
  }
}
