import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/common-services/util.service';
import { MasterDataPrestepClassesService } from '../md-prestep-classes.service';
import { PreStepClass } from 'src/app/models/pre-step-class';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'add-prestep-class',
  templateUrl: './edit-prestep-class.component.html'
})
export class EditPrestepClassComponent implements OnInit {
    @Input() prestepClass: PreStepClass;
    public errorModel: ErrorModel = null;
    public prestepClassForm: FormGroup;
    public showSpinner: boolean = false;

    constructor(public service: MasterDataPrestepClassesService, private utilService: UtilService, private errorParserService: ErrorParserService, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.prestepClassForm = this.service.getPrestepClassForm(this.prestepClass);
    }

    get form() { return this.prestepClassForm.controls; }

    public save() {
        this.showSpinner = true; 
        let prestepClass = this.service.getPrestepClassModel(this.prestepClassForm);
        this.service.updatePrestepClass(prestepClass).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }
}
