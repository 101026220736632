import { KeyVaultProfile } from "../key-vault-profile";

export class SourceSbProfile {
    id: number;
    name: string;
    namespace: string;
    authentication: number;
    queue: string;
    sharedAccessKeyName: string;
    sharedAccessKey: string;
    keyVaultProfileId: number;
    createdBy: string;
    createTimestamp: string;
    keyVaultProfile: KeyVaultProfile;
    useManagedIdentity : boolean;
}