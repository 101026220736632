import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Partner } from 'src/app/models/partner/partner';
import { MDDestinationService } from './md-destination.service';
import { ConfDestination } from 'src/app/models/destination/conf-destination';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'edit-destination',
  templateUrl: './edit-destination-model.component.html'
})
export class EditDestinationModalComponent implements OnInit {
    @Input() partner: Partner;
    @Input() confDestination: ConfDestination;

    public destinationForm: FormGroup;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public isEdit: boolean = true;
    public keyVaultProfiles: Array<KeyVaultProfile>;

    constructor(public activeModal: NgbActiveModal, public destinationService: MDDestinationService, private errorParserService: ErrorParserService) {}

    get form() { return this.destinationForm.controls; }

    ngOnInit(): void {
        this.destinationForm = this.destinationService.getDestinationForm(this.partner.id, this.confDestination);
    }

    public save() {
        let confDestination: ConfDestination = this.destinationService.getConfDestinationRequestModel(this.destinationForm);
        this.destinationService.setupProfileRequestModel(this.destinationForm, confDestination, true);
        
        this.showSpinner = true;
       
        this.destinationService.updateDestination(confDestination).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                        this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
            }
        );
    }
}
