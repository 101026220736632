import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorModel } from '../models/error-model';

@Injectable({
  providedIn: 'root',
})
export class ErrorParserService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor() {}

    /*
        Missing properties in payload for the request model gives the following error object:
        {
        "status": 400,
        "statusText": "OK",
        "error": {
            "title": "One or more validation errors occurred.",
            "errors": {
                "Source.Sftp.Encoding": [
                    "The Encoding field is required."
                ],
                "Name": [
                    "The Name field is required."
                ]
            }
        }

        BadRequest gives the following payload:
        {
            "status": 400,
            "statusText": "OK",
            "ok": false,
            "name": "HttpErrorResponse",
            "message": "Http failure response for https://localhost:54781/api/v1/partners: 400 OK",
            "error": "An error occurred while saving the entity changes. See the inner exception for details.\r\nCannot insert the value NULL into column 'PreStepClass', table 'GOH.dbo.tblPreStep'; column does not allow nulls. INSERT fails.\r\nThe statement has been terminated."
        }
}
    */
    public extractErrors(errorObject: any): ErrorModel {
        let errorModel = new ErrorModel();
        errorModel.status = errorObject.status;
        errorModel.statusText = errorObject.statusText;

        if(errorObject.error == null) {
            errorModel.errorMessage = `Status: ${errorObject.status}, Message: ${errorObject.message}`;
        } else if (typeof errorObject.error === 'string') {
            errorModel.errorMessage = errorObject.error;          
        } else if (typeof errorObject.error === 'object' && errorObject.error.errors) {
          const errorsObject = errorObject.error.errors;
          errorModel.errorMessage = Object.keys(errorsObject).map(key => {
            const messages = errorsObject[key];
            const formattedMessages = `[${messages.join('][')}]`;
            return `${key}: ${formattedMessages}`;
          }).join(', ');
          if(errorObject.error.title != undefined) {
            errorModel.errorMessage = `${errorObject.error.title} => ${errorModel.errorMessage}`
          }       
        }

        return errorModel;
    }
}
