<h3 id="tableLabel">Encode/Decode EDI messages</h3>

<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
    <p style="margin-bottom: 2rem;white-space:pre-wrap;"><b>{{errorMessage}}</b></p>
</div>


<div class="row">
    <div class="col-12">
        <div class="row">
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">EDI Type</span></legend>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="editTypeOptions1" [value]="1" (click)="onclickEdiTypeOptions(1)" [(ngModel)]="ediType" id="editTypeOptions1">
                    <label class="form-check-label" for="editTypeOptions1">
                    EDIFACT
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="editTypeOptions2" [value]="2" (click)="onclickEdiTypeOptions(2)" [(ngModel)]="ediType" id="editTypeOptions2" checked>
                    <label class="form-check-label" for="editTypeOptions2">
                    X12
                    </label>
                </div>
                <br />
            </fieldset>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="row">
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Decode/Encode</span></legend>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="decodeEncodeOptions1" [value]="true" [(ngModel)]="isDecodeOption" id="decodeEncodeOptions1">
                    <label class="form-check-label" for="decodeEncodeOptions1">
                    Decode
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="decodeEncodeOptions2" [value]="false" [(ngModel)]="isDecodeOption" id="decodeEncodeOptions2" checked>
                    <label class="form-check-label" for="decodeEncodeOptions2">
                    Encode
                    </label>
                </div>
                <!-- guestPartner (all) -->
                <div class="form-group row mb5" *ngIf="isDecodeOption === false">
                    <label for="agreementOption" class="col-sm-1 col-form-label">Agreement</label>
                    <div class="col-sm-3">
                        <select dropdown (change)="selectAgreement($event)" id="agreementOption" name="agreementOption" class="form-select form-select-sm control-label" [(ngModel)]="selectedAgreement">
                        <option value="">--- Select agreement ---</option>
                        <option *ngFor="let item of agreementsDDL" [value]="item.value">
                            {{item.text}}
                        </option>
                        </select>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>        
</div>

<div class="row">
    <div class="col-5">
        <div class="form-outline">
            <div class="row">
                <label><strong>Original message</strong></label>
            </div>
            <div class="row">
                <textarea class="form-control" id="originalMessage" rows="15" [(ngModel)]="originalMessage" (change)="onChangeCheckEdiType()"></textarea>
            </div>
        </div>
    </div>
    <div class="col-1">
        <div class="row" style="margin: 25px 5px 0px 5px;" *ngIf="isDecodeOption">
            <button class="btn btn-outline-secondary btn-sm" [disabled]="originalMessage === ''" (click)="decode()">Decode</button>
        </div>
        <div class="row" style="margin: 25px 5px 0px 5px;" *ngIf="!isDecodeOption">
            <button class="btn btn-outline-secondary btn-sm" [disabled]="selectedAgreement === '' || showSpinner === true || originalMessage === ''" (click)="encode()">Encode</button>
        </div>        
    </div>    
    <div class="col-6">
        <div class="form-outline">
            <div class="row">
                <label><strong>Message result</strong></label>
            </div>
            <div class="row">
                <textarea class="form-control" id="resultMessage" rows="15" [(ngModel)]="resultMessage"></textarea>    
            </div>
        </div>
    </div>
</div>






