import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Enrichment } from 'src/app/models/enrichment';
import { EnrichmentClass } from 'src/app/models/enrichment-class';

@Component({
  selector: 'enrichment',
  templateUrl: './enrichment.component.html',
})
export class EnrichmentComponent implements OnInit {
    @Input() enrichments: Array<Enrichment>;
    @Input() enrichmentClasses: Array<EnrichmentClass>;  
    @Output() enrichmentUpdated = new EventEmitter<any>();
  
    public selectedEnricherValue: string = "";
    public selectedEnricherText: string;

    ngOnInit(): void {
    }

    selectChangeHandler(event: any) {
        this.selectedEnricherValue = event.target.value;
        this.selectedEnricherText = event.target[event.target.selectedIndex].text;
    }

    public addEnricher() {
        if(this.enrichments == undefined) {
            this.enrichments = new Array<Enrichment>()
        }
        
        let selectedEnrichment = this.enrichmentClasses.find(x => x.enricher === this.selectedEnricherValue);
        let foundEnrichment = this.enrichments.find(x => x.enrichmentClassId === selectedEnrichment.id);
        
        if(foundEnrichment === undefined) {
            let enrichment = new Enrichment();
            enrichment.id = 0;
            enrichment.enrichmentClassId = selectedEnrichment.id;
            enrichment.enrichmentClass = selectedEnrichment;
            enrichment.order = 0;
            this.enrichments.push(enrichment);
        }
        
        this.enrichments = this.enrichments.sort((a,b) => a.order - b.order);
        this.enrichmentUpdated.emit(this.enrichments);
    }

    public removeEnrichment(e: Enrichment) {
        this.enrichments.forEach( (item, index) => {
            if(item === e) this.enrichments.splice(index,1);
        });
    }

    public onOrderChange(e: Enrichment) {
        this.enrichments = this.enrichments.sort((a,b) => a.order - b.order);
        this.enrichmentUpdated.emit(this.enrichments);
    }
}
