<form [formGroup]="postFormattingForm">
  <fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Post-formatting</span></legend>
    <div style="margin-bottom: 10px;">
        <span class="text-muted"><small>Post-formatter is used for transforming outbound messages from XML to JSON, or XML to CSV etc. If no formatter is selected it will not be used in the configuration.</small></span>
    </div>  

    <div class="form-group row" *ngIf="postFormatters">
        <label for="postFormattingDDL" class="col-sm-2 col-form-label control-label">Post-formatter</label>
        <div class="col-sm-6">
          <select dropdown formControlName="postFormattingClassId" (change)="selectChangeHandler($event)" id="postFormattingDDL" name="postFormattingDDL" class="form-select form-select-sm control-label">
            <option value="">--- Select post-formatter ---</option>
            <option *ngFor="let item of postFormatters" [value]="item.id.toString()">
              {{item.description}}
            </option>
          </select>
        </div>
    </div>
  </fieldset>
</form>
