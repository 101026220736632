import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OutboundMapXslt } from 'src/app/models/mapping/outbound-map-xslt';
import { MappingDocumentService } from '../mapping-document.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'delete-canonical-to-outbound-mapping-documents',
  templateUrl: './delete-canonical-to-outbound-mapping-documents.component.html',
})
export class DeleteCanonicalToOutboundMappingDocumentComponent implements OnInit {
    @Input() outboundMapXslt: OutboundMapXslt;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;

    constructor(public activeModal: NgbActiveModal, private errorParserService: ErrorParserService, public mtService: MappingDocumentService) {}

    ngOnInit(): void {
    }

    public delete() {
        this.mtService.deleteOutboundMappingDocument(this.outboundMapXslt).subscribe(
              {
                  next: (result) => {
                       this.activeModal.close('delete');
                  },
                  error: (error) => {
                    let error2 = error;
                  }
               }
          );
    }
}
