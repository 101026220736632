import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UtilService } from 'src/app/common-services/util.service';
import { Partner } from 'src/app/models/partner/partner';
import { EDIPreFormatter } from 'src/app/models/edi-preformatter/edi-preformatter';
import { MDEdiPreFormatterInputService } from './md-ediPreFormatter-input.service';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditEdiPreFormatterModalComponent } from './edit-ediPreFormatter-modal.component';
import { AddEdiPreFormatterModalComponent } from './add-ediPreFormatter-modal.component';
import { DeleteEdiPreFormatterModalComponent } from './delete-ediPreFormatter-modal.component';

@Component({
    selector: 'ediPreFormatter-input',
    templateUrl: './md-ediPreFormatter-input.component.html',
})
export class MDEdiPreFormatterInputComponent implements OnInit {
    @Input() partner: Partner;
    public edipfGridList: Array<EDIPreFormatter>;

    public showSpinner: boolean = false;
    public error: boolean = false;
    public errorMessage: string = '';
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;

    constructor(public edipfInputService: MDEdiPreFormatterInputService, private authService: MsalService, public utilService: UtilService, private modalService: NgbModal) { }

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }
        this.getPartnerEdiPreFormatters(this.partner.id);
    }

    private getPartnerEdiPreFormatters(inboundpartnerId: number) {
        this.showSpinner = true;
        this.edipfInputService.getInboundPartnerEdiPreFormatters(inboundpartnerId).subscribe({
            next: (edipfGridList) => {
                this.showSpinner = false;
                this.edipfGridList = edipfGridList;
            },
            error: (error) => {
                this.showSpinner = false;
                this.displayErrorMessage(error);
            }
        });
    }

    public addPartnerEdiPreFormatter() {
        const modalRef = this.modalService.open(AddEdiPreFormatterModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if (result === 'save') {
                this.getPartnerEdiPreFormatters(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    public editPartnerEdiPreFormatter(ediPreFormatter: EDIPreFormatter) {
        const modalRef = this.modalService.open(EditEdiPreFormatterModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.ediPreFormatter = ediPreFormatter;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if (result === 'save') {
                this.getPartnerEdiPreFormatters(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }



    public removePartnerEdiPreFormatter(ediPreFormatter: EDIPreFormatter) {
        const modalRef = this.modalService.open(DeleteEdiPreFormatterModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.ediPreFormatter = ediPreFormatter;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if (result === 'delete') {
                this.getPartnerEdiPreFormatters(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    private displayErrorMessage(error: any) {
        let formattedError = '';
        if (error?.error && error?.error.error == undefined) {
            if (error.error.includes('at al.goh.admin.tool.api')) {
                formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
            } else {
                formattedError = error.error;
            }
        } else {
            if (error?.error?.error) {
                formattedError = error?.error.error;
            } else {
                formattedError = 'Unknown eror';
            }
        }

        this.errorMessage = `Error when receiving partners EdiPreFormatters. Error Message ${formattedError}`;
        this.error = true;
        console.error(error);
    }
}
