<form [formGroup]="prestepClassForm" (submit)="save()" *ngIf="prestepClassForm">
    <div class="modal-header">
        <h4 class="modal-title">Edit prestep class</h4>
    </div>
    <div class="modal-body">
        <div>
            <div class="alert alert-danger" role="alert" *ngIf="errorModel">
                <b>Error when editing prestep class. Error Message: {{errorModel.errorMessage}}</b>
            </div>
            <fieldset class="border p-2 mb10 mt10">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Prestep class details</span></legend>
                <div class="form-group row mb5">
                    <label for="preStepClass" class="col-sm-3 col-form-label">Prestep class name <span class="req-color">*</span></label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" formControlName="preStepClass" required id="preStepClass" name="preStepClass" placeholder="Prestep class name">
                        <div *ngIf="form.preStepClass.invalid && (form.preStepClass.dirty || form.preStepClass.touched)" class="alert alert-danger">
                            <div *ngIf="form.preStepClass.errors?.['required']">Prestep class name is required.</div>
                        </div>
                    </div>
                </div>
                
                <div class="form-group row mb5">
                    <label for="description" class="col-sm-3 col-form-label">Description <span class="req-color">*</span></label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" formControlName="description" required id="description" name="description" placeholder="Description">
                        <div *ngIf="form.description.invalid && (form.description.dirty || form.description.touched)" class="alert alert-danger">
                            <div *ngIf="form.description.errors?.['required']">Description is required.</div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="!prestepClassForm.valid">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>
</form>

