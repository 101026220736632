<form [formGroup]="inboundPartnerForm">
  <fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Inbound partner</span></legend>
  
    <div class="form-group row">
      <label for="inboundPartnerDDL" class="col-sm-2 col-form-label control-label">Inbound partner <span class="req-color">*</span></label>
      <div class="col-sm-6">
        <select dropdown formControlName="inboundPartnerId" (change)="selectInboundPartnerIdChangeHandler($event)" *ngIf="partners" id="inboundPartnerDDL" name="inboundPartnerDDL" class="form-select form-select-sm control-label">
          <option value="">--- Select partner ---</option>
          <option *ngFor="let item of partners" [value]="item.id.toString()">
            {{item.name}} ({{item.partnerId}})
          </option>
        </select>
        <div *ngIf="form.inboundPartnerId.invalid && (form.inboundPartnerId.dirty || form.inboundPartnerId.touched)" class="alert alert-danger">
          <div *ngIf="form.inboundPartnerId.errors?.['required']">Inbound partner is required.</div>
        </div>        
      </div>
    </div>

    <fieldset class="border p-2" style="margin-top: 10px;">
      <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Authentication settings</span></legend>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" name="useBasicAuthenticationCheckbox" formControlName="useBasicAuthentication" id="useBasicAuthenticationCheckbox" (change)="onUseBasicAuthenticationChange($event)">
        <label class="form-check-label" for="useBasicAuthenticationCheckbox">
          Use Basic Authentication
        </label>
      </div>

      <!-- Name -->
      <div class="form-group row mb5" *ngIf="useBasicAuthentication">
        <label for="basicAuthenticationUsername" class="col-sm-2 col-form-label">Username <span class="req-color">*</span></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="basicAuthenticationUsername" name="basicAuthenticationUsername" placeholder="Username" formControlName="basicAuthenticationUsername">
        </div>
      </div>
      <!-- password -->
      <div class="form-group row" *ngIf="useBasicAuthentication">
        <label for="basicAuthenticationPassword" class="col-sm-2 col-form-label">Password</label>
        <div class="col-sm-6">
          <input type="password" class="form-control" id="basicAuthenticationPassword" name="basicAuthenticationPassword" placeholder="Password" formControlName="basicAuthenticationPassword">
        </div>
      </div>
    </fieldset>  
  </fieldset>
</form>

  