import { Component, Input } from '@angular/core';

@Component({
    selector: "two-col-row-display",
    templateUrl: "./two-col-row-display.component.html"
})
export class TwoColRowDisplayComponent {
    @Input() firstLabel: string;
    @Input() secondLabel: string;
    @Input() firstValue: string;
    @Input() secondValue: string;
    @Input() labelClass: string;
    @Input() valueClass: string;
}