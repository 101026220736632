import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { DestinationSharedFolderProfile } from 'src/app/models/destination/destination-shared-folder-profile';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FileShareProfileService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getAllFileShareProfiles(): Observable<Array<DestinationSharedFolderProfile>> {
        return this._http.get<Array<DestinationSharedFolderProfile>>(`${this.baseUrlLocal}confdestinations/fileshareprofiles`);
    }

    public setupRequiredValidation(destinationSharedFolderprofileForm: FormGroup) {
        const id = destinationSharedFolderprofileForm.get('id');
        const server = destinationSharedFolderprofileForm.get('server');
        const path = destinationSharedFolderprofileForm.get('path');

        if(destinationSharedFolderprofileForm.value.useExistingDestination === true) {
            id.setValue('');
            id.setValidators([Validators.required]);
            server.setValidators(null);
            path.setValidators(null);
        } else {
            id.setValue(0);
            id.setValidators(null); // We could have required here as we set the id to 0 that is a value, but as we hard code...naahh
            server.setValidators([Validators.required]);            
            path.setValidators([Validators.required]);                        
        }
        id.updateValueAndValidity();
        server.updateValueAndValidity();
        path.updateValueAndValidity();
    }
}
