// grid-view-column.directive.ts
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: 'grid-view-column',  
})
export class GridViewColumnDirective {
    @Input() field: string = '';
    @Input() title: string = '';
    @Input() template: TemplateRef<any> | null = null;
}
