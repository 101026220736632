<div class="modal-header">
    <h4 class="modal-title">Edit Canonical => Outbound Mapping-connection</h4>
</div>

<div class="modal-body">
  <div class="form-group row" *ngIf="canonicalToOutboundDocumentsDDL != undefined && canonicalToOutboundDocumentsDDL.length > 0">
      <label for="canonicalToOutboundDocumentsDDL" class="col-sm-4 col-form-label control-label">CanonicalToOutbound Document<span class="req-color">*</span></label>
      <div class="col-sm-6">
          <select dropdown (change)="selectChangeHandler($event)" id="canonicalToOutboundDocumentsDDL" name="canonicalToOutboundDocumentsDDL" class="form-select form-select-sm control-label" [(ngModel)]="canonicalToOutboundDocumentsId">
          <option value="-1">--- Select Canonical => Outbound Document ---</option>
          <option *ngFor="let item of canonicalToOutboundDocumentsDDL" [value]="item.value">
              {{item.text}}
          </option>
          </select>
      </div>
  </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" [disabled]="canonicalToOutboundDocumentsId  < 1" (click)="save()">Save</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
