import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HomeComponent } from './home.component';
import { HomeService } from './home.service';

@NgModule({
  imports:      [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule,
    NgxChartsModule
  ],
  providers:    [ HomeService ],
  declarations: [    
    HomeComponent
  ]
})
export class HomeModule { }
