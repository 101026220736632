import { Component, Input } from '@angular/core';
import { PreStep } from 'src/app/models/pre-step';
import { UtilService } from 'src/app/common-services/util.service';

@Component({
  selector: 'pre-step-info',
  templateUrl: './pre-step-info.component.html',
})
export class PreStepInfoComponent {
  @Input() preStep: PreStep;

  constructor(public utilService: UtilService) {}
}
