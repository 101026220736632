import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewConfigurationResponse } from 'src/app/models/new-configuration-response';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ConfigurationWizardService } from './configuration-wizard.service';
import { WizardRequestModel } from 'src/app/models/wizard-request-model';
import { ConfDestConnection } from 'src/app/models/destination/conf-dest-connection';
import { Scheduler } from 'src/app/models/source/scheduler';
import { Source } from 'src/app/models/source/source';
import { WizardResponseModel } from 'src/app/models/wizard-response-model';
import { TabStatuses } from 'src/app/models/tab-statuses';
import { flatMap } from 'rxjs';
import { OutboundMap } from 'src/app/models/mapping/outbound-map';
import { ResponseResolverUpdateData } from 'src/app/models/response-resolver/response-resolver-update-data';
import { TabModel } from 'src/app/models/tab-model';
import { ConfigurationTabService } from './configuration-tab.service';
import { Enrichment } from 'src/app/models/enrichment';
import { EnrichmentClass } from 'src/app/models/enrichment-class';
import { KeyVaultSecret } from '../../models/key-vault/key-vault-secret';
import { Partner } from 'src/app/models/partner/partner';
import { PreStep } from 'src/app/models/pre-step';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { DestinationModel } from './models/destination-model';

@Component({
  selector: 'app-configuration-wizard',
  templateUrl: './configuration-wizard.component.html'
})
export class ConfigurationWizardComponent implements OnInit {
    public configurationForm: FormGroup;
    public newConfigurationResponse = new NewConfigurationResponse();
    public showSpinner: boolean = true;
    public errorModel: ErrorModel = null;
    
    // Child update models    
    public outboundMaps: Array<OutboundMap> = new Array<OutboundMap>();
    public confDestConnections: Array<ConfDestConnection> = new Array<ConfDestConnection>();
    public schedulers: Array<Scheduler> = null;
    public preStep: PreStep = null;
    public responseResolverUpdateData: ResponseResolverUpdateData = new ResponseResolverUpdateData();
    public enrichments: Array<Enrichment>;
    public enrichmentClasses: Array<EnrichmentClass>;
    public keyVaultSecret: KeyVaultSecret = null;
    public sources: Array<Source>;
    public isEdit: boolean = false;
    public isCopy: boolean = false;
    public configurationLabelText: string = 'New configuration';
    public basedataModel: WizardResponseModel = new WizardResponseModel();

    // Tabs
    public tabStatuses: TabStatuses = {
        specifyAS2Inbound: false,
        specifyAS2Outbound: false,
        specifyPostformatting: false,
        specifyPreformatting: false,
        specifySource: false,
        specifyEnrichment: false
    };

    public tabModel: TabModel = new TabModel();
    public entityMapId: string;

    constructor(
        private wService: ConfigurationWizardService,
        private router: Router,
        private route: ActivatedRoute,
        public configurationTabService: ConfigurationTabService,
        private errorParserService: ErrorParserService
    ) {
        this.route.queryParams.subscribe(params => {
            this.isCopy = (params['isCopy'] === 'true') ? true : false;
            this.isEdit = (params['isEdit'] === 'true') ? true : false;

            this.configurationLabelText = this.isEdit === false ? 'New configuration' : 'Edit configuration'
            this.entityMapId = params['entityMapId'];
        });
    }

    ngOnInit(): void {
        this.configurationTabService.setupTabs(this.tabModel);
        this.wService.initModels(this.responseResolverUpdateData);

        // This is the FormGroup that holds and track all data that need to be save (on edit this is replaced with FormGroup with data)
        this.configurationForm = this.wService.getEmptyConfigurationFormGroup();

        this.getBaseData();
    }

    get form() { return this.configurationForm.controls; }

    private getBaseData() {
        this.wService.getBaseData().subscribe(
            {
                next: (result) => {
                    this.basedataModel = result;
                    this.enrichmentClasses = result.enrichmentClasses;

                    // If this is template, we need to get that data
                    if(this.isCopy || this.isEdit) {
                        this.wService.getConfiguration(+this.entityMapId).subscribe(
                            {
                                next: (existingConfiguration) => {
                                    if(this.isCopy) {
                                        this.wService.clearIdForCopy(existingConfiguration)
                                    }
                                   
                                    if(existingConfiguration.aS2ConfOut !== null || existingConfiguration.ediLookup !== null || existingConfiguration.x12Lookup !== null) {
                                        this.tabStatuses.specifyAS2Outbound = true;
                                    }
                                    this.preStep = existingConfiguration.preStep;
                                    this.confDestConnections = existingConfiguration.destinations;

                                    // This is to show configured sources for partner!
                                    // As we do not save this, there is no need for it to be in the FormGroup
                                    if(existingConfiguration.schedulers !== null && existingConfiguration.schedulers.length > 0) {
                                        this.tabStatuses.specifySource = true;
                                        this.schedulers = existingConfiguration.schedulers;
                                    }
                                    if(existingConfiguration.enrichments !== null && existingConfiguration.enrichments.length > 0) {
                                        this.enrichments = existingConfiguration.enrichments;
                                        this.tabStatuses.specifyEnrichment = true;
                                    }

                                    // We need to populate the FormGroup with data, as those are the bindings for reactive forms
                                    // For edit we set the values to the saved properties
                                    this.configurationForm = this.wService.getConfigurationFormGroupWithData(existingConfiguration, this.tabStatuses, this.basedataModel, this.outboundMaps);

                                    this.outboundMaps = this.basedataModel.outboundMaps.filter(x => x.outboundPartnerId === existingConfiguration.outboundPartner.id);

                                    this.showSpinner = false;
                                },
                                error: (error) => {
                                    this.showSpinner = false;
                                    // TODO: display error message!!!
                                    console.error(error);
                                }
                            }
                        );
                    } else {
                        this.showSpinner = false;
                    }
                },

                // TODO Add better error hanlding to present the issue!
                error: (error) => {
                    this.showSpinner = false;
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }

    public onclickGoToTab(tab: number) {
        this.tabModel.activeTab = tab;
    }

    onPartnerUpdated() {
        this.showSpinner = true;
        this.wService.setResolverClass(this.configurationForm, this.basedataModel);
        this.wService.setParserClass(this.configurationForm, this.basedataModel)
        // This is only for displaying the aS2ConfIn tab and for displaying configured values for as2. User should not be allowed to
        // configure as2 in Wizard, but in partner configuration
        this.wService.setAs2ConfIn(this.configurationForm, this.basedataModel)

        // Show sources if they exists for partner
        this.wService.getPartnerSources(this.configurationForm.value.inboundPartner.inboundPartnerId).subscribe(
        {
            next: (schedulers) => {
                this.showSpinner = false;
                if(schedulers && schedulers.length > 0) {
                    this.schedulers = schedulers;
                    this.tabStatuses.specifySource = true;
                } else {
                    this.tabStatuses.specifySource = false;
                }
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });

        // Prestep info
        // We need prestep configurations from tblPreStep for this to work!
        //this.basedataModel.preStepClasses.find(p => p.)
        // preStepClassId: new FormControl(''),
        // preStepClass: new FormControl(''), 

        this.addCanonicalMapFormGroups(this.configurationForm.value.inboundMessageType.inboundMessageTypeId, +this.configurationForm.value.inboundPartner.inboundPartnerId);
    }

    private addCanonicalMapFormGroups(inboundMessageTypeId: number, inboundPartnerId: number) {
        let selectedCanonicalMaps = this.basedataModel.canonicalMaps.filter(x => x.inboundMessageTypeId === inboundMessageTypeId && x.inboundPartnerId === inboundPartnerId);
        let inboundMappings = (this.configurationForm.get('inboundMappings') as FormArray);
        inboundMappings.clear();

        selectedCanonicalMaps.forEach(c => {
            let canonicalMapFormGroup = new FormGroup({
                id: new FormControl(c.id),
                partnerId: new FormControl(c.inboundPartnerId),
                messageTypeId: new FormControl(c.inboundMessageTypeId),
                taskId: new FormControl(c.taskId),
                xsltName: new FormControl(c.canonicalMapXslt.name),
                xsltDescription: new FormControl(c.canonicalMapXslt.description),
            });
            inboundMappings.push(canonicalMapFormGroup);
        });
    }

    public onMessageTypeUpdated() {
        if(this.configurationForm.value.inboundPartner.inboundPartnerId !== '' && this.configurationForm.value.inboundMessageType.inboundMessageTypeId !== '') {
            this.wService.setParserClass(this.configurationForm, this.basedataModel)
        } 
        
        this.addCanonicalMapFormGroups(+this.configurationForm.value.inboundMessageType.inboundMessageTypeId, +this.configurationForm.value.inboundPartner.inboundPartnerId);
    }

    onOutboundPartnerUpdated(partner: Partner) {
        this.outboundMaps = this.basedataModel.outboundMaps.filter(x => x.outboundPartnerId === partner.id);
    }

    public onDestinationUpdated(destinationModel: DestinationModel) {
        let confDestConnections = (this.configurationForm.get('confDestConnections') as FormArray);

        if(destinationModel.isAdd == true) {
            confDestConnections.push(destinationModel.formGroup);
        } else {
            let id = destinationModel.formGroup.get('id')?.value;
            let destinationId = destinationModel.formGroup.get('destinationId')?.value;
            const index = confDestConnections.controls.findIndex((g) => g.get('id')?.value === id && g.get('destinationId')?.value == destinationId);

            if (index !== -1) {
                confDestConnections.removeAt(index);
            }
        }
    }

    public onEntityMapUpdated(formGroup: FormGroup){
        if(this.configurationForm.value.entitymap.receiverId !== '' &&
            this.configurationForm.value.inboundMessageType.inboundMessageTypeId !== '') {
        }
    }

    public onEnrichmentUpdated(enrichments: Array<Enrichment>) {
        this.enrichments = enrichments;
    }

    public onclickSaveConfiguration() {
        let wizardRequestModel: WizardRequestModel = new WizardRequestModel();

        wizardRequestModel.entityMap = this.wService.getEntitymapFromFormGroup(this.configurationForm);
        wizardRequestModel.confDestConnections = this.confDestConnections;
        wizardRequestModel.preFormatting = this.wService.getPreFormatting(this.configurationForm);
        wizardRequestModel.postFormatting = this.wService.getPostFormatting(this.configurationForm);
        wizardRequestModel.responseResolver = this.wService.getResponseResolver(this.configurationForm, this.responseResolverUpdateData);
        if(this.tabStatuses.specifyEnrichment) {
            wizardRequestModel.enrichments = this.wService.getEnrichments(this.enrichments, wizardRequestModel.entityMap);
        }
        wizardRequestModel.aS2ConfOut = this.wService.getAS2ConfOutModel(this.configurationForm, wizardRequestModel.entityMap.id);
        wizardRequestModel.x12Lookup = this.wService.getX12LookupModel(this.configurationForm, wizardRequestModel.entityMap.id);
        wizardRequestModel.ediLookup = this.wService.getEdifactLookupModel(this.configurationForm, wizardRequestModel.entityMap.id);

        // Set APIM basic auth
        if(this.configurationForm.value.inboundPartner.basicAuthenticationUsername !== '' && this.configurationForm.value.inboundPartner.basicAuthenticationPassword !== '') {
            wizardRequestModel.keyVaultSecret = new KeyVaultSecret();
            wizardRequestModel.keyVaultSecret.username = this.configurationForm.value.inboundPartner.basicAuthenticationUsername;
            if(this.configurationForm.value.inboundPartner.basicAuthenticationPassword !== this.configurationForm.value.inboundPartner.currentBasicAuthenticationPassword) {
                wizardRequestModel.keyVaultSecret.password = this.configurationForm.value.inboundPartner.basicAuthenticationPassword;
            } else {
                // If password is empty string the secret is not added or updated
                wizardRequestModel.keyVaultSecret.password = '';
            }
        }

        this.wService.saveConfiguration(wizardRequestModel, this.isEdit).subscribe({
            next: (result) => {
                this.router.navigateByUrl(`/customer-configurations?sender=${wizardRequestModel.entityMap.senderName}&receiver=${wizardRequestModel.entityMap.receiverName}`);
            },
            error: (error) => {
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });
    }
}