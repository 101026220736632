import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ResolverClass } from 'src/app/models/resolver/resolver-class';
import { GridView } from 'src/app/models/grid-view';
import { PreStepClass } from 'src/app/models/pre-step-class';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/common-services/util.service';

@Injectable({
  providedIn: 'root',
})
export class MasterDataPrestepClassesService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient, public utilService: UtilService) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getPrestepClasses(pageindex: number, pagesize: number, prestepClass: string, description: string): Observable<GridView<PreStepClass>> {
      let queryStart = '';
      let queryString = '';
      
      queryStart = this.getQueryStart(queryStart);
      queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
      queryStart = this.getQueryStart(queryStart);
      queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)

      if(prestepClass != undefined && prestepClass.length > 2) {
          queryString = queryString.concat(`${queryStart}prestepClass=${prestepClass}`)
      }
      if(description != undefined && description.length > 2) {
          queryString = queryString.concat(`${queryStart}description=${description}`)
      }
      
      return this.http.get<GridView<PreStepClass>>(this.baseUrlLocal + `prestepclasses` + queryString );
  }

  public addPrestepClass(preStepClass: PreStepClass) {
    return this.http.post(this.baseUrlLocal + 'prestepclasses', preStepClass);
  }

  public updatePrestepClass(preStepClass: PreStepClass) {
      return this.http.put(this.baseUrlLocal + 'prestepclasses', preStepClass);
  }

  public deletePrestepClass(preStepClass: PreStepClass) {
      const options = {
          headers: new HttpHeaders({
          'Content-Type': 'application/json',
          }),
          body: preStepClass
      };
      return this.http.delete(this.baseUrlLocal + 'prestepclasses', options);
  }

  private getQueryStart(queryStart: string) {
    if(queryStart === '') {
        return  '?';
    } else {
        return '&';
    }
  }

  public getEmptyPrestepClassForm() {
    return new FormGroup({
      preStepClass: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required)
    });
  }

  public getPrestepClassForm(preStepClass: PreStepClass) {
    return new FormGroup({
      id:  new FormControl(preStepClass.id, Validators.required),
      preStepClass: new FormControl(this.utilService.getClassName(preStepClass.preStepClass), Validators.required),
      description: new FormControl(preStepClass.description, Validators.required)
    });
  }

  public getPrestepClassModel(formGroup: FormGroup) {
    let preStepClass: PreStepClass = new PreStepClass();
    preStepClass.id = formGroup.value.id;
    preStepClass.preStepClass = formGroup.value.preStepClass;
    preStepClass.description = formGroup.value.description;
    return preStepClass;
  }
}


