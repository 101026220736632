<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Resolver</span></legend>
 
    <div class="form-group row" *ngIf="form.name.value">
      <label for="resolverDDL" class="col-sm-2 col-form-label control-label">Resolver class name:</label>
      <div class="col-sm-6">
        {{ utilService.getClassName(form.name.value) }} <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="Resolver is used to resolve which kind of message type the incomming message is. Resolver is connected to a specific partner and is configured in the master data section."></i>
      </div>
    </div>
    
    <div class="form-group row" *ngIf="form.name.value">
        <label for="resolverDDL" class="col-sm-2 col-form-label control-label">Description:</label>
        <div class="col-sm-6">
            {{form.description.value}}
        </div>
    </div>

    <div class="form-group row" *ngIf="!form.name.value">
        <div class="col-sm-12 text-red">
            No resolver configured for the selected partner
        </div>
    </div>
</fieldset>
  