<div>
  <review-inbound-partner></review-inbound-partner>
  <review-message-type></review-message-type>
  <review-entitymap></review-entitymap>
  <review-outbound-partner></review-outbound-partner>
  <review-outbound-message-type></review-outbound-message-type>
  <div *ngIf="tabStatuses.specifySource">
      <review-source [schedulers]="schedulers"></review-source>
  </div>
  <review-pre-formatting></review-pre-formatting>
  <review-response-resolver [responseResolver]="locResponseResolver"></review-response-resolver>
  <review-resolver></review-resolver>
  <review-parser></review-parser>
  <review-enrichment [enrichments]="enrichments"></review-enrichment>
  <review-mapping></review-mapping>
  <review-post-formatting></review-post-formatting>
  <review-destinations [confDestConnections]="confDestConnections"></review-destinations>
  <review-post-formatting></review-post-formatting>
  <review-as2-confin></review-as2-confin>
  <review-edi-lookup></review-edi-lookup>
  <review-x12-lookup></review-x12-lookup>
  <review-as2-confout></review-as2-confout>
</div>
