<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div>
    <fieldset class="border p-2">
        <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Inbound => Canonical Mapping Connections</span></legend>

        <div style="margin-bottom: 10px;">
            <span class="text-muted"><small>Inbound => Canonical-documents are used to transform incomming data to a canonical format</small></span>
        </div>

          <hr />

        <div><fieldset class="border p-2 mb10 mt10" *ngIf="itcmcGridList">
  <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Connected Inbound => Canonical-documents</span></legend>
  <div>
  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="itcmcGridList">
    <thead>
      <tr>
        <th scope="col" colSpan="8">
          <div>
            <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="addPartnerInboundToCanonicalMappingConnection()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
        </th>
      </tr>
    </thead>
    <thead>
        <tr>
            <th>
              <div>InboundMessageType</div>
            </th>
            <th>
              <div>TaskId</div>
            </th>
            <th>
              <div>Inbound => Canonical Mapping Document</div>
            </th>
            <th>
              <div>Created by</div>
            </th>
            <th>
              <div>Created at</div>
            </th>
            <th>
              <div>Updated by</div>
            </th>
            <th>
              <div>Updated at</div>
            </th>
            <th>
              <div></div>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="itcmcGridList != undefined && itcmcGridList.length === 0">
        <tr>
            <td scope="col" colSpan="7">No Inbound => Canonical mapping-documents configured</td>
        </tr>
    </tbody>
    <tbody *ngIf="itcmcGridList!= undefined && itcmcGridList.length > 0">
        <tr *ngFor="let itcmc of itcmcGridList;let index = index;">
            <td>
              <span>{{itcmc.inboundMessageType.messageName}}</span>
            </td>
            <td>
              <span>{{itcmc.taskId}}</span>
            </td>
            <td>
              <span>{{itcmc.canonicalMapXslt.name}}</span>
            </td>
            <td>
              <span>{{itcmc.createdBy}}</span>
            </td>
            <td>
              <span>{{itcmc.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
            </td>
            <td>
              <span>{{itcmc.updatedBy}}</span>
            </td>
            <td>
              <span>{{itcmc.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
            </td>
            <td>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="editPartnerInboundToCanonicalMappingConnection(itcmc)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="removePartnerInboundToCanonicalMappingConnection(itcmc)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
            </td>
        </tr>
    </tbody>
    <tfoot>
    </tfoot>
</table>
</div>
</fieldset>
<br />
</div>
</fieldset>
</div>
