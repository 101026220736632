import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'test-form-validation',
    templateUrl: './test-form-validation.component.html',
})
export class TestFormValidationComponent  implements OnInit {
    userForm : FormGroup;
    formSubmitted : boolean = false;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.userForm = new FormGroup({            
            addressInfo: new FormGroup({
                addressLine1: new FormControl('', Validators.required),
            }),
            firstName: new FormControl('', Validators.required),
        });


    }

    get form() { return this.userForm.controls; }

    onSubmit(){
        if(this.userForm.valid){
            alert('good')
        } else {
          this.formSubmitted = true;
        }
    }
}


