<form [formGroup]="outboundMapXsltForm">
  <fieldset class="border p-2">
      <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound mapping</span></legend>
      <div style="margin-bottom: 10px;">
        <span class="text-muted"><small>The outbound mapping is using xslt to transform the GOH internal canonical message format to the external customer format. The selected xslt map must exist in the Integration Account.</small></span>
      </div>
      <div class="form-group row">
        <label for="outboundMappingDDL" class="col-sm-2 col-form-label control-label">Xslt map <span class="req-color">*</span></label>
        <div class="col-sm-6">
          <select dropdown formControlName="outboundMapXsltId" (change)="selectOutboundMappingChangeHandler($event)" id="outboundMappingDDL" name="outboundMappingDDL" class="form-select form-select-sm control-label">
            <option value="">--- Select outbound mapping ---</option>
            <option *ngFor="let item of outboundMaps" [value]="item.outboundMapXslt.id">
              {{item.outboundMapXslt.name}} ({{item.outboundMapXslt.description}})
            </option>
          </select>
          <div *ngIf="form.outboundMapXsltId.invalid && (form.outboundMapXsltId.dirty || form.outboundMapXsltId.touched)" class="alert alert-danger">
            <div *ngIf="form.outboundMapXsltId.errors?.['required']">Outbound mapping document is required.</div>
          </div>
        </div>
      </div>
  </fieldset>
</form>

