import { ConfDestination } from "./conf-destination";

export class ConfDestConnection {
    id: number;
    destinationId: number;
    enabled: boolean;
    removeBom: boolean;
    description: string;    
    confDestination: ConfDestination;
    createdBy: string;
    createTimestamp: string;
    entityMapId: number;
}
  