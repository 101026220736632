<h3 id="tableLabel">Test form validation - reactive forms</h3>


<form [formGroup]="userForm" (submit)="onSubmit()" class="text-center border border-light p-5">
    <div class="form-group row">
        <label for="firstName" class="col-sm-2 col-form-label">First name <span class="req-color">*</span></label>
        <div class="col-sm-5">
            <input type="text" class="form-control" formControlName="firstName" required id="firstName" name="firstName" placeholder="First name">
            <div *ngIf="form.firstName.invalid && (form.firstName.dirty || form.firstName.touched)" class="alert alert-danger">
                <div *ngIf="form.firstName.errors?.['required']">First name is required.</div>
              </div>
        </div>
    </div>

    <address-info formGroupName="addressInfo"></address-info>
    <entitymap-info></entitymap-info>
    

    <button type="submit" class="btn btn-outline-dark" [disabled]="!userForm.valid">Add</button>

    <p>Form Status: {{ userForm.status }}</p>
    <pre style="margin-top:50px; border: 1px solid red; width: fit-content">
        {{ userForm.value | json }}
    </pre>

</form>




