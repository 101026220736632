<table class="table table-bordered table-striped table-sm">
  <thead>
    <tr>
      <th *ngFor="let column of columns">{{ column.title }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="isFormArray(data.get('rows'))">
      <tr *ngFor="let row of getFormArray(data.get('rows'))?.controls">
        <td *ngFor="let column of columns">
          <ng-container *ngIf="column.isCommandColumn; else regularColumn">
            <ng-container *ngTemplateOutlet="column.template; context: { $implicit: row.value, removeClicked: removeRow, editClicked: editRow }">
            </ng-container>
          </ng-container>
          <!-- NOT WORKING AT ALL -->
          <!-- <ng-container *ngIf="column.isCommandColumn; else regularColumn">
            <ng-container *ngTemplateOutlet="column.template; context: { $implicit: row.value }"></ng-container>
          </ng-container> -->
          <!-- WORKING WITH ONLY THE REMOVE BUTTON -->
          <!-- <ng-container *ngIf="column.isCommandColumn; else regularColumn">
            <ng-container *ngTemplateOutlet="column.template; context: { $implicit: row.value, removeClicked: removeRow }" (removeClicked)="removeRow(row)">
            </ng-container>
          </ng-container> -->
          <ng-template #regularColumn>{{ getFormControl(row, column.field)?.value }}</ng-template>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>