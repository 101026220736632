import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { UtilService } from 'src/app/common-services/util.service';
import { ReviewwInputService } from '../review-input.service';

@Component({
  selector: 'review-post-formatting',
  templateUrl: './review-post-formatting.component.html',
})
export class ReviewPostFormattingComponent {
  form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, public utilService: UtilService, public rs: ReviewwInputService) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.form;
  }
}
