import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PagingService {
    public getPageNumber(currentPage: number, index: number, totalPages: number) {
        let firstPageNumbers = [1, 2, 3];
        let pageNumber: number = 1;
    
        if(totalPages === 1) 
          return 1;
    
        if(currentPage <= (totalPages - 1) && firstPageNumbers.includes(index))
          pageNumber = index;
        if(currentPage > 2 && currentPage < totalPages) {
          switch(index) { 
            case 1: { 
              pageNumber = currentPage - 1; 
               break; 
            } 
            case 2: { 
              pageNumber = currentPage; 
               break; 
            } 
            case 3: { 
              pageNumber = currentPage + 1; 
              break; 
           } 
            default: { 
              pageNumber; 
               break; 
            }
         } 
        }
        
        if(totalPages == 2 && currentPage === totalPages) {
          switch(index) { 
            case 1: { 
              pageNumber = totalPages - 1; 
               break; 
            } 
            case 2: { 
              pageNumber = totalPages; // - 1; 
               break; 
            } 
            case 3: { 
              pageNumber = totalPages; // TODO Can this even happen????
              break; 
           } 
            default: { 
              pageNumber; 
               break; 
            }
          } 
        }

        if(totalPages == 3 && currentPage === totalPages) {
          switch(index) { 
            case 1: { 
              pageNumber = 1; 
               break; 
            } 
            case 2: { 
              pageNumber = 2;
               break; 
            } 
            case 3: { 
              pageNumber = totalPages; // TODO Can this even happen????
              break; 
           } 
            default: { 
              pageNumber; 
               break; 
            }
          } 
        }

        if(totalPages > 3 && currentPage === totalPages) {
          switch(index) { 
            case 1: { 
              pageNumber = totalPages - 2; 
               break; 
            } 
            case 2: { 
              pageNumber = totalPages - 1; 
               break; 
            } 
            case 3: { 
              pageNumber = totalPages;
              break; 
           } 
            default: { 
              pageNumber; 
               break; 
            }
          } 
        }
    
        return pageNumber;
      }
    
      public pageNumberBold(pageNumber: number, index: number, totalPages: number) {
        let isBold: boolean = false;
    
        if(pageNumber === 1 && index === 1)
          return true;
    
        if((pageNumber > 1 && pageNumber < totalPages) && index === 2)
          return true;
        
        if(pageNumber === totalPages && totalPages === 2 && index === 2)
          return true;

        if(pageNumber === totalPages && index === 3)
          return true;
    
        return false;
      }
}
