import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Partner } from 'src/app/models/partner/partner';
import { ResolverTestRequest } from 'src/app/models/resolver/resolver-test-request';
import { ResolverResult } from 'src/app/models/resolver/resolver-result';

@Injectable({
  providedIn: 'root',
})
export class ResolverTesterService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getPartners(): Observable<Array<Partner>> {
        return this.http.get<Array<Partner>>(this.baseUrlLocal + `partners`);
    }

    public resolve(partnerId: string, message: string): Observable<ResolverResult> {
        let resolverRequest = new ResolverTestRequest;
        resolverRequest.partnerId = partnerId;
        resolverRequest.message = message;
        return this.http.post<ResolverResult>(this.baseUrlLocal + 'resolver', resolverRequest);
    }
}


