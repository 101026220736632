<h3 id="tableLabel">Administrate service mode</h3>

<div class="row mb10">
    <div class="col-sm-12">
        <b>Note: As changing the ServiceMode Environment variable will restart the App Service, there is a delay before the status of the above button will change.</b>
    </div>    
</div>

<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<error-display [errorModel]="errorModel"></error-display>
<div class="row mb10">
  <table>
    <tr>
      <td>
        <button *ngIf="serviceModeResponseModel && serviceModeResponseModel.status === false" type="button" class="btn btn-danger btn-lg" style="margin-right: 5px;" (click)="setServiceMode(true)"><b>Start service mode</b></button>
        <button *ngIf="serviceModeResponseModel && serviceModeResponseModel.status === true" type="button" class="btn btn-primary btn-lg" style="margin-right: 5px;" (click)="setServiceMode(false)"><b>Stop service mode</b></button>
        <button [disabled]="serviceModeResponseModel != undefined && serviceModeResponseModel.genericInboundState === 'Running'" type="button" class="btn btn-outline-secondary btn-lg" (click)="startGenericInbound()"><b>Start generic inbound</b></button>  
      </td>
    </tr>
  </table>
</div>
<div class="form-group row mb5">
    <label for="parser" class="col-sm-1 col-form-label">Comment</label>
    <div class="col-sm-7">
        <input type="text" class="form-control" id="parser" name="parser" placeholder="Comment" [(ngModel)]="comment">
    </div>
</div>

<div *ngIf="serviceModeData">
    <fieldset class="border p-2">
        <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Service mode history</span></legend>
        <div class="row mb5">
            <div class="col-sm-12">
                <table class='table table-striped table-bordered' aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th class="vtop">
                              <div>Id</div>
                            </th>
                            <th class="vtop">
                              <div>Enabled by</div>
                            </th>
                            <th class="vtop">
                              <div>Enabled at</div>
                            </th>
                            <th class="vtop">
                              <div>Finished by</div>
                            </th>
                            <th class="vtop">
                              <div>Finished at</div>
                            </th>
                            <th class="vtop">
                              <div>Comment</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="serviceModeData">
                        <tr *ngFor="let s of serviceModeData;let index = index;" [ngClass]="{'redbackgroundcolor': s.finishedBy == null}">
                            <td>
                              <span>{{s.id}}</span>
                            </td>
                            <td>
                              <span>{{s.enabledBy}}</span>
                            </td>
                            <td>
                              <span>{{s.enabledTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
                            </td>
                            <td>
                              <span>{{s.finishedBy}}</span>
                            </td>
                            <td>
                              <span>{{s.finishedTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
                            </td>
                            <td>
                              <span>{{s.comment}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </fieldset>
</div>
