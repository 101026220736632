<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Configuration information</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Sender Id: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label *ngIf="form.value.entitymap.senderId !== ''" class="col-sm-6">{{form.value.entitymap.senderId}}</label>
            <label *ngIf="form.value.entitymap.senderId === ''" class="text-red">MISSING DATA</label>
        </div>
        <div class="col-sm-2">
            <label><b>Sender name: <span class="req-color">*</span></b></label>    
        </div>
        <div class="col-sm-3">
            <label *ngIf="form.value.entitymap.senderName !== ''">{{form.value.entitymap.senderName}}</label>
            <label *ngIf="form.value.entitymap.senderName === ''" class="text-red">MISSING DATA</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Receiver Id: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label *ngIf="form.value.entitymap.receiverId !== ''">{{form.value.entitymap.receiverId}}</label>
            <label *ngIf="form.value.entitymap.receiverId === ''" class="text-red">MISSING DATA</label>
        </div>
        <div class="col-sm-2">
            <label><b>Receiver name: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label *ngIf="form.value.entitymap.receiverName !== ''">{{form.value.entitymap.receiverName}}</label>
            <label *ngIf="form.value.entitymap.receiverName === ''" class="text-red">MISSING DATA</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Legal Entity Id: <span class="req-color">*</span></b></label>    
        </div>
        <div class="col-sm-3">
            <label *ngIf="form.value.entitymap.legalEntityId !== ''">{{form.value.entitymap.legalEntityId}}</label>
            <label *ngIf="form.value.entitymap.legalEntityId === ''" class="text-red">MISSING DATA</label>
        </div>
        <div class="col-sm-2">
            <label><b>Legal Entity name: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label *ngIf="form.value.entitymap.legalEntityName !== ''">{{form.value.entitymap.legalEntityName}}</label>
            <label *ngIf="form.value.entitymap.legalEntityName === ''" class="text-red">MISSING DATA</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Area Plant Id:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{form.value.entitymap.areaPlantId}}</label>
        </div>
        <div class="col-sm-2">
            <label><b>Area Plant name:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{form.value.entitymap.areaPlantName}}</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Country:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{form.value.entitymap.country}}</label>
        </div>
        <div class="col-sm-2">
            <label><b>Custom Lookup ID:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{form.value.entitymap.customLookupId}}</label>
        </div>
    </div>
</fieldset>