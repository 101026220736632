import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageTrackerComponent } from './messages/message-tracker.component';
import { MessageTrackerService } from './messages/message-tracker.service';
import { ViewMessageComponent } from './child-components/view-message.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CorrelatedMessageComponent } from './correlated-messages/correlated-message.component';
import { CorrelatedMessageService } from './correlated-messages/correlated-message.service';
import { CommonComponentsModule } from 'src/app/common-components/common-components.module';
import { TimestampFilterModalComponent } from './child-components/timestamp-filter-modal.component';

@NgModule({
  imports:      [ 
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    CommonComponentsModule,
    NgbModule,
    NgbDatepickerModule
  ],
  providers:    [ MessageTrackerService, CorrelatedMessageService, DatePipe ],
  declarations: [ MessageTrackerComponent, ViewMessageComponent, CorrelatedMessageComponent, TimestampFilterModalComponent ]
})
export class MessageTrackerModule { }