import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ParserClass } from 'src/app/models/parser/parser-class';
import { UtilService } from 'src/app/common-services/util.service';
import { GridView } from 'src/app/models/grid-view';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ParserClassService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient, private utilService: UtilService) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getParsers(pageindex: number, pagesize: number, parser: string, description: string): Observable<GridView<ParserClass>> {
        let queryStart = '';
        let queryString = '';
        
        queryStart = this.utilService.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
        queryStart = this.utilService.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)
  
        if(parser != undefined && parser.length > 2) {
            queryString = queryString.concat(`${queryStart}parser=${parser}`)
        }
        if(description != undefined && description.length > 2) {
            queryString = queryString.concat(`${queryStart}description=${description}`)
        }
        
        return this.http.get<GridView<ParserClass>>(this.baseUrlLocal + `parserClasses` + queryString );
    }

    public addParsers(parserClass: ParserClass) {
        return this.http.post(this.baseUrlLocal + 'parserClasses', parserClass);
    }

    public updateParsers(parserClass: ParserClass) {
        return this.http.put(this.baseUrlLocal + 'parserClasses', parserClass);
    }

    public getEmptyParserClassForm() {
        return new FormGroup({
            id: new FormControl(0, Validators.required),
            parser: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required)
        });
    }

    public getParserClassForm(parserClass: ParserClass) {
        return new FormGroup({
            id: new FormControl(parserClass.id, Validators.required),
            parser: new FormControl(parserClass.parser, Validators.required),
            description: new FormControl(parserClass.description, Validators.required)
        });
    }

    public getParserClassModel(formGroup: FormGroup) {
        let parserClass: ParserClass = new ParserClass();
        parserClass.id = formGroup.value.id;
        parserClass.parser = formGroup.value.parser;
        parserClass.description = formGroup.value.description;
        return parserClass;
    }
}