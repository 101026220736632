<div class="modal-header">
    <h4 class="modal-title">Delete source for partner: {{partner.partnerId}}</h4>
</div>
<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<error-display [errorModel]="errorModel"></error-display>
<div class="modal-body">
    Are you really sure you want to delete this source?
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
