import { Component, Input, Output, EventEmitter, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { generate } from 'rxjs';
import { TabModel } from 'src/app/models/tab-model';
import { MessageTrackerService } from '../messages/message-tracker.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlobRequest } from '../models/blob-request';
import { Tracking } from '../models/tracking';
import { BlobResponse } from '../models/blob-response';
import { TrackingView } from '../models/tracking-view';
import { TrackingEventDetails } from '../models/tracking-event-details';
import { UtilService } from 'src/app/common-services/util.service';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { TrackingEventAS2Details } from '../models/tracking-event-as2details';
import { TrackingEventDelivery } from '../models/tracking-event-delivery';
import { DatePipe } from '@angular/common';
import { CancelRequestModel } from '../models/cancel-request-model';
import { TrackingEventMain } from '../models/tracking-event-main';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'view-message',
  templateUrl: './view-message.component.html'
})
export class ViewMessageComponent implements OnInit {
    @Input() trackingView: TrackingView;
    @Input() isArchive: boolean;

    public errorModel: ErrorModel = null;
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = false;
    public tabModel: TabModel = new TabModel();
    public trackingEventDetails: TrackingEventDetails;    
    public trackingEventAS2Details: Array<TrackingEventAS2Details>
    public tracking: Array<Tracking>
    public errorRows: Array<Tracking>;
    public cancelRows: Array<Tracking>;
    public deliveryRows: Array<TrackingEventDelivery>;
    public blobPathAS2Encoded: string = '';
    public blobPathAS2OutboundMDN: string = '';
    public blobPathAS2InboundMDN: string = '';
    public aS2DecodedBlobPath: string = '';
    public aS2InboundMDNBlobPath: string = '';
    public aS2OutboundMDNBlobPath: string = '';
    public blobPathIn: string = '';
    public blobPathCanonical: string = '';
    public blobPathEnrichedCanonical: string = '';
    public blobPathEncoded: string = '';
    public blobPathPreEncoded: string = '';
    public blobPathOut: string = '';
    public blobPathError: string = '';
    public blobPathMdnDecoded: string = '';
    public blobResponse: BlobResponse;
    public payloadAS2Encoded: string = '';
    public payloadAS2Decoded: string = '';
    public payloadIn: string = '';
    public payloadAS2InboundMDN: string = '';
    public payloadAS2OutboundMDN: string = '';
    public payloadMdnDecoded: string = '';
    public payloadCanonical: string = '';
    public payloadEnrichedCanonical: string = '';
    public payloadEncoded: string = '';    
    public payloadPreEncoded: string = '';    
    public payloadOut: string = '';
    public payloadError: string = '';
    public hasAS2Details: boolean = false;
    public hasSentRows: boolean = false;
    public mdnLableName: string = 'MDN'
    public showAS2Encoded: boolean = false;
    public showAS2Decoded: boolean = false;
    public showAS2OutboundMDN: boolean = false;    
    public showAS2InboundMDN: boolean = false;
    public showMdnDecoded: boolean = false;
    public showEdiIn: boolean = false;
    public showEdiEncoded: boolean = false;
    public showEdiPreEncoded: boolean = false;
    public showInfileTab: boolean = false;
    public showCanonicalfileTab: boolean = false;
    public showEnrichedCanonicalfileTab: boolean = false;
    public showOutFileTab: boolean = false;
    public isError: boolean = false;
    public isCancel: boolean = false;
    public isNotMdn: boolean = true;
    public showErrorTab: boolean = false;
    public editTypeName: string = '';
    public trackingEventMain: TrackingEventMain;
    public isSuperUser: boolean = false;

    public errorRowsColumns = [
        { header: 'Timestamp (utc)', field: 'timestamp', pipe: { name: DatePipe, args: ['yyyy-MM-dd HH:mm:ss'] }, class: 'width150' },
        { header: 'Message', field: 'message' }
    ];
    public errorRowsData: Array<any> = null;
    public cancelRowsColumns = [
        { header: 'Timestamp (utc)', field: 'timestamp', pipe: { name: DatePipe, args: ['yyyy-MM-dd HH:mm:ss'] }, class: 'width150' },
        { header: 'Message', field: 'message' },
        { header: 'Canceled by', field: 'canceledBy', class: 'width150' },
        { header: 'Canceled at', field: 'canceledAt', class: 'width150' }
    ];
    public cancelRowsData: Array<any> = new Array<any>;
    public cancelMessage: string = '';

    constructor(public trackerService: MessageTrackerService, public utilService: UtilService, public activeModal: NgbActiveModal, private errorParserService: ErrorParserService, private authService: MsalService) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isSuperUser = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.SuperUser') == undefined ? false : true;
        }

        this.getTrackingData(this.trackingView);
    }

    public onCancel() {
        let cancelRequestModel: CancelRequestModel = new CancelRequestModel();
        cancelRequestModel.flowId = this.trackingView.flowID;
        cancelRequestModel.message = this.cancelMessage;
        //this.showSpinner = true;
        
        this.trackerService.addCancelMessage(cancelRequestModel).subscribe(
            {
                next: (result) => {
                    //this.showSpinner = false; 
                    //this.activeModal.close('save');

                    this.getTrackingData(this.trackingView);
                },
                error: (error) => {
                    //this.showSpinner = false; 
                    //this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }

    private getTrackingData(tv: TrackingView) {
      if(this.isArchive == undefined){
        this.isArchive = tv.isArchive;
      }
      this.trackerService.getTrackingDataForFlowId(tv.flowID, this.isArchive).subscribe(
            {
                next: (trackingData) => {
                    // this.modalService.open(content, {windowClass: 'xlModalClass', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
                    //     // This is on OK button, Use for save for example

                    // }, (reason) => {
                    //     //This is on click outside and on cancel button
                    //     //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    // });

                    /*
                    Message Type    Status  Substatus   Step                Action
                    AS2	(9)         2	    2	        DECODE_AS2_EDIFACT  Show AS2 Edifact Encoded tab
                    AS2	(9)         2	    2	        DECODE_AS2_X12      Show AS2 X12 Encoded tab
                    EDIFACT (10)    2       1           DECODE_EDIFACT      Show Edifact In tab
                    AS2	(9)         2	    5	        DECODE_AS2          Show AS2 Decoded tab

                    -               2	    3	        INBOUND             Show In tab
                    -               2	    3	        MAP_TO_CANONICAL    Show canonical tab
                    -               2	    3	        MAP_TO_OUTBOUND     Show Out tab


                    */

                    let blobRequest = new BlobRequest();
                    this.trackingEventDetails = trackingData;
                    this.tracking = trackingData.trackingEvents; // Change to include Main-row later on and present data from that in view instead                 
                    let blobMessageType = tv.messageType.split('_').join('-').toLocaleLowerCase();
                    let partner = tv.partner.toLowerCase();
                    this.trackingEventMain = trackingData.trackingEventMain;
                    
                    //MDN Part
                    //msg/advansor/as2-mdn/nxmas2https/alfalaval/in/8f6e7900-5aa6-48c7-9dcd-33d353b52c86
                    this.hasAS2Details = this.trackingEventDetails.trackingEventAS2Details.length > 0 ? true : false;
                    if(this.hasAS2Details)                    {
                        this.trackingEventAS2Details = this.trackingEventDetails.trackingEventAS2Details;
                        if(this.trackingEventAS2Details[0].direction == 'IN'){
                            this.mdnLableName = 'SENT MDN';
                            this.aS2OutboundMDNBlobPath = `/msg/${partner}/as2-mdn/${this.trackingEventAS2Details[0].aS2SenderId.toLowerCase()}/${this.trackingEventAS2Details[0].aS2ReceiverId.toLowerCase()}/decoded`;
                            this.showAS2OutboundMDN = true;
                        }                            
                        else if(this.trackingEventAS2Details[0].direction == 'OUT')                        {
                            this.mdnLableName = 'RECEIVED MDN';
                            this.errorRows != undefined && this.errorRows.length > 0
                            if(this.trackingEventAS2Details[0].aS2MdnPartner != undefined && this.trackingEventAS2Details[0].aS2MdnPartner.length > 0){
                                this.aS2InboundMDNBlobPath = `/msg/${this.trackingEventAS2Details[0].aS2MdnPartner.toLowerCase()}/as2-mdn/${this.trackingEventAS2Details[0].aS2MdnSender.toLowerCase()}/${this.trackingEventAS2Details[0].aS2MdnReceiver.toLowerCase()}/decoded`;
                                this.showAS2InboundMDN = true;
                            }                                                                                                                                    
                        }
                    } 

                    if(this.isArchive) {
                        // If we view tracking there is only one line
                        let inRow = this.trackingEventDetails.trackingEvents.find(x => +x.status === 3 && +x.subStatus === 3);
                        if(inRow) {
                            this.blobPathIn = `/msg/${partner}/${blobMessageType}/${inRow.senderID.toLowerCase()}/${inRow.receiverID.toLowerCase()}/in`;
                            this.showInfileTab = true;
                            this.blobPathCanonical = `/msg/${partner}/${blobMessageType}/${inRow.senderID.toLowerCase()}/${inRow.receiverID.toLowerCase()}/canonical`;
                            this.showCanonicalfileTab = true;
                            this.blobPathOut = `/msg/${partner}/${blobMessageType}/${inRow.senderID.toLowerCase()}/${inRow.receiverID.toLowerCase()}/out`;
                            this.showOutFileTab = true;
                            blobRequest.path = this.blobPathIn;
                            if(inRow.isAS2) {
                                this.tabModel.activeTab = 1;
                                this.blobPathAS2Encoded = `/msg/${partner}/as2/${inRow.aS2Sender.toLowerCase()}/${inRow.aS2Receiver.toLowerCase()}/in`;
                                this.showAS2Encoded = true;
                                blobRequest.path = this.blobPathAS2Encoded;
                                this.aS2DecodedBlobPath = `/msg/${partner}/as2/${inRow.ediSenderIdentifier.toLowerCase()}/${inRow.ediReceiverIdentifier.toLowerCase()}/decoded`;
                                this.showEdiIn = true;
                                this.showAS2Decoded = true;
                                this.editTypeName = inRow.ediType;
                            }
                        }
                    } else {
                        // We got as2 in file
                        let as2FileRow = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'AS2' && +x.status === 2 && +x.subStatus === 2 && x.step === 'DECODE_AS2');
                        if(as2FileRow) {
                            this.tabModel.activeTab = 1;
                            this.blobPathAS2Encoded = `/msg/${partner}/as2/${as2FileRow.senderID.toLowerCase()}/${as2FileRow.receiverID.toLowerCase()}/in`;
                            this.showAS2Encoded = true;
                            blobRequest.path = this.blobPathAS2Encoded;
                        }

                        // We got as2 decoded file
                        let as2DecodedRow = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'AS2' && +x.status === 2 && +x.subStatus === 5);
                        if(as2DecodedRow) {
                            this.aS2DecodedBlobPath = `/msg/${partner}/as2/${as2DecodedRow.senderID.toLowerCase()}/${as2DecodedRow.receiverID.toLowerCase()}/decoded`;
                            this.showEdiIn = true;
                            this.showAS2Decoded = true;
                            let isEdifact = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'AS2' && +x.status === 1 && +x.subStatus === 1 && x.step === 'INBOUND_AS2_EDIFACT');
                            let isX12 = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'AS2' && +x.status === 1 && +x.subStatus === 1 && x.step === 'INBOUND_AS2_X12');

                            if(isEdifact != undefined) {
                                this.editTypeName = 'Edifact';
                            } else if(isX12 != undefined) {
                                this.editTypeName = 'X12';
                            }
                        }

                        let ediIn = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'EDIFACT' && +x.status === 2 && +x.subStatus === 1 && x.step === 'DECODE_EDIFACT');
                        if(ediIn && this.showAS2Decoded === false) {
                            this.aS2DecodedBlobPath = `/msg/${partner}/edifact/in`;
                            this.showAS2Decoded = true;
                            this.editTypeName = 'Edifact';
                        }

                        let x12In = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'X12' && +x.status === 2 && +x.subStatus === 1 && x.step === 'DECODE_X12');
                        if(x12In && this.showAS2Decoded === false) {
                            this.aS2DecodedBlobPath = `/msg/${partner}/x12/in`;
                            this.showAS2Decoded = true;
                            this.editTypeName = 'X12';
                        }

                        // We got In file (we know when we tracked finised in Inbound, otherwise error??? )
                        let inRow = this.trackingEventDetails.trackingEvents.find(x => +x.status === 2 && +x.subStatus === 3 && x.step === 'INBOUND');
                        if(inRow) {
                            this.blobPathIn = `/msg/${partner}/${blobMessageType}/${inRow.senderID.toLowerCase()}/${inRow.receiverID.toLowerCase()}/in`;
                            this.showInfileTab = true;
                            if(this.showAS2Encoded == false) {
                                this.tabModel.activeTab = 3;
                                blobRequest.path = this.blobPathIn;
                            }
                        }else{
                            // Untill we set an other status of the StatusUpdateRequests this will have to do 
                            inRow = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'ROQUETTE_CXML_STATUS_UPDATE_REQUEST' && x.step === 'INBOUND');
                            if(inRow) {
                                this.blobPathIn = `/msg/${partner}/${blobMessageType}/${inRow.senderID.toLowerCase()}/${inRow.receiverID.toLowerCase()}/in`;
                                this.showInfileTab = true;
                                if(this.showAS2Encoded == false) {
                                    this.tabModel.activeTab = 3;
                                    blobRequest.path = this.blobPathIn;
                                }
                            }
                        }

                        let canonicalRow = this.trackingEventDetails.trackingEvents.find(x => +x.status === 2 && +x.subStatus === 3 && x.step === 'MAP_TO_CANONICAL');
                        if(canonicalRow) {
                            this.blobPathCanonical = `/msg/${partner}/${blobMessageType}/${canonicalRow.senderID.toLowerCase()}/${canonicalRow.receiverID.toLowerCase()}/canonical`;
                            this.showCanonicalfileTab = true;
                        }

                        if(this.trackingEventDetails.hasEnrichments && canonicalRow) {
                            this.blobPathEnrichedCanonical = this.blobPathCanonical = `/msg/${partner}/${blobMessageType}/${canonicalRow.senderID.toLowerCase()}/${canonicalRow.receiverID.toLowerCase()}/enrichedcanonical`;
                            this.showEnrichedCanonicalfileTab = true;
                        }

                        let outRow = this.trackingEventDetails.trackingEvents.find(x => +x.status === 2 && +x.subStatus === 3 && x.step === 'MAP_TO_OUTBOUND');
                        if(outRow) {
                            this.blobPathOut = `/msg/${partner}/${blobMessageType}/${outRow.senderID.toLowerCase()}/${outRow.receiverID.toLowerCase()}/out`;
                            this.showOutFileTab = true;
                        }

                        let edifactEndodedRow = this.trackingEventDetails.trackingEvents.find(x => (x.step === 'ENCODE_EDIFACT' || x.step === 'ENCODE_X12') && +x.status === 2 && +x.subStatus === 4);
                        if(edifactEndodedRow) {
                            this.blobPathEncoded = `/msg/${partner}/${blobMessageType}/${edifactEndodedRow.senderID.toLowerCase()}/${edifactEndodedRow.receiverID.toLowerCase()}/encoded`;
                            this.showEdiEncoded = true;

                            // If it's an EDI encoded blob we also will have the preencoded
                            this.blobPathPreEncoded = `/msg/${partner}/${blobMessageType}/${edifactEndodedRow.senderID.toLowerCase()}/${edifactEndodedRow.receiverID.toLowerCase()}/preencode`;
                            this.showEdiPreEncoded = true;
                        }

                        // If we show the infile or as encoded there is no reason to show error tab
                        if(this.showAS2Encoded == false && this.showInfileTab == false) {
                            if(tv.status === 'FAILED' || tv.status === 'CANCELLED') {
                                if(tv.messageType === 'UNKNOWN') {
                                    this.blobPathError = `/msg/${partner}/error`;
                                } else {
                                    if(tv.senderId != null && tv.senderId.length > 0) {
                                        this.blobPathError = `/msg/${partner}/${blobMessageType}/${tv.senderId.toLowerCase()}/${tv.receiverId.toLowerCase()}/error`;
                                    } else {
                                        this.blobPathError = `/msg/${partner}/${blobMessageType}/error`;
                                    }
                                }
                                this.showErrorTab = true;
                                blobRequest.path = this.blobPathError;
                                this.tabModel.activeTab = 8;
                            }
                        }

                        if(x12In && this.showAS2Decoded === false) {
                            
                        }

                        let as2InMdnRowDecoded = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'AS2_MDN' && +x.status === 4 && +x.subStatus === 3);
                        if(as2InMdnRowDecoded) {
                            this.blobPathMdnDecoded = `/msg/${partner}/as2-mdn/${tv.senderId.toLowerCase()}/${tv.receiverId.toLowerCase()}/decoded`;
                            this.showMdnDecoded = true;
                            // If persist stored mdn correct it will be in the normal in folder... Hide the rest
                            this.showAS2Encoded = false;
                            this.isNotMdn = false;
                            this.showErrorTab = false;
                        }

                        let as2InMdnRowIn = this.trackingEventDetails.trackingEvents.find(x => x.inboundMessageTypeName === 'AS2_MDN' && +x.status === 2 && +x.subStatus === 1 && x.step === 'DECODE_AS2_MDN');
                        if(as2InMdnRowIn) {
                            this.blobPathIn = `/msg/${partner}/as2-mdn/${tv.senderId.toLowerCase()}/${tv.receiverId.toLowerCase()}/in`;
                            this.showInfileTab = true;
                            if(this.showAS2Encoded == false) {
                                this.tabModel.activeTab = 3;
                                blobRequest.path = this.blobPathIn;
                            }
                        }
                    }

                    // TODO Change to use TrackingEventDeliveries
                    this.deliveryRows = this.trackingEventDetails.trackingEventDeliveries;
                    this.hasSentRows = this.deliveryRows.length > 0 ? true : false;
                    this.errorRows = this.trackingEventDetails.trackingEvents.filter(x => +x.status === 999);
                    this.cancelRows = this.trackingEventDetails.trackingEvents.filter(x => +x.status === 998);
                    
                    if(this.errorRows != undefined) {
                        this.errorRowsData = new Array<any>;
                        this.errorRows.forEach(e => {
                            this.errorRowsData.push({ timestamp: e.timestamp, message: e.message })
                        });
                    }

                    this.cancelRows.forEach(e => {
                        this.cancelRowsData.push({ timestamp: e.timestamp, message: e.message, canceledBy: this.trackingEventMain.canceledBy, canceledAt: this.trackingEventMain.canceledAt })
                    });

                    // Do not show error and cancel rows if there are sent rows
                    this.isError = (this.errorRows != undefined && this.errorRows.length > 0 && this.hasSentRows == false) ? true : false;
                    this.isCancel = (this.cancelRows != undefined && this.cancelRows.length > 0) ? true : false;

                    blobRequest.flowId = tv.flowID;
                    this.trackerService.getBlobData(blobRequest).subscribe(
                        {
                            next: (blob) => {
                                this.blobResponse = blob;

                                if(this.showAS2Encoded == false) {
                                    this.payloadIn = blob.blob;
                                } else {
                                    this.payloadAS2Encoded = blob.blob;
                                }
                                if(this.showAS2Encoded == false && this.showInfileTab == false) {
                                    this.payloadError = blob.blob
                                }

                                this.showSpinner = false;
                            },
                            error: (error) => {
                              this.showSpinner = false;
                              this.errorModel = this.errorParserService.extractErrors(error);
                            }
                        }
                    );
                },
                error: (error) => {
                  this.showSpinner = false;
                  this.errorModel = this.errorParserService.extractErrors(error);
                }
              }
          );
    }

    public closeConfigTypeSelectionPopup() {
        //this.viewConfigDisplayStyle = "none";
    }

    public onclickGoToTab(tab: number) {
      this.showSpinner = true;
      this.tabModel.activeTab = tab;
      let selectedPath = this.getSelectedPath(tab);

      let blobRequest = new BlobRequest();

      if(tab == 9)
        blobRequest.flowId = this.trackingEventDetails.trackingEventAS2Details[0].aS2MdnFlowId;
      else
        blobRequest.flowId = this.trackingEventDetails.trackingEvents[0].flowID;
      blobRequest.path = selectedPath;

      this.trackerService.getBlobData(blobRequest).subscribe(
          {
              next: (blob) => {
                  this.blobResponse = blob;
                  switch(tab) {
                      case 1: // AS2 Encoded
                          this.payloadAS2Encoded = blob.blob;
                          break;
                      case 2: // AS2 Decoded
                          this.payloadAS2Decoded = blob.blob;
                          break;
                      case 3: // In
                          this.payloadIn = blob.blob;
                          break;
                      case 4: // Canonical
                          this.payloadCanonical = blob.blob;
                          break;
                      case 5: // Canonical
                          this.payloadEnrichedCanonical = blob.blob;
                          break;
                          //public payloadEnrichedCanonical: string = '';
                      case 6: // Pre-encoded
                          this.payloadPreEncoded = blob.blob;
                          break;                      
                      case 7: // Encoded
                          this.payloadEncoded = blob.blob;
                          break;
                      case 8: // Out
                          this.payloadOut = blob.blob;
                          break;
                      case 9: // Error
                          this.payloadError = blob.blob;
                          break;
                      case 10: // InboundMDN
                          this.payloadAS2InboundMDN = blob.blob;
                          break;
                      case 11: // OutboundMDN
                          this.payloadAS2OutboundMDN = blob.blob;
                          break;
                      case 12: // MdnDecoded
                          this.payloadMdnDecoded = blob.blob;
                          break;
                  }

                  this.showSpinner = false;
              },
              error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
              }
          }
      );
  }

  private getSelectedPath(tab: number) {
      let selectedPath = '';
      switch(tab) {
          case 1: // AS2 Decoded
              selectedPath = this.blobPathAS2Encoded;
              break;
          case 2: // AS2 Decoded
              selectedPath = this.aS2DecodedBlobPath;
              break;
          case 3: // In
              selectedPath = this.blobPathIn;
              break;
          case 4: // Canonical
              selectedPath = this.blobPathCanonical;
              break;
          case 5: // Canonical
              selectedPath = this.blobPathEnrichedCanonical;
              break;
          case 6: // Pre-encoded
              selectedPath = this.blobPathPreEncoded;
              break;
          case 7: // Encoded
              selectedPath = this.blobPathEncoded;
              break;
          case 8: // Out
              selectedPath = this.blobPathOut;
              break;
          case 9: // Error
              selectedPath = this.blobPathError;
              break;
          case 10: // InboundMDN
              selectedPath = this.aS2InboundMDNBlobPath;
              break;
          case 11: // OutboundMDN
              selectedPath = this.aS2OutboundMDNBlobPath;
              break;
          case 12: // MdnDecoded
              selectedPath = this.blobPathMdnDecoded;
              break;
      }
      return selectedPath;
  }
}
