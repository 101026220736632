<h3 id="tableLabel">Correlation view</h3>
<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
    <p style="margin-bottom: 2rem;white-space:pre-wrap;"><b>{{errorMessage}}</b></p>
</div>

<!-- Common data -->
<div class="row mb5">
  <div class="col-sm-1">
      <label for="flowIdLabel"><b>Reference Id:</b></label>
  </div>
  <div class="col-sm-3" >
      <label for="flowIdValue" *ngIf="trackingViews">{{trackingViews[0].referenceId}}</label>
  </div>
</div>
<div class="row mb10">
  <div class="col-sm-1">
      <label for="flowIdLabel"><b>Correlation Id:</b></label>
  </div>
  <div class="col-sm-3" >
      <label for="flowIdValue" *ngIf="trackingViews">{{this.correlationId}}</label>
  </div>
</div>

<!-- Correlation rows -->
<div class="row">
    <table class='table table-striped table-bordered' aria-labelledby="tableLabel">
         <thead>
            <tr>
                <th>
                  <div class="row" style="margin-left: 1px; margin-right: 5px; width: 120px;">Timestamp (utc)</div>
                </th>
                <th>
                  <div>Sender</div>
                </th>
                <th>
                  <div>Sender Id</div>
                </th>
                <th>
                  <div>Receiver</div>
                </th>
                <th>
                  <div>Receiver Id</div>
                </th>
                <th>
                  <div>Message type</div>
                </th>
                <th>
                  <div>Flow Id</div>
                </th>
                <th>
                  <div>Primary Id</div>
                </th>
                <th>
                  <div>Partner Id</div>
                </th>
                <th>
                  <div>Status</div>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngIf="trackingViews">
            <tr *ngFor="let tv of trackingViews">
                <td>{{ tv.timestamp | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                <td>{{ tv.sender }}</td>
                <td>{{ tv.senderId }}</td>
                <td>{{ tv.receiver }}</td>
                <td>{{ tv.receiverId }}</td>
                <td>{{ tv.messageType }}</td>
                <td>{{ tv.flowID }}</td>
                <td>{{ tv.primaryID }}</td>
                <td>{{ tv.partner }}</td>
                <td>
                    <span style="color: green;" *ngIf="tv.status === 'FINISHED'">
                        <i class="fa-solid fa-circle"></i>
                    </span>
                    <span style="color: red;" *ngIf="tv.status === 'FAILED'">
                        <i class="fa-solid fa-circle"></i>
                    </span>
                    <span style="color: gray;" *ngIf="tv.status === 'CANCELLED'">
                      <i class="fa-solid fa-circle"></i>
                  </span>
                </td>
                <td>
                  <button class="btn btn-outline-secondary btn-sm" (click)="openViewConfig(tv)" style="margin-right: 5px;"><i class="fa fa-magnifying-glass"></i></button>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="trackingViews == null">
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
      </tbody>
    </table>
  </div>
