<div>
    <div class="modal-header">
        <h4 class="modal-title">Filter by Timestamp</h4>
    </div>
</div>

<div class="modal-body">
    <div class="form-group row mb5">
        <div class="input-group">
            <label for="start-date" class="col-sm-3 col-form-label control-label">Start date</label>        
            <input class="form-control" placeholder="yyyy-mm-dd" name="start-date" [(ngModel)]="startdatemodel" ngbDatepicker #sd="ngbDatepicker"/>
            <button class="btn btn-outline-secondary" (click)="sd.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
        </div>
    </div>
    
    <div class="form-group row mb5">
        <div class="input-group">
            <label for="start-time" class="col-sm-3 col-form-label control-label">Start time</label>        
            <input input="text" class="form-control" placeholder="hh:mm:ss" name="start-time" [(ngModel)]="startTime" (input)="onTimeInput($event)" />
            
        </div>
    </div>

    <div class="form-group row mb5">
        <div class="input-group">
            <label for="end-date" class="col-sm-3 col-form-label control-label">End date</label>        
            <input class="form-control" placeholder="yyyy-mm-dd" name="end-date" [(ngModel)]="enddatemodel" ngbDatepicker #ed="ngbDatepicker"/>
            <button class="btn btn-outline-secondary" (click)="ed.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
        </div>
    </div>

    <div class="form-group row mb5">
        <div class="input-group">
            <label for="end-time" class="col-sm-3 col-form-label control-label">End time</label>        
            <input input="text" class="form-control" placeholder="hh:mm:ss" name="end-time" [(ngModel)]="endTime" (input)="onTimeInput($event)" />            
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" [disabled]="startdatemodel == undefined || enddatemodel == undefined || startTime == '' || endTime == ''" (click)="applyFilter()">Filter</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Clear filter</button>
</div>