<h3 id="tableLabel">Mapping explorer</h3>

<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div>

<div class="row" style="margin-top: 10px; margin-bottom: 10px;">
  <div class="col-12">
      <div class="form-check">
          <input class="form-check-input" type="radio" name="mappingTypeOptions1" [value]="1" (click)="onclickEdiTypeOptions(1)" [(ngModel)]="mappingType" id="mappingTypeOptions1">
          <label class="form-check-label" for="editTypeOptions1">
          Inbound
          </label>
      </div>
      <div class="form-check">
          <input class="form-check-input" type="radio" name="mappingTypeOptions2" [value]="2" (click)="onclickEdiTypeOptions(2)" [(ngModel)]="mappingType" id="mappingTypeOptions2" checked>
          <label class="form-check-label" for="editTypeOptions2">
          Outbound
          </label>
      </div>
  </div>
</div>

<div class="row" *ngIf="inMaps">
  <div class="col-12">
    <in-mapping-explorer [maps]="inMaps"></in-mapping-explorer>
  </div>
  
</div>
<div class="row" *ngIf="outMaps">
  <div class="col-12">
    <out-mapping-explorer [maps]="outMaps"></out-mapping-explorer>
</div>
</div>


