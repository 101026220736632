<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Parser</span></legend>

    <div class="form-group row" *ngIf="form.name.value">
      <label for="parserDDL" class="col-sm-2 col-form-label control-label">Parser class name:</label>
      <div class="col-sm-6">
        {{ utilService.getClassName(form.name.value) }} <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="Parser is used to extract and return Sender ID, Receiver ID, Primary ID and Reference ID from the incomming message."></i>
      </div>
    </div>

    <div class="form-group row" *ngIf="form.description.value">
        <label for="parserDDL" class="col-sm-2 col-form-label control-label">Description:</label>
        <div class="col-sm-6">
            {{form.description.value}}
        </div>
    </div>

    <div class="form-group row" *ngIf="!form.name.value">
        <div class="col-sm-12 text-red">
            No parser configured for the selected partner and messagetype
        </div>
    </div>

</fieldset>
