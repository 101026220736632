import { Component, OnInit, Inject } from '@angular/core';
import { Partner } from 'src/app/models/partner/partner';
import { PartnerService } from './partner.service';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppPartnerComponent } from './child-components/add-partner.component';
import { EditPartnerComponent } from './child-components/edit-partner.component';
import { GridView } from 'src/app/models/grid-view';
import { PagingService } from 'src/app/common-services/paging.service';
import { Paging } from 'src/app/models/paging/paging';

@Component({
    selector: 'md-partners',
    templateUrl: './md-partners.component.html',
  })
export class MasterDataPartnersComponent  implements OnInit {
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = true;
    public partners: Array<Partner>;
    public filterPartnerId: string = '';
    public filterName: string = '';
    public filterDescription: string = '';
    public gridView: GridView<Partner>;
    public partnerPaging: Paging = new Paging();

    constructor(public partnerService: PartnerService, private authService: MsalService, private modalService: NgbModal, public pService: PagingService) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        this.getData();
    }

    private getData() {
        this.partnerService.getPartners(this.partnerPaging.currentPage, this.partnerPaging.pageCount, this.filterPartnerId, this.filterName, this.filterDescription).subscribe(
        {
          next: (result) => {
            this.gridView = result;
            this.partnerPaging.setLastDisplayIndex();
            this.partnerPaging.totalPages = Math.floor(this.gridView.totalRows/this.partnerPaging.pageCount) + (this.gridView.totalRows % this.partnerPaging.pageCount > 0 ? 1 : 0);
            this.showSpinner = false; 
          },
          error: (error) => { 
            this.setErrorText(error);
            this.showSpinner = false; 
          }
        });
    }

    public onFilter() {
        this.partnerPaging.currentPage = 1;
        this.getData();
    }

    public createNewPartner() {
        // let highestId = Math.max(...this.partners.map(o => o.id))
        const modalRef = this.modalService.open(AppPartnerComponent, { size: 'size_80' });
        modalRef.componentInstance.nextPartnerId = 0; // (highestId + 1);
        
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public editPartner(gridRow: Partner) {
        const modalRef = this.modalService.open(EditPartnerComponent, { size: 'size_80' });
        modalRef.componentInstance.partner = gridRow
        
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    // --- Paging ----------------------------------------------------------------------------------  
    public onclickPrev() {
        if (this.partnerPaging.currentPage > 1) {
            this.partnerPaging.currentPage = this.partnerPaging.currentPage - 1;
            this.partnerPaging.setStartEndIndex();
            this.getData();      
        }
    }

    public onclickNext() {
        if ((this.partnerPaging.currentPage) * this.partnerPaging.pageCount < this.gridView.totalRows) {
            this.partnerPaging.nextButtonDisabled = false;
            this.partnerPaging.currentPage = this.partnerPaging.currentPage + 1;
            this.partnerPaging.setStartEndIndex();
            this.getData();
        } else {
            this.partnerPaging.nextButtonDisabled = true;
        }
    }

    public onclickPage(page: number, index: number) {
        this.partnerPaging.currentPage = this.partnerPaging.currentPage === page ? this.partnerPaging.currentPage : page;
        this.partnerPaging.setStartEndIndex();
        this.getData();
    }

    selectChangeHandler(event: any) {
        this.partnerPaging.currentPage = 1;
        this.partnerPaging.setStartEndIndex();      
        this.getData();
    }
    
    private setErrorText(error: any) {
        this.error = true;
        let msg: string = '';
        let title: string = error?.error?.title;
        msg = (title !== undefined) ? title : error?.error;
        this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
        console.error(error); 
    }

    // TODO: Partner is never removed if used for en onboarding
    // public removePartner(gridRow: PartnerModel) {        
    // }
}
