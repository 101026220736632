export class Paging {
    //entityMapId: number;
    currentPage: number = 1;
    public startIndex: number = 0;
    public endIndex: number = 10;
    public pageCount = 10;
    public totalPages = 0;
    public totalRows: number = 0;
    public firstDisplayIndex: number = 0;
    public lastDisplayIndex: number = 0;
    public nextButtonDisabled: boolean = false;
    
    // private getData: () => void;

    // constructor(getData: () => void) {
    //     this.getData = getData;
    // }

    public setStartEndIndex(): void {
        this.startIndex = ((this.currentPage - 1) * this.pageCount);
        this.endIndex = ((this.currentPage - 1) * this.pageCount) + this.pageCount;
    }

    public setLastDisplayIndex(): void {
        if((this.currentPage) * this.pageCount > this.totalRows) {
            this.lastDisplayIndex = this.totalRows;
        } else {
            this.lastDisplayIndex = (this.currentPage) * this.pageCount;        
        }
    }
}
