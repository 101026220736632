import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleFlowConfigurationsService } from '../simpleflow-configurations.service';
import { SimplifiedFlowConfigurationResponse } from '../models/simplified-flow-configuration-response';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ViewConfigurationModelService } from 'src/app/common-components/view-configuration/view-configuration-modal.service';

@Component({
  selector: 'view-simplified-flow-configuration.comonent',
  templateUrl: './view-simplified-flow-configuration.comonent.html'
})
export class ViewSimplifiedFlowConfigurationModalComponent implements OnInit {
    @Input() displayStyle: string;
    @Input() simpleEntityMapId: number;
    @Output() returnedDisplayStyle = new EventEmitter<any>();
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = true;
    public configuration: SimplifiedFlowConfigurationResponse;
    
    constructor(public sfcs: SimpleFlowConfigurationsService, public activeModal: NgbActiveModal, private errorParserService: ErrorParserService, public viewService: ViewConfigurationModelService) {}

    ngOnInit(): void {
      this.getData();
    }

    private getData() {
      this.showSpinner = true; 
      this.sfcs.getSimpleConfiguration(this.simpleEntityMapId).subscribe(
        {
            next: (savedresult) => {
                this.configuration = savedresult;

                this.showSpinner = false; 
            },
            error: (error) => {
                this.showSpinner = false; 
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });
    }

    // TODO Get data here on init!

    public closePopup() {
        this.displayStyle = "none";
        // As close modal is in child we need to inform parent of the current display style
        this.returnedDisplayStyle.emit(this.displayStyle);
    }
}
