import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { DestinationMqprofile } from 'src/app/models/destination/destination-mq-profile';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class MqProfileService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getAllMqProfiles(): Observable<Array<DestinationMqprofile>> {
        return this._http.get<Array<DestinationMqprofile>>(`${this.baseUrlLocal}confdestinations/mqprofiles`);
    }

    public setupRequiredValidation(destinationMqprofileForm: FormGroup) {
        const id = destinationMqprofileForm.get('id');
        const host = destinationMqprofileForm.get('host');
        const channel = destinationMqprofileForm.get('channel');
        const qm = destinationMqprofileForm.get('qm');
        const queue = destinationMqprofileForm.get('queue');
        const port = destinationMqprofileForm.get('port');

        if(destinationMqprofileForm.value.useExistingDestination === true) {
            id.setValue('');
            id.setValidators([Validators.required]);
            host.setValidators(null);
            channel.setValidators(null);
            qm.setValidators(null);
            queue.setValidators(null);
            port.setValidators(null);            
        } else {
            id.setValue(0);
            id.setValidators(null); // We could have required here as we set the id to 0 that is a value, but as we hard code...naahh
            host.setValidators([Validators.required]);            
            channel.setValidators([Validators.required]);            
            qm.setValidators([Validators.required]);            
            queue.setValidators([Validators.required]);            
            port.setValidators([Validators.required]);            
        }
        id.updateValueAndValidity();
        host.updateValueAndValidity();
        channel.updateValueAndValidity();
        qm.updateValueAndValidity();
        queue.updateValueAndValidity();
        port.updateValueAndValidity();
    }
}
