<form [formGroup]="form">
    <div class="form-group row">
        <label for="addressLine1" class="col-sm-2 col-form-label">Address line 1 <span class="req-color">*</span></label>
        <div class="col-sm-5">
            <input type="text" class="form-control" formControlName="addressLine1" [(ngModel)]="addressLine1" required id="addressLine1" name="addressLine1" placeholder="Address line 1">
            <div *ngIf="formA.addressLine1.invalid && (formA.addressLine1.dirty || formA.addressLine1.touched)" class="alert alert-danger">
                <div *ngIf="formA.addressLine1.errors?.['required']">Address line 1 is required.</div>
                </div>
        </div>
    </div>
</form>








