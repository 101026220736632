<form [formGroup]="simplifiedFlowConfigurationForm" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">Edit simplified flow configuration</h4>
    </div>
    <div class="modal-body">
        <div>
            <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
            <div class="alert alert-danger" role="alert" *ngIf="errorModel">
                <b>Error in edit simplified flow configuration form. Error Message: {{errorModel.errorMessage}}</b>
            </div>
                <div class="form-group row mb5">
                    <label for="inboundPartnerDDL" class="col-sm-3 col-form-label">Inbound partner <span class="req-color">*</span></label>

                    <div class="col-sm-6">
                        <select dropdown formControlName="inboundPartnerId" *ngIf="simplifiedFlowConfigurationData" id="inboundPartnerDDL" name="inboundPartnerDDL" class="form-select form-select-sm control-label">
                            <option value="">--- Select inbound partner ---</option>
                            <option *ngFor="let item of simplifiedFlowConfigurationData.partners" [value]="item.id">
                                {{item.partnerId}} ({{item.name}})
                            </option>
                        </select>
                        <div *ngIf="form.inboundPartnerId.invalid && (form.inboundPartnerId.dirty || form.inboundPartnerId.touched)" class="alert alert-danger">
                            <div *ngIf="form.inboundPartnerId.errors?.['required']">Inbound partner is required.</div>
                        </div>        
                    </div>
                </div>
                
                <div class="form-group row mb5">
                    <label for="inboundMessageTypeDDL" class="col-sm-3 col-form-label">Inbound message type <span class="req-color">*</span></label>
                    <div class="col-sm-6">
                        <select dropdown formControlName="inboundMessageTypeId" *ngIf="simplifiedFlowConfigurationData" id="inboundMessageTypeDDL" name="inboundMessageTypeDDL" class="form-select form-select-sm control-label">
                            <option value="">--- Select inbound message type ---</option>
                            <option *ngFor="let item of simplifiedFlowConfigurationData.inboundMessageTypes" [value]="item.inboundMessageTypeId">
                            {{item.messageName}}
                            </option>
                        </select>
                        <div *ngIf="form.inboundMessageTypeId.invalid && (form.inboundMessageTypeId.dirty || form.inboundMessageTypeId.touched)" class="alert alert-danger">
                            <div *ngIf="form.inboundMessageTypeId.errors?.['required']">Inbound message type is required.</div>
                        </div>        
                    </div>
                </div>

                <div class="form-group row mb5">
                    <label for="outboundPartnerDDL" class="col-sm-3 col-form-label">Outbound partner <span class="req-color">*</span></label>
                    <div class="col-sm-6">
                        <select dropdown formControlName="outboundPartnerId" *ngIf="simplifiedFlowConfigurationData" id="outboundPartnerDDL" name="outboundPartnerDDL" class="form-select form-select-sm control-label">
                            <option value="">--- Select outbound partner ---</option>
                            <option *ngFor="let item of simplifiedFlowConfigurationData.partners" [value]="item.id">
                                {{item.partnerId}} ({{item.name}})
                            </option>
                        </select>
                        <div *ngIf="form.outboundPartnerId.invalid && (form.outboundPartnerId.dirty || form.outboundPartnerId.touched)" class="alert alert-danger">
                            <div *ngIf="form.outboundPartnerId.errors?.['required']">Outbound partner is required.</div>
                        </div>        
                    </div>
                </div>
                
                <div class="form-group row mb5">
                    <label for="outboundMessageTypeDDL" class="col-sm-3 col-form-label">Outbound message type <span class="req-color">*</span></label>
                    <div class="col-sm-6">
                        <select dropdown formControlName="outboundMessageTypeId" *ngIf="simplifiedFlowConfigurationData" id="outboundMessageTypeDDL" name="outboundMessageTypeDDL" class="form-select form-select-sm control-label">
                            <option value="">--- Select outbound message type ---</option>
                            <option *ngFor="let item of simplifiedFlowConfigurationData.outboundMessageTypes" [value]="item.messageTypeId">
                            {{item.messageName}}
                            </option>
                        </select>
                        <div *ngIf="form.outboundMessageTypeId.invalid && (form.outboundMessageTypeId.dirty || form.outboundMessageTypeId.touched)" class="alert alert-danger">
                            <div *ngIf="form.outboundMessageTypeId.errors?.['required']">Outbound message type is required.</div>
                        </div>        
                    </div>
                </div>
        </div>
        <div>
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Destinations</span></legend>
                <div style="margin-bottom: 10px;">
                    <span class="text-muted"><small>A message can be sent to one or more destinations. Destinations protocals can be HTTP endpoint, Service Bus, MQ, SFTP/FTP or File Share.</small></span>
                </div>
        
                <div class="form-group row form-inline mb10">
                    <label for="protocolDDL" class="col-sm-2 col-form-label control-label">Destinations</label>
                    <div class="col-sm-8">
                        <select dropdown formControlName="selectedDestinationValue" id="confDestinationDDL" (change)="selectDestinationChangeHandler($event)" *ngIf="simplifiedFlowConfigurationData" name="confDestinationDDL" class="form-select form-select-sm control-label">
                            <option value="-1">--- Select destination ---</option>
                            <option *ngFor="let item of confDestinations" [value]="item.id">
                                {{item.description}}
                            </option>
                        </select>
                    </div>
                </div>
                <button type="button" class="btn btn-outline-secondary btn-sm" [disabled]="selectedDestinationValue == -1" (click)="addDestination()">Add</button>
        
                <hr />
        
                <div>
                    <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="simplifiedFlowDestinationConnections != undefined && simplifiedFlowDestinationConnections.length > 0">
                        <thead>
                            <tr>                        
                                <th><div>Protocol</div></th>
                                <th><div>Endpoint type</div></th>
                                <th><div>Destination</div></th>                        
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of simplifiedFlowDestinationConnections">
                                <td>{{ utilService.getProtocolName(d.confDestination?.protocol) }}</td>
                                <td>{{ utilService.getTypeText(d.confDestination?.type) }}</td>
                                <td>
                                    <div *ngIf="d.confDestination.destinationHttpprofile !== null">
                                        <row-display [label]="'Name:'" [value]="d.confDestination.destinationHttpprofile.name"></row-display>
                                        <row-display [label]="'Host:'" [value]="d.confDestination.destinationHttpprofile.host"></row-display>
                                        <row-display [label]="'Port:'" [value]="d.confDestination.destinationHttpprofile.port"></row-display>
                                        <row-display [label]="'Path:'" [value]="d.confDestination.destinationHttpprofile.path"></row-display>
                                        <row-display [label]="'Authentication:'" [value]="utilService.getAuthenticationName(d.confDestination.destinationHttpprofile.authentication)"></row-display>
                                        <row-display [label]="'Username:'" [value]="d.confDestination.destinationHttpprofile.userName"></row-display>
                                        <row-display *ngIf="d.confDestination.destinationHttpprofile.keyVaultProfile != undefined" [label]="'Key vault:'" [value]="d.confDestination.destinationHttpprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="d.confDestination.destinationHttpprofile.keyVaultProfile != undefined" [label]="'Secret:'" [value]="d.confDestination.destinationHttpprofile.keyVaultProfile.secretName"></row-display>
                                    </div>
                                    <div *ngIf="d.confDestination.destinationMqprofile !== null">
                                        <row-display [label]="'Name:'" [value]="d.confDestination.destinationMqprofile.name"></row-display>
                                        <row-display [label]="'Host:'" [value]="d.confDestination.destinationMqprofile.host"></row-display>
                                        <row-display [label]="'Port:'" [value]="d.confDestination.destinationMqprofile.port"></row-display>
                                        <row-display [label]="'Channel:'" [value]="d.confDestination.destinationMqprofile.channel"></row-display>
                                        <row-display [label]="'Queue manager:'" [value]="d.confDestination.destinationMqprofile.qm"></row-display>
                                        <row-display [label]="'Queue:'" [value]="d.confDestination.destinationMqprofile.queue"></row-display>
                                        <row-display [label]="'Username:'" [value]="d.confDestination.destinationMqprofile.userName"></row-display>
                                    </div>
                                    <div *ngIf="d.confDestination.destinationSbprofile !== null">
                                        <row-display [label]="'Name:'" [value]="d.confDestination.destinationSbprofile.name"></row-display>
                                        <row-display [label]="'Namespace:'" [value]="d.confDestination.destinationSbprofile.namespace"></row-display>
                                        <row-display [label]="'Queue:'" [value]="d.confDestination.destinationSbprofile.queue"></row-display>
                                        <row-display [label]="'Shared access key:'" [value]="d.confDestination.destinationSbprofile.sharedAccessKeyName"></row-display>
                                        <row-display [label]="'Use session:'" [value]="d.confDestination.destinationSbprofile.useSession === false ? 'NO' : 'YES'"></row-display>
                                        <row-display [label]="'Name:'" [value]="d.confDestination.destinationSbprofile.name"></row-display>
                                        <row-display *ngIf="d.confDestination.destinationSbprofile.keyVaultProfile != undefined" [label]="'Key vault:'" [value]="d.confDestination.destinationSbprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="d.confDestination.destinationSbprofile.keyVaultProfile != undefined" [label]="'Secret:'" [value]="d.confDestination.destinationSbprofile.keyVaultProfile.secretName"></row-display>
                                    </div>
                                    <div *ngIf="d.confDestination.destinationSharedFolderProfile !== null">
                                        <row-display [label]="'Name:'" [value]="d.confDestination.destinationSharedFolderProfile.name"></row-display>
                                        <row-display [label]="'Server:'" [value]="d.confDestination.destinationSharedFolderProfile.server"></row-display>
                                        <row-display [label]="'Path:'" [value]="d.confDestination.destinationSharedFolderProfile.path"></row-display>
                                        <row-display [label]="'Filter:'" [value]="d.confDestination.destinationSharedFolderProfile.filter"></row-display>
                                        <row-display [label]="'Encoding:'" [value]="d.confDestination.destinationSharedFolderProfile.encoding"></row-display>
                                        <row-display [label]="'Filename:'" [value]="d.confDestination.destinationSharedFolderProfile.filename"></row-display>
                                    </div>
                                    <div *ngIf="d.confDestination.destinationSftpprofile !== null">
                                        <row-display [label]="'Name:'" [value]="d.confDestination.destinationSftpprofile.name"></row-display>
                                        <row-display [label]="'Host:'" [value]="d.confDestination.destinationSftpprofile.host"></row-display>
                                        <row-display [label]="'Port:'" [value]="d.confDestination.destinationSftpprofile.port"></row-display>
                                        <row-display [label]="'Path:'" [value]="d.confDestination.destinationSftpprofile.path"></row-display>
                                        <row-display [label]="'Temporary path:'" [value]="d.confDestination.destinationSftpprofile.tempPath"></row-display>
                                        <row-display [label]="'Encoding:'" [value]="d.confDestination.destinationSftpprofile.encoding"></row-display>
                                        <row-display [label]="'Filename:'" [value]="d.confDestination.destinationSftpprofile.filename"></row-display>
                                        <row-display [label]="'Username:'" [value]="d.confDestination.destinationSftpprofile.userName"></row-display>
                                        <row-display *ngIf="d.confDestination.destinationSftpprofile.keyVaultProfile != undefined" [label]="'Key vault:'" [value]="d.confDestination.destinationSftpprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="d.confDestination.destinationSftpprofile.keyVaultProfile != undefined" [label]="'Secret:'" [value]="d.confDestination.destinationSftpprofile.keyVaultProfile.secretName"></row-display>
                                    </div>
                                    <div *ngIf="d.confDestination.destinationFtpprofile !== null">
                                        <row-display [label]="'Name:'" [value]="d.confDestination.destinationFtpprofile.name"></row-display>
                                        <row-display [label]="'Host:'" [value]="d.confDestination.destinationFtpprofile.host"></row-display>
                                        <row-display [label]="'Port:'" [value]="d.confDestination.destinationFtpprofile.port"></row-display>
                                        <row-display [label]="'Path:'" [value]="d.confDestination.destinationFtpprofile.path"></row-display>
                                        <row-display [label]="'Temporary path:'" [value]="d.confDestination.destinationFtpprofile.tempPath"></row-display>
                                        <row-display [label]="'Encoding:'" [value]="d.confDestination.destinationFtpprofile.encoding"></row-display>
                                        <row-display [label]="'Filename:'" [value]="d.confDestination.destinationFtpprofile.filename"></row-display>
                                        <row-display [label]="'Username:'" [value]="d.confDestination.destinationFtpprofile.userName"></row-display>
                                        <row-display *ngIf="d.confDestination.destinationFtpprofile.keyVaultProfile != undefined" [label]="'Key vault:'" [value]="d.confDestination.destinationFtpprofile.keyVaultProfile.keyVaultName"></row-display>
                                        <row-display *ngIf="d.confDestination.destinationFtpprofile.keyVaultProfile != undefined" [label]="'Secret:'" [value]="d.confDestination.destinationFtpprofile.keyVaultProfile.secretName"></row-display>
                                    </div>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-outline-secondary btn-sm" (click)="removeConfDestConnection(d)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                </td>
                            </tr>
                        </tbody>                
                    </table>
                  </div>
            </fieldset>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" [disabled]="!simplifiedFlowConfigurationForm.valid" (click)="save()">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>
</form>

