<form [formGroup]="inboundMessageTypeForm" (submit)="save()" *ngIf="inboundMessageTypeForm">
    <div class="modal-header">
        <h4 class="modal-title">Edit inbound message type</h4>
    </div>
    <div class="modal-body">
        <div>
            <error-display [errorModel]="errorModel"></error-display>
            
            <div class="form-group row mb5">
                <label for="editmessageName" class="col-sm-3 col-form-label">Name <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <input type="text" *ngIf="form" class="form-control" formControlName="messageName" required id="editmessageName" name="editmessageName" placeholder="Message type name">
                    <div *ngIf="form.messageName.invalid && (form.messageName.dirty || form.messageName.touched)" class="alert alert-danger">
                        <div *ngIf="form.messageName.errors?.['required']">Name is required.</div>
                    </div>                    
                </div>
            </div>
    
            <div class="form-group row mb5">
                <label for="editmessageTypeDescription" class="col-sm-3 col-form-label">Description <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <input type="text" *ngIf="form" class="form-control" formControlName="messageTypeDescription" required id="editmessageTypeDescription" name="editmessageTypeDescription" placeholder="Description">
                    <div *ngIf="form.messageTypeDescription.invalid && (form.messageTypeDescription.dirty || form.messageTypeDescription.touched)" class="alert alert-danger">
                        <div *ngIf="form.messageTypeDescription.errors?.['required']">Description is required.</div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="!inboundMessageTypeForm.valid">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>    
</form>
