import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Configuration } from 'src/app/models/configuration';
import { ViewConfigurationModelService } from './view-configuration-modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerConfigurationsService } from 'src/app/customer-configurations/list/customer-configurations.service';

@Component({
  selector: 'view-configuration-modal',
  templateUrl: './view-configuration-modal.component.html'
})
export class ViewConfigurationModalComponent implements OnInit {
    @Input() displayStyle: string;
    @Input() entityMapId: number;
    @Output() returnedDisplayStyle = new EventEmitter<any>();
    public showSpinner: boolean = true;
    public configuration: Configuration;
    public labelClass: string = 'col-sm-2';

    public enricherColumns = [
      { header: 'Enricher', field: 'enricher' },
      { header: 'Description', field: 'description' },
      { header: 'Order', field: 'order' }
    ];
    public enricherData: Array<any> = null;
    
    constructor(
      public viewService: ViewConfigurationModelService, 
      public ccService: CustomerConfigurationsService, 
      public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
      this.getData();
    }

    private getData() {
      this.showSpinner = true; 
      this.ccService.getConfiguration(this.entityMapId).subscribe(
        {
          next: (result) => {
            this.configuration = result;

            // Populate enrichement grid data
            if(this.configuration.enrichments != undefined) {
              this.enricherData = new Array<any>;
              this.configuration.enrichments.forEach(e => {
                this.enricherData.push({ enricher: this.viewService.getClassName(e.enrichmentClass.enricher), description: e.enrichmentClass.description, order: e.order })
              });
            }

            this.showSpinner = false; 
          },
          error: (error) => { 
            this.showSpinner = false;
              // TODO pick up error message and display.
              // this.error = true;
              // let msg: string = '';
              // let title: string = error?.error?.title;
              // msg = (title !== undefined) ? title : error?.error;
              // this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
              console.error(error); 
              
          }
      });
    }

    // TODO Get data here on init!

    public closePopup() {
        this.displayStyle = "none";
        // As close modal is in child we need to inform parent of the current display style
        this.returnedDisplayStyle.emit(this.displayStyle);
    }
}
