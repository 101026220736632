<div class="modal-header">
    <h4 class="modal-title">Delete parser for partner {{partner.partnerId}}</h4>
</div>

<div class="modal-body">
    Are you really sure you want to delete parser {{ utilService.getClassName(parserClassName) }} connected to partner {{partner.partnerId}} and message type {{messageTypeName}}?
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
