import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { MDInboundParsersInputService } from './md-inboundparsers-input.service';
import { InboundParser } from 'src/app/models/parser/parser';
import { Partner } from 'src/app/models/partner/partner';
import { resultMemoize } from '@ngrx/store';
import { UtilService } from 'src/app/common-services/util.service';

@Component({
  selector: 'delete-parser-connection-modal', // TODO: Is selector needed for modal?
  templateUrl: './delete-parser-connection-modal.component.html',
})
export class DeleteParserConnectionModalComponent implements OnInit {
    @Input() inboundParser: InboundParser;
    @Input() partner: Partner;

    public messageTypeId: number = -1;
    public parserClassId: number = -1;
    public messageTypeName: string = 'UNKNOWN';
    public parserClassName: string = 'UNKNOWN';
    
    constructor(public activeModal: NgbActiveModal, public utilService: UtilService, private inboundParsersInputService: MDInboundParsersInputService) {}

    ngOnInit(): void {
      
        this.inboundParsersInputService.getParserBaseData().subscribe(
          {
              next: (result) => {
                let messageType = result.inboundMessageTypes.find(x => x.inboundMessageTypeId === this.inboundParser.messageType);
                this.messageTypeName = messageType != undefined ? messageType.messageName : '';
                let parserClass = result.parserClasses.find(x => x.id === this.inboundParser.parserClassId);
                this.parserClassName = parserClass != undefined ? parserClass.parser : '';
              },
              // TODO Add better error hanlding to present the issue!
              error: (error) => {
                //   this.showSpinner = false;
                  console.error(error);
              }
            }
        );
        
      }

    public delete() {
        this.inboundParsersInputService.deleteParserPartnerConnection(this.inboundParser).subscribe(
              {
                  next: (result) => {
                       this.activeModal.close('delete');
                  },
                  error: (error) => {
                    let error2 = error;
                      //this.displayErrorMessage(error);
                  }
               }
          );
      }
}
