<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div>
    <fieldset class="border p-2">
        <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Source</span></legend>

        <div style="margin-bottom: 10px;">
            <span class="text-muted"><small>For any protocol other then Http, the source is needed to be specified. Source can be Service Bus, MQ or File Share.</small></span>
        </div>

        <!-- Configured scedulers -->
        <div>
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Existing sources</span></legend>
                
                <table class='table table-striped table-bordered' aria-labelledby="tableLabel" >
                    <thead>
                        <tr>
                          <th scope="col" colSpan="8">
                            <div>
                              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="openAddSourceModal()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                    <thead>
                        <tr>                        
                            <th>Name</th>
                            <th>Protocol</th>
                            <th>Enabled</th>
                            <th>Validation type</th>
                            <th>EDI type</th>
                            <th>Simplified flow</th>
                            <th>Details</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="schedulers != undefined && schedulers.length === 0">
                        <p>No sources configured</p>
                    </tbody>
                    <tbody *ngIf="schedulers != undefined && schedulers.length > 0">
                        <tr *ngFor="let s of schedulers">
                            <td>{{s.name}}</td>
                            <td>{{ utilService.getProtocolName(s.source?.protocolType) }}</td>
                            <td>{{ s.enabled === true ? 'YES' : 'NO' }}</td>
                            <td>{{ sourceInputService.getValidationTypeText(s.validationType.toString()) }}</td>
                            <td>{{ sourceInputService.getEDITypeName(s.source.ediType) }} </td>
                            <td>{{ s.isSimplified === true ? 'YES' : 'NO' }}</td>
                            <td>
                                <source-view-table-data [source]="s.source"></source-view-table-data>
                            </td>
                            <td>
                                <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="editSource(s)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="removeScheduler(s)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                    </tbody>                
                </table>
            </fieldset>
            <br />
        </div>

    </fieldset>
</div>