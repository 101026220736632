import { KeyVaultProfile } from "../key-vault-profile";

export class SourceFtpProfile {
    id: number;
    name: string;
    host: string;
    port: string;
    path: string;
    archivePath: string;
    authenticationTypeId: number;
    userName: string;
    keyVaultProfileId: number;
    passPhrase: string;
    encoding: string;
    useRegEx: boolean;
    filterOrRegEx: string;
    delete: boolean;
    createdBy: string;
    createTimestamp: string;
    keyVaultProfile: KeyVaultProfile;
}