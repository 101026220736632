import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ServiceModeService } from './service-mode.service';
import { ServiceModeResponseModel } from 'src/app/master-data/service-mode/models/service-mode-response-model';
import { Observable, Subscription, timer } from 'rxjs';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ServiceModeRequestModel } from './models/service-mode-request-model';

@Component({
    selector: 'service-mode.component',
    templateUrl: './service-mode.component.html',
  })
export class ServiceModeComponent implements OnInit, OnDestroy {
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = true;
    public isSuperUser: boolean = false;
    public serviceModeResponseModel: ServiceModeResponseModel;
    public comment: string = '';
    timerSubscription: Subscription;
    everyFiveSeconds: Observable<number> = timer(0, 5000);

    public serviceModeColumns = [
        { header: 'Id', field: 'id' },
        { header: 'Enabled by', field: 'enabledBy' },
        { header: 'Enabled at', field: 'enabledTimestamp' },
        { header: 'Finished by', field: 'finishedBy' },
        { header: 'Finished at', field: 'finishedTimestamp' },
        { header: 'Comment', field: 'comment' },
    ];

    public serviceModeData: Array<any> = null;

    constructor(private authService: MsalService, private serviceModeService: ServiceModeService, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        this.showSpinner = true;

        // Refresh the data every 5 seconds to change the status when the app service has restarted
        this.timerSubscription = this.everyFiveSeconds.subscribe(() => {
            this.getData();
        });

        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isSuperUser = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.SuperUser') == undefined ? false : true;
        }

        this.getData();
    }

    ngOnDestroy() {
        // Automatically unsubscribe when the component is destroyed
        this.timerSubscription.unsubscribe();
    }

    private getData() {
        this.serviceModeService.getServiceMode().subscribe(
        {
            next: (result) => {
                this.serviceModeResponseModel = result;

                this.serviceModeData = new Array<any>;
                this.serviceModeResponseModel.serviceModeList.forEach(x => {
                    
                    this.serviceModeData.push({ id: x.id, enabledBy: x.enabledBy, enabledTimestamp: x.enabledTimestamp, finishedBy: x.finishedBy, finishedTimestamp: x.finishedTimestamp, comment: x.comment });
                })

                this.showSpinner = false; 
            },
            error: (error) => {
                this.showSpinner = false;       
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });
    }

    public setServiceMode(status: boolean) {
        this.showSpinner = true;
        let serviceModeRequestModel = new ServiceModeRequestModel();
        serviceModeRequestModel.status = status;
        serviceModeRequestModel.comment = this.comment;

        this.serviceModeService.setServiceMode(serviceModeRequestModel).subscribe(
        {
            next: (result) => {
                this.showSpinner = false; 
                this.getData();
            },
            error: (error) => {
                this.showSpinner = false;       
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });        
    }

    public startGenericInbound() {
        this.showSpinner = true;
        let serviceModeRequestModel = new ServiceModeRequestModel();
        serviceModeRequestModel.status = this.serviceModeResponseModel.status;
        serviceModeRequestModel.comment = 'dummy text as comment is required';

        this.serviceModeService.startGenericInbound(serviceModeRequestModel).subscribe(
        {
            next: (result) => {
                this.showSpinner = false; 
                this.getData();
            },
            error: (error) => {
                this.showSpinner = false;       
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });        
    }
}
