<h3 id="tableLabel">Simplified flow configurations</h3>

<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="errorModel">
    <b>Error when listing simplified flow configurations. Error Message: {{errorModel.errorMessage}}</b>
</div>

<div>
    <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="pagedSimplifiedFlowConfigurations">
        <thead>
          <tr>
            <th scope="col" colSpan="9">
              <div>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="isReader" (click)="createNewConfig()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
              </div>
            </th>
          </tr>
        </thead>
        <thead>
            <tr>
                <th>
                  <div>Inbound partner</div>
                  <div>
                    <input type="text" class="form-control" id="inboundPartnerName" name="inboundPartnerName" placeholder="Filter inbound partner" [(ngModel)]="filterInboundPartner" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Inbound message type</div>
                  <div>
                    <input type="text" class="form-control" id="inboundMessageTypeName" name="inboundMessageTypeName" placeholder="Filter inbound message type" [(ngModel)]="filterInboundMessageTypeName" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                    <div>Outbound partner</div>
                  <div>
                    <input type="text" class="form-control" id="outboundPartnerName" name="outboundPartnerName" placeholder="Filter outbound partner" [(ngModel)]="filterOutboundPartner" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                    <div>Outbound message type</div>
                  <div>
                    <input type="text" class="form-control" id="outboundMessageTypeName" name="outboundMessageTypeName" placeholder="Filter outbound message type" [(ngModel)]="filterOutboundMessageTypeName" (keyup)="onFilter()">
                  </div>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cc of pagedSimplifiedFlowConfigurations">
                <td>{{ cc.inboundPartnerName }}</td>
                <td>{{ cc.inboundMessageTypeName }}</td>
                <td>{{ cc.outboundPartnerName }}</td>
                <td>{{ cc.outboundMessageTypeName }}</td>
                <td class="fixed120">
                  <button class="btn btn-outline-secondary btn-sm" (click)="viewConfig(cc)" style="margin-right: 5px;"><i class="fa fa-magnifying-glass"></i></button>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="isReader" (click)="editConfig(cc)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="isReader" (click)="removeConfig(cc)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                </td>
            </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="9">
              <table>
                <tr>
                  <td style="margin: auto;">
                    <nav>
                      <ul class="pagination">
                        <li class="page-item" ng-class="{disabled: currentPage == 1}">
                          <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 1, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 1, totalPages), 1)">{{pService.getPageNumber(currentPage, 1, totalPages)}}</a></li>
                        <li class="page-item" *ngIf="totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 2, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 2, totalPages), 2)">{{pService.getPageNumber(currentPage, 2, totalPages)}}</a></li>
                        <li class="page-item" *ngIf="totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 3, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 3, totalPages), 3)">{{pService.getPageNumber(currentPage, 3, totalPages)}}</a></li>
                        <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                          <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                        </li>
                      </ul>
                    </nav>
                  </td>
                  <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                    <label style="margin-right: 5px;">{{(((currentPage) - 1) * (+pageCount)) + 1}} - {{lastDisplayIndex}} of {{configurationCount}}</label>
                  </td>
                  <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                    <select dropdown (change)="selectPagingChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="pageCount">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </td>               
                </tr>                  
              </table>
            </td>
          </tr>
        </tfoot>
    </table>
</div>