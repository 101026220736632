
export class Partner {
    id: number;
    partnerId: string;
    name: string;
    description: string;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
}