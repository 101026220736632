import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { OutboundMap } from 'src/app/models/mapping/outbound-map';
import { OutboundMapXslt } from 'src/app/models/mapping/outbound-map-xslt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MDOutboundMappingConnectionInputService {
  public baseUrlLocal: string = "";
  constructor(private http: HttpClient) {
    this.baseUrlLocal = environment.apiBaseUrl;
  }


  public getAllCanonicalToOutboundDocuments(): Observable<Array<OutboundMapXslt>> {
    return this.http.get<Array<OutboundMapXslt>>(this.baseUrlLocal + 'outboundmappingsxslt');
  }

  public getPartnerCanonicalToOutboundMappingConnections(outboundpartnerId: number): Observable<Array<OutboundMap>> {
    return this.http.get<Array<OutboundMap>>(`${this.baseUrlLocal}outboundmappings/${outboundpartnerId}`);
  }

  public getPartnerCanonicalToOutboundMappingDocuments(outboundpartnerId: number): Observable<Array<OutboundMapXslt>> {
    return this.http.get<Array<OutboundMapXslt>>(`${this.baseUrlLocal}outboundmappings/documents/${outboundpartnerId}`);
  }

  public addPartnerCanonicalToOutboundMappingConnection(outboundMap: OutboundMap) {
    return this.http.post(this.baseUrlLocal + 'outboundmappings', outboundMap);
  }

  public updatePartnerCanonicalToOutboundMappingConnection(outboundMap: OutboundMap) {
    return this.http.put(this.baseUrlLocal + 'outboundmappings', outboundMap);
  }

  public deletePartnerCanonicalToOutboundMappingConnection(outboundMap: OutboundMap) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: outboundMap
    };
    return this.http.delete(this.baseUrlLocal + 'outboundmappings', options);
  }
}
