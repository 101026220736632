<div class="modal-header">
    <h4 class="modal-title">Edit Inbound => Canonical Mapping-connection</h4>
</div>

<div class="modal-body">
  <div class="form-group row mb5" *ngIf="messageTypesDDL != undefined && messageTypesDDL.length > 0">
      <label for="messageTypeDDL" class="col-sm-4 col-form-label control-label">Message type <span class="req-color">*</span></label>
      <div class="col-sm-6">
          <select dropdown (change)="selectChangeHandler($event)" id="messageTypeDDL" name="messageTypeDDL" class="form-select form-select-sm control-label" [(ngModel)]="messageTypeId" [disabled]='false'>
          <option value="-1">--- Select message type ---</option>
          <option *ngFor="let item of messageTypesDDL" [value]="item.value">
              {{item.text}}
          </option>
          </select>
      </div>
  </div>
  <div class="form-group row">
    <label for="taskId" class="col-sm-4 col-form-label">TaskId (optional)</label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="taskId" name="taskId" [(ngModel)]="taskId" [disabled]='false'>
    </div>
  </div>
  <div class="form-group row" *ngIf="inboundToCanonicalDocumentsDDL != undefined && inboundToCanonicalDocumentsDDL.length > 0">
      <label for="inboundToCanonicalDocumentsDDL" class="col-sm-4 col-form-label control-label">InboundToCanonical Document<span class="req-color">*</span></label>
      <div class="col-sm-6">
          <select dropdown (change)="selectChangeHandler($event)" id="inboundToCanonicalDocumentsDDL" name="inboundToCanonicalDocumentsDDL" class="form-select form-select-sm control-label" [(ngModel)]="inboundToCanonicalDocumentsId">
          <option value="-1">--- Select Inbound => Canonical Document ---</option>
          <option *ngFor="let item of inboundToCanonicalDocumentsDDL" [value]="item.value">
              {{item.text}}
          </option>
          </select>
      </div>
  </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" [disabled]="messageTypeId === -1 || inboundToCanonicalDocumentsId === -1" (click)="save()">Save</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
