<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<h3>GOH Admin Tool</h3>
<p>GOH Admin Tool is the tool for setting up integrations between a sending and a receiving system.</p>

<div class="row">
  <div class="col-sm-6" style="min-width: 620px;">
    <fieldset class="border p-2" *ngIf="orderLinesPerDay">
      <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Orderlines/Day</span></legend>
      <div class="d-flex align-items-center">
        <div class="d-inline-block mr5">
            Display last
        </div>
        <div class="d-inline-block mr5">
          <select dropdown (change)="selectOrderLinesPerDaysChangeHandler()" id="orderLinesPerDayDDL" name="orderLinesPerDayDDL" class="form-select form-select-sm control-label" [(ngModel)]="orderLinesPerDayCount">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="30">30</option>
            <option [value]="90">90</option>
          </select>
        </div>
        <div class="d-inline-block">
          days
        </div>
      </div>
      <ngx-charts-bar-vertical *ngIf="orderLinesPerDay"
        [view]="view"
        [scheme]="colorScheme"
        [results]="orderLinesPerDay"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxisLabel]="'Days'"
        [yAxisLabel]="'Orderlines'"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </fieldset>

    
  </div>
  <div class="col-sm-6" style="min-width: 620px;">
    <fieldset class="border p-2" *ngIf="ordersPerSender">
      <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Orders/Sender</span></legend>

      <div class="d-flex align-items-center">
        <div class="d-inline-block mr5">
            Display last
        </div>
        <div class="d-inline-block mr5">
          <select dropdown (change)="selectOrderSenderDaysChangeHandler()" id="orderSenderDaysDDL" name="orderSenderDaysDDL" class="form-select form-select-sm control-label" [(ngModel)]="orderSenderDaysCount">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="30">30</option>
            <option [value]="90">90</option>
          </select>
        </div>
        <div class="d-inline-block">
          days
        </div>
      </div>
      <ngx-charts-bar-vertical *ngIf="ordersPerSender"
        [view]="view"
        [scheme]="colorScheme"
        [results]="ordersPerSender"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxisLabel]="'Sender'"
        [yAxisLabel]="'Order count'"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </fieldset>   
  </div>
</div>
<div class="row">
  <div class="col-sm-6" style="min-width: 620px;">
    <fieldset class="border p-2" *ngIf="messagesPerDay">
      <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Messages/Day</span></legend>

      <div class="d-flex align-items-center">
        <div class="d-inline-block mr5">
            Display last
        </div>
        <div class="d-inline-block mr5">
          <select dropdown (change)="selectMessagesPerDayChangeHandler()" id="orderSenderDaysDDL" name="orderSenderDaysDDL" class="form-select form-select-sm control-label" [(ngModel)]="messagesPerDaysCount">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="30">30</option>
            <option [value]="90">90</option>
          </select>
        </div>
        <div class="d-inline-block">
          days
        </div>
      </div>
      <ngx-charts-bar-vertical *ngIf="messagesPerDay"
        [view]="view"
        [scheme]="colorScheme"
        [results]="messagesPerDay"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxisLabel]="'Days'"
        [yAxisLabel]="'Message count'"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </fieldset>
  </div>
  <div class="col-sm-6" style="min-width: 620px;">
    <fieldset class="border p-2" *ngIf="messagesPerSender">
      <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Messages/Sender</span></legend>

      <div class="d-flex align-items-center">
        <div class="d-inline-block mr5">
            Display last
        </div>
        <div class="d-inline-block mr5">
          <select dropdown (change)="selectMessageSenderDaysChangeHandler()" id="messageSenderDaysDDL" name="messageSenderDaysDDL" class="form-select form-select-sm control-label" [(ngModel)]="messageSenderDaysCount">
            <option [value]="5">5</option>
            <option [value]="10">10</option>
            <option [value]="30">30</option>
            <option [value]="90">90</option>
          </select>
        </div>
        <div class="d-inline-block">
          days
        </div>
      </div>
      <ngx-charts-bar-vertical *ngIf="messagesPerSender"
        [view]="view"
        [scheme]="colorScheme"
        [results]="messagesPerSender"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxisLabel]="'Sender'"
        [yAxisLabel]="'Message count'"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </fieldset>   
  </div>
</div>

<!-- <div>
<pre style="margin-top:10px; border: 1px solid red; width: fit-content">
  {{ messagesPerDay | json }}
</pre> 
</div> -->

    