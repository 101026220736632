import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { Agreements } from '../models/integration-account/agreements';
import { Certificates } from 'src/app/models/integration-account/certificate/certificates';
import { AgreementRequest } from '../models/integration-account/agreement-request';
import { SchemaReference } from '../models/integration-account/schema/schema-reference';
import { As2Agreement } from '../models/integration-account/agreement/as2-agreement';
import { X12Agreement } from '../models/integration-account/agreement/x12-agreement';
import { EdifactAgreement } from '../models/integration-account/agreement/edifact-agreement';
import { PartnerList } from '../models/integration-account/partner/partner-list';
import { EdiPartner } from '../models/integration-account/partner/edi-partner';
import { PartnerRequest } from '../models/integration-account/partner/partner-request';
import { DropDownListModel } from '../models/dropdown-list-model';
import { XsltMap } from '../models/integration-account/maps/xslt-map';

@Injectable({
  providedIn: 'root',
})
export class EdiConfigurationsService {
  public _http: HttpClient;
  public baseUrlLocal: string = "";

  constructor(private http: HttpClient) {
    this._http = http;
  }

  public getPartners(): Observable<PartnerList> {
    // At the moment server-side paging is not used
    return this._http.get<PartnerList>(environment.apiBaseUrl + 'integrationaccount/partners');
  }

  public getPartner(name: string): Observable<EdiPartner> {
    // At the moment server-side paging is not used
    return this._http.get<EdiPartner>(environment.apiBaseUrl + 'integrationaccount/partner?partnerName='+ name);
  }

  public upsertPartner(partnerRequest: PartnerRequest) {
   return this.http.post(environment.apiBaseUrl + 'integrationaccount/partner', partnerRequest);
  }

  public deletePartner(partnerName: string): Observable<string> {
    return this.http.delete<string>(environment.apiBaseUrl + 'integrationaccount/partner?partnerName=' + partnerName);
  }

  public getAgreements(): Observable<Agreements> {
    // At the moment server-side paging is not used
    return this._http.get<Agreements>(environment.apiBaseUrl + 'integrationaccount/agreements');
  }

  public getAs2Agreement(agreementName: string): Observable<As2Agreement> {
    return this.http.get<As2Agreement>(environment.apiBaseUrl + 'integrationaccount/agreements/as2?agreementName=' + agreementName);
  }

  public getX12Agreement(agreementName: string): Observable<X12Agreement> {
    return this.http.get<X12Agreement>(environment.apiBaseUrl + 'integrationaccount/agreements/x12?agreementName=' + agreementName);
  }

  public getEdifactAgreement(agreementName: string): Observable<EdifactAgreement> {
    return this.http.get<EdifactAgreement>(environment.apiBaseUrl + 'integrationaccount/agreements/edifact?agreementName=' + agreementName);
  }

  public deleteAgreement(agreementName: string): Observable<string> {
    return this.http.delete<string>(environment.apiBaseUrl + 'integrationaccount/agreements?agreementName=' + agreementName);
  }

  // TODO add Observable for the returned data
  public upsertAgreement(agreementRequest: AgreementRequest, agreementType: number) {
    let endpoint = '';
    switch(agreementType) {
      case 1: {
        endpoint = 'integrationaccount/agreements/as2';
         break;
      }
      case 2: {
        endpoint = 'integrationaccount/agreements/x12';
        break;
      }
      case 3: {
        endpoint = 'integrationaccount/agreements/edifact';
        break;
     }
      default: {
         //statements;
         break;
      }
   }

   return this.http.post(environment.apiBaseUrl + endpoint, agreementRequest);
  }

  public getCertificates(): Observable<Certificates> {
    return this._http.get<Certificates>(environment.apiBaseUrl + 'integrationaccount/certificates');
  }

  public getSchemas(): Observable<Array<SchemaReference>> {
    return this._http.get<Array<SchemaReference>>(environment.apiBaseUrl + 'integrationaccount/schemas');
  }

  public getMaps(): Observable<Array<XsltMap>> {
    return this._http.get<Array<XsltMap>>(environment.apiBaseUrl + 'integrationaccount/maps');
  }

  public getPartnerRequest(ediPartner: EdiPartner) {
    let partnerRequest = new PartnerRequest();

    partnerRequest.name = ediPartner.name;
    partnerRequest.businessIdentities = ediPartner.properties.content.b2b.businessIdentities;

    return partnerRequest;
  }

  public getAs2AgreementRequest(as2Agreement: As2Agreement) {
    let agreementRequest = new AgreementRequest();

    agreementRequest.name = as2Agreement.name;
    agreementRequest.guestPartner = as2Agreement.properties.guestPartner;
    agreementRequest.hostPartner = as2Agreement.properties.hostPartner;
    agreementRequest.guestIdentity = as2Agreement.properties.guestIdentity.value;
    agreementRequest.hostIdentity = as2Agreement.properties.hostIdentity.value;
    agreementRequest.qualifier = as2Agreement.properties.guestIdentity.qualifier;

    agreementRequest.receiverSigningCertificateName = as2Agreement.properties.content.aS2.receiveAgreement.protocolSettings.securitySettings.signingCertificateName;
    agreementRequest.receiverEncryptionCertificateName = as2Agreement.properties.content.aS2.receiveAgreement.protocolSettings.securitySettings.encryptionCertificateName;
    agreementRequest.senderSigningCertificateName = as2Agreement.properties.content.aS2.sendAgreement.protocolSettings.securitySettings.signingCertificateName;
    agreementRequest.senderEncryptionCertificateName = as2Agreement.properties.content.aS2.sendAgreement.protocolSettings.securitySettings.encryptionCertificateName;

    agreementRequest.receiverSendAsynchronousMDN = as2Agreement.properties.content.aS2.receiveAgreement.protocolSettings.mdnSettings.sendMDNAsynchronously;
    agreementRequest.receiverReceiptDeliveryUrl = as2Agreement.properties.content.aS2.receiveAgreement.protocolSettings.mdnSettings.receiptDeliveryUrl;
    agreementRequest.senderReceiptDeliveryUrl = as2Agreement.properties.content.aS2.sendAgreement.protocolSettings.mdnSettings.receiptDeliveryUrl;

    agreementRequest.receiverMdnMicHashingAlgorithm = as2Agreement.properties.content.aS2.receiveAgreement.protocolSettings.mdnSettings.micHashingAlgorithm;
    agreementRequest.reveiverEncryptionAlgorithm = as2Agreement.properties.content.aS2.receiveAgreement.protocolSettings.validationSettings.encryptionAlgorithm;
    agreementRequest.senderMdnMicHashingAlgorithm = as2Agreement.properties.content.aS2.sendAgreement.protocolSettings.mdnSettings.micHashingAlgorithm;
    agreementRequest.senderEncryptionAlgorithm = as2Agreement.properties.content.aS2.sendAgreement.protocolSettings.validationSettings.encryptionAlgorithm;
    agreementRequest.senderSigningAlgorithm = as2Agreement.properties.content.aS2.sendAgreement.protocolSettings.validationSettings.signingAlgorithm;

    agreementRequest.agreementType = 1;

    return agreementRequest;
}

public getX12AgreementRequest(x12Agreement: X12Agreement) {
    let agreementRequest = new AgreementRequest();

    agreementRequest.name = x12Agreement.name;
    agreementRequest.guestPartner = x12Agreement.properties.guestPartner;
    agreementRequest.hostPartner = x12Agreement.properties.hostPartner;
    agreementRequest.guestIdentity = x12Agreement.properties.guestIdentity.value;
    agreementRequest.hostIdentity = x12Agreement.properties.hostIdentity.value;
    agreementRequest.qualifier = x12Agreement.properties.guestIdentity.qualifier;
    agreementRequest.receiverSchemaReferences = new Array<SchemaReference>();
    agreementRequest.senderSchemaReferences = new Array<SchemaReference>();

    // this is the same for envelopeSettings.controlVersionNumber and acknowledgementSettings.functionalAcknowledgementVersion
    agreementRequest.schemaVersion = x12Agreement.properties.content.x12.receiveAgreement.protocolSettings.envelopeSettings.controlVersionNumber;

    x12Agreement.properties.content.x12.receiveAgreement.protocolSettings.schemaReferences.forEach(x => {
        let schemaReferences = new SchemaReference();
        schemaReferences.messageId = x.messageId;
        schemaReferences.schemaVersion = x.schemaVersion;
        schemaReferences.schemaName = x.schemaName;
        agreementRequest.receiverSchemaReferences.push(schemaReferences);
    });

    x12Agreement.properties.content.x12.sendAgreement.protocolSettings.schemaReferences.forEach(x => {
        let schemaReferences = new SchemaReference();
        schemaReferences.messageId = x.messageId;
        schemaReferences.schemaVersion = x.schemaVersion;
        schemaReferences.schemaName = x.schemaName;
        agreementRequest.senderSchemaReferences.push(schemaReferences);
    });

    agreementRequest.agreementType = 2;

    return agreementRequest;
}

public getEdifactAgreementRequest(edifactAgreement: EdifactAgreement) {
    let agreementRequest = new AgreementRequest();

    agreementRequest.name = edifactAgreement.name;
    agreementRequest.guestPartner = edifactAgreement.properties.guestPartner;
    agreementRequest.hostPartner = edifactAgreement.properties.hostPartner;
    agreementRequest.guestIdentity = edifactAgreement.properties.guestIdentity.value;
    agreementRequest.hostIdentity = edifactAgreement.properties.hostIdentity.value;
    agreementRequest.qualifier = edifactAgreement.properties.guestIdentity.qualifier;

    agreementRequest.receiverSchemaReferences = new Array<SchemaReference>();
    agreementRequest.senderSchemaReferences = new Array<SchemaReference>();

    edifactAgreement.properties.content.edifact.receiveAgreement.protocolSettings.schemaReferences.forEach(x => {
        let schemaReferences = new SchemaReference();
        schemaReferences.messageId = x.messageId;
        schemaReferences.schemaName = x.schemaName;
        schemaReferences.messageVersion = x.messageVersion;
        schemaReferences.messageRelease = x.messageRelease;
        schemaReferences.associationAssignedCode = x.associationAssignedCode;
        agreementRequest.receiverSchemaReferences.push(schemaReferences);
    });

    edifactAgreement.properties.content.edifact.sendAgreement.protocolSettings.schemaReferences.forEach(x => {
        let schemaReferences = new SchemaReference();
        schemaReferences.messageId = x.messageId;
        schemaReferences.schemaName = x.schemaName;
        schemaReferences.messageVersion = x.messageVersion;
        schemaReferences.messageRelease = x.messageRelease;
        schemaReferences.associationAssignedCode = x.associationAssignedCode;
        agreementRequest.senderSchemaReferences.push(schemaReferences);
    });

    agreementRequest.agreementType = 3;

    return agreementRequest;
  }

  public getEmptyAgreement() {
    let agreementRequest = new AgreementRequest();
    agreementRequest.agreementType = 1;

    agreementRequest.name = '';
    agreementRequest.guestPartner = '';
    agreementRequest.hostPartner = 'Alfalaval'; // Host Partner should always be Alfalaval!
    agreementRequest.guestIdentity = '';
    agreementRequest.hostIdentity = '';
    agreementRequest.qualifier = '';

    agreementRequest.receiverSigningCertificateName = '';
    agreementRequest.receiverEncryptionCertificateName = '';
    agreementRequest.senderSigningCertificateName = '';
    agreementRequest.senderEncryptionCertificateName = '';

    agreementRequest.receiverSendAsynchronousMDN = true;
    agreementRequest.receiverReceiptDeliveryUrl = '';
    agreementRequest.senderReceiptDeliveryUrl = '';

    agreementRequest.receiverMdnMicHashingAlgorithm = '';
    agreementRequest.reveiverEncryptionAlgorithm = '';
    agreementRequest.senderMdnMicHashingAlgorithm = '';
    agreementRequest.senderEncryptionAlgorithm = '';
    agreementRequest.senderSigningAlgorithm = '';

    return agreementRequest;
  }

  public getPartnerListDDL(partnerList: PartnerList) {
    let partnerListDDL: Array<DropDownListModel> = new Array<DropDownListModel>()
    partnerList.value.forEach(x => {
        let ddl: DropDownListModel = new DropDownListModel();
        ddl.value = x.name;
        ddl.text = x.name;
        partnerListDDL.push(ddl);
    });

    return partnerListDDL;
}
}


