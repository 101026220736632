<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div>
    <fieldset class="border p-2">
        <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Parsers</span></legend>

        <div style="margin-bottom: 10px;">
            <span class="text-muted"><small>Parsers are used, depending of messagetype, to extract vital data from incomming file, like SenderId, ReceiverId, PrimaryId etc</small></span>
        </div>

          <hr />

        <!-- Partner configured parsers -->
        <div><fieldset class="border p-2 mb10 mt10" *ngIf="parsersGridList">
  <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Connected Parsers</span></legend>
  <!-- Hidden for now, dont delete -->
  <!--div class="form-group row ">
    <label for="messageTypesDDLTest" class="col-sm-3 col-form-label control-label">MessageType <span class="req-color">*</span></label>
    <div class="col-sm-6">
      <select dropdown id="messageTypesDDLTest" name="messageTypesDDLTest" class="form-select form-select-sm control-label" [(ngModel)]="selectedMessageType">
        <option value="">--- Select MessageType ---</option>
        <option *ngFor="let item of messageTypesddl" [ngValue]="item.value">
          {{item.text}}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group row ">
    <label for="parserClassesDDL" class="col-sm-3 col-form-label control-label">ParserClass <span class="req-color">*</span></label>
    <div class="col-sm-6">
      <select dropdown id="parserClassesDDL" name="parserClassesDDL" class="form-select form-select-sm control-label" [(ngModel)]="selectedParserClass">
        <option value="">--- Select ParserClass ---</option>
        <option *ngFor="let item of parserClassesddl" [ngValue]="item.value">
          {{item.text}}
        </option>
      </select>
      <td><button class="btn btn-outline-secondary btn-sm" (click)="addParserConnection()">Add</button></td>
    </div>
  </div-->
  <div>
  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="parsersGridList">
    <thead>
      <tr>
        <th scope="col" colSpan="8">
          <div>
            <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="addParserConnection()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
        </th>
      </tr>
    </thead>
    <thead>
        <tr>
            <!--th>
              <div>ConnectionId</div>
            </th-->
            <th>
              <div>MessageType</div>
            </th>
            <th>
              <div>Parser</div>
            </th>
            <th>
              <div>Created by</div>
            </th>
            <th>
              <div>Created at</div>
            </th>
            <th>
              <div>Updated by</div>
            </th>
            <th>
              <div>Updated at</div>
            </th>
            <th>
              <div></div>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="parsersGridList">
        <tr *ngFor="let p of parsersGridList;let index = index;">
            <!--td>
              <span *ngIf="!p.editMode">{{p.id}}</span>
              <input name="parserId{{index}}" *ngIf="p.editMode && p.isNew" class="form-control" type="text" [(ngModel)]="p.id">
            </td-->
            <td>
              <span *ngIf="!p.editMode">{{p.messageTypeName}}</span>
              <input name="parserId{{index}}" *ngIf="p.editMode" class="form-control" type="text" [(ngModel)]="p.messageTypeName">
            </td>
            <td>
              <span *ngIf="!p.editMode">{{p.parser}}</span>
              <input name="parserId{{index}}" *ngIf="p.editMode" class="form-control" type="text" [(ngModel)]="p.parser">
            </td>
            <td>
              <span>{{p.createdBy}}</span>
            </td>
            <td>
              <span>{{p.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
            </td>
            <td>
              <span>{{p.updatedBy}}</span>
            </td>
            <td>
              <span>{{p.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
            </td>
            <td>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="editParser(p)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="removeParserPartnerConnection(p)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
            </td>
        </tr>
    </tbody>
    <tfoot>
    </tfoot>
</table>
</div>
</fieldset>
<br />
</div>
</fieldset>
</div>
