import { CanonicalMap } from './mapping/canonical-map';
import { ConfDestination } from './destination/conf-destination';
import { EnrichmentClass } from './enrichment-class';
import { KeyVaultProfile } from './key-vault-profile';
import { InboundMessageType, OutboundMessageType } from './message-type';
import { OutboundMap } from './mapping/outbound-map';
import { InboundParser } from './parser/parser';
import { Partner } from './partner/partner';
import { PostFormattingClass } from './post-formatting-class';
import { PreFormattingClass } from './pre-formatting-class';
import { Resolver } from './resolver/resolver';
import { ResponseResolverClass } from './response-resolver/response-resolver-class'
import { Scheduler } from './source/scheduler';
import { CanonicalMapXslt } from './mapping/canonical-map-xslt';
import { OutboundMapXslt } from './mapping/outbound-map-xslt';
import { AS2ConfIn } from './edi/as2-conf-In';
import { ResolverClass } from './resolver/resolver-class';
import { ParserClass } from './parser/parser-class';
import { PreStep } from './pre-step';
import { PreStepClass } from './pre-step-class';

export class WizardResponseModel {
  inboundMessageTypes: Array<InboundMessageType>;
  outboundMessageTypes: Array<OutboundMessageType>;
  partners: Array<Partner>;
  resolvers: Array<Resolver>;
  resolverClasses: Array<ResolverClass>;
  inboundParsers: Array<InboundParser>;
  parserClasses: Array<ParserClass>
  canonicalMaps: Array<CanonicalMap>;
  canonicalMapXslts: Array<CanonicalMapXslt>;
  outboundMaps: Array<OutboundMap>;
  outboundMapXslts: Array<OutboundMapXslt>
  confDestinations: Array<ConfDestination>;
  keyVaultProfiles: Array<KeyVaultProfile>;
  dropDownListSources: Array<DropDownListSource>;
  schedulers: Array<Scheduler>;
  enrichmentClasses: Array<EnrichmentClass>;
  postFormattingClasses: Array<PostFormattingClass>;
  preFormattingClasses: Array<PreFormattingClass>;
  responseResolverClasses: Array<ResponseResolverClass>;
  aS2ConfIn: Array<AS2ConfIn>;
  basicAuthUsername: string;
  preStepClasses: Array<PreStepClass>;
}

export class DropDownListSource
{
    id: number;
    protocolType: number;
    name: string;
}
