import { Component, OnInit, Inject } from '@angular/core';
import { EdiEncodeDecodeService } from './edi-encode-decode.service';
import { EdiModel } from '../../models/edi/edi-decode-model';
import { EdiConfigurationsService } from '../../edi-configurations/edi-configurations.service';
import { Agreements } from 'src/app/models/integration-account/agreements';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { EdifactAgreement } from 'src/app/models/integration-account/agreement/edifact-agreement';
import { X12Agreement } from 'src/app/models/integration-account/agreement/x12-agreement';

@Component({
  selector: 'edi-encode-decode',
  templateUrl: './edi-encode-decode.component.html'
})
export class EdiEncodeDecodeComponent implements OnInit {
  public originalMessage: string = '';
  public resultMessage: string = '';
  public error: boolean = false;
  public errorMessage: string = '';
  public showSpinner: boolean = false;
  public ediType: number = 1;
  public isDecodeOption: boolean = true;
  public agreements: Agreements = null;
  public agreementsDDL: Array<DropDownListModel> = new Array<DropDownListModel>();
  public selectedAgreement: string = '';
  public edifactAgreement: EdifactAgreement = new EdifactAgreement();
  public x12Agreement: X12Agreement = new X12Agreement();

  constructor(public ediEncodeDecodeService: EdiEncodeDecodeService, public ediService: EdiConfigurationsService) {}

  ngOnInit(): void {
    this.showSpinner = true;
    this.getAgreements();
  }

  public decode() {
    this.error = false;
    this.errorMessage = '';
    this.resultMessage = '';
    this.showSpinner = true;

    if(this.ediType === 1) {
      let edifactDecode: EdiModel = new EdiModel();
      edifactDecode.message = this.originalMessage;
      this.ediEncodeDecodeService.edifactDecode(edifactDecode).subscribe(
        {
            next: (result) => {
              this.resultMessage = result.message;
                this.showSpinner = false;
            },
            error: (error) => {
              this.showSpinner = false;
              this.displayErrorMessage(error);
            }
          }
      );
    }

    if(this.ediType === 2) {
      let edifactDecode: EdiModel = new EdiModel();
      edifactDecode.message = this.originalMessage;
      this.ediEncodeDecodeService.x12Decode(edifactDecode).subscribe(
        {
            next: (result) => {
              this.resultMessage = result.message;
                this.showSpinner = false;
            },
            error: (error) => {
              this.showSpinner = false;
              this.displayErrorMessage(error);
            }
          }
      );
    }
  }

  public encode() {
    this.error = false;
    this.errorMessage = '';
    this.resultMessage = '';
    this.showSpinner = true;

    // Edifact
    if(this.ediType === 1) {
      let edifactEncode: EdiModel = new EdiModel();
      edifactEncode.message = this.originalMessage;
      edifactEncode.hostidentity = this.edifactAgreement.properties.content.edifact.sendAgreement.senderBusinessIdentity.value;
      edifactEncode.hostidentityQualifier = this.edifactAgreement.properties.content.edifact.sendAgreement.senderBusinessIdentity.qualifier == undefined ? '' : this.edifactAgreement.properties.content.edifact.sendAgreement.senderBusinessIdentity.qualifier;
      edifactEncode.guestidentity = this.edifactAgreement.properties.content.edifact.sendAgreement.receiverBusinessIdentity.value;
      edifactEncode.guestidentityQualifier = this.edifactAgreement.properties.content.edifact.sendAgreement.receiverBusinessIdentity.qualifier == undefined ? '' : this.edifactAgreement.properties.content.edifact.sendAgreement.receiverBusinessIdentity.qualifier;

      this.ediEncodeDecodeService.edifactEncode(edifactEncode).subscribe(
        {
            next: (result) => {
              this.resultMessage = result.message;
                this.showSpinner = false;
            },
            error: (error) => {
              this.showSpinner = false;
              this.displayErrorMessage(error);
            }
          }
      );
    }
    // X12
    if(this.ediType === 2) {
      let x12Encode: EdiModel = new EdiModel();
      x12Encode.message = this.originalMessage;
      x12Encode.hostidentity = this.x12Agreement.properties.content.x12.sendAgreement.senderBusinessIdentity.value;
      x12Encode.hostidentityQualifier = this.x12Agreement.properties.content.x12.sendAgreement.senderBusinessIdentity.qualifier == undefined ? '' : this.x12Agreement.properties.content.x12.sendAgreement.senderBusinessIdentity.qualifier;
      x12Encode.guestidentity = this.x12Agreement.properties.content.x12.sendAgreement.receiverBusinessIdentity.value;
      x12Encode.guestidentityQualifier = this.x12Agreement.properties.content.x12.sendAgreement.receiverBusinessIdentity.qualifier == undefined ? '' : this.x12Agreement.properties.content.x12.sendAgreement.receiverBusinessIdentity.qualifier;

      this.ediEncodeDecodeService.x12Encode(x12Encode).subscribe(
        {
            next: (result) => {
              this.resultMessage = result.message;
                this.showSpinner = false;
            },
            error: (error) => {
              this.showSpinner = false;
              this.displayErrorMessage(error);
            }
          }
      );
    }
  }

  public onChangeCheckEdiType() {
    if(this.originalMessage.startsWith('UNA')) {
      this.ediType = 1;
    } else if(this.originalMessage.startsWith('ISA')) {
      this.ediType = 2;
    }
  }

  public onclickEdiTypeOptions(ediType: number) {
    this.selectedAgreement = '';
    this.resultMessage = '';
    this.loadAgreementsDDL(ediType);
  }

  public selectAgreement(event: any) {
    this.showSpinner = true;
    if(this.ediType === 1) {
      this.ediService.getEdifactAgreement(this.selectedAgreement).subscribe({
        next: (result) => {
          this.edifactAgreement = result;
            this.showSpinner = false;
        },
        error: (error) => {
            this.displayErrorMessage(error);
            this.showSpinner = false;
        }
      });
    }
    if(this.ediType === 2) {
      this.ediService.getX12Agreement(this.selectedAgreement).subscribe({
        next: (result) => {
          this.x12Agreement = result;
            this.showSpinner = false;
        },
        error: (error) => {
            this.displayErrorMessage(error);
            this.showSpinner = false;
        }
      });
    }
  }

  private loadAgreementsDDL(ediType: number) {
    this.agreementsDDL = new Array<DropDownListModel>();
    this.agreements.value.forEach(x => {
      let d: DropDownListModel = {
        value: x.name,
        text: x.name
      };
      if(ediType === 1) {
        if(x.name.toUpperCase().includes('EDIFACT') === true) {
          this.agreementsDDL.push(d);
        }
      } else if(ediType === 2) {
        if(x.name.toUpperCase().includes('X12') === true) {
          this.agreementsDDL.push(d);
        }
      }
    });
  }

  private getAgreements() {
    this.ediService.getAgreements().subscribe(
    {
      next: (result) => {
        this.agreements = result;
        this.loadAgreementsDDL(this.ediType );

        this.showSpinner = false;
      },
      error: (error) => {
        this.displayErrorMessage(error);
        this.showSpinner = false;
      }
    });
  }

  private displayErrorMessage(error: any) {
    let formattedError = '';
    if(error?.error && error?.error.error == undefined) {
      if(error.error.includes('at al.goh.admin.tool.api')) {
        formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
      } else {
        formattedError = error.error;
      }
    } else {
      if(error?.error?.error) {
        formattedError = error?.error.error;
      } else {
        formattedError = 'Unknown eror';
      }
    }

    this.errorMessage = `Error when decoding message. Error Message ${formattedError}`;
    this.error = true;
    console.error(error);
  }
}
