import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'entitymap-input',
  templateUrl: './entitymap-input.component.html'
})
export class EntityMapInputComponent {
    @Input() formGroupName: string;
    @Output() entityMapUpdated = new EventEmitter<any>();
    public entitymapForm: FormGroup;

    constructor(private rootFormGroup: FormGroupDirective) { }

    ngOnInit(): void {
      this.entitymapForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    }

    get form() { return this.entitymapForm.controls; }

    onDataChange(event: any) {
      this.entityMapUpdated.emit(this.form);
    }
}
