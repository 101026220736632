import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/common-services/util.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';
import { PreStepClass } from 'src/app/models/pre-step-class';
import { SimpleFlowConfigurationsService } from '../simpleflow-configurations.service';
import { SimplifiedFlowConfigurationEntitymap } from '../models/simplified-flow-configuration-entitymap';
import { SimpleFlowConfiguration } from '../models/simple-flow-configuration';

@Component({
  selector: 'delete-simplified-flow.component', // TODO: Is selector needed for modal?
  templateUrl: './delete-simplified-flow.component.html',
})
export class DeleteSimplifiedFlowConfigurationComponent implements OnInit {
    @Input() simplifiedFlowConfiguration: SimpleFlowConfiguration;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = true;
    public simplifiedFlowConfigurationEntitymap: SimplifiedFlowConfigurationEntitymap = null;

    constructor(public activeModal: NgbActiveModal, public sfcs: SimpleFlowConfigurationsService, private errorParserService: ErrorParserService, public utilService: UtilService) {}

    ngOnInit(): void {
        this.sfcs.getSimpleConfiguration(this.simplifiedFlowConfiguration.simpleEntityMapId).subscribe(
        {
            next: (result) => {
                this.simplifiedFlowConfigurationEntitymap = result.simpleEntityMap;
                this.showSpinner = false; 
            },
            error: (error) => {
                this.showSpinner = false; 
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });
    }

    public delete() {
        this.showSpinner = true; 
        this.sfcs.deleteSimplifiedFlowConfiguration(this.simplifiedFlowConfigurationEntitymap).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('delete');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
              }
        );
    }
}
