import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OutboundMessageType } from 'src/app/models/message-type';
import { MessageTypeService } from '../message-type.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'edit-outbound-message-type',
  templateUrl: './edit-outbound-message-type.component.html'  
})
export class EditOutboundMessageTypeComponent implements OnInit {
    @Input() outboundMessageType: OutboundMessageType;
    public outboundMessageTypeForm: FormGroup;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public nextId: number;

    constructor(public mtService: MessageTypeService, public activeModal: NgbActiveModal, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        this.outboundMessageTypeForm = this.mtService.getOutboundMessageTypeForm(this.outboundMessageType);

        // Subscribe on value changes for messageName input control to force to uppercase
        this.outboundMessageTypeForm.get('messageName').valueChanges.subscribe((value: string) => {
            const formattedValue = this.mtService.formatValue(value);
            this.outboundMessageTypeForm.get('messageName').setValue(formattedValue, { emitEvent: false });
        });
    }

    get form() { return this.outboundMessageTypeForm.controls; }

    public save() {
        let outboundMessageType = this.mtService.getOutboundMessageTypeModel(this.outboundMessageTypeForm);

        this.mtService.updateOutboundMessageType(outboundMessageType).subscribe(
            {
                next: (result) => {
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
            }
        );
    }
}
