<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">AS2 Inbound</span></legend>

    <div class="form-group row">
        <label for="requestFormatDDL" class="col-sm-2 col-form-label control-label">RequestFormat <span class="req-color">*</span></label>
        <div class="col-sm-6">
            {{requestFormat}}
        </div>
    </div>
      
    <div class="form-group row">
        <label for="mdnformatDDL" class="col-sm-2 col-form-label control-label">MDN format <span class="req-color">*</span></label>
        <div class="col-sm-6">
            {{mdnformat}}
        </div>
    </div>
</fieldset>
<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">AS2 Inbound Callbacks</span></legend>

    <div class="form-group row">
        <label for="as2AckformatDDL" class="col-sm-2 col-form-label control-label">Ack format</label>
        <div class="col-sm-6">
            {{ackFormat}}
        </div>
    </div>

    <div class="form-group row mb5">
        <label for="as2AckformatDDL" class="col-sm-2 col-form-label control-label">Ack endpoint</label>
        <div class="col-sm-6">
            {{ackEndpoint}}
        </div>
    </div>

    <div class="form-group row" style="margin-top: 5px;">
        <label for="as2AckformatDDL" class="col-sm-2 col-form-label control-label">Ack format</label>
        <div class="col-sm-6">
            {{ackEnabled === true ? 'YES' : 'NO'}}
        </div>
    </div>
</fieldset>




