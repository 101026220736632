// grid-view-command-column.directive.ts
import { AfterContentInit, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({
  selector: 'grid-view-command-column'
})
export class GridViewCommandColumnDirective implements AfterContentInit {
    @Input() title: string = '';
    @Input() template: TemplateRef<any> | null = null;
    @Output() removeClicked = new EventEmitter<any>();
    @Output() editClicked = new EventEmitter<any>();
    
    // Use the default template if not provided
    @Input() defaultTemplate: TemplateRef<any> | null = null;
  
    constructor() {
      //this.defaultTemplate = null; // Initialize the property here
      
    }
  
    ngAfterContentInit(): void {
      // Set the default template if a custom template is not provided
      this.template = this.template || this.defaultTemplate;
    }
  
    onRemoveClicked(): void {
      // ... (any logic you need before emitting the event)
    
      this.removeClicked.emit(/* some data */);
    }
}