import { Component, Input, OnInit } from '@angular/core';
import { CanonicalMap } from 'src/app/models/mapping/canonical-map';

@Component({
  selector: 'inbound-mapping-input',
  templateUrl: './inbound-mapping-input.component.html',
})
export class InboundMappingInputComponent implements OnInit {
  @Input() canonicalMaps: Array<CanonicalMap>;
  @Input() inboundPartnerId: number;
  @Input() inboundMessageTypeId: number;

  public selectedCanonicalMaps: Array<CanonicalMap>;
  public canonicalMapsColumns = [
    { header: 'Xslt filename', field: 'name' },
    { header: 'Xslt description', field: 'description' },
    { header: 'Task Id', field: 'taskId' }
  ];
  public canonicalMapsData: Array<any> = null;

  constructor() {}

  ngOnInit(): void {
    this.selectedCanonicalMaps = this.canonicalMaps.filter(x => x.inboundMessageTypeId === +this.inboundMessageTypeId && x.inboundPartnerId === +this.inboundPartnerId);

     // Populate enrichement grid data
     if(this.selectedCanonicalMaps != undefined) {
      this.canonicalMapsData = new Array<any>;
      this.selectedCanonicalMaps.forEach(c => {
        this.canonicalMapsData.push({ name: c.canonicalMapXslt.name, description: c.canonicalMapXslt.description, taskId: c.taskId })
      });
    }
  }
}
