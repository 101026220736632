import { Component, OnInit } from '@angular/core';
import { OutboundMessageType } from 'src/app/models/message-type';
import { MessageTypeModel } from '../message-type-model';
import { MessageTypeService } from '../message-type.service';
import { OutboundMessageTypesGridView } from '../models/OutboundMessageTypesGridView';
import { PagingService } from 'src/app/common-services/paging.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppOutboundMessageTypeComponent } from './add-outbound-message-type.component';
import { MsalService } from '@azure/msal-angular';
import { DeleteOutboundMessageTypeComponent } from './delete-outbound-message-type.component';
import { EditOutboundMessageTypeComponent } from './edit-outbound-message-type.component';
import { Paging } from 'src/app/models/paging/paging';

@Component({
    selector: 'md-outbound-message-types',
    templateUrl: './md-outbound-message-types.component.html',
  })
export class MasterDataOutboundMessageTypesComponent  implements OnInit {
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = true;
    public omtGridView: OutboundMessageTypesGridView;
    public messageTypeGridList: Array<MessageTypeModel> = new Array<MessageTypeModel>();    
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public isSuperUser: boolean = false;
    public filterMessageTypeName: string = '';
    public filterMessageTypeDescription: string = '';
    public outboundMessageTypesPaging: Paging = new Paging();

    constructor(public messageTypeService: MessageTypeService, public pService: PagingService, private authService: MsalService, private modalService: NgbModal) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
            this.isSuperUser = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.SuperUser') == undefined ? false : true;
        }

        this.getData();
    }

    private getData() {
        this.messageTypeService.getOutboundMessageTypes(this.outboundMessageTypesPaging.currentPage, this.outboundMessageTypesPaging.pageCount, this.filterMessageTypeName, this.filterMessageTypeDescription).subscribe(
        {
          next: (result) => {
            this.omtGridView = result;
            this.outboundMessageTypesPaging.setLastDisplayIndex();
            this.outboundMessageTypesPaging.totalPages = Math.floor(this.omtGridView.totalRows/this.outboundMessageTypesPaging.pageCount) + (this.omtGridView.totalRows % this.outboundMessageTypesPaging.pageCount > 0 ? 1 : 0);
            // this.messageTypeGridList = this.messageTypeService.getMessageTypeModelList(result);
            this.showSpinner = false; 
          },
          error: (error) => { 
            this.setErrorText(error);
            this.showSpinner = false; 
          }
        });
    }

    public onFilter() {
        this.outboundMessageTypesPaging.currentPage = 1;
        this.getData();
    }

    public createNewOutboundMessageType() {
        const modalRef = this.modalService.open(AppOutboundMessageTypeComponent, { size: 'size_40' });
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public editOutboundMessageType(gridRow: OutboundMessageType) {
        const modalRef = this.modalService.open(EditOutboundMessageTypeComponent, { size: 'size_40' });
        modalRef.componentInstance.outboundMessageType = gridRow;
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public removeOutboundMessageType(gridRow: OutboundMessageType) {
        const modalRef = this.modalService.open(DeleteOutboundMessageTypeComponent, { size: 'size_40' });
        modalRef.componentInstance.outboundMessageType = gridRow;
        modalRef.result.then((result) => {            
            if(result === 'delete') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }
    
    private setErrorText(error: any) {
        this.error = true;
        let msg: string = '';
        let title: string = error?.error?.title;
        msg = (title !== undefined) ? title : error?.error;
        this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
        console.error(error); 
    }

    // TODO: Message Type is never removed if used for en onboarding
    public removeMessageType(gridRow: MessageTypeModel) {        
    }

    // --- Paging ----------------------------------------------------------------------------------  
    public onclickPrev() {
        if (this.outboundMessageTypesPaging.currentPage > 1) {
            this.outboundMessageTypesPaging.currentPage = this.outboundMessageTypesPaging.currentPage - 1;
            this.outboundMessageTypesPaging.setStartEndIndex();
            this.getData();      
        }
    }

    public onclickNext() {
        if ((this.outboundMessageTypesPaging.currentPage) * this.outboundMessageTypesPaging.pageCount < this.omtGridView.totalRows) {
            this.outboundMessageTypesPaging.nextButtonDisabled = false;
            this.outboundMessageTypesPaging.currentPage = this.outboundMessageTypesPaging.currentPage + 1;
            this.outboundMessageTypesPaging.setStartEndIndex();
            this.getData();
        } else {
            this.outboundMessageTypesPaging.nextButtonDisabled = true;
        }
    }

    public onclickPage(page: number, index: number) {
        this.outboundMessageTypesPaging.currentPage = this.outboundMessageTypesPaging.currentPage === page ? this.outboundMessageTypesPaging.currentPage : page;
        this.outboundMessageTypesPaging.setStartEndIndex();
        this.getData();
    }

    selectChangeHandler(event: any) {
        this.outboundMessageTypesPaging.currentPage = 1;
        this.outboundMessageTypesPaging.setStartEndIndex();      
        this.getData();
    }
}
