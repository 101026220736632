import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PreStepClass } from 'src/app/models/pre-step-class';
import { PreStep } from 'src/app/models/pre-step';

@Injectable({
  providedIn: 'root',
})
export class PreStepService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getPreStepClasses(): Observable<Array<PreStepClass>> {
        return this._http.get<Array<PreStepClass>>(this.baseUrlLocal + 'prestepclasses/all');
    }

    public getPreStep(partnerId: number): Observable<PreStep> {
        return this._http.get<PreStep>(this.baseUrlLocal + `prestep?partnerId=${partnerId}`);
    }
}


