import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataParsersComponent } from './md-parser-class.component';
import { ParserClassService } from './md-parser-class.service';
import { AppParserClassComponent } from './child-components/add-parser-class.component';
import { EditParserClassComponent } from './child-components/edit-parser-class.component';
import { ErrorDisplayModule } from 'src/app/common-components/error-display/error-display.module';

@NgModule({
  imports:      [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule,
    ErrorDisplayModule
  ],
  providers:    [ ParserClassService ],
  declarations: [ MasterDataParsersComponent, AppParserClassComponent, AppParserClassComponent, EditParserClassComponent ]
})
export class MasterDataParsersModule { }
