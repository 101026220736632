<h3 id="tableLabel">Test Resolvers</h3>
<p>Provided there is a matching Resolver created in the Global Order Hub common library, you can use the resolver tester to see if your message payload can be resolved to the expected Message Type.</p>
<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
    <p style="margin-bottom: 2rem;white-space:pre-wrap;"><b>{{errorMessage}}</b></p>
</div>

<div class="row">
    <div class="col-12">
        <div class="row">
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Partner</span></legend>
                <div class="form-group row">
                    <label for="partnerDDL" class="col-sm-2 col-form-label control-label">Partner <span class="req-color">*</span></label>
                    <div class="col-sm-6">
                      <select *ngIf="partnersDDL" dropdown id="partnerDDL" name="partnerDDL" class="form-select form-select-sm control-label" [(ngModel)]="partnerId">
                        <option value="">--- Select partner ---</option>
                        <option *ngFor="let item of partnersDDL" [value]="item.value">
                          {{item.text}}
                        </option>
                      </select>
                    </div>    
                </div>
            </fieldset>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="row">
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Message (XML, JSON, etc)</span></legend>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <textarea class="form-control" id="originalMessage" rows="15" [(ngModel)]="originalMessage"></textarea>    
                    </div>    
                </div>
            </fieldset>
        </div>
    </div>
</div>

<div class="row" style="margin-top: 10px;">
    <div class="col-12">
        <button class="btn btn-outline-secondary btn-sm" [disabled]="partnerId === ''" style="float: right;" (click)="onclickResolve()">Resolve</button>
    </div>                
</div>

<div class="row" *ngIf="resolverResult">
    <div class="col-12">
        <div class="row">
            <fieldset class="border p-2">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Result</span></legend>
                <div class="form-group row mb5">
                    <div class="col-sm-12">
                        The provided message was resolved to the following...<br /><br /> 
                        &emsp;Message type: <b>{{resolverResult.messageTypeString}}</b><br />
                        &emsp;Message type Id: <b>{{resolverResult.messageType.toString()}}</b>
                    </div>    
                </div>
            </fieldset>
        </div>
    </div>
</div>