<header>
    <nav
      class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow mb-3" style="background-color: #11387F;" >
      <div class="container">
        <a class="navbar-brand" [routerLink]="['/']"><img src="assets/logo.png" alt="logo"> GOH Admin Tool ({{env}})</a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target=".navbar-collapse"
          aria-label="Toggle navigation"
          [attr.aria-expanded]="isExpanded"
          (click)="toggle()"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div id="collapseBasic" [collapse]="isCollapsed" class="navbar-collapse collapse d-sm-inline-flex justify-content-end">
          <ul class="navbar-nav flex-grow">
            <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
              <a class="nav-link" [routerLink]="['/']" style="color: white;">Home</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
              <a class="nav-link" [routerLink]="['/customer-configurations']" style="color: white;">Configurations</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
              <a class="nav-link" [routerLink]="['/simplified-flow-configurations']" style="color: white;">Simplified Flow Configurations</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="!isReader">
              <a class="nav-link" [routerLink]="['/edi-configurations']" style="color: white;">EDI Configurations</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
              <a class="nav-link" [routerLink]="['/track-messages']" style="color: white;">Message tracker</a>
            </li>
            <li class="nav-item dropdown" *ngIf="!isReader">
              <span dropdown>
                <a href class="nav-link dropdown-toggle" dropdownToggle (click)="false" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white;">Tools</a>
                <div class="dropdown-menu dropdown-menu-end" *dropdownMenu aria-labelledby="navbarDropdown">

                  <a class="dropdown-item" [routerLink]="['/edi-encode-decode']">Edi Decode/Encode</a>
                  <a class="dropdown-item" [routerLink]="['/resolver-tester']">Test Resolvers</a>
                  <a class="dropdown-item" [routerLink]="['/mapping-explorer']">Mapping Explorer</a>
                </div>
              </span>
            </li>
            <li class="nav-item dropdown" *ngIf="isAdmin || isOnboarder">
              <span dropdown>
                <a href class="nav-link dropdown-toggle" dropdownToggle (click)="false" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white;">Master data</a>
                <div class="dropdown-menu dropdown-menu-end" *dropdownMenu aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" *ngIf="isAdmin || isOnboarder" [routerLink]="['/partners']">Partners</a>
                  <a class="dropdown-item" *ngIf="isAdmin" [routerLink]="['/mapping-documents']">Mapping Documents</a>
                  <a class="dropdown-item" *ngIf="isAdmin" [routerLink]="['/message-types']">Message types</a>
                  <a class="dropdown-item" *ngIf="isAdmin" [routerLink]="['/parsers']">Parsers</a>
                  <a class="dropdown-item" *ngIf="isAdmin" [routerLink]="['/resolvers']">Resolvers</a>
                  <a class="dropdown-item" *ngIf="isAdmin" [routerLink]="['/prestepclasses']">Prestep classes</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" *ngIf="isSuperUser" [routerLink]="['/service-mode']">Service Mode</a>
                </div>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
