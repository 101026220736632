import { SourceFtpProfile } from "./source-ftp-profile";
import { SourceMqProfile } from "./source-mq-profile";
import { SourceSbProfile } from "./source-sb-profile";
import { SourceSftpProfile } from "./source-sftp-profile";
import { SourceSharedFolderProfile } from "./source-shared-folder-profile";

export class Source {
    id: number;
    protocolType: number;
    name: string;
    profileId: number;
    ediType: number;
    sourceMQProfile: SourceMqProfile;
    sourceSBProfile: SourceSbProfile;
    sourceSharedFolderProfile: SourceSharedFolderProfile;
    sourceSftpProfile: SourceSftpProfile
    sourceFtpProfile: SourceFtpProfile
    createdBy: string;
    createTimestamp: string;
}