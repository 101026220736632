import { Injectable, PipeTransform, Pipe, Injector } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dynamicPipe' })
@Injectable({ providedIn: 'root' })
export class DynamicPipeService implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(value: any, pipeToken: any, pipeArgs: any[] = []): any {
    const pipe = this.injector.get(pipeToken);
    return pipe.transform(value, ...pipeArgs);
  }
}