import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TestFormValidationComponent } from './test-form-validation.component';
import { AddressInfoComponent } from './child-components/address-info.component';
import { EntitymapInfoComponent } from './child-components/entitymap-info.component';

@NgModule({
    imports:      [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule, // not used?
        BrowserAnimationsModule,// not used?
        NgbModule
      ],
      providers:    [  ],
      declarations: [    
        TestFormValidationComponent, AddressInfoComponent, EntitymapInfoComponent
      ]
})
export class TestFormValidationModule { }
