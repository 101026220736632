import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ServiceModeResponseModel } from 'src/app/master-data/service-mode/models/service-mode-response-model';
import { ServiceModeRequestModel } from 'src/app/master-data/service-mode/models/service-mode-request-model';

@Injectable({
  providedIn: 'root',
})
export class ServiceModeService {
  public _http: HttpClient;
  public baseUrlLocal: string = "";

  constructor(private http: HttpClient) {
      this._http = http;
      this.baseUrlLocal = environment.apiBaseUrl;
  }

  public getServiceMode(): Observable<ServiceModeResponseModel> {
    return this.http.get<ServiceModeResponseModel>(this.baseUrlLocal + `servicemode`);
  }

  public setServiceMode(serviceModeRequestModel: ServiceModeRequestModel) {
    return this.http.post(this.baseUrlLocal + 'servicemode', serviceModeRequestModel);
  }

  public startGenericInbound(serviceModeRequestModel: ServiceModeRequestModel) {
    return this.http.post(this.baseUrlLocal + 'servicemode/startgenericinbound', serviceModeRequestModel);
  }
}
