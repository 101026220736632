<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Enrichments</span></legend>
    <div style="margin-bottom: 10px;">
        <span class="text-muted"><small>Enrichemnts is used when the message need to be enriched with data from other system before it is sent to the receiving party.</small></span>
    </div>  

    <div class="form-group row" style="margin-left: 5px; margin-bottom: 10px;">
        <table>
            <tr>
                <td class="col-sm-3">
                    <select dropdown (change)="selectChangeHandler($event)" id="enricherDDL" name="enricherDDL" class="form-select form-select-sm control-label" [(ngModel)]="selectedEnricherValue">
                        <option value="">--- Select enricher ---</option>
                        <option *ngFor="let item of enrichmentClasses" [value]="item.enricher">
                            {{item.description}}
                        </option>
                    </select>
                </td>
                <td><button type="button" class="btn btn-outline-secondary btn-sm" style="margin-left: 5px;" [disabled]="selectedEnricherValue == ''" (click)="addEnricher()">Add</button></td>
            </tr>
        </table>       
    </div>

    <div class="ml5">
        <table class='table table-striped table-bordered col-sm-6' aria-labelledby="tableLabel" *ngIf="enrichments">
            <thead>
                <tr>
                    <th>Enricher class</th>
                    <th>Description</th>
                    <th>Order</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of enrichments">
                    <td>{{e.enrichmentClass.enricher}}</td>
                    <td>{{e.enrichmentClass.description}}</td>
                    <td><input type="text" class="form-control" id="order" name="order" [(ngModel)]="e.order" (change)="onOrderChange(e)"></td>
                    <td><button type="button" class="btn btn-outline-secondary btn-sm" (click)="removeEnrichment(e)"><i class="fa fa-trash-o" aria-hidden="true"></i></button></td>
                </tr>
            </tbody>
        </table>   
    </div>
</fieldset>