<form [formGroup]="sourceSharedFolderProfileForm">
    <hr>
    <div class="form-group row mb5">
    <label for="sourceSharedFolderProfileServer" class="col-sm-3 col-form-label">Server<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="server" class="form-control" id="sourceSharedFolderProfileServer" name="sourceSharedFolderProfileServer" placeholder="Server">
        <div *ngIf="form.server.invalid && (form.server.dirty || form.server.touched)"
                class="alert alert-danger">
                <div *ngIf="form.server.errors?.['required']">Server is required.</div>
            </div>
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSharedFolderProfilePath" class="col-sm-3 col-form-label">Path</label>
    <div class="col-sm-6">
        <input type="text" formControlName="path" class="form-control" id="sourceSharedFolderProfilePath" name="sourceSharedFolderProfilePath" placeholder="Path">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSharedFolderProfileFilter" class="col-sm-3 col-form-label">Filter</label>
    <div class="col-sm-6">
        <input type="text" formControlName="filter" class="form-control" id="sourceSharedFolderProfileFilter" name="sourceSharedFolderProfileFilter" placeholder="Filter">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSharedFolderProfileInputEncoding" class="col-sm-3 col-form-label">Input encoding</label>
    <div class="col-sm-6">
        <input type="text" formControlName="inputEncoding" class="form-control" id="sourceSharedFolderProfileInputEncoding" name="sourceSharedFolderProfileInputEncoding" placeholder="Input encoding">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSharedFolderProfileOutputEncoding" class="col-sm-3 col-form-label">Output encoding</label>
    <div class="col-sm-6">
        <input type="text" formControlName="outputEncoding" class="form-control" id="sourceSharedFolderProfileOutputEncoding" name="sourceSharedFolderProfileOutputEncoding" placeholder="Output encoding">
    </div>
</div>
</form>