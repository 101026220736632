import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { EdiEncodeDecodeComponent } from './edi-encode-decode.component';
import { EdiEncodeDecodeService } from './edi-encode-decode.service';
import { EdiConfigurationsService } from '../../edi-configurations/edi-configurations.service';

@NgModule({
  imports:      [ CommonModule, FormsModule ],
  providers:    [ EdiEncodeDecodeService, EdiConfigurationsService ],
  declarations: [ EdiEncodeDecodeComponent ]
})
export class EdiEncodeDecodeModule { }