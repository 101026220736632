import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { Partner } from 'src/app/models/partner/partner';
import { PartnerModel } from './models/partner-model';
import { ResolverClass } from 'src/app/models/resolver/resolver-class';
import { PartnerRequestModel } from './models/partner-request-model';
import { InboundParserResponseModel} from './models/inboundparser-response-model';
import { Resolver } from 'src/app/models/resolver/resolver';
import { ParserClass } from 'src/app/models/parser/parser-class';
import { Scheduler } from 'src/app/models/source/scheduler';
import { ConfDestination } from 'src/app/models/destination/conf-destination';
import { OutboundMessageTypesGridView } from '../message-types/models/OutboundMessageTypesGridView';
import { GridView } from 'src/app/models/grid-view';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    // public getPartners(page: number, pageCount: number): Observable<Array<Partner>> {
    //     return this._http.get<Array<Partner>>(this.baseUrlLocal + 'partners');
    // }

    public getPartners(pageindex: number, pagesize: number, partnerId: string, name: string, description: string): Observable<GridView<Partner>> {
        let queryStart = '';
        let queryString = '';
        
        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)
  
        if(partnerId != undefined && partnerId.length > 2) {
            queryString = queryString.concat(`${queryStart}partnerId=${partnerId}`)
        }
        if(name != undefined && name.length > 2) {
            queryString = queryString.concat(`${queryStart}name=${name}`)
        }
        if(description != undefined && description.length > 2) {
            queryString = queryString.concat(`${queryStart}description=${description}`)
        }
        
        return this.http.get<GridView<Partner>>(this.baseUrlLocal + `partners` + queryString );
    }

    private getQueryStart(queryStart: string) {
        if(queryStart === '') {
            return  '?';
        } else {
            return '&';
        }
    }

    // TODO propably better to put this in a specific service
    public getResolver(partnerId: number): Observable<Resolver> {
        return this._http.get<Resolver>(this.baseUrlLocal + 'resolver?partnerId=' + partnerId);
    }

    public getParsers(partnerId: number): Observable<Array<InboundParserResponseModel>> {
      return this._http.get<Array<InboundParserResponseModel>>(this.baseUrlLocal + 'inboundparser/partnerconnection?inboundpartnerId=' + partnerId);
    }

    public addPartner(partner: PartnerRequestModel) {
        return this.http.post(this.baseUrlLocal + 'partners', partner);
    }

    public updatePartner(partner: PartnerRequestModel) {
        return this.http.put(this.baseUrlLocal + 'partners', partner);
    }

    public getResolverClasses(): Observable<Array<ResolverClass>> {
        return this._http.get<Array<ResolverClass>>(this.baseUrlLocal + 'resolverclasses/all');
    }

    // public getParserClasses(): Observable<Array<ParserClass>> {
    //   return this._http.get<Array<ParserClass>>(this.baseUrlLocal + 'parserclasses');
    // }

    public getParserClasses(): Observable<Array<ParserClass>> {
        return this._http.get<Array<ParserClass>>(this.baseUrlLocal + 'parserclasses/all');
    }

    public getOutboundMessageTypes(): Observable<OutboundMessageTypesGridView> { 
      return this._http.get<OutboundMessageTypesGridView>(this.baseUrlLocal + 'messagetypes');
    }

    public getPartnerSources(partnerId: number): Observable<Array<Scheduler>> {
        return this._http.get<Array<Scheduler>>(`${this.baseUrlLocal}scheduler/${partnerId}`);
    }

    // public getPartnerDestinations(partnerId: number): Observable<Array<ConfDestination>> {
    //     return this._http.get<Array<ConfDestination>>(`${this.baseUrlLocal}destinations/${partnerId}`);
    // }

    public setEditMode(gridRow: PartnerModel, partnersGridList: Array<PartnerModel>, editMode: boolean) {
        const foundGridRow = partnersGridList.filter((obj) => {
            return obj.id === gridRow.id;

        });
        foundGridRow[0].editMode = editMode;
    }

    public getPartnerModelList(partners: Array<Partner>) {
        let partnersGridList: Array<PartnerModel> = new Array<PartnerModel>();

            partners.forEach(p => {
                let partner: PartnerModel = {
                    id: p.id,
                    partnerId: p.partnerId,
                    name: p.name,
                    description: p.description,
                    createdBy: p.createdBy,
                    createTimestamp: p.createTimestamp,
                    editMode: false,
                    isNew: false
                };
                partnersGridList.push(partner);
            });

        return partnersGridList;
    }

    public getPartner(gridRow: PartnerModel) {
        let partner: Partner = new Partner()
        partner.id = gridRow.id;
        partner.partnerId = gridRow.partnerId;
        partner.name = gridRow.name;
        partner.description = gridRow.description;

        if(!gridRow.isNew) {
            partner.createTimestamp = gridRow.createTimestamp;
            partner.createdBy = gridRow.createdBy;
        }

        return partner;
    }

    public populateNewPartner(highestId: number) {
        let partner: PartnerModel = {
            id: highestId + 1,
            partnerId: '',
            name: '',
            description: '',
            createdBy: null,
            createTimestamp: null,
            editMode: true,
            isNew: true
        };

        return partner;
    }
}


