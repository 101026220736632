<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="modal-header">
    <h4 class="modal-title">Delete destination {{ utilService.getClassName(confDestination.description) }}</h4>
</div>

<div class="modal-body">
    <!-- <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div> -->
    <div class="alert alert-danger" role="alert" [hidden]="errorMessage.length === 0">
        <b>{{errorMessage}}</b>
    </div>
    <div>
        Are you really sure you want to delete destination {{ utilService.getClassName(confDestination.description) }}?
    </div>    
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
