<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div>

<div class="row">
    <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="pagedMaps">
         <thead>
            <tr>
                <th>
                  <div>Map name</div>
                  <div>
                    <input type="text" class="form-control" id="mapName" name="mapName" placeholder="Filter map name" [(ngModel)]="filterMapName" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Map description</div>
                  <div>
                    <input type="text" class="form-control" id="mapDescription" name="mapDescription" placeholder="Filter map description" [(ngModel)]="filterMapDescription" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Partner Id</div>
                  <div>
                    <input type="text" class="form-control" id="partnerId" name="partnerId" placeholder="Filter partner Id" [(ngModel)]="filterPartnerId" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Message type</div>
                  <div>
                    <input type="text" class="form-control" id="messageType" name="messageType" placeholder="Filter message type" [(ngModel)]="filterMessageType" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Sender Id</div>
                  <div>
                    <input type="text" class="form-control" id="senderId" name="senderId" placeholder="Filter sender Id" [(ngModel)]="filterSenderId" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Sender name</div>
                  <div>
                    <input type="text" class="form-control" id="messageTypeName" name="messageTypeName" placeholder="Filter sender name" [(ngModel)]="filterSenderName" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Receiver Id</div>
                  <div>
                    <input type="text" class="form-control" id="messageTypeName" name="messageTypeName" placeholder="Filter receiver type" [(ngModel)]="filterReceiverId" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Receiver name</div>
                  <div>
                    <input type="text" class="form-control" id="messageTypeName" name="messageTypeName" placeholder="Filter receiver name" [(ngModel)]="filterReceiverName" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Legal entity</div>
                  <div>
                    <input type="text" class="form-control" id="legalEntity" name="legalEntity" placeholder="Filter legal entity" [(ngModel)]="filterLegalEntity" (keyup)="onFilter()">
                  </div>
                </th>
                <th>
                  <div>Area plant</div>
                  <div>
                    <input type="text" class="form-control" id="areaPlant" name="areaPlant" placeholder="Filter area plant" [(ngModel)]="filterAreaPlant" (keyup)="onFilter()">
                  </div>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cc of pagedMaps">
                <td>{{ cc.mapName }}</td>
                <td>{{ cc.mapDescription }}</td>
                <td>{{ cc.partnerId }}</td>
                <td>{{ cc.messageType }}</td>
                <td>{{ cc.senderId }}</td>
                <td>{{ cc.senderName }}</td>
                <td>{{ cc.receiverId }}</td>
                <td>{{ cc.receiverName }}</td>
                <td>{{ cc.legalEntityName }}</td>
                <td>{{ cc.areaPlantName }}</td>
                <td class="fixed30">
                  <button class="btn btn-outline-secondary btn-sm" (click)="viewConfig(cc)" style="margin-right: 5px;"><i class="fa fa-magnifying-glass"></i></button>
                </td>
            </tr>         
        </tbody>
        <tfoot>
          <tr>
            <td colspan="11">
              <div class="pull-left">
                <nav>
                  <ul class="pagination">
                    <li class="page-item" ng-class="{disabled: currentPage == 1}">
                      <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                    </li>
                    <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 1, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 1, totalPages), 1)">{{pService.getPageNumber(currentPage, 1, totalPages)}}</a></li>
                    <li class="page-item" *ngIf="totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 2, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 2, totalPages), 2)">{{pService.getPageNumber(currentPage, 2, totalPages)}}</a></li>
                    <li class="page-item" *ngIf="totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 3, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 3, totalPages), 3)">{{pService.getPageNumber(currentPage, 3, totalPages)}}</a></li>
                    <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                      <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="pull-right">
                <label style="margin-right: 5px;">{{(((currentPage) - 1) * 10) + 1}} - {{lastDisplayIndex}} of {{mapCount}}</label>
              </div>
            </td>
          </tr>
        </tfoot>
    </table>
  </div>
