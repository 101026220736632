import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MappingDocumentService } from '../mapping-document.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { FormGroup } from '@angular/forms';
import { ErrorModel } from 'src/app/models/error-model';
import { DropDownListModelNumber } from 'src/app/models/dropdown-list-model';

@Component({
  selector: 'add-inbound-to-canonical-mapping-documents',
  templateUrl: './add-inbound-to-canonical-mapping-documents.component.html'
})
export class AppInboundToCanonicalMappingDocumentComponent implements OnInit {
    public canonicalMapXsltForm: FormGroup;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public modelTypes: Array<DropDownListModelNumber>;
    public modelTypeVersions: Array<DropDownListModelNumber>;
    
    constructor(public mdService: MappingDocumentService, private errorParserService: ErrorParserService, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.canonicalMapXsltForm = this.mdService.getEmptyInboundToCanonicalMappingForm();
        this.modelTypes = this.mdService.getModelTypes();
        this.modelTypeVersions = this.mdService.getModelTypeVersions();
    }

    get form() { return this.canonicalMapXsltForm.controls; }

    public save() {
        this.showSpinner = true; 
        let model = this.mdService.getInboundToCanonicalMappingModel(this.canonicalMapXsltForm);
        this.mdService.addInboundToCanonicalMappingDocument(model).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }
}
