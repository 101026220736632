import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { UtilService } from 'src/app/common-services/util.service';
import { ReviewwInputService } from '../review-input.service';

@Component({
  selector: 'review-parser',
  templateUrl: './review-parser.component.html',
})
export class ReviewParserComponent {
  form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, public rs: ReviewwInputService, public utilService: UtilService) {}
  
  ngOnInit(): void {
    this.form = this.rootFormGroup.form;
  }
}
