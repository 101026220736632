import { Component, OnInit, Inject } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DropDownListModel } from '../models/dropdown-list-model';
import { AgreementRequest } from '../models/integration-account/agreement-request';
import { As2Agreement } from '../models/integration-account/agreement/as2-agreement';
import { X12Agreement } from '../models/integration-account/agreement/x12-agreement';
import { EdifactAgreement } from '../models/integration-account/agreement/edifact-agreement';
import { Agreements } from '../models/integration-account/agreements';
import { Certificates } from '../models/integration-account/certificate/certificates';
import { SchemaReference } from '../models/integration-account/schema/schema-reference';
import { Value } from '../models/integration-account/value';
import { EdiConfigurationsService } from './edi-configurations.service';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TabModel } from '../models/tab-model';
import { PartnerList } from '../models/integration-account/partner/partner-list';
import { PartnerRequest } from '../models/integration-account/partner/partner-request';
import { BusinessIdentity } from '../models/integration-account/partner/business-identity';
import { MsalService } from '@azure/msal-angular';
import { ErrorModel } from '../models/error-model';
import { ErrorParserService } from '../common-services/error-parser.service';

@Component({
  selector: 'edi-configurations',
  templateUrl: './edi-configurations.component.html'
})
export class EdiConfigurationsComponent implements OnInit {
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public agreements: Agreements = null;
    public partnerList: PartnerList = null;
    public vewAddAgreementDisplayStyle: string = 'none';
    public viewUpsertPartnerDisplayStyle: string = 'none';
    public showSpinner: boolean = true;
    // public error: boolean = false;
    // public errorMessage: string = '';
    public certificates: Certificates = null;
    public errorModel: ErrorModel = null;
    public certificatesPrivateDDL: Array<DropDownListModel> = null;
    public certificatesPublicDDL: Array<DropDownListModel> = null;
    public schemas: Array<SchemaReference> = null;
    public schemasEdifactDDL: Array<DropDownListModel> = null;
    public schemasX12DDL: Array<DropDownListModel> = null;
    public agreementRequest: AgreementRequest = new AgreementRequest();
    public partnerRequest: PartnerRequest = new PartnerRequest();
    public isNew: boolean = true;
    public viewDeleteConfirmWindowDisplayStyle: string = "none";
    public viewDeleteConfirmWindow: boolean = false;
    public deleteAgreementName: string = '';
    public partnerListDDL: Array<DropDownListModel> = new Array<DropDownListModel>();
    public viewDeletePartnerConfirmWindowDisplayStyle: string = "none";
    public viewDeletePartnerConfirmWindow: boolean = false;
    public deletePartnerName: string = '';

    public tabModel: TabModel = new TabModel();
    
    constructor(public ediService: EdiConfigurationsService, private authService: MsalService, private errorParserService: ErrorParserService) {}

    public onclickGoToTab(tab: number) {        
        this.tabModel.activeTab = tab;
        
    }

    ngOnInit(): void {
        this.showSpinner = true;

        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        this.ediService.getCertificates().subscribe(
        {
            next: (result) => {
                this.certificates = result;
                this.certificatesPrivateDDL = new Array<DropDownListModel>();
                this.certificatesPublicDDL = new Array<DropDownListModel>();

                if(this.certificates.value != undefined) {
                    this.certificates.value.forEach(x => {
                        let d: DropDownListModel = {
                          value: x.name.toString(),
                          text: x.name
                        };
    
                        if(x.name.includes("public") === true) {
                            this.certificatesPublicDDL.push(d);
                        }
                        if(x.name.includes("private") === true) {
                            this.certificatesPrivateDDL.push(d);    
                        }
                    });
                }
                

                this.getSchemas();
            },
            error: (error) => { 
                // TODO pick up error message and display.
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
                this.showSpinner = false;
            //   let msg: string = '';
            //   let title: string = error?.error?.title;
            //   msg = (title !== undefined) ? title : error?.error;
            //   this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
            }
        });

        
        this.getPartners();
    }

    private getSchemas() {
        this.ediService.getSchemas().subscribe(
            {
                next: (result) => {
                    this.schemas = result;
                    this.schemasEdifactDDL = new Array<DropDownListModel>();
                    this.schemasX12DDL = new Array<DropDownListModel>();
                    this.schemas.forEach(x => {
                        let splittedSchema = x.schemaName.split('_');
                        // TODO filter on efact and x12
                        // if(x.messageId)
                        let d: DropDownListModel = {
                        value: x.schemaName,
                        text: x.schemaName
                        };
                        if(splittedSchema[0] === 'X12') {
                            this.schemasX12DDL.push(d);
                        } else {
                            this.schemasEdifactDDL.push(d);
                        }
                    });

                    this.getData(1, 10);
                },
                error: (error) => { 
                    // TODO pick up error message and display.
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                    this.showSpinner = false;
                //   let msg: string = '';
                //   let title: string = error?.error?.title;
                //   msg = (title !== undefined) ? title : error?.error;
                //   this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
                }
            });
    }

    private getPartners() {
        this.ediService.getPartners().subscribe(
            {
                next: (result) => {
                    this.partnerList = result;
                    this.showSpinner = false;
                },
                error: (error) => { 
                    // TODO pick up error message and display.
                    console.error(error);
                    this.showSpinner = false;
                //   let msg: string = '';
                //   let title: string = error?.error?.title;
                //   msg = (title !== undefined) ? title : error?.error;
                //   this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
                }
            });
    }

    private getData(page: number, pageCount: number) {
        
        this.ediService.getAgreements().subscribe(
        {
        next: (result) => {
            this.agreements = result;
            this.showSpinner = false;
        },
        error: (error) => { 
            // TODO pick up error message and display.
            this.errorModel = this.errorParserService.extractErrors(error);
            console.error(error);
            this.showSpinner = false;
            //   let msg: string = '';
            //   let title: string = error?.error?.title;
            //   msg = (title !== undefined) ? title : error?.error;
            //   this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
        }
        });
    }

    public addAgreement() {
        this.isNew = true;
        this.partnerListDDL = this.ediService.getPartnerListDDL(this.partnerList);
        // Clear agreement on new
        this.agreementRequest = this.ediService.getEmptyAgreement();
        this.vewAddAgreementDisplayStyle = "block";        
    }

    public onCloseAddAgreement() {
        // TODO get data again for refresh of grid
        this.vewAddAgreementDisplayStyle = "none";
        this.getData(1, 10);
    }

    public editPartner(name: string) {
        this.isNew = false;
        this.showSpinner = true;
        this.ediService.getPartner(name).subscribe(
            {
            next: (result) => {
                this.partnerRequest = this.ediService.getPartnerRequest(result);
                this.viewUpsertPartnerDisplayStyle = "block";
                this.showSpinner = false;
            },
            error: (error) => { 
                // TODO pick up error message and display.
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
                this.showSpinner = false;
                //   let msg: string = '';
                //   let title: string = error?.error?.title;
                //   msg = (title !== undefined) ? title : error?.error;
                //   this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
            }
            });
    }

    public removePartner(name: string) {
        this.deletePartnerName = name;
        this.viewDeletePartnerConfirmWindowDisplayStyle = "block";
        this.viewDeletePartnerConfirmWindow = true;
    }

    public cancelDeletePartner() {
        this.viewDeletePartnerConfirmWindowDisplayStyle = "none";
        this.viewDeletePartnerConfirmWindow = false;
    }
    
    public deletePartner() {
        this.showSpinner = true; 

        this.ediService.deletePartner(this.deletePartnerName).subscribe(
        {
            next: (result) => {
                this.viewDeletePartnerConfirmWindowDisplayStyle = "none";
                this.viewDeletePartnerConfirmWindow = false;
                this.deletePartnerName = '';
                this.getPartners();
            },
            error: (error) => { 
                // TODO pick up error message and display.
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
                this.viewDeletePartnerConfirmWindowDisplayStyle = "none";
                this.viewDeletePartnerConfirmWindow = false;
                this.deletePartnerName = '';
                this.showSpinner = false;
            }
        });
    }

    public addPartner() {
        this.isNew = true;
        // Clear agreement on new
        this.partnerRequest = new PartnerRequest();
        this.partnerRequest.businessIdentities = new Array<BusinessIdentity>()
        this.viewUpsertPartnerDisplayStyle = "block";
    }

    public onCloseUpsertPartner() {
        this.viewUpsertPartnerDisplayStyle = "none";

        this.getPartners();
    }

    public editAgreement(value: Value) {
        this.isNew = false;
        this.showSpinner = true;
        this.partnerListDDL = this.ediService.getPartnerListDDL(this.partnerList);
        if(value.properties.agreementType === 'AS2') {
            this.ediService.getAs2Agreement(value.name).subscribe(
                {
                next: (result) => {
                    this.agreementRequest = this.ediService.getAs2AgreementRequest(result);
                    this.vewAddAgreementDisplayStyle = "block";
                    this.showSpinner = false;
                },
                error: (error) => { 
                    // TODO pick up error message and display.
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                    this.showSpinner = false;
                    //   let msg: string = '';
                    //   let title: string = error?.error?.title;
                    //   msg = (title !== undefined) ? title : error?.error;
                    //   this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
                }
                });
        }
        if(value.properties.agreementType === 'X12') {
            this.ediService.getX12Agreement(value.name).subscribe(
                {
                next: (result) => {
                    this.agreementRequest = this.ediService.getX12AgreementRequest(result);
                    this.vewAddAgreementDisplayStyle = "block";
                    this.showSpinner = false;
                },
                error: (error) => { 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    // TODO pick up error message and display.
                    console.error(error);
                    this.showSpinner = false;
                    //   let msg: string = '';
                    //   let title: string = error?.error?.title;
                    //   msg = (title !== undefined) ? title : error?.error;
                    //   this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
                }
                });
        }
        if(value.properties.agreementType === 'EDIFACT') {
            this.ediService.getEdifactAgreement(value.name).subscribe(
                {
                next: (result) => {
                    this.agreementRequest = this.ediService.getEdifactAgreementRequest(result);
                    this.vewAddAgreementDisplayStyle = "block";
                    this.showSpinner = false;
                },
                error: (error) => { 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    // TODO pick up error message and display.
                    console.error(error);
                    this.showSpinner = false;
                    //   let msg: string = '';
                    //   let title: string = error?.error?.title;
                    //   msg = (title !== undefined) ? title : error?.error;
                    //   this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
                }
                });
        }
    }

    public removeAgreement(value: Value) {
        this.deleteAgreementName = value.name;

        this.viewDeleteConfirmWindowDisplayStyle = "block";
        this.viewDeleteConfirmWindow = true;
    }

    public cancelDeleteAgreement() {
        this.viewDeleteConfirmWindowDisplayStyle = "none";
        this.viewDeleteConfirmWindow = false;
        // this.configurationToDelete = null;
    }
    
    public deleteAgreement() {
        this.showSpinner = true; 

        this.ediService.deleteAgreement(this.deleteAgreementName ).subscribe(
        {
            next: (result) => {
                this.viewDeleteConfirmWindowDisplayStyle = "none";
                this.viewDeleteConfirmWindow = false;
                this.deleteAgreementName = '';
                this.getData(1, 10);          
            },
            error: (error) => { 
                // TODO pick up error message and display.
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
                this.viewDeleteConfirmWindowDisplayStyle = "none";
                this.viewDeleteConfirmWindow = false;
                this.showSpinner = false;
            }
        });
    }
  
}
