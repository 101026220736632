import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';

@Injectable({
  providedIn: 'root',
})
export class KeyVaultService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor(private http: HttpClient) {
      this._http = http;
      this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getKeyVaultProfiles() {
      return this.http.get<Array<KeyVaultProfile>>(this.baseUrlLocal + 'bff/keyvaultprofiles');
    }
}
