<div class="modal-header">
    <h4 class="modal-title">Delete partner: {{partner.partnerId}}´s Inbound => Canonical mapping-connection</h4>
</div>

<div class="modal-body">
    Are you really sure you want to delete the connection between the Mapping {{ inboundToCanonicalMap.canonicalMapXslt.name }}, Partner {{partner.partnerId}}, Message type {{inboundToCanonicalMap.inboundMessageType.messageName}}?
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
