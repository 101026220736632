import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomerConfigurationsComponent } from './customer-configurations.component';
import { CustomerConfigurationsService } from './customer-configurations.service';
import { ConfigurationSelectionGridComponent } from './configuration-selection-grid/configuration-selection-grid.component';
import { ViewConfigurationModalModule } from 'src/app/common-components/view-configuration/view-configuration-modal.module';

@NgModule({
  imports:      [ 
    CommonModule, 
    FormsModule, 
    ViewConfigurationModalModule 
  ],
  providers:    [ CustomerConfigurationsService ],
  declarations: [ 
    CustomerConfigurationsComponent, 
    ConfigurationSelectionGridComponent 
  ],
  exports: [ ]
})
export class CustomerConfigurationsModule { }
