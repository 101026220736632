export class SimplifiedFlowConfigurationEntitymap {
    id: number;
    inboundPartnerId: number;
    inboundMessageTypeId: number;
    outboundPartnerId: number;
    outboundMessageTypeId: number;
    transformToCanonicalId: number;
    transformToOutboundId: number;
    createdBy: string;
    updatedBy: string;
    createTimestamp: string;
    updateTimestamp: string;
}