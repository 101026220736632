<h3 id="tableLabel">Administrate prestep classes</h3>

<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="errorModel">
    <b>Error when administrating partner. Error Message: {{errorModel.errorMessage}}</b>
</div>

<div>
    <table class='table table-striped table-bordered' aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th scope="col" colSpan="9">
              <div>
                <button class="btn btn-outline-secondary btn-sm" (click)="createPrestepClass()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
              </div>
            </th>
          </tr>
        </thead>
        <thead>
            <tr>
                <th class="vtop">
                  <div>Id</div>
                </th>
                <th>
                  <div>Prestep class</div>
                  <div>
                    <input type="text" class="form-control" id="prestepClasses" name="prestepClasses" placeholder="Filter prestep classes" [(ngModel)]="filterPrestepClasses" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                  </div>
                </th>
                <th>
                  <div>Description</div>
                  <div>
                    <input type="text" class="form-control" id="prestepClassesDescription" name="prestepClassesDescription" placeholder="Filter description" [(ngModel)]="filterDescription" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                  </div>
                </th>                      
                <th class="vtop">
                  <div>Created by</div>
                </th>  
                <th class="vtop">
                  <div>Created at</div>
                </th>  
                <th class="vtop">
                  <div>Updated by</div>
                </th>  
                <th class="vtop">
                  <div>Updated at</div>
                </th>
                <th>                
                </th>  
            </tr>
        </thead>
        <tbody *ngIf="gridView">
            <tr *ngFor="let p of gridView.rows;let index = index;">
                <td>
                  <span>{{p.id}}</span>
                </td>
                <td>
                  <span>{{ utilService.getClassName(p.preStepClass) }}</span>
                </td>            
                <td>
                  <span>{{p.description}}</span>
                </td>
                <td>
                  <span>{{p.createdBy}}</span>
                </td>
                <td>
                  <span>{{p.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss' }}</span>
                </td>   
                <td>
                  <span>{{p.updatedBy}}</span>
                </td>
                <td>
                  <span>{{p.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss' }}</span>
                </td>             
                <td>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="!isAdmin" (click)="editPrestepClass(p)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="!isAdmin" (click)="removePrestepClass(p)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                </td>
            </tr>
        </tbody>
        <tfoot>
          <tr *ngIf="gridView">
            <td colspan="11">
              <div>
                <table>
                  <tr>
                    <td style="margin: auto;">
                      <nav>
                        <ul class="pagination">
                          <li class="page-item" ng-class="{disabled: currentPage == 1}">
                            <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                          </li>
                          <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(prestepPaging.currentPage, 1, prestepPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(prestepPaging.currentPage, 1, prestepPaging.totalPages), 1)">{{pService.getPageNumber(prestepPaging.currentPage, 1, prestepPaging.totalPages)}}</a></li>
                          <li class="page-item" *ngIf="prestepPaging.totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(prestepPaging.currentPage, 2, prestepPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(prestepPaging.currentPage, 2, prestepPaging.totalPages), 2)">{{pService.getPageNumber(prestepPaging.currentPage, 2, prestepPaging.totalPages)}}</a></li>
                          <li class="page-item" *ngIf="prestepPaging.totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(prestepPaging.currentPage, 3, prestepPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(prestepPaging.currentPage, 3, prestepPaging.totalPages), 3)">{{pService.getPageNumber(prestepPaging.currentPage, 3, prestepPaging.totalPages)}}</a></li>
                          <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                            <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                          </li>
                        </ul>
                        </nav>
                    </td>
                    <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                      <label>{{(((prestepPaging.currentPage) - 1) * prestepPaging.pageCount) + 1}} - {{prestepPaging.lastDisplayIndex}} of {{gridView.totalRows}}</label>
                    </td>
                    <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;"> 
                      <select dropdown (change)="selectChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="prestepPaging.pageCount">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </td>
                  </tr>                  
                </table>
              </div>
            </td>
          </tr>
        </tfoot>
    </table>
  </div>