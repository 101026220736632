<h3 id="tableLabel">Administrate message types</h3>

<!-- <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div> -->

<div class="mt10">
  <ul ngbNav #nav="ngbNav" [(activeId)]="tabModel.activeTab" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="onclickGoToTab(1)">Inbound message types</a>
      <ng-template ngbNavContent>
        <div class="ml5">
          <div class="row">
            <div class="col-12">
                <div style="margin-bottom: 10px;">
                  <!-- Add inbound message types -->
                  <md-inbound-message-types></md-inbound-message-types>
                </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink (click)="onclickGoToTab(2)">Outbound message types</a>
      <ng-template ngbNavContent>
        <div class="ml5">
          <div class="row">
            <div class="col-12">
                <div style="margin-bottom: 10px;">
                  <md-outbound-message-types></md-outbound-message-types>
                </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>    
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>