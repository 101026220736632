import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Scheduler } from 'src/app/models/source/scheduler';
import { MDSourceInputService } from './md-source-input.service';
import { FormGroup } from '@angular/forms';
import { Partner } from 'src/app/models/partner/partner';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'edit-partner-source',
  templateUrl: './edit-source-model.component.html'
})
export class EditSourceModalComponent implements OnInit {
    @Input() partner: Partner;
    @Input() scheduler: Scheduler;

    public inboundPartnerSourceForm: FormGroup;
    public errorModel: ErrorModel = null;
    public isEdit: boolean = true;
    public showSpinner: boolean = false;    
    public keyVaultProfiles: Array<KeyVaultProfile>;    
    public inboundMessageTypesDDL: DropDownListModel[];

    public ediTypes: Array<DropDownListModel> = [
        { value: '1', text: 'EDIFACT' },
        { value: '2', text: 'X12' }
      ];

    constructor(public activeModal: NgbActiveModal, public sourceInputService: MDSourceInputService, private errorParserService: ErrorParserService) {}

    get form() { return this.inboundPartnerSourceForm.controls; }

    ngOnInit(): void {
        this.inboundPartnerSourceForm = this.sourceInputService.getInboundPartnerSourceForm(this.partner.id, this.scheduler);

        this.sourceInputService.getAllInboundMessageTypes().subscribe(
            {
                next: (result) => {
                  this.inboundMessageTypesDDL = new Array<DropDownListModel>();
                  result.forEach(x => {
                      let d: DropDownListModel = {
                          value: x.inboundMessageTypeId.toString(),
                          text: `${x.messageTypeDescription} (${x.messageName})`
                        };
                        if(x.inboundMessageTypeId != 0){
                          this.inboundMessageTypesDDL.push(d);
                        }
                  });
                },
                error: (error) => {
                    this.showSpinner = false;
                    console.error(error);
                }
              }
          );

         this.showSpinner = false;
    }

    public save() {
        this.showSpinner = true;
        let scheduler: Scheduler = this.sourceInputService.getSchedulerRequestModel(this.inboundPartnerSourceForm);
        this.sourceInputService.setupProfileRequestModel(this.inboundPartnerSourceForm, scheduler, true);
       
        this.sourceInputService.updateScheduler(scheduler).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
            }
        );
    }

    selectInboundMessageTypeChangeHandler(event: any) {
        let messageTypeId = this.inboundPartnerSourceForm.get('inboundMessageType').value;    
      }

      selectIsSimplifiedChangeHandler(event: any) {        
        
        if(this.inboundPartnerSourceForm.value.isSimplified === true)
        {          
          this.sourceInputService.getAllInboundMessageTypes().subscribe(
            {
                next: (result) => {
                  this.inboundMessageTypesDDL = new Array<DropDownListModel>();
                result.forEach(x => {
                    let d: DropDownListModel = {
                        value: x.inboundMessageTypeId.toString(),
                        text: `${x.messageTypeDescription} (${x.messageName})`
                      };
                      if(x.inboundMessageTypeId != 0){
                        this.inboundMessageTypesDDL.push(d);
                      }
                      
                });
                },
                error: (error) => {
                    this.showSpinner = false;
                    console.error(error);
                }
              }
          );
          var temp = this.inboundPartnerSourceForm.value.inboundMessageTypeId;
          this.inboundPartnerSourceForm.patchValue({inboundMessageTypeId:temp})
        }
        else{
          this.inboundPartnerSourceForm.patchValue({inboundMessageTypeId:''})
          this.scheduler.inboundMessageTypeId = 0;
        }
        this.sourceInputService.changeValidators(this.inboundPartnerSourceForm);
      }   
}
