import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { Scheduler } from 'src/app/models/source/scheduler';
import { MDSourceInputService } from './md-source-input.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';
import { Partner } from 'src/app/models/partner/partner';

@Component({
  selector: 'add-partner-source',
  templateUrl: './add-source-model.component.html'
})
export class AddSourceModalComponent implements OnInit {
  @Input() partner: Partner;

  public inboundPartnerSourceForm: FormGroup;
  public errorModel: ErrorModel = null;
  public showSpinner: boolean = false;
  public inboundMessageTypesDDL: DropDownListModel[];
  public ediType: string = '';

  public ediTypes: Array<DropDownListModel> = [
    { value: '1', text: 'EDIFACT' },
    { value: '2', text: 'X12' }
  ];


  constructor(public activeModal: NgbActiveModal, public sourceInputService: MDSourceInputService, private errorParserService: ErrorParserService) { }
  
  get form() { return this.inboundPartnerSourceForm.controls; }

  ngOnInit(): void {  
    this.inboundPartnerSourceForm = this.sourceInputService.getEmptyInboundPartnerSourceForm(this.partner.id);  
  }

  public save() {
    this.showSpinner = true;
    let scheduler: Scheduler = this.sourceInputService.getSchedulerRequestModel(this.inboundPartnerSourceForm);
    this.sourceInputService.setupProfileRequestModel(this.inboundPartnerSourceForm, scheduler, false);

    this.sourceInputService.addScheduler(scheduler).subscribe(
      {
        next: (result) => {
          this.showSpinner = false; 
          this.activeModal.close('save');
        },
        error: (error) => {
          this.showSpinner = false; 
          this.errorModel = this.errorParserService.extractErrors(error);
          console.error(error);
        }
      }
    );
  }

  selectIsSimplifiedChangeHandler(event: any) {
    if(this.inboundPartnerSourceForm.value.isSimplified === true)
    {
      this.sourceInputService.getAllInboundMessageTypes().subscribe(
        {
            next: (result) => {
              this.inboundMessageTypesDDL = new Array<DropDownListModel>();
            result.forEach(x => {
                let d: DropDownListModel = {
                    value: x.inboundMessageTypeId.toString(),
                    text: `${x.messageTypeDescription} (${x.messageName})`
                  };
                  if(x.inboundMessageTypeId != 0){
                    this.inboundMessageTypesDDL.push(d);
                  }
                  
            });
            },
            error: (error) => {
                this.showSpinner = false;
                console.error(error);
            }
          }
      );      
      var temp = this.inboundPartnerSourceForm.value.inboundMessageTypeId;
      this.inboundPartnerSourceForm.patchValue({inboundMessageTypeId:temp})
    }
    else{
      this.inboundPartnerSourceForm.patchValue({inboundMessageTypeId:''})
    }
    this.sourceInputService.changeValidators(this.inboundPartnerSourceForm);
  }

  selectProtocolChangeHandler(event: any) {
    this.sourceInputService.setupSourceFormGroup(this.inboundPartnerSourceForm)    
  }
}
