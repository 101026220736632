import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { Partner } from 'src/app/models/partner/partner';
import { ResolverClass } from 'src/app/models/resolver/resolver-class';
import { GridView } from 'src/app/models/grid-view';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { PartnerModel } from './models/partner-model';
// import { ResolverClass } from 'src/app/models/resolver/resolver-class';
// import { PartnerRequestModel } from './models/partner-request-model';
// import { Resolver } from 'src/app/models/resolver/resolver';

@Injectable({
  providedIn: 'root',
})
export class MasterDataResolverService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getResolverClasses(pageindex: number, pagesize: number, resolver: string, description: string): Observable<GridView<ResolverClass>> {
        let queryStart = '';
        let queryString = '';
        
        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)

        if(resolver != undefined && resolver.length > 2) {
            queryString = queryString.concat(`${queryStart}resolver=${resolver}`)
        }
        if(description != undefined && description.length > 2) {
            queryString = queryString.concat(`${queryStart}description=${description}`)
        }
        
        return this.http.get<GridView<ResolverClass>>(this.baseUrlLocal + `resolverclasses` + queryString );
    }

    public addResolverClass(resolverclasses: ResolverClass) {
        return this.http.post(this.baseUrlLocal + 'resolverclasses', resolverclasses);
    }

    public updateResolverClass(resolverclasses: ResolverClass) {
        return this.http.put(this.baseUrlLocal + 'resolverclasses', resolverclasses);
    }

    public deleteResolverClass(resolverclasses: ResolverClass) {
        const options = {
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            }),
            body: resolverclasses
        };
        return this.http.delete(this.baseUrlLocal + 'resolverclasses', options);
    }

    private getQueryStart(queryStart: string) {
        if(queryStart === '') {
            return  '?';
        } else {
            return '&';
        }
    }

    public getResolverClassForm(resolverClass: ResolverClass) {
        return new FormGroup({
            id: new FormControl(resolverClass.id, Validators.required),
            resolver: new FormControl(resolverClass.resolver, Validators.required),
            description: new FormControl(resolverClass.description, Validators.required)
        });
    }

    public getEmptyResolverClassForm() {
        return new FormGroup({
            id: new FormControl(0, Validators.required),
            resolver: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required)
        });
    }

    public getResolverClassModel(formGroup: FormGroup) {
        let resolverClass: ResolverClass = new ResolverClass();
        resolverClass.id = formGroup.value.id;
        resolverClass.resolver = formGroup.value.resolver;
        resolverClass.description = formGroup.value.description;
        return resolverClass;
    }
}


