import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WizardResponseModel } from 'src/app/models/wizard-response-model';
import { environment } from '../../../../../environments/environment';
import { InboundParser } from 'src/app/models/parser/parser';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class MDInboundParsersInputService {
  public baseUrlLocal: string = "";
  constructor(private http: HttpClient) {
    this.baseUrlLocal = environment.apiBaseUrl;
  }

  public getParserBaseData() {
    return this.http.get<WizardResponseModel>(this.baseUrlLocal + 'bff/parserconfigurationdata');
  }

  public addParserPartnerConnection(inboundParser: InboundParser) {
    return this.http.post(this.baseUrlLocal + 'inboundparser/partnerconnection', inboundParser);
  }

  public updateParserPartnerConnection(inboundParser: InboundParser) {
    return this.http.put(this.baseUrlLocal + 'inboundparser/partnerconnection', inboundParser);
  }

  public deleteParserPartnerConnection(inboundParser: InboundParser) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: inboundParser
    };
    return this.http.delete(this.baseUrlLocal + 'inboundparser/partnerconnection', options);
  }

  public getEmptyInboundParserFormWithDynamicParser(partnerId: number, xPathParserId: number) {
    return new FormGroup({
        id: new FormControl(0, Validators.required),
        partner: new FormControl(partnerId, Validators.required),
        messageType: new FormControl('', Validators.required),
        useDynamicParser: new FormControl(true),
        parserClassId: new FormControl(xPathParserId, Validators.required),
        type: new FormControl(2, Validators.required),
        senderIdPath: new FormControl('', Validators.required),
        receiverIdPath: new FormControl('', Validators.required),
        primaryIdPath: new FormControl('', Validators.required),
        referenceIdPath: new FormControl('', Validators.required),
        taskIdPath: new FormControl(''),
        countryCodePath: new FormControl(''),
        customLookupIdPath: new FormControl(''),
        areaPlantIdPath: new FormControl(''),
        namespacesUri: new FormControl('')
    });
  }

  public getEmptyInboundParserFormNoDynamicParser(partnerId: number) {
    return new FormGroup({
        id: new FormControl(0, Validators.required),
        partner: new FormControl(partnerId, Validators.required),
        messageType: new FormControl('', Validators.required),
        useDynamicParser: new FormControl(false),
        parserClassId: new FormControl('', Validators.required),
        type: new FormControl(1, Validators.required),
    });
  }

  public getInboundParserRequestModel(inboundParserForm: FormGroup, useDynamicParser: boolean, xPathParserId: number) : InboundParser {
    let inboundParser = new InboundParser();
    inboundParser.id = inboundParserForm.value.id;
    inboundParser.partner = inboundParserForm.value.partner;
    inboundParser.messageType = inboundParserForm.value.messageType;
    //inboundParser.type = this.inboundParserForm.value.type;

    if(useDynamicParser) {
      inboundParser.senderIdPath = inboundParserForm.value.senderIdPath;
      inboundParser.receiverIdPath = inboundParserForm.value.receiverIdPath;
      inboundParser.primaryIdPath = inboundParserForm.value.primaryIdPath;
      inboundParser.referenceIdPath = inboundParserForm.value.referenceIdPath;
      inboundParser.taskIdPath = inboundParserForm.value.taskIdPath;
      inboundParser.countryCodePath = inboundParserForm.value.countryCodePath;
      inboundParser.customLookupIdPath = inboundParserForm.value.customLookupIdPath;
      inboundParser.areaPlantIdPath = inboundParserForm.value.areaPlantIdPath;
      inboundParser.namespacesUri = inboundParserForm.value.namespacesUri;
      inboundParser.parserClassId = xPathParserId;
    } else {
      inboundParser.parserClassId = inboundParserForm.value.parserClassId;
    }

    return inboundParser;
  }

  public getInboundParserFormNoDynamicParser(inboundParser: InboundParser) {
    return new FormGroup({
      id: new FormControl(inboundParser.id, Validators.required),
      partner: new FormControl(inboundParser.partner, Validators.required),
      messageType: new FormControl(inboundParser.messageType, Validators.required),
      useDynamicParser: new FormControl(false),
      parserClassId: new FormControl(inboundParser.parserClassId, Validators.required)      
    });
  }

  public getInboundParserFormWithDynamicParser(inboundParser: InboundParser) {
    return new FormGroup({
      id: new FormControl(inboundParser.id, Validators.required),
      partner: new FormControl(inboundParser.partner, Validators.required),
      messageType: new FormControl(inboundParser.messageType, Validators.required),
      useDynamicParser: new FormControl(true),
      parserClassId: new FormControl(inboundParser.parserClassId, Validators.required),
      senderIdPath: new FormControl(inboundParser.senderIdPath, Validators.required),
      receiverIdPath: new FormControl(inboundParser.receiverIdPath, Validators.required),
      primaryIdPath: new FormControl(inboundParser.primaryIdPath, Validators.required),
      referenceIdPath: new FormControl(inboundParser.referenceIdPath, Validators.required),
      taskIdPath: new FormControl(inboundParser.taskIdPath),
      countryCodePath: new FormControl(inboundParser.countryCodePath),
      customLookupIdPath: new FormControl(inboundParser.customLookupIdPath),
      areaPlantIdPath: new FormControl(inboundParser.areaPlantIdPath),
      namespacesUri: new FormControl(inboundParser.namespacesUri)
    });
  }
}
