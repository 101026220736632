<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div>
<div class="row mb5" *ngIf="!isSuperUser">
  <b>Note: In order to add, edit and remove inbound message types the Super User role is required</b>
</div>
<div>
    <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="imtGridView">
        <thead>
          <tr>
            <th scope="col" colSpan="9">
              <div>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="!isSuperUser" (click)="createNewInboundMessageType()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
              </div>
            </th>
          </tr>
        </thead>
        <thead>
            <tr>
                <th class="vtop">
                  <div>Id</div>
                </th>
                <th>
                  <div>Message type</div>
                  <div>
                    <input type="text" class="form-control" id="messageTypeName" name="messageTypeName" placeholder="Filter message type name" [(ngModel)]="filterMessageTypeName" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                  </div>
                </th>
                <th>
                  <div>Description</div>
                  <div>
                    <input type="text" class="form-control" id="messageTypeDescription" name="messageTypeDescription" placeholder="Filter description" [(ngModel)]="filterMessageTypeDescription" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                  </div>
                </th>                      
                <th class="vtop">
                  <div>Created by</div>
                </th>  
                <th class="vtop">
                  <div>Created at</div>
                </th>  
                <th class="vtop">
                  <div>Updated by</div>
                </th>  
                <th class="vtop">
                  <div>Updated at</div>
                </th>
                <th>                
                </th>  
            </tr>
        </thead>
        <tbody *ngIf="messageTypeGridList">
            <tr *ngFor="let imt of imtGridView.rows;let index = index;">
                <td>
                  <span>{{imt.inboundMessageTypeId}}</span>
                </td>
                <td>
                  <span>{{imt.messageName}}</span>
                </td>            
                <td>
                  <span>{{imt.messageTypeDescription}}</span>
                </td>
                <td>
                  <span>{{imt.createdBy}}</span>                
                </td>
                <td>
                  <span>{{imt.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>                
                </td>   
                <td>
                  <span>{{imt.updatedBy}}</span>                
                </td>
                <td>
                  <span>{{imt.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>                
                </td>             
                <td>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="!isSuperUser" (click)="editInboundMessageType(imt)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="!isSuperUser" (click)="removeInboundMessageType(imt)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                </td>
            </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="11">
              <div>
                <table>
                  <tr>
                    <td style="margin: auto;">
                      <nav>
                        <ul class="pagination">
                          <li class="page-item" ng-class="{disabled: currentPage == 1}">
                            <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                          </li>
                          <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(inboundMessageTypesPaging.currentPage, 1, inboundMessageTypesPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(inboundMessageTypesPaging.currentPage, 1, inboundMessageTypesPaging.totalPages), 1)">{{pService.getPageNumber(inboundMessageTypesPaging.currentPage, 1, inboundMessageTypesPaging.totalPages)}}</a></li>
                          <li class="page-item" *ngIf="inboundMessageTypesPaging.totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(inboundMessageTypesPaging.currentPage, 2, inboundMessageTypesPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(inboundMessageTypesPaging.currentPage, 2, inboundMessageTypesPaging.totalPages), 2)">{{pService.getPageNumber(inboundMessageTypesPaging.currentPage, 2, inboundMessageTypesPaging.totalPages)}}</a></li>
                          <li class="page-item" *ngIf="inboundMessageTypesPaging.totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(inboundMessageTypesPaging.currentPage, 3, inboundMessageTypesPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(inboundMessageTypesPaging.currentPage, 3, inboundMessageTypesPaging.totalPages), 3)">{{pService.getPageNumber(inboundMessageTypesPaging.currentPage, 3, inboundMessageTypesPaging.totalPages)}}</a></li>
                          <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                            <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                          </li>
                        </ul>
                        </nav>
                    </td>
                    <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                      <label>{{(((inboundMessageTypesPaging.currentPage) - 1) * inboundMessageTypesPaging.pageCount) + 1}} - {{inboundMessageTypesPaging.lastDisplayIndex}} of {{imtGridView.totalRows}}</label>
                    </td>
                    <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;"> 
                      <select dropdown (change)="selectChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="inboundMessageTypesPaging.pageCount">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </td>
                  </tr>                  
                </table>
              </div>
            </td>
          </tr>
        </tfoot>
    </table>
  </div>