<div class="modal-header">
    <h4 class="modal-title">Delete simplified flow configuration for inbound partner {{ simplifiedFlowConfiguration.inboundPartnerName}}</h4>
</div>

<div class="modal-body">
    <div class="alert alert-danger" role="alert" *ngIf="errorModel">
        <b>Error when deleting simplified flow configuration. Error Message: {{errorModel.errorMessage}}</b>
    </div>
    <div>
        <div class="mb10">Are you really sure you want to delete the following simplified flow configuration:</div>
        <row-display [label]="'Inbound partner:'" [value]="simplifiedFlowConfiguration.inboundPartnerName"></row-display>
        <row-display [label]="'Inbound message type:'" [value]="simplifiedFlowConfiguration.inboundMessageTypeName"></row-display>
        <row-display [label]="'Outbound partner:'" [value]="simplifiedFlowConfiguration.outboundPartnerName"></row-display>
        <row-display [label]="'Outbound message type:'" [value]="simplifiedFlowConfiguration.outboundMessageTypeName"></row-display>
        <!-- {{ simplifiedFlowConfiguration.inboundPartnerName }}? -->
    </div>    
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
