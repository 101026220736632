import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Configuration } from 'src/app/models/configuration';
import { EntityMap } from 'src/app/models/entity-map';
import { CustomerConfiguration } from '../../models/customer-configuration';
import { CustomerConfigurationsService } from './customer-configurations.service';
import { environment } from '../../../environments/environment';
import { PagingService } from 'src/app/common-services/paging.service';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewConfigurationModalComponent } from 'src/app/common-components/view-configuration/view-configuration-modal.component';
import { Paging } from 'src/app/models/paging/paging';

@Component({
  selector: 'app-customer-configurations',
  templateUrl: './customer-configurations.component.html'
})
export class CustomerConfigurationsComponent implements OnInit {
  public isReader: boolean = false;
  public originalCustomerConfigurations: CustomerConfiguration[] = [];
  public allCustomerConfigurations: CustomerConfiguration[] = [];
  public pagedCustomerConfigurations: CustomerConfiguration[] = [];
  public configurationToDelete: Configuration;
  public ccLength: number = 0;
  public error: boolean = false;
  public errorMessage: string = '';
  public showSpinner: boolean = true;
  public configurationCount: number = 0;

  public configurationPaging: Paging = new Paging();

  public prevenv: string = '';

  public filterPartner: string;
  public filterSender: string;
  public filterSenderId: string;
  public filterMessageTypeName: string;
  public filterReceiver: string;
  public filterReceiverId: string;
  public filterLegalEntity: string;
  public filterAreaPlant: string;

  // --- Use template 
  public useTemplate: boolean = false;
  public viewConfigTypeSelection: boolean = false;
  public viewConfigTypeSelectionDisplayStyle: string = 'none';

  public closeConfigTypeSelectionPopup() {
    this.viewConfigTypeSelectionDisplayStyle = "none";
  }

  constructor(
    public ccService: CustomerConfigurationsService,
    public pService: PagingService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: MsalService,
    private modalService: NgbModal
  ) {
    this.route.queryParams.subscribe(params => {
      this.filterSender = params['sender'];
      this.filterReceiver = params['receiver'];
    });
  }

  ngOnInit(): void {
    let allAccounts = this.authService.instance.getAllAccounts();
    if (allAccounts.length > 0) {
      let account = allAccounts[0];
      this.isReader = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Reader') == undefined ? false : true;
    }

    this.prevenv = environment.prevenv;
    this.getData(1, 10);
  }

  private getData(page: number, pageCount: number) {
    this.error = false;
    this.errorMessage = '';
    this.ccService.getData(false, 1, 10).subscribe(
    {
      next: (result) => {
        this.configurationCount = result.length;
        this.allCustomerConfigurations = result;
        this.originalCustomerConfigurations = result;
        this.pagedCustomerConfigurations = result.slice(this.configurationPaging.startIndex, this.configurationPaging.endIndex);
        
        this.configurationPaging.lastDisplayIndex = (this.configurationPaging.currentPage) * this.configurationPaging.pageCount;
        this.configurationPaging.totalPages = Math.floor(this.configurationCount/this.configurationPaging.pageCount) + (this.configurationCount % this.configurationPaging.pageCount > 0 ? 1 : 0);

        this.onFilter();

        this.showSpinner = false; 
      },
      error: (error) => { 
          // TODO pick up error message and display.
          if(error.message != undefined) {
            this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${error.message}`;
          } else {
            this.showSpinner = false;
            let msg: string = '';
            let title: string = error?.error?.title;
            msg = (title !== undefined) ? title : error?.error;
            this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
          }
          console.error(error);
          this.error = true;
      }
    });
  }

  // --- View config -------------------------------------------------------------------
  public vewConfigurationDisplayStyle: string = "none";
  public viewConfigData: Configuration;

  public viewConfig(cc: CustomerConfiguration) {
    const modalRef = this.modalService.open(ViewConfigurationModalComponent, { size: 'size_80' });
    modalRef.componentInstance.entityMapId = cc.entityMapId;
  }
 
  public onCloseViewConfiguration() {
    this.vewConfigurationDisplayStyle = "none";
  }

  // --- Paging ----------------------------------------------------------------------------------  
  public onclickPrev() {
    if (this.configurationPaging.currentPage > 1) {
      this.configurationPaging.currentPage = this.configurationPaging.currentPage - 1;
      this.configurationPaging.setStartEndIndex();
      this.configurationPaging.lastDisplayIndex = (this.configurationPaging.currentPage) * (+this.configurationPaging.pageCount);
      this.pagedCustomerConfigurations = this.allCustomerConfigurations.slice(this.configurationPaging.startIndex, this.configurationPaging.endIndex);
    }
  }

  public onclickNext() {
    if ((this.configurationPaging.currentPage) * (+this.configurationPaging.pageCount) < this.configurationCount) {
      this.configurationPaging.nextButtonDisabled = false;
      this.configurationPaging.currentPage = this.configurationPaging.currentPage + 1;
      this.configurationPaging.setStartEndIndex();

      if((this.configurationPaging.currentPage) * (+this.configurationPaging.pageCount) > this.configurationCount) {
        this.configurationPaging.lastDisplayIndex = this.configurationCount;
      } else {
        this.configurationPaging.lastDisplayIndex = (this.configurationPaging.currentPage) * (+this.configurationPaging.pageCount);        
      }

      this.pagedCustomerConfigurations = this.allCustomerConfigurations.slice(this.configurationPaging.startIndex, this.configurationPaging.endIndex);
    } else {
      this.configurationPaging.nextButtonDisabled = true;
    }
  }

  public onclickPage(page: number, index: number) {
    this.configurationPaging.currentPage = this.configurationPaging.currentPage === page ? this.configurationPaging.currentPage : page;
    this.configurationPaging.setStartEndIndex();
    if((this.configurationPaging.currentPage) * (+this.configurationPaging.pageCount) > this.configurationCount) {
      this.configurationPaging.lastDisplayIndex = this.configurationCount;
    } else {
      this.configurationPaging.lastDisplayIndex = (this.configurationPaging.currentPage) * (+this.configurationPaging.pageCount);        
    }
    this.pagedCustomerConfigurations = this.allCustomerConfigurations.slice(this.configurationPaging.startIndex, this.configurationPaging.endIndex);
  }

  // private setStartEndIndex() {
  //   this.startIndex = +((this.currentPage - 1) * (+this.pageCount));
  //   this.endIndex = +((this.currentPage - 1) * (+this.pageCount)) + (+this.pageCount);
  // }

  selectPagingChangeHandler(event: any) {
    this.configurationPaging.currentPage = 1;
    let locPageCount = (+this.configurationPaging.pageCount);
    this.configurationPaging.setStartEndIndex();    
    this.onFilter();
  }

  // --- Paging ----------------------------------------------------------------------------------  

  public createNewConfig() {
    if(this.useTemplate) {
      this.viewConfigTypeSelection = true;
      this.viewConfigTypeSelectionDisplayStyle = 'block';
    } else {
      this.router.navigateByUrl('/new-customer-configuration');
    }
  }

  public onSelectedCustomerConfiguration(cc: CustomerConfiguration) {
    this.viewConfigTypeSelection = false;
      this.viewConfigTypeSelectionDisplayStyle = 'none';
      this.router.navigateByUrl(`/new-customer-configuration?isTemplate=true&partnerId=${cc.partnerId}&messageTypeId=${cc.messageTypeId}&sender=${cc.senderId}&receiver=${cc.receiverId}&legalEntityId=${cc.legalEntityId}&areaPlantId=${cc.areaPlantId}`);
  }

  public editConfig(cc: CustomerConfiguration) {
    cc.entityMapId

    this.router.navigateByUrl(`/new-customer-configuration?isEdit=true&entityMapId=${cc.entityMapId}`);
  }

  public copyConfig(cc: CustomerConfiguration) {
    this.router.navigateByUrl(`/new-customer-configuration?isCopy=true&entityMapId=${cc.entityMapId}`);
  }

  // --- View delete -------------------------------------------------------------------
  public viewDeleteConfirmWindowDisplayStyle: string = "none";
  public viewDeleteConfirmWindow: boolean = false;

  public removeConfig(cc: CustomerConfiguration) {
    this.showSpinner = true;
    this.ccService.getConfiguration(cc.entityMapId).subscribe(
      {
        next: (result) => {
          this.viewDeleteConfirmWindowDisplayStyle = "block";
          this.viewDeleteConfirmWindow = true;
          this.configurationToDelete = result;
          this.showSpinner = false;
        },
        error: (error) => { 
            // TODO pick up error message and display.
            this.error = true;
            let msg: string = '';
            let title: string = error?.error?.title;
            msg = (title !== undefined) ? title : error?.error;
            this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
            console.error(error); 
            this.showSpinner = false;
        }
      });
  }

  public cancelDeleteConfiguration() {
    this.viewDeleteConfirmWindowDisplayStyle = "none";
    this.viewDeleteConfirmWindow = false;
    this.configurationToDelete = null;
  }

  public deleteConfiguration() {
    this.showSpinner = true; 
    let entityMap: EntityMap = new EntityMap();
    entityMap = this.configurationToDelete.entityMap

    this.ccService.deleteConfiguration(entityMap).subscribe(
      {
        next: (result) => {
          this.viewDeleteConfirmWindowDisplayStyle = "none";
          this.viewDeleteConfirmWindow = false;
          this.configurationToDelete = null;
          this.getData(1, 10);          
        },
        error: (error) => { 
            // TODO pick up error message and display.
            this.error = true;
            let title: string = error?.error?.title;
            this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${title}`;

            // if (error.error instanceof ErrorEvent) {
            //   // A client-side or network error occurred. Handle it accordingly.
            //   console.error('An error occurred:', error.error.message);
            // } else {
            //   // The backend returned an unsuccessful response code.
            //   // The response body may contain clues as to what went wrong,
            //   console.error(
            //     `Backend returned code ${error.status}, ` +
            //     `body was: ${error.error}`);
            // }
            // return an observable with a user-facing error message
            // return throwError(
            //   'Something bad happened; please try again later.');

            //console.error(error);
            this.viewDeleteConfirmWindowDisplayStyle = "none";
            this.viewDeleteConfirmWindow = false;
            this.configurationToDelete = null;
            this.showSpinner = false;
        }
      });

    
  }

  public onFilter() {
    this.allCustomerConfigurations = this.originalCustomerConfigurations;      

    if(this.filterPartner !== undefined && this.filterPartner.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.partnerName.toLowerCase().includes(this.filterPartner.toLowerCase()));
    }
    if(this.filterSender !== undefined && this.filterSender.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.senderName.toLowerCase().includes(this.filterSender.toLowerCase()));
    }
    if(this.filterSenderId !== undefined && this.filterSenderId.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.senderId.toLowerCase().includes(this.filterSenderId.toLowerCase()));
    }
    if(this.filterReceiver !== undefined && this.filterReceiver.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.receiverName.toLowerCase().includes(this.filterReceiver.toLowerCase()));
    }
    if(this.filterReceiverId !== undefined && this.filterReceiverId.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.receiverId.toLowerCase().includes(this.filterReceiverId.toLowerCase()));
    }
    if(this.filterMessageTypeName !== undefined && this.filterMessageTypeName.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.messageName.toLowerCase().includes(this.filterMessageTypeName.toLowerCase()));
    }
    if(this.filterLegalEntity !== undefined && this.filterLegalEntity.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.legalEntityName.toLowerCase().includes(this.filterLegalEntity.toLowerCase()));
    }
    if(this.filterAreaPlant !== undefined && this.filterAreaPlant.length > 0) {
      this.allCustomerConfigurations = this.allCustomerConfigurations.filter((cat) => cat.areaPlantName.toLowerCase().includes(this.filterAreaPlant.toLowerCase()));
    }
    
    if((this.filterPartner === undefined || this.filterPartner === "") && 
      (this.filterSender === undefined || this.filterSender === "") && 
      (this.filterSenderId === undefined || this.filterSenderId === "") && 
      (this.filterReceiver === undefined || this.filterReceiver === "") && 
      (this.filterReceiverId === undefined || this.filterReceiverId === "") && 
      (this.filterLegalEntity === undefined || this.filterLegalEntity === "") && 
      (this.filterAreaPlant === undefined || this.filterAreaPlant === "") && 
      (this.filterMessageTypeName === undefined || this.filterMessageTypeName === "")) {
      this.allCustomerConfigurations = this.originalCustomerConfigurations;      
    }

    this.configurationPaging.currentPage = 1;
    this.configurationPaging.setStartEndIndex();

    this.pagedCustomerConfigurations = this.allCustomerConfigurations.slice(this.configurationPaging.startIndex, this.configurationPaging.endIndex);
    this.configurationPaging.lastDisplayIndex = this.allCustomerConfigurations.length >= this.configurationPaging.pageCount ? (this.configurationPaging.currentPage) * this.configurationPaging.pageCount : this.allCustomerConfigurations.length;
    this.configurationCount = this.allCustomerConfigurations.length;   
    this.configurationPaging.totalPages = Math.floor(this.configurationCount/this.configurationPaging.pageCount) + (this.configurationCount % this.configurationPaging.pageCount > 0 ? 1 : 0);
  }
}
