import { Component, Input, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Scheduler } from 'src/app/models/source/scheduler';
import { Source } from 'src/app/models/source/source';
import { UtilService } from 'src/app/common-services/util.service';
import { SourceService } from './source.service';
import { Partner } from 'src/app/models/partner/partner';

@Component({
  selector: 'source-view',
  templateUrl: './source.component.html'
})
export class SourceViewComponent implements OnInit {
    @Input() partnerId: string;
    @Input() schedulers: Array<Scheduler>;
    @Input() existingSchedulers: Array<Scheduler>;
    @Input() keyVaultProfiles: Array<KeyVaultProfile>;

    public sources: Array<Source> = new Array<Source>();

    constructor(public utilService: UtilService, public sourceService: SourceService) {}

    ngOnInit(): void {
        this.existingSchedulers.forEach(x => {
            this.sources.push(x.source);
        });
    }
}
