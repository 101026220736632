import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InboundMessageType } from '../../../../models/message-type';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'messagetype-input',
  templateUrl: './messagetype-input.component.html',
})
export class MessagetypeInputComponent {
  @Input() messageTypes: Array<InboundMessageType>;
  @Input() formGroupName: string;
  @Output() messageTypeUpdated = new EventEmitter<any>();  
  public inboundMessageTypeForm: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.inboundMessageTypeForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  get form() { return this.inboundMessageTypeForm.controls; }

  selectChangeHandler(event: any) {
    let selectedMessageType = this.messageTypes.find(x => x.inboundMessageTypeId === +event.target.value);
    this.inboundMessageTypeForm.controls['messageName'].setValue(selectedMessageType.messageName);
    this.inboundMessageTypeForm.controls['messageTypeDescription'].setValue(selectedMessageType.messageTypeDescription);
    this.messageTypeUpdated.emit();
  }
}
