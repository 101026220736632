import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MDOutboundMappingConnectionInputService } from './md-canonicalToOutboundMappingConnection-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { UtilService } from 'src/app/common-services/util.service';
import { OutboundMap } from 'src/app/models/mapping/outbound-map';

@Component({
  selector: 'delete-canonicalToOutboundMappingConnection-modal',
  templateUrl: './delete-canonicalToOutboundMappingConnection-modal.component.html',
})
export class DeleteOutboundMappingConnectionModalComponent{
  @Input() partner: Partner;
  @Input() canonicalToOutboundMap: OutboundMap;
  public showSpinner: boolean = false;
  public errorMessage: string = '';

  constructor(public activeModal: NgbActiveModal, public utilService: UtilService, public outboundMappingConnectionInputService: MDOutboundMappingConnectionInputService) {}

  public delete() {
    this.showSpinner = true;
    this.outboundMappingConnectionInputService.deletePartnerCanonicalToOutboundMappingConnection(this.canonicalToOutboundMap).subscribe(
      {
        next: (result) => {
              this.activeModal.close('delete');
        },
        error: (error) => {
          let error2 = error;
            this.displayErrorMessage(error);
        }
      }
    );
  }

  private displayErrorMessage(error: any) {
    let formattedError = '';
    if(error?.error && error?.error.error == undefined) {
        if(error.error.includes('at al.goh.admin.tool.api')) {
            formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
        } else {
          if(error.error.includes("DELETE statement conflicted"))
          {
            formattedError = "Cannot delete the mapping connected to this Partner/Messagetype/TaskId-combination."
          } else {
            formattedError = error.error;
          }
        }
    } else {
    if(error?.error?.error) {
        formattedError = error?.error.error;
    } else {
        formattedError = 'Unknown error';
    }
    }

    this.errorMessage = formattedError;
    console.error(error);
  }
}
