<form [formGroup]="inboundMessageTypeForm">
  <fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Inbound message type</span></legend>

    <div class="form-group row">
      <label for="inboundMessageTypeDDL" class="col-sm-2 col-form-label control-label">Inbound message type <span class="req-color">*</span></label>
      <div class="col-sm-6">
        <select dropdown (change)="selectChangeHandler($event)" formControlName="inboundMessageTypeId" id="inboundMessageTypeDDL" name="inboundMessageTypeDDL" class="form-select form-select-sm control-label" *ngIf="messageTypes">
          <option value="">--- Select inbound message type ---</option>
          <option *ngFor="let item of messageTypes" [value]="item.inboundMessageTypeId.toString()">
            {{item.messageTypeDescription}} ({{item.messageName}})
          </option>
        </select>
        <div *ngIf="form.inboundMessageTypeId.invalid && (form.inboundMessageTypeId.dirty || form.inboundMessageTypeId.touched)" class="alert alert-danger">
          <div *ngIf="form.inboundMessageTypeId.errors?.['required']">Inbound message type is required.</div>
        </div>
      </div>
    </div>
  </fieldset>
</form>

  