import { Component, OnInit, Inject } from '@angular/core';
// import { MessageType } from 'src/app/models/message-type';
// import { DataTableType } from '../../common-components/data-table-type'
import { MessageTypeModel } from './message-type-model';
import { MessageTypeService } from './message-type.service';
import { TabModel } from 'src/app/models/tab-model';
@Component({
    selector: 'md-message-types',
    templateUrl: './md-message-types.component.html',
  })
export class MasterDataMessageTypesComponent  implements OnInit {
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = true;
    // public messageTypes: Array<MessageType>;
    public messageTypeGridList: Array<MessageTypeModel> = new Array<MessageTypeModel>();
    public tabModel: TabModel = new TabModel();
    
    constructor(public messageTypeService: MessageTypeService) {}

    ngOnInit(): void {
        //this.getData(1, 10);
    }

    public onclickGoToTab(tab: number) {

        this.tabModel.activeTab = tab;

        switch(tab) {
            case 1: {
                // this.showSpinner = true;
                //this.getPartnerSources(this.partner.id);
                break;
            }
            case 2: {
                // this.showSpinner = true;
                //this.getPartnerDestinations(this.partner.id);
                break;
            }
            case 3: {
                //this.showSpinner = true;
                //this.getParsers(this.partner.id)
            }

        }
    }

    // private getData(page: number, pageCount: number) {
    //     this.messageTypeService.getMessageTypes(1, 10).subscribe(
    //     {
    //       next: (result) => {
    //         this.messageTypes = result;
    //         this.messageTypeGridList = this.messageTypeService.getMessageTypeModelList(result);
    //         this.showSpinner = false; 
    //       },
    //       error: (error) => { 
    //         this.setErrorText(error);
    //         this.showSpinner = false; 
    //       }
    //     });
    // }

    // public createNewMessageType() {
    //     let highestId = Math.max(...this.messageTypes.map(o => o.messageTypeId))
    //     let messageType = this.messageTypeService.populateNewMessageType(highestId);
    //     this.messageTypeGridList.unshift(messageType);
    // }

    // public editMessageType(gridRow: MessageTypeModel) {
    //     this.messageTypeService.setEditMode(gridRow, this.messageTypeGridList, true);
    // }

    // public cancelMessageTypeEdit(gridRow: MessageTypeModel) {
    //     this.messageTypeService.setEditMode(gridRow, this.messageTypeGridList, false);

    //     if(gridRow.isNew) {
    //         var removeIndex = this.messageTypeGridList.map(item => item.messageTypeId).indexOf(gridRow.messageTypeId);
    //         ~removeIndex && this.messageTypeGridList.splice(removeIndex, 1);
    //     }
    // }

    // public saveMessageType(gridRow: MessageTypeModel) {
    //     this.messageTypeService.setEditMode(gridRow, this.messageTypeGridList, false);

    //     let messageType = this.messageTypeService.getMessageType(gridRow); 
       
    //     if(!gridRow.isNew) {
    //         this.messageTypeService.updateMessageType(messageType).subscribe(
    //             {
    //                 next: (result) => {      
    //                     this.getData(1, 10);
    //                 },
    //                 error: (error) => { 
    //                     this.setErrorText(error);
    //                 }
    //              }
    //         );        
    //     } else {
    //         this.messageTypeService.addMessageType(messageType).subscribe(
    //             {
    //                 next: (result) => {      
    //                     this.getData(1, 10);
    //                 },
    //                 error: (error) => { 
    //                     this.setErrorText(error);
    //                 }
    //              }
    //         );    
    //     }
    // }
    
    // private setErrorText(error: any) {
    //     this.error = true;
    //     let msg: string = '';
    //     let title: string = error?.error?.title;
    //     msg = (title !== undefined) ? title : error?.error;
    //     this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
    //     console.error(error); 
    // }

    // // TODO: Message Type is never removed if used for en onboarding
    // public removeMessageType(gridRow: MessageTypeModel) {        
    // }
}
