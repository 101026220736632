import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MDEdiPreFormatterInputService } from './md-ediPreFormatter-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { FormGroup } from '@angular/forms';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

export function controlContainerFactory(controlContainer?: ControlContainer) {
  return controlContainer;
}

@Component({
  selector: 'add-edipreformatter',
  templateUrl: './add-ediPreFormatter-modal.component.html',
})
export class AddEdiPreFormatterModalComponent implements OnInit {
  @Input() partner: Partner;
  public ediPreFormatterForm: FormGroup;
  public errorModel: ErrorModel = null;
  public showSpinner: boolean = false;

  constructor(public activeModal: NgbActiveModal, private ediPreFormatterInputService: MDEdiPreFormatterInputService, private errorParserService: ErrorParserService) { }

  ngOnInit(): void {
    this.showSpinner = true;
    this.ediPreFormatterForm = this.ediPreFormatterInputService.getEmptyInboundPartnerEdiPreFormatterForm(this.partner.id);
    this.showSpinner = false;
  }

  get form() { return this.ediPreFormatterForm.controls; }

  public save() {
    this.showSpinner = true;

    let ediPreFormatter = this.ediPreFormatterInputService.getInboundPartnerEdiPreFormatterModel(this.ediPreFormatterForm)
    this.ediPreFormatterInputService.addInboundPartnerEdiPreFormatter(ediPreFormatter).subscribe(
      {
        next: (result) => {
          this.showSpinner = false;
          this.activeModal.close('save');
        },
        error: (error) => {
          this.showSpinner = false;
          this.errorModel = this.errorParserService.extractErrors(error);
          console.error(error);
        }
      }
    );
  }
}
