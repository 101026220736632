import { OutboundMapXslt } from "./outbound-map-xslt";

export class OutboundMap {
    id: number;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
    outboundPartnerId: number;
    outboundMapXsltId: number;
    outboundMapXslt: OutboundMapXslt;
}
