import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomerConfiguration } from '../../models/customer-configuration';
import { environment } from '../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { EntityMap } from 'src/app/models/entity-map';
import { Configuration } from 'src/app/models/configuration';

@Injectable({
  providedIn: 'root',
})
export class CustomerConfigurationsService {
  public _http: HttpClient;
  public baseUrlLocal: string = "";

  constructor(private http: HttpClient, 
    //@Inject('BASE_URL') baseUrl: string
    ) {
    this._http = http;
    this.baseUrlLocal = environment.apiBaseUrl; // 'https://localhost:44376/api/';
  }

  public getData(usePrevEnv: boolean, page: number, pageCount: number): Observable<Array<CustomerConfiguration>> {
    // At the moment server-side paging is not used
    let baseUrlLocal = usePrevEnv === false ? this.baseUrlLocal : environment.apiPrevBaseUrl;
    return this._http.get<Array<CustomerConfiguration>>(baseUrlLocal + 'bff/configurations');
  }

  public getConfiguration(id: number): Observable<Configuration> {
    return this._http.get<Configuration>(this.baseUrlLocal + `configurations/${id}`);
  }

  public deleteConfiguration(entityMap: EntityMap) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: entityMap
    };
    return this.http.delete(this.baseUrlLocal + 'configurations', options);
  }
}


