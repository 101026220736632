<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Inbound mappings</span></legend>
    <div class="row mb5">
        <table class='table table-striped table-bordered' aria-labelledby="tableLabel">
            <thead>
                <tr>
                    <th>Xslt filename</th>
                    <th>Xslt description</th>
                    <th>Task Id</th>
                </tr>
            </thead>
            <tbody *ngIf="form.value.inboundMappings.length > 0">
                <tr *ngFor="let c of form.value.inboundMappings">
                    <td>{{ c.xsltName }}</td>
                    <td>{{ c.xsltDescription }}</td>
                    <td>{{ c.taskId }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="form.value.inboundMappings.length === 0">
                <tr>
                <td colspan="2">Missing Inbound xslt configuration for Partner and Message Type</td>
                </tr>
            </tbody>
        </table>
    </div>
</fieldset>
<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound mappings</span></legend>
    <div class="row mb5">
        <label class="col-sm-2"><b>Outbound mapping: <span class="req-color">*</span></b></label>
        <div class="col-sm-3">
            <label *ngIf="form.value.outboundMapXslt.outboundMapXsltName" class="col-sm-3">{{form.value.outboundMapXslt.outboundMapXsltName}}</label>
            <label *ngIf="!form.value.outboundMapXslt.outboundMapXsltName" class="text-red">MISSING DATA</label>
        </div>
        <div class="col-sm-2">
            <label><b>Description: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label *ngIf="form.value.outboundMapXslt.outboundMapXsltDescription">{{ form.value.outboundMapXslt.outboundMapXsltDescription }}</label>
            <label *ngIf="!form.value.outboundMapXslt.outboundMapXsltDescription" class="text-red">MISSING DATA</label>
        </div>
    </div>
</fieldset>
