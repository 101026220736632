import { Injectable } from '@angular/core';
import { TabModel } from 'src/app/models/tab-model';
import { TabStatuses } from 'src/app/models/tab-statuses';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationTabService {  
    public aS2InboundManualSet: boolean;

    constructor() {
        this.aS2InboundManualSet = false;
    }

    public setupTabs(tabModel: TabModel) {
        tabModel.activeTab = 1;
        tabModel.basicInfoNextText = "Next : Resolver/Parser";
        tabModel.basicInfoNextTab = 5;
        tabModel.sourceNextTab = 5;
        tabModel.sourceNextText = 'Next : Resolver/Parser';
        tabModel.as2InboundPrevTab = 2;
        tabModel.as2InboundPrevText = 'Prev: Basic';
        tabModel.resolverNextTab = 7; // Default is to go to mapping
        tabModel.resolverPrevTab = 2;
        tabModel.resolverPrevText = 'Prev: Basic information';
        tabModel.resolverNextText = 'Next : Mapping'
        tabModel.mappingPrevTab = 5;
        tabModel.mappingPrevText = 'Prev : Resolver/Parser';

        tabModel.destinationsNextTab = 10;
        tabModel.destinationsNextText = 'Review + create';
        tabModel.reviewPrevTab = 8;
        tabModel.reviewPrevText = 'Prev: Destinations';
    }

    public onSpecifySource($event: any, tabStatuses: TabStatuses, tabModel: TabModel) {
        if($event.target.checked) {
            tabModel.basicInfoNextTab = 3;
            tabModel.basicInfoNextText = 'Next : Source';
        } else {
            tabModel.basicInfoNextTab = tabStatuses.specifyAS2Inbound == true ? 4 : 5;
            tabModel.basicInfoNextText = tabStatuses.specifyAS2Inbound == true ? 'Next : AS2 Inbound' : 'Next : Resolver/Parser';
            tabModel.as2InboundPrevTab = tabStatuses.specifySource ? 3 : 2;
            tabModel.as2InboundPrevText = tabStatuses.specifySource ? 'Prev : Source' : 'Prev: Basic information'

            tabModel.sourceNextText = tabStatuses.specifyAS2Inbound == true ? 'Next : AS2 Inbound' : 'Next : Resolver/Parser';

            tabModel.resolverPrevTab = tabStatuses.specifyAS2Inbound ? 4: 2;
            tabModel.resolverPrevText = tabStatuses.specifyAS2Inbound ? 'Prev: AS2 Inbound' : 'Prev: Basic information';
        }
    }

    public onSpecifyAs2Inbound($event: any, tabStatuses: TabStatuses, tabModel: TabModel) {
        tabStatuses.specifyAS2Inbound = $event.target.checked;
        if($event.target.checked) {
            tabModel.as2InboundPrevTab = tabStatuses.specifySource ? 3 : 2;
            tabModel.as2InboundPrevText = tabStatuses.specifySource ? 'Prev : Source' : 'Prev: Basic information';
            
            tabModel.sourceNextTab = tabStatuses.specifyAS2Inbound ? 4 : 5;
            tabModel.sourceNextText = tabStatuses.specifyAS2Inbound == true ? 'Next : AS2 Inbound' : 'Next : Resolver/Parser';

            tabModel.resolverPrevTab = 4;
            tabModel.resolverPrevText = 'Prev: AS2 Inbound';

            this.aS2InboundManualSet = true;
        } 
        else {
            tabModel.resolverPrevTab = tabStatuses.specifySource ? 3: 2;
            tabModel.resolverPrevText = tabStatuses.specifySource ? 'Prev: Source' : 'Prev: Basic information';
            this.aS2InboundManualSet = false;
        }
    }
    
    public onSpecifyAs2Outbound($event: any, tabStatuses: TabStatuses, tabModel: TabModel) {
        tabStatuses.specifyAS2Outbound = $event.target.checked;
        if($event.target.checked) {
            tabModel.reviewPrevTab = 9;
            tabModel.reviewPrevText = 'Prev: EDI Outbound';
            tabModel.destinationsNextTab = 9;
            tabModel.destinationsNextText = 'Next: EDI Outbound';
        } 
        else {
            tabModel.reviewPrevTab = 8;
            tabModel.reviewPrevText = 'Prev: Destinations';
            tabModel.destinationsNextTab = 10
            tabModel.destinationsNextText = 'Review + create';
        }        
    }

    public onSpecifyEnrichment($event: any, tabStatuses: TabStatuses, tabModel: TabModel) {
        tabStatuses.specifyEnrichment = $event.target.checked;
        if($event.target.checked) {

            tabModel.resolverNextText = 'Next : Enrichments';
            tabModel.resolverNextTab = 6;
            // this.tabModel.as2InboundPrevTab = this.tabStatuses.specifySource ? 3 : 2;
            // this.tabModel.as2InboundPrevText = this.tabStatuses.specifySource ? 'Prev : Source' : 'Prev: Basic information';
            
            tabModel.mappingPrevTab = 6;
            tabModel.mappingPrevText = 'Prev: Enrichments'

            // this.tabModel.sourceNextTab = this.tabStatuses.specifyAS2Inbound ? 4 : 5;
            // this.tabModel.sourceNextText = this.tabStatuses.specifyAS2Inbound == true ? 'Next : AS2 Inbound' : 'Next : Resolver/Parser';

            // this.tabModel.resolverPrevTab = 4;
            // this.tabModel.resolverPrevText = 'Prev: AS2 Inbound';
        } 
        else {
            tabModel.resolverNextText = 'Next : Mappings';
            tabModel.resolverNextTab = 7;
            tabModel.mappingPrevTab = 7;
            tabModel.mappingPrevText = 'Prev: Mappings'

            // this.tabModel.resolverPrevTab = this.tabStatuses.specifySource ? 3: 2;
            // this.tabModel.resolverPrevText = this.tabStatuses.specifySource ? 'Prev: Source' : 'Prev: Basic information';
        }
    }
}
