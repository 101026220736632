<h3 id="tableLabel">Configurations</h3>

<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div>

<div>
  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="pagedCustomerConfigurations">
      <thead>
        <tr>
          <th scope="col" colSpan="9">
            <div>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="isReader" (click)="createNewConfig()" style="float: right;">New configuration</button>
            </div>
          </th>
        </tr>
      </thead>
      <thead>
          <tr>
              <th>
                <div>Inbound partner</div>
                <div>
                  <input type="text" class="form-control" id="partnerName" name="partnerName" placeholder="Filter partner" [(ngModel)]="filterPartner" (keyup)="onFilter()">
                </div>
              </th>
              <th>
                <div>Message type</div>
                <div>
                  <input type="text" class="form-control" id="messageTypeName" name="messageTypeName" placeholder="Filter message type" [(ngModel)]="filterMessageTypeName" (keyup)="onFilter()">
                </div>
              </th>
              <th>
                <div>Sender</div>
                <div>
                  <input type="text" class="form-control" id="senderName" name="senderName" placeholder="Filter sender" [(ngModel)]="filterSender" (keyup)="onFilter()">
                </div>
              </th>
              <th>
                <div>Sender Id</div>
                <div>
                  <input type="text" class="form-control" id="senderId" name="senderId" placeholder="Filter sender" [(ngModel)]="filterSenderId" (keyup)="onFilter()">
                </div>
              </th>
              <th>
                <div>Receiver</div>
                <div>
                  <input type="text" class="form-control" id="receiverName" name="receiverName" placeholder="Filter receiver" [(ngModel)]="filterReceiver" (keyup)="onFilter()">
                </div>
              </th>
              <th>
                <div>Receiver Id</div>
                <div>
                  <input type="text" class="form-control" id="receiverId" name="receiverId" placeholder="Filter receiver id" [(ngModel)]="filterReceiverId" (keyup)="onFilter()">
                </div>
              </th>
              <th>
                <div>Legal entity</div>
                <div>
                  <input type="text" class="form-control" id="legalEntityId" name="legalEntityId" placeholder="Filter legal entity" [(ngModel)]="filterLegalEntity" (keyup)="onFilter()">
                </div>
              </th>
              <th>
                <div>Area plant</div>
                <div>
                  <input type="text" class="form-control" id="legalEntityName" name="legalEntityName" placeholder="Filter area plant" [(ngModel)]="filterAreaPlant" (keyup)="onFilter()">
                </div>
              </th>
              <th></th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let cc of pagedCustomerConfigurations">
              <td>{{ cc.partnerName }}</td>
              <td>{{ cc.messageName }}</td>
              <td>{{ cc.senderName }}</td>
              <td>{{ cc.senderId }}</td>
              <td>{{ cc.receiverName }}</td>
              <td>{{ cc.receiverId }}</td>
              <td>{{ cc.legalEntityName }}</td>
              <td>{{ cc.areaPlantName }}</td>
              <td class="fixed120">
                <button class="btn btn-outline-secondary btn-sm" (click)="viewConfig(cc)" style="margin-right: 5px;"><i class="fa fa-magnifying-glass"></i></button>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="isReader" (click)="copyConfig(cc)" style="margin-right: 5px;" ngbTooltip="Copy an existing configuration as base for a new configuration with simular data."><i class="fa fa-copy" aria-hidden="true"></i></button>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="isReader" (click)="editConfig(cc)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="isReader" (click)="removeConfig(cc)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
              </td>
          </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="9">
            <table>
              <tr>
                <td style="margin: auto;">
                  <nav>
                    <ul class="pagination">
                      <li class="page-item" ng-class="{disabled: currentPage == 1}">
                        <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                      </li>
                      <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(configurationPaging.currentPage, 1, configurationPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(configurationPaging.currentPage, 1, configurationPaging.totalPages), 1)">{{pService.getPageNumber(configurationPaging.currentPage, 1, configurationPaging.totalPages)}}</a></li>
                      <li class="page-item" *ngIf="configurationPaging.totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(configurationPaging.currentPage, 2, configurationPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(configurationPaging.currentPage, 2, configurationPaging.totalPages), 2)">{{pService.getPageNumber(configurationPaging.currentPage, 2, configurationPaging.totalPages)}}</a></li>
                      <li class="page-item" *ngIf="configurationPaging.totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(configurationPaging.currentPage, 3, configurationPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(configurationPaging.currentPage, 3, configurationPaging.totalPages), 3)">{{pService.getPageNumber(configurationPaging.currentPage, 3, configurationPaging.totalPages)}}</a></li>
                      <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                        <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                      </li>
                    </ul>
                  </nav>
                </td>
                <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                  <label style="margin-right: 5px;">{{(((configurationPaging.currentPage) - 1) * (+configurationPaging.pageCount)) + 1}} - {{configurationPaging.lastDisplayIndex}} of {{configurationCount}}</label>
                </td>
                <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                  <select dropdown (change)="selectPagingChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="configurationPaging.pageCount">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </td>               
              </tr>                  
            </table>
          </td>
        </tr>
      </tfoot>
  </table>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':viewConfigTypeSelectionDisplayStyle}" *ngIf="viewConfigTypeSelection">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Use configuration from {{prevenv}}</h4>
      </div>
      <div class="modal-body">
          <div>
            <configuration-selection-grid [customerConfigurations]="originalCustomerConfigurations" (selectedCustomerConfiguration)="onSelectedCustomerConfiguration($event)"></configuration-selection-grid>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="closeConfigTypeSelectionPopup()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':viewDeleteConfirmWindowDisplayStyle}" *ngIf="viewDeleteConfirmWindow">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Delete configuration</h4>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-bottom: 15px;">
          <label for="receiverName" class="col-sm-12">Confirm delete of the following configuration:</label>
        </div>
        <div class="row mb5">
          <label for="receiverName" class="col-sm-3"><b>Partner:</b></label>
          <div class="col-sm-9">
              <label for="receiverName">{{configurationToDelete.inboundPartner.name}}</label>
          </div>
        </div>
        <div class="row mb5">
          <label for="receiverName" class="col-sm-3"><b>Message type:</b></label>
          <div class="col-sm-9">
              <label for="receiverName">{{configurationToDelete.inboundMessageType.messageTypeDescription}}</label>
          </div>
        </div>
        <div class="row mb5">
          <label for="receiverName" class="col-sm-3"><b>Sender:</b></label>
          <div class="col-sm-9">
              <label for="receiverName">{{configurationToDelete.entityMap.senderName}} ({{configurationToDelete.entityMap.senderId}})</label>
          </div>
        </div>
        <div class="row mb5">
          <label for="receiverName" class="col-sm-3"><b>Receiver:</b></label>
          <div class="col-sm-9">
              <label for="receiverName">{{configurationToDelete.entityMap.receiverName}} ({{configurationToDelete.entityMap.receiverId}})</label>
          </div>
        </div>
        <div class="row mb5">
          <label for="receiverName" class="col-sm-3"><b>Legal entity:</b></label>
          <div class="col-sm-9">
              <label for="receiverName">{{configurationToDelete.entityMap.legalEntityName}} ({{configurationToDelete.entityMap.legalEntityId}})</label>
          </div>
        </div>
        <div class="row mb5">
          <label for="receiverName" class="col-sm-3"><b>Area plant:</b></label>
          <div class="col-sm-9">
              <label for="receiverName">{{configurationToDelete.entityMap.areaPlantName}}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="cancelDeleteConfiguration()">Cancel</button>
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="deleteConfiguration()">Delete</button>
      </div>
    </div>
  </div>
</div>