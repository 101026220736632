import { KeyVaultProfile } from "../key-vault-profile";

export class DestinationMqprofile {
    id: number;
    name: string
    host: string;
    channel: string;
    qm: string;
    queue: string;
    port: string;
    userName: string;
    password: string;
    keyVaultProfile: KeyVaultProfile;
    createdBy: string;
    createTimestamp: string;
}