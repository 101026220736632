import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { OutboundMessageType } from '../../../../models/message-type';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'outbound-messagetype-input',
  templateUrl: './outbound-messagetype-input.component.html',
})
export class OutboundMessagetypeInputComponent implements OnInit {
  @Input() outboundMessageTypes: Array<OutboundMessageType>;
  @Input() formGroupName: string;  
  @Output() outboundMessageTypeUpdated = new EventEmitter<any>();
  public outboundMessageTypeForm: FormGroup;
    
  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.outboundMessageTypeForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  get form() { return this.outboundMessageTypeForm.controls; }

  public selectOutboundPartnerChangeHandler(event: any) {
    let selectedMessageType = this.outboundMessageTypes.find(x => x.messageTypeId === +event.target.value);
    this.outboundMessageTypeForm.controls['messageName'].setValue(selectedMessageType.messageName);
    this.outboundMessageTypeForm.controls['messageTypeDescription'].setValue(selectedMessageType.messageTypeDescription);
    this.outboundMessageTypeUpdated.emit(selectedMessageType);
  }
}
