<h3 id="tableLabel">Administrate Mapping Documents</h3>

<div class="mt10">
  <ul ngbNav #nav="ngbNav" [(activeId)]="tabModel.activeTab" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="onclickGoToTab(1)">Inbound => Canonical Mapping Documents</a>
      <ng-template ngbNavContent>
        <div class="ml5">
          <div class="row">
            <div class="col-12">
                <div style="margin-bottom: 10px;">
                  <md-inbound-to-canonical-mapping-documents></md-inbound-to-canonical-mapping-documents>
                </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink (click)="onclickGoToTab(2)">Canonical => Outbound Mapping Documents</a>
      <ng-template ngbNavContent>
        <div class="ml5">
          <div class="row">
            <div class="col-12">
                <div style="margin-bottom: 10px;">
                  <md-canonical-to-outbound-mapping-documents></md-canonical-to-outbound-mapping-documents>
                </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
