import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageTypeService } from '../message-type.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'add-outbound-message-type',
  templateUrl: './add-outbound-message-type.component.html'
})
export class AppOutboundMessageTypeComponent implements OnInit {
    public outboundMessageTypeForm: FormGroup;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public nextId: number;

    constructor(public mtService: MessageTypeService, public activeModal: NgbActiveModal, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        this.outboundMessageTypeForm = this.mtService.getEmptyOutboundMessageTypeForm()

        this.showSpinner = true;
        this.mtService.getMaxOutboundMessageTypeId().subscribe({
            next: (maxid) => {
                this.showSpinner = false;
                this.outboundMessageTypeForm.controls['outboundMessageTypeId'].setValue((maxid + 1));
            },
            error: (error) => {
                this.showSpinner = false; 
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });

        // Subscribe on value changes for messageName input control to force to uppercase and underscore
        this.outboundMessageTypeForm.get('messageName').valueChanges.subscribe((value: string) => {
            const formattedValue = this.mtService.formatValue(value);
            this.outboundMessageTypeForm.get('messageName').setValue(formattedValue, { emitEvent: false });
        });
    }

    get form() { return this.outboundMessageTypeForm.controls; }

    public save() {
        let newOutboundMessageType = this.mtService.getOutboundMessageTypeModel(this.outboundMessageTypeForm);

        if(this.outboundMessageTypeForm.valid){
            this.mtService.addOutboundMessageType(newOutboundMessageType).subscribe(
                {
                    next: (result) => {
                        this.activeModal.close('save');
                    },
                    error: (error) => {
                        this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                    }
                 }
            );
        }
    }
}
