import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ReviewwInputService } from '../review-input.service';

@Component({
  selector: 'review-x12-lookup',
  templateUrl: './review-x12-lookup.component.html',
})
export class ReviewX12LookupComponent {
  public form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, public rs: ReviewwInputService) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.form;
  }
}
