import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RowDisplayComponent } from './row-display.component';
import { TwoColRowDisplayComponent } from './two-col-row-display.component';
import { SimpleGridComponent } from './simple-grid/simple-grid.component';
import { DynamicPipeService } from './simple-grid/dynamic-pipe.service';

@NgModule({
  imports:      [ 
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule 
  ],
  exports:      [ RowDisplayComponent, TwoColRowDisplayComponent, SimpleGridComponent ],
  providers:    [ DynamicPipeService ],
  declarations: [ RowDisplayComponent, TwoColRowDisplayComponent, SimpleGridComponent ]
})
export class CommonComponentsModule { }