import { Component, OnInit } from '@angular/core';
import { InboundMessageType } from 'src/app/models/message-type';
import { MessageTypeModel } from '../message-type-model';
import { MessageTypeService } from '../message-type.service';
import { InboundMessageTypesGridView } from '../models/inbound-message-types-gridview';
import { PagingService } from 'src/app/common-services/paging.service';
import { MsalService } from '@azure/msal-angular';
import { AppInboundMessageTypeComponent } from './add-inbound-message-type.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditInboundMessageTypeComponent } from './edit-inbound-message-type.component';
import { DeleteInboundMessageTypeComponent } from './delete-inbound-message-type.component';
import { Paging } from 'src/app/models/paging/paging';

@Component({
    selector: 'md-inbound-message-types',
    templateUrl: './md-inbound-message-types.component.html',
  })
export class MasterDataInboundMessageTypesComponent  implements OnInit {
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = true;
    public messageTypeGridList: Array<MessageTypeModel> = new Array<MessageTypeModel>();
    public imtGridView: InboundMessageTypesGridView;
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public isSuperUser: boolean = false;
    public filterMessageTypeName: string = '';
    public filterMessageTypeDescription: string = '';
    public inboundMessageTypesPaging: Paging = new Paging();

    constructor(public messageTypeService: MessageTypeService, public pService: PagingService, private authService: MsalService, private modalService: NgbModal) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
            this.isSuperUser = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.SuperUser') == undefined ? false : true;
        }

        this.getData();
    }

    private getData() {
        this.messageTypeService.getMessageTypes(this.inboundMessageTypesPaging.currentPage, this.inboundMessageTypesPaging.pageCount, this.filterMessageTypeName, this.filterMessageTypeDescription).subscribe(
        {
          next: (result) => {
            this.imtGridView = result;
            this.inboundMessageTypesPaging.setLastDisplayIndex();
            this.inboundMessageTypesPaging.totalPages = Math.floor(this.imtGridView.totalRows/this.inboundMessageTypesPaging.pageCount) + (this.imtGridView.totalRows % this.inboundMessageTypesPaging.pageCount > 0 ? 1 : 0);
            this.showSpinner = false; 
          },
          error: (error) => { 
            this.setErrorText(error);
            this.showSpinner = false; 
          }
        });
    }

    public onFilter() {
        this.inboundMessageTypesPaging.currentPage = 1;
        this.getData();
    }

    public createNewInboundMessageType() {
        const modalRef = this.modalService.open(AppInboundMessageTypeComponent, { size: 'size_40' });
        modalRef.result.then((result) => {            
            if(result === 'save') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public editInboundMessageType(gridRow: InboundMessageType) {
        const modalRef = this.modalService.open(EditInboundMessageTypeComponent, { size: 'size_40' });
        modalRef.componentInstance.inboundMessageType = gridRow;
        modalRef.result.then((result) => {
            if(result === 'save') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }
   
    private setErrorText(error: any) {
        this.error = true;
        let msg: string = '';
        let title: string = error?.error?.title;
        msg = (title !== undefined) ? title : error?.error;
        this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
        console.error(error); 
    }

    public removeInboundMessageType(gridRow: InboundMessageType) {
        const modalRef = this.modalService.open(DeleteInboundMessageTypeComponent, { size: 'size_40' });
        modalRef.componentInstance.inboundMessageType = gridRow;
        modalRef.result.then((result) => {            
            if(result === 'delete') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    // --- Paging ----------------------------------------------------------------------------------  
    public onclickPrev() {
        if (this.inboundMessageTypesPaging.currentPage > 1) {
            this.inboundMessageTypesPaging.currentPage = this.inboundMessageTypesPaging.currentPage - 1;
            this.inboundMessageTypesPaging.setStartEndIndex();
            this.getData();      
        }
    }

    public onclickNext() {
        if ((this.inboundMessageTypesPaging.currentPage) * this.inboundMessageTypesPaging.pageCount < this.imtGridView.totalRows) {
            this.inboundMessageTypesPaging.nextButtonDisabled = false;
            this.inboundMessageTypesPaging.currentPage = this.inboundMessageTypesPaging.currentPage + 1;
            this.inboundMessageTypesPaging.setStartEndIndex();
            this.getData();
        } else {
            this.inboundMessageTypesPaging.nextButtonDisabled = true;
        }
    }

    public onclickPage(page: number, index: number) {
        this.inboundMessageTypesPaging.currentPage = this.inboundMessageTypesPaging.currentPage === page ? this.inboundMessageTypesPaging.currentPage : page;
        this.inboundMessageTypesPaging.setStartEndIndex();
        this.getData();
    }

    selectChangeHandler(event: any) {
        this.inboundMessageTypesPaging.currentPage = 1;
        this.inboundMessageTypesPaging.setStartEndIndex();      
        this.getData();
    }

    // selectChangeHandler(event: any) {
    //     // Do som stuff
    //     this.getData();
    // }

}
