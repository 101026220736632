<form [formGroup]="destinationMqprofileForm">
    <hr>
    <!-- Selection -->
   <div class="form-group row mb5" *ngIf="!isEdit">
       <div class="col-sm-3">        
       </div>
       <div class="col-sm-9">
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="true" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestination">
                    Use existing destination
               </label>
           </div>
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="false" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestination">
                   Add new destination
               </label>
           </div>
       </div>
   </div>
   
    <!-- Use existing physical destination -->
    <div class="form-group row" *ngIf="destinationMqprofileForm.value.useExistingDestination === true">
       <label for="confDestinationDDL" class="col-sm-3 col-form-label control-label">Destinations <span class="req-color">*</span></label>
        <div class="col-sm-9">
            <select dropdown formControlName="id" id="confDestinationDDL" name="confDestinationDDL" class="form-select form-select-sm control-label">
            <option value="">--- Select destination ---</option>
            <option *ngFor="let item of mqProfiles" [value]="item.id">
                {{item.name}} (queue: {{item.queue}}, host: {{item.host}})
            </option>
            </select>
            <div *ngIf="form.id.invalid && (form.id.dirty || form.id.touched)" class="alert alert-danger">
                <div *ngIf="form.id.errors?.['required']">Destination is required.</div>
            </div>
        </div>
   </div>
   
   <!-- New physical destination -->
   <div *ngIf="destinationMqprofileForm.value.useExistingDestination === false">
        <!-- Host -->
       <div class="form-group row mb5">
            <label for="destinationHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
            <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="host" id="destinationHost" name="destinationHost" placeholder="Host">
                <div *ngIf="form.host.invalid && (form.host.dirty || form.host.touched)" class="alert alert-danger">
                    <div *ngIf="form.host.errors?.['required']">Host is required.</div>
                </div>
            </div>            
       </div>
       <!-- Channel -->
       <div class="form-group row mb5">
            <label for="destinationChannel" class="col-sm-3 col-form-label">Channel<span class="req-color">*</span></label>
            <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="channel" id="destinationChannel" name="destinationChannel" placeholder="Channel">
                <div *ngIf="form.channel.invalid && (form.channel.dirty || form.channel.touched)" class="alert alert-danger">
                    <div *ngIf="form.channel.errors?.['required']">Channel is required.</div>
                </div>
            </div>           
       </div>
       <!-- Queue manager -->
       <div class="form-group row mb5">
            <label for="destinationQM" class="col-sm-3 col-form-label">Queue manager<span class="req-color">*</span></label>
            <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="qm" id="destinationQM" name="destinationQM" placeholder="Queue manager">
                <div *ngIf="form.qm.invalid && (form.qm.dirty || form.qm.touched)" class="alert alert-danger">
                    <div *ngIf="form.qm.errors?.['required']">Queue manager is required.</div>
                </div>
            </div>            
       </div>
       <!-- Queue -->
       <div class="form-group row mb5">
            <label for="destinationQueue" class="col-sm-3 col-form-label">Queue<span class="req-color">*</span></label>
            <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="queue" id="destinationQueue" name="destinationQueue" placeholder="Queue">
                <div *ngIf="form.queue.invalid && (form.queue.dirty || form.queue.touched)" class="alert alert-danger">
                    <div *ngIf="form.queue.errors?.['required']">Queue is required.</div>
                </div>
            </div>            
       </div>
       <!-- Port -->
       <div class="form-group row mb5">
            <label for="destinationPort" class="col-sm-3 col-form-label">Port<span class="req-color">*</span></label>
            <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="port" id="destinationPort" name="destinationPort" placeholder="Port">
                <div *ngIf="form.port.invalid && (form.port.dirty || form.port.touched)" class="alert alert-danger">
                    <div *ngIf="form.port.errors?.['required']">Port is required.</div>
                </div>
            </div>
       </div>
   </div>
</form>


