import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimpleFlowConfigurationsComponent } from './simpleflow-configurations.component';
import { SimpleFlowConfigurationsService } from './simpleflow-configurations.service';
import { AddSimplifiedFlowConfigurationComponent } from './child-components/add-simplified-flow.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { EditSimplifiedFlowConfigurationComponent } from './child-components/edit-simplified-flow.component';
import { SourceViewTableRowModule } from '../common-components/source-vew/source-view-table-data.module';
import { ViewSimplifiedFlowConfigurationModalComponent } from './child-components/view-simplified-flow-configuration.comonent';
import { DeleteSimplifiedFlowConfigurationComponent } from './child-components/delete-simplified-flow.component';

@NgModule({
  imports:      [ 
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule,
    CommonComponentsModule,
    SourceViewTableRowModule
  ],
  providers:    [ SimpleFlowConfigurationsService ],
  declarations: [ 
    SimpleFlowConfigurationsComponent, 
    AddSimplifiedFlowConfigurationComponent, 
    EditSimplifiedFlowConfigurationComponent, 
    ViewSimplifiedFlowConfigurationModalComponent,
    DeleteSimplifiedFlowConfigurationComponent ]
})
export class SimpleFlowConfigurationsModule { }