<h3>GOH Admin Tool - testview</h3>
<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div>{{resultString}}</div>

<h1>Angular 8 Bootstrap Modal Popup Example</h1>
   
<button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">Open My Modal</button>

<div>
    closeResult: {{closeResult}}
</div>

<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    This is example from ItSolutionStuff.com
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>