import { Component, Input, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { AS2OutboundService } from './as2-outbound-input.service';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'as2-outbound-input',
  templateUrl: './as2-outbound-input.component.html',
})
export class As2OutboundInputComponent implements OnInit {
    @Input() formGroupName: string;
    
    public outboundEdiForm: FormGroup;
    public qualifierDDL: Array<DropDownListModel> = new Array<DropDownListModel>();
   
    constructor(private oService: AS2OutboundService, private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        this.outboundEdiForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        this.qualifierDDL = this.oService.getQualifiers();
    }

    public onChangeConfigureEdifact() {
      if(this.outboundEdiForm.value.configureEdifact === true) {
        const ediLookupFormGroup = new FormGroup({
          id: new FormControl(0),
          hostidentity: new FormControl(''),
          hostidentityQualifier: new FormControl('-1'),
          guestidentity: new FormControl(''),
          guestidentityQualifier: new FormControl('-1'),
        });
        this.outboundEdiForm.addControl('ediLookup', ediLookupFormGroup);
      } else {
        this.outboundEdiForm.removeControl('ediLookup');
      }
    }

    public onChangeConfigureX12() {
      if(this.outboundEdiForm.value.configureX12 === true) {
        const x12LookupFormGroup = new FormGroup({
          id: new FormControl(0),
          hostidentity: new FormControl(''),
          hostidentityQualifier: new FormControl('-1'),
          guestidentity: new FormControl(''),
          guestidentityQualifier: new FormControl('-1'),
        });
  
        this.outboundEdiForm.addControl('x12Lookup', x12LookupFormGroup);
      } else {
        this.outboundEdiForm.removeControl('x12Lookup');
      }      
    }

    public onChangeConfigureAS2() {
      if(this.outboundEdiForm.value.configureAS2 === true) {
        const aS2ConfOutFormGroup = new FormGroup({
          id: new FormControl(0),
          responseFormat: new FormControl(0),
          mdnformat: new FormControl(0),
          as2Agreement: new FormControl(''),
          as2Sender: new FormControl(''),
          as2Receiver: new FormControl(''),
        });
  
        this.outboundEdiForm.addControl('as2ConfOut', aS2ConfOutFormGroup);
      } else {
        this.outboundEdiForm.removeControl('as2ConfOut');
      }
    }
}
