import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/common-services/util.service';
import { MDDestinationService } from './md-destination.service';
import { ConfDestination } from 'src/app/models/destination/conf-destination';

@Component({
  selector: 'delete-destination', // TODO: Is selector needed for modal?
  templateUrl: './delete-destination.component.html',
})
export class DeleteDestinationComponent {
    @Input() confDestination: ConfDestination;
    public errorMessage: string = '';
    public showSpinner: boolean = false;

    constructor(public activeModal: NgbActiveModal, public dService: MDDestinationService, public utilService: UtilService) {}

    public delete() {
      this.showSpinner = true;
      this.dService.deleteDestination(this.confDestination).subscribe(
            {
                next: (result) => {
                  this.showSpinner = false;
                  this.activeModal.close('delete');
                },
                error: (error) => {
                  this.showSpinner = false;
                  this.displayErrorMessage(error);
                }
              }
        );
    }

    private displayErrorMessage(error: any) {
      let formattedError = '';
      if(error?.error && error?.error.error == undefined) {
          if(error.error.includes('at al.goh.admin.tool.api')) {
              formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
          } else {
            if(error.error.includes("DELETE statement conflicted"))
            {
              formattedError = "Cannot remove destination as it is used in a configuration."
            } else {
              formattedError = error.error;
            }
          }
      } else {
      if(error?.error?.error) {
          formattedError = error?.error.error;
      } else {
          formattedError = 'Unknown error';
      }
      }

      this.errorMessage = formattedError;
      console.error(error);
    }
}
