import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UtilService } from 'src/app/common-services/util.service';
import { Partner } from 'src/app/models/partner/partner';
import { CanonicalMap } from 'src/app/models/mapping/canonical-map';
import { MDInboundMappingConnectionInputService } from './md-inboundToCanonicalMappingConnection-input.service';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditInboundMappingConnectionModalComponent } from './edit-inboundToCanonicalMappingConnection-modal.component';
import { AddInboundMappingConnectionModalComponent } from './add-inboundToCanonicalMappingConnection-modal.component';
import { DeleteInboundMappingConnectionModalComponent } from './delete-inboundToCanonicalMappingConnection-modal.component';

@Component({
  selector: 'inboundToCanonicalMappingConnection-input',
  templateUrl: './md-inboundToCanonicalMappingConnection-input.component.html',
})
export class MDInboundMappingConnectionInputComponent implements OnInit {
    @Input() partner: Partner;
    public itcmcGridList: Array<CanonicalMap>;

    //public parsersGridList: Array<InboundParserResponseModel> = new Array<InboundParserResponseModel>();
    public showSpinner: boolean = false;
    public error: boolean = false;
    public errorMessage: string = '';
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;

    constructor(public itcmcInputService: MDInboundMappingConnectionInputService, private authService: MsalService, public utilService: UtilService, private modalService: NgbModal) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }
        this.getPartnerInboundToCanonicalMappingConnections(this.partner.id);
    }

    private getPartnerInboundToCanonicalMappingConnections(inboundpartnerId: number) {
      this.showSpinner = true;
      this.itcmcInputService.getPartnerInboundToCanonicalMappingConnections(inboundpartnerId).subscribe({
          next: (itcmcGridList) => {
              this.showSpinner = false;
              this.itcmcGridList = itcmcGridList;
          },
          error: (error) => {
              this.showSpinner = false;
              this.displayErrorMessage(error);
          }
      });
    }

    public addPartnerInboundToCanonicalMappingConnection() {
        const modalRef = this.modalService.open(AddInboundMappingConnectionModalComponent, { size: 'size_40' });
        let inboundToCanonicalMap: CanonicalMap = new CanonicalMap();
        inboundToCanonicalMap.id = 0;
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.inboundToCanonicalMap = inboundToCanonicalMap;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getPartnerInboundToCanonicalMappingConnections(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    public editPartnerInboundToCanonicalMappingConnection(inboundToCanonicalMap: CanonicalMap) {
        const modalRef = this.modalService.open(EditInboundMappingConnectionModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.inboundToCanonicalMap = inboundToCanonicalMap;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getPartnerInboundToCanonicalMappingConnections(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }



    public removePartnerInboundToCanonicalMappingConnection(inboundToCanonicalMap: CanonicalMap) {
        const modalRef = this.modalService.open(DeleteInboundMappingConnectionModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.inboundToCanonicalMap = inboundToCanonicalMap;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'delete') {
                this.getPartnerInboundToCanonicalMappingConnections(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    private displayErrorMessage(error: any) {
      let formattedError = '';
      if(error?.error && error?.error.error == undefined) {
      if(error.error.includes('at al.goh.admin.tool.api')) {
          formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
      } else {
          formattedError = error.error;
      }
      } else {
      if(error?.error?.error) {
          formattedError = error?.error.error;
      } else {
          formattedError = 'Unknown eror';
      }
      }

      this.errorMessage = `Error when receiving connected inboundToCanonical-mappings. Error Message ${formattedError}`;
      this.error = true;
      console.error(error);
  }

}
