import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ViewConfigurationModelService } from './view-configuration-modal.service';
import { ViewConfigurationModalComponent } from './view-configuration-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SourceViewTableRowModule } from '../source-vew/source-view-table-data.module';
import { CommonComponentsModule } from '../common-components.module';

@NgModule({
  imports:      [ CommonModule, FormsModule, SourceViewTableRowModule, CommonComponentsModule ],
  providers:    [ ViewConfigurationModelService, NgbActiveModal ],
  declarations: [ ViewConfigurationModalComponent ],
  exports: [ ViewConfigurationModalComponent ]
})
export class ViewConfigurationModalModule { }
