import { Injectable } from '@angular/core';
// import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfDestConnection } from '../../../../models/destination/conf-dest-connection';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DestinationInputService {
    // public _http: HttpClient;
    public baseUrlLocal: string = "";
  
    constructor(private http: HttpClient) {
      this.baseUrlLocal = environment.apiBaseUrl;
    }

    // public getBaseData() {
    //   return this.http.get<WizardResponseModel>(this.baseUrlLocal + 'bff/configurationdata');
    // }

    public getDestinationText(confDestConnection: ConfDestConnection) {
        let displayText = '';
        if(confDestConnection.confDestination === undefined) {
            return '';
        }
        switch(confDestConnection.confDestination.protocol) { 
            case 9: { 
            displayText = `${confDestConnection.confDestination.destinationHttpprofile?.host}`
                break; 
            }
            case 1: { 
            displayText = `${confDestConnection.confDestination.destinationSbprofile?.queue} (${confDestConnection.confDestination.destinationSbprofile?.namespace})`
                break; 
            } 
            case 2: { 
            displayText = `\\${confDestConnection.confDestination.destinationSharedFolderProfile?.server}${confDestConnection.confDestination.destinationSharedFolderProfile?.path}`
                break; 
            }  
            case 7: { 
            displayText = `${confDestConnection.confDestination.destinationMqprofile?.queue} (${confDestConnection.confDestination.destinationMqprofile?.host}:${confDestConnection.confDestination.destinationMqprofile?.port}, ${confDestConnection.confDestination.destinationMqprofile?.qm})`
                break; 
            } 
            default: { 
                break; 
            } 
        }
        return displayText;
    }
}
