import { Component, OnInit, Inject } from '@angular/core';
import { TrackingView } from '../models/tracking-view';
import { Tracking } from '../models/tracking';
import { TabModel } from 'src/app/models/tab-model';
import { BlobRequest } from '../models/blob-request';
import { BlobResponse } from '../models/blob-response';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessageTrackerService } from '../messages/message-tracker.service';
import { ActivatedRoute } from '@angular/router';
import { CorrelatedMessageService } from './correlated-message.service';
import { ViewMessageComponent } from '../child-components/view-message.component';

@Component({
  selector: 'message-tracker',
  templateUrl: './correlated-message.component.html'
})
export class CorrelatedMessageComponent implements OnInit {
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = false;
    public correlationId: string = '';
    public trackingViews: Array<TrackingView>;

    constructor(public cService: CorrelatedMessageService, private modalService: NgbModal, private route: ActivatedRoute) {
        this.route.queryParams.subscribe(params => {
            this.correlationId = params['correlationId'];
        });
    }

    ngOnInit(): void {
        this.showSpinner = true;
        this.getTrackingData();
    }

    private getTrackingData() {
        this.error = false;
        this.errorMessage = '';
        
        this.cService.getTrackingData(this.correlationId).subscribe(
            {
                next: (trackingData) => {      
                    this.trackingViews = trackingData;
                    this.showSpinner = false;
                },
                error: (error) => {
                  this.showSpinner = false; 
                  this.displayErrorMessage(error);
                }
              }
          );
    }

    public openViewConfig(tv: TrackingView) {
        const modalRef = this.modalService.open(ViewMessageComponent, { size: 'size_80' });
        modalRef.componentInstance.trackingView = tv;
    }

    private displayErrorMessage(error: any) {
        let formattedError = '';
        if(error?.error && error?.error.error == undefined) {
        if(error.error.includes('at al.goh.admin.tool.api')) {
            formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
        } else {
            formattedError = error.error;
        }
        } else {
        if(error?.error?.error) { 
            formattedError = error?.error.error;
        } else {
            formattedError = 'Unknown eror';
        }      
        }

        this.errorMessage = `Error when decoding message. Error Message ${formattedError}`;
        this.error = true;
        console.error(error); 
    }
}
