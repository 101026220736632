import { DestinationFtpprofile } from "./destination-ftp-profile";
import { DestinationHttpprofile } from "./destination-http-profile";
import { DestinationMqprofile } from "./destination-mq-profile";
import { DestinationSbprofile } from "./destination-sb-profile";
import { DestinationSftpprofile } from "./destination-sftp-profile";
import { DestinationSharedFolderProfile } from "./destination-shared-folder-profile";

export class ConfDestination {
    id: number;
    description: string;
    type: number;
    protocol: number;
    profileId: number;
    destinationHttpprofile: DestinationHttpprofile;
    destinationMqprofile: DestinationMqprofile;
    destinationSbprofile: DestinationSbprofile;
    destinationSharedFolderProfile: DestinationSharedFolderProfile;
    destinationSftpprofile: DestinationSftpprofile;
    destinationFtpprofile: DestinationFtpprofile
    createdBy: string;
    createTimestamp: string;
    outboundPartnerId: number;
}