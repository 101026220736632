import { KeyVaultProfile } from "../key-vault-profile";

export class DestinationSftpprofile {
    id: number;
    name: string;
    host: string;
    port: string;
    path: string;
    tempPath: string;
    authenticationTypeId: number;
    keyVaultProfile: KeyVaultProfile;
    userName: string;
    keyVaultProfileId: number;
    passPhrase: string;
    encoding: string;
    filename: string;
    createdBy: string;
    createTimestamp: string;
}
