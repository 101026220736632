import { Component, Input, Output, EventEmitter, OnInit, Optional } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Partner } from 'src/app/models/partner/partner';
import { PartnerService } from '../partner.service';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { UtilService } from 'src/app/common-services/util.service';
import { PartnerRequestModel } from '../models/partner-request-model';
import { PreStepService } from '../../services/pre-step.service';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';

@Component({
  selector: 'add-partner',
  templateUrl: './add-partner.component.html'
})
export class AppPartnerComponent implements OnInit {
    @Input() nextPartnerId: number;

    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public partner: Partner = new Partner()
    public resolversddl: Array<DropDownListModel>;
    public preStepClassesDDL: Array<DropDownListModel>;
    public resolverId: number = -1;
    public preStepClassId: number = -1;

    constructor(public partnerService: PartnerService, public preStepService: PreStepService, private errorParserService: ErrorParserService, private utilService: UtilService, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.showSpinner = true;

        // TODO Best practice is to avoid multiple calls to backend for populating frontend dropdowns etc.
        //      But for now this will do
        this.partnerService.getResolverClasses().subscribe({
            next: (result) => {
                this.showSpinner = false;
                this.resolversddl = new Array<DropDownListModel>();
                result.forEach(x => {
                    let d: DropDownListModel = {
                        value: x.id.toString(),
                        text: `${this.utilService.getClassName(x.resolver)} (${x.description})`
                    };

                    this.resolversddl.push(d);
                });
            },
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });

        this.preStepService.getPreStepClasses().subscribe({
            next: (result) => {
                this.showSpinner = false;
                this.preStepClassesDDL = new Array<DropDownListModel>();
                result.forEach(x => {
                    let d: DropDownListModel = {
                        value: x.id.toString(),
                        text: `${this.utilService.getClassName(x.preStepClass)} (${x.description})`
                    };

                    this.preStepClassesDDL.push(d);
                });
            },
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });        
    }

    public save() {
        this.showSpinner = true;
        this.partner.id = this.nextPartnerId;
        let partnerRequestModel: PartnerRequestModel = new PartnerRequestModel();
        partnerRequestModel.partner = this.partner;
        partnerRequestModel.resolverClassId = this.resolverId;
        partnerRequestModel.preStepClassId = this.preStepClassId;

        this.partnerService.addPartner(partnerRequestModel).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false;
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false;
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }
}
