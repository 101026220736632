import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { Scheduler } from 'src/app/models/source/scheduler';
import { MDSourceInputService } from './md-source-input.service';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { Partner } from 'src/app/models/partner/partner';

@Component({
  selector: 'delete-source-input', 
  templateUrl: './delete-source-modal.component.html',
})
export class DeleteSourceModalComponent {
  @Input() partner: Partner;
  @Input() scheduler: Scheduler;
  public errorMessage: string = '';
  public showSpinner: boolean = false;

  constructor(public activeModal: NgbActiveModal, public sourceInputService: MDSourceInputService, private errorParserService: ErrorParserService) {}

  public errorModel: ErrorModel = null;

  public delete() {
    this.showSpinner = true;
    this.sourceInputService.deleteScheduler(this.scheduler).subscribe(
          {
              next: (result) => {
                this.showSpinner = false;
                this.activeModal.close('delete');
              },
              error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
              }
            }
      );
  }
}
