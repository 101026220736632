import { Component, Input, OnInit } from '@angular/core';
import { PostFormattingClass } from 'src/app/models/post-formatting-class';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'post-formatting-input',
  templateUrl: './post-formatting-input.component.html',
})
export class PostFormattingInputComponent implements OnInit {
  @Input() postFormatters: Array<PostFormattingClass>;  
  @Input() formGroupName: string;  
  
  public postFormattingForm: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.postFormattingForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  selectChangeHandler(event: any) {
    let selectedPostFormatter = this.postFormatters.find(x => x.id === +event.target.value);

    this.postFormattingForm.controls['postFormattingClassName'].setValue(selectedPostFormatter.formattingClass);
    this.postFormattingForm.controls['postFormattingClassDescription'].setValue(selectedPostFormatter.description);
  }
}
