import { Component, OnInit } from '@angular/core';
import { OutboundMapXslt } from 'src/app/models/mapping/outbound-map-xslt';
import { MappingDocumentModel } from '../mapping-document-model';
import { MappingDocumentService } from '../mapping-document.service';
import { CanonicalToOutboundMappingDocumentsGridView } from '../models/canonical-to-outbound-mapping-documents-gridview';
import { PagingService } from 'src/app/common-services/paging.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppCanonicalToOutboundMappingDocumentComponent } from './add-canonical-to-outbound-mapping-documents.component';
import { MsalService } from '@azure/msal-angular';
import { DeleteCanonicalToOutboundMappingDocumentComponent } from './delete-canonical-to-outbound-mapping-documents.component';
import { EditCanonicalToOutboundMappingDocumentComponent } from './edit-canonical-to-outbound-mapping-documents.component';

@Component({
    selector: 'md-canonical-to-outbound-mapping-documents',
    templateUrl: './md-canonical-to-outbound-mapping-documents.component.html',
  })
export class MasterDataCanonicalToOutboundMappingDocumentsComponent  implements OnInit {
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = true;
    public comdGridView: CanonicalToOutboundMappingDocumentsGridView;
    public mappingDocumentGridList: Array<MappingDocumentModel> = new Array<MappingDocumentModel>();
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public filterCanonicalToOutboundMappingDocumentName: string = '';
    public filterCanonicalToOutboundMappingDocumentDescription: string = '';
    // Paging
    public totalPages: number = 0;
    public currentPage: number = 1;
    public startIndex: number = 0;
    public endIndex: number = 10;
    public pageCount: number = 10;
    public lastDisplayIndex: number = 0;
    public nextButtonDisabled: boolean = false;

    constructor(public mappingDocumentService: MappingDocumentService, public pService: PagingService, private authService: MsalService, private modalService: NgbModal) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        this.getData();
    }

    private getData() {
        this.mappingDocumentService.getCanonicalToOutboundMappingDocuments(this.currentPage, this.pageCount, this.filterCanonicalToOutboundMappingDocumentName, this.filterCanonicalToOutboundMappingDocumentDescription).subscribe(
        {
          next: (result) => {
            this.comdGridView = result;
            this.setLastDisplayIndex();
            this.totalPages = Math.floor(this.comdGridView.totalRows/this.pageCount) + (this.comdGridView.totalRows % this.pageCount > 0 ? 1 : 0);
            this.showSpinner = false;
          },
          error: (error) => {
            this.setErrorText(error);
            this.showSpinner = false;
          }
        });
    }

    public onFilter() {
        this.currentPage = 1;
        this.getData();
    }

    public createNewCanonicalToOutboundMappingDocument() {
        const modalRef = this.modalService.open(AppCanonicalToOutboundMappingDocumentComponent, { size: 'size_40' });
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    public editCanonicalToOutboundMappingDocument(gridRow: OutboundMapXslt) {
        const modalRef = this.modalService.open(EditCanonicalToOutboundMappingDocumentComponent, { size: 'size_40' });
        modalRef.componentInstance.outboundMapXslt = gridRow;
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    private setErrorText(error: any) {
        this.error = true;
        let msg: string = '';
        let title: string = error?.error?.title;
        msg = (title !== undefined) ? title : error?.error;
        this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
        console.error(error);
    }

    public removeCanonicalToOutboundMappingDocument(gridRow: OutboundMapXslt) {
      const modalRef = this.modalService.open(DeleteCanonicalToOutboundMappingDocumentComponent, { size: 'size_40' });
      modalRef.componentInstance.outboundMapXslt = gridRow;
      modalRef.result.then((result) => {
          if(result === 'delete') {
              // Refresh grid after save
              this.getData();
          }
      }, (reason) => {
          // User clicked outside modal window => do nothing (same as cancel)
      });
  }

    // --- Paging ----------------------------------------------------------------------------------
    public onclickPrev() {
        if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
            this.setStartEndIndex();
            this.getData();
        }
    }

    private setLastDisplayIndex() {
        if((this.currentPage) * this.pageCount > this.comdGridView.totalRows) {
            this.lastDisplayIndex = this.comdGridView.totalRows;
        } else {
            this.lastDisplayIndex = (this.currentPage) * this.pageCount;
    }
    }

    public onclickNext() {
        if ((this.currentPage) * this.pageCount < this.comdGridView.totalRows) {
            this.nextButtonDisabled = false;
            this.currentPage = this.currentPage + 1;
            this.setStartEndIndex();
            this.getData();
        } else {
            this.nextButtonDisabled = true;
        }
    }

    private setStartEndIndex() {
        this.startIndex = ((this.currentPage - 1) * this.pageCount);
        this.endIndex = ((this.currentPage - 1) * this.pageCount) + this.pageCount;
    }

    public onclickPage(page: number, index: number) {
        this.currentPage = this.currentPage === page ? this.currentPage : page;
        this.setStartEndIndex();
        this.getData();
    }
    selectChangeHandler(event: any) {
        this.currentPage = 1;
        this.setStartEndIndex();
        this.getData();
    }
}
