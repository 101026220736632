import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CanonicalMapXslt } from 'src/app/models/mapping/canonical-map-xslt';
import { MappingDocumentService } from '../mapping-document.service';
import { DropDownListModelNumber } from 'src/app/models/dropdown-list-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'edit-inbound-to-canonical-mapping-documents',
  templateUrl: './edit-inbound-to-canonical-mapping-documents.component.html'
})
export class EditInboundToCanonicalMappingDocumentComponent implements OnInit {
    @Input() canonicalMapXslt: CanonicalMapXslt;
    public canonicalMapXsltForm: FormGroup;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public nextId: number;
    public modelTypes: Array<DropDownListModelNumber>;
    public modelTypeVersions: Array<DropDownListModelNumber>;

    constructor(public mtService: MappingDocumentService, private errorParserService: ErrorParserService, public activeModal: NgbActiveModal) {}

    get form() { return this.canonicalMapXsltForm.controls; }

    ngOnInit(): void {
        this.canonicalMapXsltForm = this.mtService.getInboundToCanonicalMappingForm(this.canonicalMapXslt);
        this.modelTypes = this.mtService.getModelTypes();
        this.modelTypeVersions = this.mtService.getModelTypeVersions();
    }

    public save() {
        this.showSpinner = true; 
        let model = this.mtService.getInboundToCanonicalMappingModel(this.canonicalMapXsltForm);
        this.mtService.updateInboundToCanonicalMappingDocument(model).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }
}
