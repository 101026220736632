<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div>
<div class="row mb5" *ngIf="!isSuperUser">
  <b>Note: In order to add, edit and remove outbound message types the Super User role is required</b>
</div>
<div>
  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="omtGridView">
      <thead>
        <tr>
          <th scope="col" colSpan="9">
            <div>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="!isSuperUser" (click)="createNewOutboundMessageType()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
            </div>
          </th>
        </tr>
      </thead>
      <thead>
          <tr>
              <th class="vtop">
                <div>Id</div>
              </th>
              <th>
                <div>Message type</div>
                <div>
                  <input type="text" class="form-control" id="messageTypeName" name="messageTypeName" placeholder="Filter message type name" [(ngModel)]="filterMessageTypeName" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                </div>
              </th>
              <th>
                <div>Description</div>
                <div>
                  <input type="text" class="form-control" id="messageTypeDescription" name="messageTypeDescription" placeholder="Filter description" [(ngModel)]="filterMessageTypeDescription" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                </div>
              </th>                      
              <th class="vtop">
                <div>Created by</div>
              </th>  
              <th class="vtop">
                <div>Created at</div>
              </th>  
              <th class="vtop">
                <div>Updated by</div>
              </th>  
              <th class="vtop">
                <div>Updated at</div>
              </th>  
              <th>                
              </th>  
          </tr>
      </thead>
      <tbody *ngIf="messageTypeGridList">
          <tr *ngFor="let omt of omtGridView.rows;let index = index;">
              <td>
                <span>{{omt.messageTypeId}}</span>
              </td>
              <td>
                <span>{{omt.messageName}}</span>
              </td>            
              <td>
                <span>{{omt.messageTypeDescription}}</span>
              </td>
              <td>
                <span>{{omt.createdBy}}</span>                
              </td>
              <td>
                <span>{{omt.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>                
              </td>   
              <td>
                <span>{{omt.updatedBy}}</span>                
              </td>
              <td>
                <span>{{omt.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>                
              </td>             
              <td>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="!isSuperUser" (click)="editOutboundMessageType(omt)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="!isSuperUser" (click)="removeOutboundMessageType(omt)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
              </td>
          </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="11">
            <div>
              <table>
                <tr>
                  <td style="margin: auto;">
                    <nav>
                      <ul class="pagination">
                        <li class="page-item" ng-class="{disabled: currentPage == 1}">
                          <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(outboundMessageTypesPaging.currentPage, 1, outboundMessageTypesPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(outboundMessageTypesPaging.currentPage, 1, outboundMessageTypesPaging.totalPages), 1)">{{pService.getPageNumber(outboundMessageTypesPaging.currentPage, 1, outboundMessageTypesPaging.totalPages)}}</a></li>
                        <li class="page-item" *ngIf="outboundMessageTypesPaging.totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(outboundMessageTypesPaging.currentPage, 2, outboundMessageTypesPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(outboundMessageTypesPaging.currentPage, 2, outboundMessageTypesPaging.totalPages), 2)">{{pService.getPageNumber(outboundMessageTypesPaging.currentPage, 2, outboundMessageTypesPaging.totalPages)}}</a></li>
                        <li class="page-item" *ngIf="outboundMessageTypesPaging.totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(outboundMessageTypesPaging.currentPage, 3, outboundMessageTypesPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(outboundMessageTypesPaging.currentPage, 3, outboundMessageTypesPaging.totalPages), 3)">{{pService.getPageNumber(outboundMessageTypesPaging.currentPage, 3, outboundMessageTypesPaging.totalPages)}}</a></li>
                        <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                          <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                        </li>
                      </ul>
                      </nav>
                  </td>
                  <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                    <label>{{(((outboundMessageTypesPaging.currentPage) - 1) * outboundMessageTypesPaging.pageCount) + 1}} - {{outboundMessageTypesPaging.lastDisplayIndex}} of {{omtGridView.totalRows}}</label>
                  </td>
                  <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;"> 
                    <select dropdown (change)="selectChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="outboundMessageTypesPaging.pageCount">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>                  
              </table>
            </div>
          </td>
        </tr>
      </tfoot>
  </table>
</div>