import { Component, OnInit, Input } from '@angular/core';
import { InboundMapping } from './../models/inbound-mapping';
import { PagingService } from 'src/app/common-services/paging.service';
import { Configuration } from 'src/app/models/configuration';
import { CustomerConfigurationsService } from 'src/app/customer-configurations/list/customer-configurations.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewConfigurationModalComponent } from 'src/app/common-components/view-configuration/view-configuration-modal.component';

@Component({
  selector: 'in-mapping-explorer',
  templateUrl: './in-mapping-explorer.component.html'
})
export class InMappingExplorerComponent implements OnInit {
    @Input() maps: Array<InboundMapping>;;

    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = false;
    public mapCount: number = 0;
    public currentPage: number = 1;
    public startIndex: number = 0;
    public endIndex: number = 10;
    public pageCount = 10;
    public totalPages = 0;
    public lastDisplayIndex: number = 0;
    public nextButtonDisabled: boolean = false;
    public mappingType: number = 1;
    public vewConfigurationDisplayStyle: string = "none";
    public viewConfigData: Configuration;

    public originalMaps: Array<InboundMapping> = [];
    public allMaps: Array<InboundMapping> = [];
    public pagedMaps: Array<InboundMapping> = [];

    public filterMapName: string;
    public filterMapDescription: string;
    public filterPartnerId: string;
    public filterMessageType: string;
    public filterSenderId: string;
    public filterSenderName: string;
    public filterReceiverId: string;
    public filterReceiverName: string;
    public filterLegalEntity: string;
    public filterAreaPlant: string;

    constructor(public pService: PagingService, public ccService: CustomerConfigurationsService, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.mapCount = this.maps.length;

        this.allMaps = this.maps;
        this.originalMaps = this.maps;
        this.pagedMaps = this.maps.slice(this.startIndex, this.endIndex);
        
        this.lastDisplayIndex = (this.currentPage) * this.pageCount;
        this.totalPages = Math.floor(this.mapCount/this.pageCount) + (this.mapCount % this.pageCount > 0 ? 1 : 0);
    }

    public viewConfig(im: InboundMapping) {
        const modalRef = this.modalService.open(ViewConfigurationModalComponent, { size: 'size_80' });
        modalRef.componentInstance.entityMapId = im.entityMapId;
    }

    // --- Paging ----------------------------------------------------------------------------------  
    public onclickPrev() {
    if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
        this.setStartEndIndex();
        this.lastDisplayIndex = (this.currentPage) * this.pageCount;
        this.pagedMaps = this.allMaps.slice(this.startIndex, this.endIndex);
    }
    }

    public onclickNext() {
        if ((this.currentPage) * this.pageCount < this.mapCount) {
            this.nextButtonDisabled = false;
            this.currentPage = this.currentPage + 1;
            this.setStartEndIndex();

            if((this.currentPage) * this.pageCount > this.mapCount) {
                this.lastDisplayIndex = this.mapCount;
            } else {
                this.lastDisplayIndex = (this.currentPage) * this.pageCount;        
            }

            this.pagedMaps = this.allMaps.slice(this.startIndex, this.endIndex);
        } else {
            this.nextButtonDisabled = true;
        }
    }

    public onclickPage(page: number, index: number) {
        this.currentPage = this.currentPage === page ? this.currentPage : page;
        this.setStartEndIndex();
        if((this.currentPage) * this.pageCount > this.mapCount) {
            this.lastDisplayIndex = this.mapCount;
        } else {
            this.lastDisplayIndex = (this.currentPage) * this.pageCount;        
        }
        this.pagedMaps = this.allMaps.slice(this.startIndex, this.endIndex);
    }

    private setStartEndIndex() {
        this.startIndex = ((this.currentPage - 1) * 10);
        this.endIndex = ((this.currentPage - 1) * 10) + 10;
    }

    public onFilter() {
    this.allMaps = this.originalMaps;      

    if(this.filterMapName !== undefined && this.filterMapName.length > 0) {
        this.allMaps = this.allMaps.filter((x) => x.mapName.toLowerCase().includes(this.filterMapName.toLowerCase()));
    }
    if(this.filterMapDescription !== undefined && this.filterMapDescription.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.senderName.toLowerCase().includes(this.filterMapDescription.toLowerCase()));
    }
    if(this.filterPartnerId !== undefined && this.filterPartnerId.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.partnerId.toLowerCase().includes(this.filterPartnerId.toLowerCase()));
    }
    if(this.filterMessageType !== undefined && this.filterMessageType.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.messageType.toLowerCase().includes(this.filterMessageType.toLowerCase()));
    }
    if(this.filterSenderId !== undefined && this.filterSenderId.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.senderId.toLowerCase().includes(this.filterSenderId.toLowerCase()));
    }
    if(this.filterSenderName !== undefined && this.filterSenderName.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.senderName.toLowerCase().includes(this.filterSenderName.toLowerCase()));
    }
    if(this.filterReceiverId !== undefined && this.filterReceiverId.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.receiverId.toLowerCase().includes(this.filterReceiverId.toLowerCase()));
    }
    if(this.filterReceiverName !== undefined && this.filterReceiverName.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.receiverName.toLowerCase().includes(this.filterReceiverName.toLowerCase()));
    }
    if(this.filterLegalEntity !== undefined && this.filterLegalEntity.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.legalEntityName.toLowerCase().includes(this.filterLegalEntity.toLowerCase()));
    }
    if(this.filterAreaPlant !== undefined && this.filterAreaPlant.length > 0) {
        this.allMaps = this.allMaps.filter((cat) => cat.areaPlantName.toLowerCase().includes(this.filterAreaPlant.toLowerCase()));
    }

    if((this.filterMapName === undefined || this.filterMapName === "") &&
        (this.filterMapDescription === undefined || this.filterMapDescription === "") && 
        (this.filterPartnerId === undefined || this.filterPartnerId === "") && 
        (this.filterMessageType === undefined || this.filterMessageType === "") && 
        (this.filterSenderId === undefined || this.filterSenderId === "") && 
        (this.filterSenderName === undefined || this.filterSenderName === "") && 
        (this.filterReceiverId === undefined || this.filterReceiverId === "") && 
        (this.filterReceiverName === undefined || this.filterReceiverName === "") && 
        (this.filterLegalEntity === undefined || this.filterLegalEntity === "") && 
        (this.filterAreaPlant === undefined || this.filterAreaPlant === "")
        ) {
        this.allMaps = this.originalMaps;      
    }

    this.currentPage = 1;
    this.setStartEndIndex();

    this.pagedMaps = this.allMaps.slice(this.startIndex, this.endIndex);
    this.lastDisplayIndex = this.allMaps.length >= this.pageCount ? (this.currentPage) * this.pageCount : this.allMaps.length;
    this.mapCount = this.allMaps.length;   
    this.totalPages = Math.floor(this.mapCount/this.pageCount) + (this.mapCount % this.pageCount > 0 ? 1 : 0);
    }

    private displayErrorMessage(error: any) {
        let formattedError = '';
        if(error?.error && error?.error.error == undefined) {
        if(error.error.includes('at al.goh.admin.tool.api')) {
            formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
        } else {
            formattedError = error.error;
        }
        } else {
        if(error?.error?.error) {
            formattedError = error?.error.error;
        } else {
            formattedError = 'Unknown eror';
        }
        }

        this.errorMessage = `Error when decoding message. Error Message ${formattedError}`;
        this.error = true;
        console.error(error);
    }
}
