import { Component, Input } from '@angular/core';
import { UtilService } from 'src/app/common-services/util.service';
import { ResponseResolverUpdateData } from 'src/app/models/response-resolver/response-resolver-update-data';

@Component({
  selector: 'review-response-resolver',
  templateUrl: './review-response-resolver.component.html',
})
export class ReviewResponseResolverComponent {
    @Input() responseResolver: ResponseResolverUpdateData

    constructor(public utilService: UtilService) {}
}
