import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ViewConfigurationModelService } from 'src/app/common-components/view-configuration/view-configuration-modal.service';
import { ReviewwInputService } from '../review-input.service';

@Component({
  selector: 'review-as2-confout',
  templateUrl: './review-as2-confout.component.html',
})
export class ReviewAS2ConfoutComponent {
  form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, public rs: ReviewwInputService, public viewService: ViewConfigurationModelService) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.form;
  }
}
