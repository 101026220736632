import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EdiModel } from '../../models/edi/edi-decode-model';

@Injectable({
  providedIn: 'root',
})
export class EdiEncodeDecodeService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public edifactDecode(ediModel: EdiModel) {
        return this.http.post<any>(this.baseUrlLocal + 'edifactdecode', ediModel);
    }

    public edifactEncode(ediModel: EdiModel) {
        return this.http.post<any>(this.baseUrlLocal + 'edifactencode', ediModel);
    }

    public x12Decode(ediModel: EdiModel) {
        return this.http.post<any>(this.baseUrlLocal + 'x12decode', ediModel);
    }

    public x12Encode(ediModel: EdiModel) {
        return this.http.post<any>(this.baseUrlLocal + 'x12encode', ediModel);
    }

    public encode(message: string) {
        return this.http.put<string>(this.baseUrlLocal + 'encode', message);
    }
}


