import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { generate } from 'rxjs';
import { KeyVaultSecret } from 'src/app/models/key-vault/key-vault-secret';
import { Partner } from 'src/app/models/partner/partner';
import { ConfigurationWizardService } from '../../configuration-wizard.service';

@Component({
  selector: 'inbound-partner-input',
  templateUrl: './partner-input.component.html'
})
export class PartnerInputComponent implements OnInit {
  @Input() partners: Array<Partner>;
  @Input() formGroupName: string;  
  @Output() partnerUpdated = new EventEmitter<any>();
  
  public inboundPartnerForm: FormGroup;
  public useBasicAuthentication: boolean = false;
  public password: string = '';
  public keyVaultSecret: KeyVaultSecret = null;

  constructor(private rootFormGroup: FormGroupDirective, private wService: ConfigurationWizardService) {}

  ngOnInit(): void {
    this.inboundPartnerForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  get form() { return this.inboundPartnerForm.controls; }

  public onUseBasicAuthenticationChange(event: any) {
    if(event.currentTarget.checked === false) {
      // Clear values is we deselect to use basic auth!
      this.inboundPartnerForm.controls['basicAuthenticationUsername'].setValue('');
      this.inboundPartnerForm.controls['basicAuthenticationPassword'].setValue('');
    }
  }

  selectInboundPartnerIdChangeHandler(event: any) {
    let selectedPartner = this.partners.find(x => +x.id === +event.target.value);

    this.inboundPartnerForm.controls['partnerId'].setValue(selectedPartner.partnerId);
    this.inboundPartnerForm.controls['name'].setValue(selectedPartner.name);
    this.inboundPartnerForm.controls['description'].setValue(selectedPartner.description);

    this.partnerUpdated.emit();
  }
}