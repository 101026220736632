<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div>
  <fieldset class="border p-2">
    <legend class="float-none w-auto" style="font-size: 16px; font-weight: bold;"><span
        style="margin-left: 5px; margin-right: 5px;">EDI Pre-Formatting</span></legend>

    <div style="margin-bottom: 10px;">
      <span class="text-muted"><small>EDI Pre-formatters are used to transform incomming EDI-data</small></span>
    </div>
    <hr />
    <div>
      <fieldset class="border p-2 mb10 mt10" *ngIf="edipfGridList">
        <legend class="float-none w-auto" style="font-size: 16px; font-weight: bold;"><span
            style="margin-left: 5px; margin-right: 5px;">Added EDI Pre-formatters</span></legend>
        <div>
          <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="edipfGridList">
            <thead>
              <tr>
                <th scope="col" colSpan="10">
                  <div>
                    <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder"
                      (click)="addPartnerEdiPreFormatter()" style="float: right;"><i class="fa fa-plus"
                        aria-hidden="true"></i></button>
                  </div>
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>
                  <div>Description</div>
                </th>
                <th>
                  <div>RegEx</div>
                </th>
                <th>
                  <div>ReplaceValue</div>
                </th>
                <th>
                  <div>Encoding</div>
                </th>
                <th>
                  <div>Order</div>
                </th>
                <th>
                  <div>Created by</div>
                </th>
                <th>
                  <div>Created at</div>
                </th>
                <th>
                  <div>Updated by</div>
                </th>
                <th>
                  <div>Updated at</div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="edipfGridList != undefined && edipfGridList.length === 0">
              <tr>
                <td scope="col" colSpan="10">No EDI Preformatters added</td>
              </tr>
            </tbody>
            <tbody *ngIf="edipfGridList!= undefined && edipfGridList.length > 0">
              <tr *ngFor="let edipf of edipfGridList;let index = index;">
                <td>
                  <span>{{edipf.description}}</span>
                </td>
                <td>
                  <span>{{edipf.regEx}}</span>
                </td>
                <td>
                  <span>{{edipf.replaceValue}}</span>
                </td>
                <td>
                  <span>{{edipf.encoding}}</span>
                </td>
                <td>
                  <span>{{edipf.order}}</span>
                </td>
                <td>
                  <span>{{edipf.createdBy}}</span>
                </td>
                <td>
                  <span>{{edipf.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
                </td>
                <td>
                  <span>{{edipf.updatedBy}}</span>
                </td>
                <td>
                  <span>{{edipf.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
                </td>
                <td>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder"
                    (click)="editPartnerEdiPreFormatter(edipf)" style="margin-right: 5px;"><i class="fa fa-pencil"
                      aria-hidden="true"></i></button>
                  <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder"
                    (click)="removePartnerEdiPreFormatter(edipf)"><i class="fa fa-trash-o"
                      aria-hidden="true"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>
      </fieldset>
      <br />
    </div>
  </fieldset>
</div>