
<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="errorModel">
  <b>Error in EDI Configuration view. Error Message: {{errorModel.errorMessage}}</b>
</div>
<div style="margin-bottom: 10px;">
  <ul ngbNav #nav="ngbNav" [(activeId)]="tabModel.activeTab" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="onclickGoToTab(1)">Partner</a>
      <ng-template ngbNavContent>
        <div class="ml5">
          <div class="row">
            <div class="col-12">
              <form>        
                <div class="form-check">
                  <h3>Partners</h3>
                  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="partnerList">
                    <thead>
                      <tr>
                        <th scope="col" colSpan="7">
                          <div>
                            <button class="btn btn-outline-secondary btn-sm" (click)="addPartner()" style="float: right;">Add partner</button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th><div>Name</div></th>
                            <th><div>Partner type</div></th>
                            
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let v of partnerList.value">
                            <td>{{ v.name }}</td>
                            <td>{{ v.properties.partnerType }}</td>                                        
                            <td>
                              <button class="btn btn-outline-secondary btn-sm" (click)="editPartner(v.name)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="removePartner(v.name)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>                 
              </form>                    
            </div>
          </div>
          <!-- <div class="row" style="margin-top: 10px;">
            <div class="col-10">                
              <button class="btn btn-outline-secondary btn-sm" (click)="onclickGoToTab(2)">Next : Basic information</button>
            </div>
          </div> -->
        </div>        
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink (click)="onclickGoToTab(2)">Agreement</a>
      <ng-template ngbNavContent>

          <div class="ml5">
            <div class="row">
              <div class="col-12">
                <form>        
                  <div class="form-check">
                    <h3>Agreements</h3>
                    <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="agreements">
                      <thead>
                        <tr>
                          <th scope="col" colSpan="7">
                            <div>
                              <button class="btn btn-outline-secondary btn-sm" (click)="addAgreement()" style="float: right;">Add aggreement</button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <thead>
                          <tr>
                              <th><div>Name</div></th>
                              <th><div>Guest partner</div></th>
                              <th><div>Host partner</div></th>
                              <th><div>Guest identity qualifier</div></th>
                              <th><div>Guest identity value</div></th>
                              <th><div>Host identity qualifier</div></th>
                              <th><div>Host identity value</div></th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let v of agreements.value">
                              <td>{{ v.name }}</td>
                              <td>{{ v.properties.guestPartner }}</td>            
                              <td>{{ v.properties.hostPartner }}</td>
                              <td>{{ v.properties.guestIdentity.qualifier }}</td>
                              <td>{{ v.properties.guestIdentity.value }}</td>
                              <td>{{ v.properties.hostIdentity.qualifier }}</td>
                              <td>{{ v.properties.hostIdentity.value }}</td>
                              <td>
                                <button class="btn btn-outline-secondary btn-sm" (click)="editAgreement(v)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="removeAgreement(v)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  </div>                 
                </form>    
              </div>
            </div>            
          </div>  
      </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':viewDeleteConfirmWindowDisplayStyle}" *ngIf="viewDeleteConfirmWindow">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Delete agreement {{deleteAgreementName}}</h4>
      </div>
      <div class="modal-body">         
        <div class="row" style="margin-bottom: 15px;">
          <label for="receiverName" class="col-sm-12">Confirm delete of the agreement {{deleteAgreementName}}.</label>          
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="cancelDeleteAgreement()">Cancel</button>
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="deleteAgreement()">Delete</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':viewDeletePartnerConfirmWindowDisplayStyle}" *ngIf="viewDeletePartnerConfirmWindow">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Delete partner {{deletePartnerName}}</h4>
      </div>
      <div class="modal-body">         
        <div class="row" style="margin-bottom: 15px;">
          <label for="receiverName" class="col-sm-12">Confirm delete of partner {{deletePartnerName}}.</label>          
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="cancelDeletePartner()">Cancel</button>
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="deletePartner()">Delete</button>
      </div>
    </div>
  </div>
</div>

<upsert-agreement [displayStyle]="vewAddAgreementDisplayStyle" [isNew]="isNew" [agreementRequest]="agreementRequest" [certificatesPrivateDDL]="certificatesPrivateDDL" [certificatesPublicDDL]="certificatesPublicDDL" [certificates]="certificates" [schemas]="schemas" [schemasEdifactDDL]="schemasEdifactDDL" [schemasX12DDL]="schemasX12DDL" [partnerListDDL]="partnerListDDL" (returnedDisplayStyle)="onCloseAddAgreement()"></upsert-agreement>

<upsert-partner [displayStyle]="viewUpsertPartnerDisplayStyle" [isNew]="isNew" [partnerRequest]="partnerRequest" (returnedDisplayStyle)="onCloseUpsertPartner()"></upsert-partner>



