<!-- As as2Sender is required we can check if it exist in order to display this fieldset -->
<fieldset class="border p-2" *ngIf="form.get('outboundEdi.as2ConfOut')">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound AS2 aggrement lookup configuration</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Sender:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'outboundEdi.as2ConfOut', 'as2Sender') }}</label> 
        </div>
        <div class="col-sm-2">
            <label><b>Receiver:</b></label>
        </div>                        
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'outboundEdi.as2ConfOut', 'as2Receiver') }}</label> 
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>AS2 Agreement:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'outboundEdi.as2ConfOut', 'as2Agreement') }}</label> 
        </div>
        <div class="col-sm-2">
            <label><b>Response format:</b></label>
        </div>                        
        <div class="col-sm-3">
            <label>{{ viewService.getFormatType(+rs.getNestedFormControlValue(form, 'outboundEdi.as2ConfOut', 'responseFormat')) }}</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>MDN format:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ viewService.getFormatType(+rs.getNestedFormControlValue(form, 'outboundEdi.as2ConfOut', 'mdnformat')) }}</label>
        </div>
    </div>
</fieldset>