<!-- As hostidentityQualifier is required we can check if it exist in order to display this fieldset -->
<fieldset class="border p-2" *ngIf="form.get('outboundEdi.ediLookup')">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Outbound edifact aggrement lookup configuration</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Sender qualifier:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'outboundEdi.ediLookup', 'hostidentityQualifier') }}</label>
        </div>
        <div class="col-sm-2">
            <label><b>Sender Id:</b></label>
        </div>                        
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'outboundEdi.ediLookup', 'hostidentity') }}</label>
        </div>
    </div>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Receiver qualifier:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'outboundEdi.ediLookup', 'guestidentityQualifier') }}</label>
        </div>
        <div class="col-sm-2">
            <label><b>Receiver Id:</b></label>
        </div>                        
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'outboundEdi.ediLookup', 'guestidentity') }}</label>
        </div>
    </div>
</fieldset>