<div>
    <fieldset class="border p-2">
        <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Existing sources connected to partner {{partnerId}}</span></legend>

        <div style="margin-bottom: 10px;">
            For any protocol other then Http, the source is needed to be specified. Protocol can be Service Bus, MQ or File Share. Sources can be configured for a partner in the Master Data menu under Partners. Required role is Global Order Hub Administrator.
        </div>
        <!-- Configured scedulers -->
        <div>
            <div *ngIf="schedulers.length === 0">
                <p>No schedulers configured</p>
            </div>
            <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="schedulers.length > 0">
                <thead>
                    <tr>                        
                        <th>Name</th>
                        <th>Protocol</th>
                        <th>Enabled</th>
                        <th>Existing source selected</th>
                        <th>Validation type</th>
                        <th>EDI type</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let s of schedulers">
                        <td>{{s.name}}</td>
                        <td>{{ utilService.getProtocolName(s.source?.protocolType) }}</td>
                        <td>
                            {{ s.enabled === true ? 'YES' : 'NO' }}
                        </td>
                        <td>{{ s.source.profileId === 0 ? 'NO' : 'YES' }}</td>
                        <td>{{ sourceService.getValidationTypeText(s.validationType.toString()) }}</td>
                        <td>{{ sourceService.getEDITypeName(s.source.ediType) }} </td>
                        <td>
                            <source-view-table-data [source]="s.source"></source-view-table-data>                            
                        </td>                            
                    </tr>
                </tbody>                
            </table>
        </div>
    </fieldset>
</div>