<form [formGroup]="destinationSharedFolderprofileForm">
    <hr>
    <!-- Selection -->
   <div class="form-group row mb5" *ngIf="!isEdit">
       <div class="col-sm-3">        
       </div>
       <div class="col-sm-9">
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="true" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestination">
               Use existing destination
               </label>
           </div>
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="false" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestination">
                   Add new destination
               </label>
           </div>
       </div>
   </div>
   
   <!-- Use existing physical destination -->
   <div class="form-group row" *ngIf="destinationSharedFolderprofileForm.value.useExistingDestination === true">
       <label for="confDestinationDDL" class="col-sm-3 col-form-label control-label">Destinations <span class="req-color">*</span></label>
       <div class="col-sm-9">
           <select dropdown formControlName="id" id="confDestinationDDL" name="confDestinationDDL" class="form-select form-select-sm control-label">
           <option value="">--- Select destination ---</option>
           <option *ngFor="let item of sharedFolderProfiles" [value]="item.id">
               {{item.name}} ({{item.server}}{{item.path}})
           </option>
           </select>
       </div>
   </div>
   
   <!-- New physical destination -->
   <div *ngIf="destinationSharedFolderprofileForm.value.useExistingDestination === false">
        <!-- Server -->
       <div class="form-group row mb5">
           <label for="destinationHost" class="col-sm-3 col-form-label">Server<span class="req-color">*</span></label>
           <div class="col-sm-9">
               <input type="text" formControlName="server" class="form-control" id="destinationHost" name="destinationHost" placeholder="Server">
           </div>
       </div>
       <!-- Path -->
       <div class="form-group row mb5">
           <label for="destinationPath" class="col-sm-3 col-form-label">Path<span class="req-color">*</span></label>
           <div class="col-sm-9">
               <input type="text" formControlName="path" class="form-control" id="destinationPath" name="destinationPath" placeholder="Path">
           </div>
       </div>
       <!-- Filter -->
       <div class="form-group row mb5">
           <label for="destinationFilter" class="col-sm-3 col-form-label">Filter</label>
           <div class="col-sm-9">
               <input type="text" formControlName="filter" class="form-control" id="destinationFilter" name="destinationFilter" placeholder="Filter">
           </div>
       </div>
       <!-- Encoding -->
       <div class="form-group row mb5">
           <label for="destinationEncoding" class="col-sm-3 col-form-label">Encoding</label>
           <div class="col-sm-9">
               <input type="text" formControlName="encoding" class="form-control" id="destinationEncoding" name="destinationEncoding" placeholder="Encoding">
           </div>
       </div>
       <!-- Filename -->
       <div class="form-group row mb5">
           <label for="destinationEncoding" class="col-sm-3 col-form-label">Filename</label>
           <div class="col-sm-9">
               <input type="text" formControlName="filename" class="form-control" id="filename" name="filename" placeholder="Filename">
           </div>
       </div>
   </div>
</form>