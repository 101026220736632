import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
    public getYesNo(property: boolean) {
        return property === true ? 'YES' : 'NO';
    }

    public getProtocolName(protocol: number) {
        let protocolName = '';
        switch(protocol) { 
          case 9: { 
            protocolName = 'HTTP';
             break; 
          }
          case 1: { 
            protocolName = 'Service bus';
             break; 
          } 
          case 3: { 
            protocolName = 'FTP';
            break; 
          } 
          case 2: { 
            protocolName = 'Fileshare';
             break; 
          } 
          case 7: { 
            protocolName = 'MQ';
             break; 
          }
          case 8: { 
            protocolName = 'SFTP';
             break; 
          } 
          default: { 
             break; 
          } 
        }
    
        return protocolName;
    }

    public getProtocolValue(destinationProtocol: string) {
      let protocolValue: number;
      switch(destinationProtocol) { 
          case 'HTTP': { 
              protocolValue = 9;
              break; 
          }
          case 'SB': { 
              protocolValue = 1;
              break; 
          } 
          case 'FILESHARE': { 
              protocolValue = 2;
              break; 
          } 
          case 'MQ': { 
              protocolValue = 7;
              break; 
          }
          case 'FTP': { 
            protocolValue = 3;
            break; 
          }  
          case 'SFTP': { 
            protocolValue = 8;
            break; 
          }  
          default: { 
              break; 
          } 
      }

      return protocolValue;
  }

    public getClassName(namespaceAndClass: string) {
      let splitted = namespaceAndClass.split(".");
      return splitted[splitted.length - 1];
    }

    public getTypeText(type: number) {
      return type == 0 ? 'PRIMARY' : 'SECONDARY';
    }

    public getAuthenticationName(protocol: number) {
      let protocolName = '';
      switch(protocol) { 
        case 0: { 
          protocolName = 'None';
           break; 
        }
        case 1: { 
          protocolName = 'Basic'; // Use username and Password to authenticate
           break; 
        } 
        case 2: { 
          protocolName = 'OAuth'; // Use OAuth2 to authenticate using clientid, clientsecret and tenantid
           break; 
        } 
        case 3: { 
          protocolName = 'ClientCredential';  // Use Client credential to authenticate
           break; 
        }
        case 4: { 
          protocolName = 'Bearer';  // Use a Key to authenticate
           break; 
        }
        case 5: { 
          protocolName = 'Static authentication';  // Use a Key to authenticate
           break; 
        } 
        default: { 
           break; 
        } 
      }
  
      return protocolName;
  }

  public getQueryStart(queryStart: string) {
    if(queryStart === '') {
        return  '?';
    } else {
        return '&';
    }
  }
}
