import { Component, Input, OnInit } from '@angular/core';
import { OutboundMap } from 'src/app/models/mapping/outbound-map';
import { EdiConfigurationsService } from 'src/app/edi-configurations/edi-configurations.service';
import { MDOutboundMappingConnectionInputService } from 'src/app/master-data/partners/child-components/outboundMapping/md-canonicalToOutboundMappingConnection-input.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'outbound-mapping-input',
  templateUrl: './outbound-mapping-input.component.html',
})
export class OutboundMappingInputComponent implements OnInit {
  @Input() outboundMaps: Array<OutboundMap>;
  @Input() formGroupName: string;

  public outboundMapXsltForm: FormGroup;
  public rootForm!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, public ediService: EdiConfigurationsService,private outboundMappingService: MDOutboundMappingConnectionInputService) {}

  ngOnInit(): void {
    this.outboundMapXsltForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  get form() { return this.outboundMapXsltForm.controls; }

  selectOutboundMappingChangeHandler(event: any) {
    let outboundMapXslt = this.outboundMaps.find(x => x.outboundMapXsltId === +event.target.value)?.outboundMapXslt;
    this.outboundMapXsltForm.controls['outboundMapXsltName'].setValue(outboundMapXslt?.name);
    this.outboundMapXsltForm.controls['outboundMapXsltDescription'].setValue(outboundMapXslt?.description);
  }
}
