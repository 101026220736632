<form [formGroup]="ediPreFormatterForm" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">Edit EDI RegEx Preformatter</h4>
    </div>
    <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
    <error-display [errorModel]="errorModel"></error-display>
    <div class="modal-body">
        <div>
            <div class="form-group row">
                <label for="description" class="col-sm-4 col-form-label">Description<span
                        class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="description" required id="description"
                        name="description" placeholder="Description">
                    <div *ngIf="form.description.invalid && (form.description.dirty || form.description.touched)"
                        class="alert alert-danger">
                        <div *ngIf="form.description.errors?.['required']">Description is required.</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="regex" class="col-sm-4 col-form-label">RegEx<span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="regEx" required id="regEx" name="regEx"
                        placeholder="RegEx">
                    <div *ngIf="form.regEx.invalid && (form.regEx.dirty || form.regEx.touched)"
                        class="alert alert-danger">
                        <div *ngIf="form.regEx.errors?.['required']">RegEx is required.</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="replaceValue" class="col-sm-4 col-form-label">ReplaceValue<span
                        class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="replaceValue" required id="replaceValue"
                        name="replaceValue" placeholder="ReplaceValue">
                    <div *ngIf="form.replaceValue.invalid && (form.replaceValue.dirty || form.replaceValue.touched)"
                        class="alert alert-danger">
                        <div *ngIf="form.replaceValue.errors?.['required']">ReplaceValue is required.</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="encoding" class="col-sm-4 col-form-label">Encoding</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="encoding" id="encoding" name="encoding" placeholder="Encoding">
                </div>
            </div>
            <div class="form-group row">
                <label for="encoding" class="col-sm-4 col-form-label">Order</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="order" id="order" name="order"
                        placeholder="Order">
                    <div *ngIf="form.order.invalid && (form.order.dirty || form.order.touched)"
                        class="alert alert-danger">
                        <div *ngIf="form.order.errors?.['required']">Order is required.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="!ediPreFormatterForm.valid">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>
</form>