import { ResponseResolverClass } from "./response-resolver-class";

export class ResponseResolver
{
    id: number;
    description: string;
    createTimestamp: string;
    updateTimestamp: string;
    createdBy: string;
    updatedBy: string;
    resolver: string;
    responseResolverClassId: number;
    responseResolverClass: ResponseResolverClass
    partner: number;
    messageType: number;
}