import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParserClass } from 'src/app/models/parser/parser-class';
import { UtilService } from 'src/app/common-services/util.service';
import { MasterDataPrestepClassesService } from '../md-prestep-classes.service';
import { FormGroup } from '@angular/forms';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'add-prestep-class',
  templateUrl: './add-prestep-class.component.html'
})
export class AddPrestepClassComponent implements OnInit {
    public prestepClassForm: FormGroup;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public parserClass: ParserClass = new ParserClass();

    constructor(public service: MasterDataPrestepClassesService, private utilService: UtilService, private errorParserService: ErrorParserService, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.prestepClassForm = this.service.getEmptyPrestepClassForm();
    }

    get form() { return this.prestepClassForm.controls; }

    public save() {
        this.showSpinner = true; 
        let prestepClass = this.service.getPrestepClassModel(this.prestepClassForm);

        this.service.addPrestepClass(prestepClass).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }
}
