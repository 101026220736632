import { Component, OnInit, Inject } from '@angular/core';
import { ParserClass } from 'src/app/models/parser/parser-class';
import { ParserClassService } from './md-parser-class.service';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppParserClassComponent } from './child-components/add-parser-class.component';
import { EditParserClassComponent } from './child-components/edit-parser-class.component';
import { GridView } from 'src/app/models/grid-view';
import { PagingService } from 'src/app/common-services/paging.service';
import { UtilService } from 'src/app/common-services/util.service';
import { Paging } from 'src/app/models/paging/paging';

@Component({
    selector: 'md-parser-class',
    templateUrl: './md-parser-class.component.html',
  })
export class MasterDataParsersComponent  implements OnInit {
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = true;
    public parsers: Array<ParserClass>;
    public filterParser: string = '';
    public filterDescription: string = '';
    public gridView: GridView<ParserClass>;
    public parserPaging: Paging = new Paging();

    constructor(public parserClassService: ParserClassService, private authService: MsalService, private modalService: NgbModal, public pService: PagingService, public utilService: UtilService) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        this.getData();
    }

    private getData() {
        this.parserClassService.getParsers(this.parserPaging.currentPage, this.parserPaging.pageCount, this.filterParser, this.filterDescription).subscribe(
        {
          next: (result) => {
            this.gridView = result;
            this.parserPaging.setLastDisplayIndex();
            this.parserPaging.totalPages = Math.floor(this.gridView.totalRows/this.parserPaging.pageCount) + (this.gridView.totalRows % this.parserPaging.pageCount > 0 ? 1 : 0);
            this.showSpinner = false; 
          },
          error: (error) => {
            this.setErrorText(error);
            this.showSpinner = false;
          }
        });
    }

    public onFilter() {
        this.parserPaging.currentPage = 1;
        this.getData();
    }

    public createNewParser() {
        const modalRef = this.modalService.open(AppParserClassComponent, { size: 'size_40' });
        //const modalRef = this.modalService.open(AppParserClassComponent);
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    public editParser(gridRow: ParserClass) {
        const modalRef = this.modalService.open(EditParserClassComponent, { size: 'size_40' });
        modalRef.componentInstance.parserClass = gridRow;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    private setErrorText(error: any) {
        this.error = true;
        let msg: string = '';
        let title: string = error?.error?.title;
        msg = (title !== undefined) ? title : error?.error;
        this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
        console.error(error);
    }

    // --- Paging ----------------------------------------------------------------------------------  
    public onclickPrev() {
        if (this.parserPaging.currentPage > 1) {
            this.parserPaging.currentPage = this.parserPaging.currentPage - 1;
            this.parserPaging.setStartEndIndex();
            this.getData();      
        }
    }

    public onclickNext() {
        if ((this.parserPaging.currentPage) * this.parserPaging.pageCount < this.gridView.totalRows) {
            this.parserPaging.nextButtonDisabled = false;
            this.parserPaging.currentPage = this.parserPaging.currentPage + 1;
            this.parserPaging.setStartEndIndex();
            this.getData();
        } else {
            this.parserPaging.nextButtonDisabled = true;
        }
    }

    public onclickPage(page: number, index: number) {
        this.parserPaging.currentPage = this.parserPaging.currentPage === page ? this.parserPaging.currentPage : page;
        this.parserPaging.setStartEndIndex();
        this.getData();
    }
    
    selectChangeHandler(event: any) {
        this.parserPaging.currentPage = 1;
        this.parserPaging.setStartEndIndex();      
        this.getData();
    }
}
