import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GridViewComponent } from './grid-view.component';
import { GridViewColumnDirective } from './directives/grid-view-column.directive';
import { GridViewCommandColumnDirective } from './directives/grid-view-command-column.directive';
import { CommonModule } from '@angular/common';
import { GenericEditDialogComponent } from './generic-edit-dialog.component';

@NgModule({
  declarations: [ GridViewComponent, GridViewColumnDirective, GridViewCommandColumnDirective, GenericEditDialogComponent 
  ],
  imports: [ BrowserModule, CommonModule ],
  providers: [],
  exports: [ GridViewComponent, GridViewColumnDirective, GridViewCommandColumnDirective ]
})
export class GridViewModule { }
