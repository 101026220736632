import { Component, Input } from '@angular/core';

@Component({
    selector: "row-display",
    templateUrl: "./row-display.component.html"
})
export class RowDisplayComponent {
    @Input() label: string;
    @Input() value: string;
    @Input() labelClass: string;
    @Input() valueClass: string;
}