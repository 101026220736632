import { Component, OnInit } from '@angular/core';
import { MessageTrackerService } from './message-tracker.service';
import { TrackingView } from '../models/tracking-view';
import { Tracking } from '../models/tracking';
import { TabModel } from 'src/app/models/tab-model';
import { BlobResponse } from '../models/blob-response';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ViewMessageComponent } from '../child-components/view-message.component';
import { PagingService } from 'src/app/common-services/paging.service';
import { GridView } from '../models/grid-view';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { Paging } from 'src/app/models/paging/paging';
import { TimestampFilterModalComponent } from '../child-components/timestamp-filter-modal.component';

@Component({
  selector: 'message-tracker',
  templateUrl: './message-tracker.component.html'
})
export class MessageTrackerComponent implements OnInit {
    public errorModel: ErrorModel = null;
    //public error: boolean = false;
    //public errorMessage: string = '';
    public showSpinner: boolean = false;
    public gridView: GridView;
    public trackingView: TrackingView;
    public isArchive: boolean = false;
    public filterTimestamp: string;
    public filterStartDateTime: string = null;
    public filterEndDateTime: string = null;
    public filterStartDate: string = null;
    public filterStartTime: string = null;
    public filterEndDate: string = null;
    public filterEndTime: string = null;
    public filterSender: string;
    public filterSenderId: string;
    public filterReceiver: string;
    public filterReceiverId: string;
    public filterMessageType: string;
    public filterFlowID: string;
    public filterPrimaryID: string;
    public filterStatus: string = "-1";
    public filterPartner: string;
    public viewConfigDisplayStyle: string = 'none';
    public tracking: Array<Tracking>;
    public errorRows: Array<Tracking>;
    public sentRows: Array<Tracking>;
    public hasSentRows: boolean = false;
    public tabModel: TabModel = new TabModel();
    public blobResponse: BlobResponse;
    public payloadAS2Encoded: string = '';
    public payloadAS2Decoded: string = '';
    public payloadIn: string = '';
    public payloadCanonical: string = '';
    public payloadEncoded: string = '';
    public payloadOut: string = '';
    public payloadError: string = '';
    public blobPathAS2Encoded: string = '';
    public blobPathAS2Decoded: string = '';
    public blobPathIn: string = '';
    public blobPathCanonical: string = '';
    public blobPathEncoded: string = '';
    public blobPathOut: string = '';
    public blobPathError: string = '';
    public showAS2Encoded: boolean = false;
    public showAS2Decoded: boolean = false;
    public showEdiEncoded: boolean = false;
    public showInfileTab: boolean = false;
    public showCanonicalfileTab: boolean = false;
    public showOutFileTab: boolean = false;
    public isError: boolean = false;
    public isNotMdn: boolean = true;
    public showErrorTab: boolean = false;    

    // Paging    
    public nextButtonDisabled: boolean = false;
    public prevenv: string = '';
    public trackingPaging: Paging = new Paging();

    constructor(public trackerService: MessageTrackerService, private modalService: NgbModal, private router: Router, public pService: PagingService, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        this.gridView = new GridView();
        this.gridView.rows = new Array<TrackingView>();
        this.getTrackingData()
        this.tabModel.activeTab = 3;
    }

    private getTrackingData() {
        this.errorModel = null;
        this.showSpinner = true;
        this.trackerService.getTrackingData(this.filterStartDateTime, this.filterEndDateTime, this.filterSender, this.filterReceiver, this.filterMessageType, this.filterPrimaryID, this.filterStatus, this.filterFlowID, this.filterPartner, this.filterSenderId, this.filterReceiverId, this.trackingPaging.currentPage, this.trackingPaging.pageCount, this.isArchive).subscribe(
            {
                next: (trackingData) => {      
                    this.gridView = trackingData;
                    this.trackingPaging.setLastDisplayIndex();
                    this.trackingPaging.totalRows = trackingData.totalRows;
                    this.trackingPaging.totalPages = Math.floor(this.trackingPaging.totalRows/this.trackingPaging.pageCount) + (this.trackingPaging.totalRows % this.trackingPaging.pageCount > 0 ? 1 : 0);
                    this.showSpinner = false;
                },
                error: (error) => {
                  this.showSpinner = false; 
                  this.errorModel = this.errorParserService.extractErrors(error);
                  console.error(error); 
                }
              }
          );
    }

    selectChangeHandler(event: any) {
      this.trackingPaging.currentPage = 1;
      this.trackingPaging.setStartEndIndex();      
      this.getTrackingData();
    }

    public onCheckArchive(event: any) {
        this.getTrackingData();
    }

    public onFilter() {
        this.trackingPaging.currentPage = 1;
        this.getTrackingData();
    }

    public refresh() {
        this.getTrackingData();
    }

    public selectStatusChangeHandler(status: any) {
        this.getTrackingData();
    }

    public openViewConfig(tv: TrackingView) {
        const modalRef = this.modalService.open(ViewMessageComponent, { size: 'size_80' });
        modalRef.componentInstance.isArchive = this.isArchive;
        modalRef.componentInstance.trackingView = tv;
    }

    public viewCorrelatedMessage(tv: any) {
        this.router.navigateByUrl(`/correlated-messages?correlationId=${tv.correlationId}`);
    }

    // --- Paging ----------------------------------------------------------------------------------  
  public onclickPrev() {
    if (this.trackingPaging.currentPage > 1) {
      this.trackingPaging.currentPage = this.trackingPaging.currentPage - 1;
      this.trackingPaging.setStartEndIndex();
      this.getTrackingData();      
    }
  }

  public onclickNext() {
    if ((this.trackingPaging.currentPage) * this.trackingPaging.pageCount < this.trackingPaging.totalRows) {
      this.nextButtonDisabled = false;
      this.trackingPaging.currentPage = this.trackingPaging.currentPage + 1;
      this.trackingPaging.setStartEndIndex();
      this.getTrackingData();
    } else {
      this.nextButtonDisabled = true;
    }
  }

  public onclickPage(page: number, index: number) {
    this.trackingPaging.currentPage = this.trackingPaging.currentPage === page ? this.trackingPaging.currentPage : page;
    this.trackingPaging.setStartEndIndex();
    this.getTrackingData();
  }

  // Timestamp filter
  public isTimestampModalVisible: boolean = false;

  public showTimestampModal() {
    const modalRef = this.modalService.open(TimestampFilterModalComponent, { size: 'size_20' });
    modalRef.componentInstance.startDate = this.filterStartDate;
    modalRef.componentInstance.endDate = this.filterEndDate;
    modalRef.componentInstance.startTime = this.filterStartTime;
    modalRef.componentInstance.endTime = this.filterEndTime;

    modalRef.result.then((result) => {
      if(result.startDate != null && result.endDate != null) {
        this.filterStartDateTime = this.formatDateToString(new Date(`${result.startDate}T${result.startTime}`)); //.toString();
        this.filterEndDateTime = this.formatDateToString(new Date(`${result.endDate}T${result.endTime}`)); //.toString();        

        this.filterStartDate = result.startDate;
        this.filterStartTime = result.startTime;
        this.filterEndDate = result.endDate;
        this.filterEndTime = result.endTime;

      } else {
        this.filterStartDateTime = null;
        this.filterEndDateTime = null;

        this.filterStartDate = null;
        this.filterStartTime = null;
        this.filterEndDate = null;
        this.filterEndTime = null;
      }

      this.onFilter();
    }, (reason) => {
        // User clicked outside modal window => do nothing (same as cancel)            
    });

  }

  private formatDateToString(date: Date): string {
    const pad = (num: number) => String(num).padStart(2, '0');
    
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

  // public showTimestampModal() {
  //   this.isTimestampModalVisible = true;
  // }

  // public hideTimestampModal() {
  //   this.isTimestampModalVisible = false;
  // }

  public applyTimestampFilter(filter: { startDate: Date; endDate: Date }) {
    this.isTimestampModalVisible = false;

    // this.filteredData = this.data.filter(row => {
    //   const timestamp = new Date(row.timestamp).getTime();
    //   return (
    //     timestamp >= new Date(filter.startDate).getTime() &&
    //     timestamp <= new Date(filter.endDate).getTime()
    //   );
    // });
  }

}