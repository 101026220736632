<form [formGroup]="sourceMqProfileForm">
    <hr>
    <div class="form-group row mb5">
    <label for="sourceMQProfileHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="host" class="form-control" id="sourceMQProfileHost" name="sourceMQProfileHost" placeholder="Host">
        <div *ngIf="form.host.invalid && (form.host.dirty || form.host.touched)" class="alert alert-danger">
            <div *ngIf="form.host.errors?.['required']">Host is required.</div>
        </div>
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceMQProfilePort" class="col-sm-3 col-form-label">Port<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="port" class="form-control" id="sourceMQProfilePort" name="sourceMQProfilePortt" placeholder="Port">
        <div *ngIf="form.port.invalid && (form.port.dirty || form.port.touched)" class="alert alert-danger">
            <div *ngIf="form.port.errors?.['required']">Port is required.</div>
        </div>
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceMQProfileChannel" class="col-sm-3 col-form-label">Channel<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="channel" class="form-control" id="sourceMQProfileChannel" name="sourceMQProfileChannel" placeholder="Channel">
        <div *ngIf="form.channel.invalid && (form.channel.dirty || form.channel.touched)" class="alert alert-danger">
            <div *ngIf="form.channel.errors?.['required']">Channel is required.</div>
        </div>
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceMQProfileQm" class="col-sm-3 col-form-label">Queue manager<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="qm" class="form-control" id="sourceMQProfileQm" name="sourceMQProfileQm" placeholder="Queue manager">
        <div *ngIf="form.qm.invalid && (form.qm.dirty || form.qm.touched)" class="alert alert-danger">
            <div *ngIf="form.qm.errors?.['required']">Queue manager is required.</div>
        </div>
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceMQProfileQueue" class="col-sm-3 col-form-label">Queue<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" formControlName="queue" class="form-control" id="sourceMQProfileQueue" name="sourceMQProfileQueue" placeholder="Queue">
        <div *ngIf="form.queue.invalid && (form.queue.dirty || form.queue.touched)" class="alert alert-danger">
            <div *ngIf="form.queue.errors?.['required']">Queue is required.</div>
        </div>
    </div>
</div>
</form>
