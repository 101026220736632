import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';
import { SimpleFlowConfigurationsService } from './simpleflow-configurations.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddSimplifiedFlowConfigurationComponent } from './child-components/add-simplified-flow.component';
import { SimpleFlowConfiguration } from './models/simple-flow-configuration';
import { EditSimplifiedFlowConfigurationComponent } from './child-components/edit-simplified-flow.component';
import { ViewSimplifiedFlowConfigurationModalComponent } from './child-components/view-simplified-flow-configuration.comonent';
import { PagingService } from '../common-services/paging.service';
import { DeleteSimplifiedFlowConfigurationComponent } from './child-components/delete-simplified-flow.component';

@Component({
  selector: 'simple-configurations',
  templateUrl: './simpleflow-configurations.component.html'
})
export class SimpleFlowConfigurationsComponent implements OnInit {
  public originalSimplifiedFlowConfigurations: Array<SimpleFlowConfiguration> = [];
  public allSimplifiedFlowConfigurations: Array<SimpleFlowConfiguration> = [];
  public pagedSimplifiedFlowConfigurations: Array<SimpleFlowConfiguration> = [];
  public configurationCount: number = 0;

  public isReader: boolean = false;
  public isAdmin: boolean = false;
  public isOnboarder: boolean = false;
  public errorModel: ErrorModel = null;
  public showSpinner: boolean = true;

  public filterInboundPartner: string = '';
  public filterInboundMessageTypeName: string = '';
  public filterOutboundPartner: string = '';
  public filterOutboundMessageTypeName: string = '';

  // Paging
  public currentPage: number = 1;
  public startIndex: number = 0;
  public endIndex: number = 10;
  public pageCount = 10;
  public totalPages = 0;
  public firstDisplayIndex: number = 0;
  public lastDisplayIndex: number = 0;
  public nextButtonDisabled: boolean = false;

  constructor(
    private errorParserService: ErrorParserService,
    public sfc: SimpleFlowConfigurationsService,
    public pService: PagingService,
    // private router: Router,
    // private route: ActivatedRoute,
    private authService: MsalService,
    private modalService: NgbModal
  ) {
    // this.route.queryParams.subscribe(params => {
    //   this.filterSender = params['sender'];
    //   this.filterReceiver = params['receiver'];
    // });
  }

  ngOnInit(): void {
    let allAccounts = this.authService.instance.getAllAccounts();
    if (allAccounts.length > 0) {
      let account = allAccounts[0];
      this.isReader = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Reader') == undefined ? false : true;
    }

    this.getData();
  }

  private getData() {
    this.showSpinner = true;
    this.sfc.getData(false, this.currentPage, this.pageCount).subscribe(
    {
      next: (result) => {
        this.configurationCount = result.length;
        this.allSimplifiedFlowConfigurations = result;
        this.originalSimplifiedFlowConfigurations = result;
        this.pagedSimplifiedFlowConfigurations = result.slice(this.startIndex, this.endIndex);
        this.lastDisplayIndex = (this.currentPage) * this.pageCount;
        this.totalPages = Math.floor(this.configurationCount/this.pageCount) + (this.configurationCount % this.pageCount > 0 ? 1 : 0);

        this.onFilter();
        this.showSpinner = false; 
      },
      error: (error) => { 
        this.errorModel = this.errorParserService.extractErrors(error);
        console.error(error);
        this.showSpinner = false;
      }
    });
  }

  public onFilter() {
    this.allSimplifiedFlowConfigurations = this.originalSimplifiedFlowConfigurations;      

    if(this.filterInboundPartner !== undefined && this.filterInboundPartner.length > 0) {
      this.allSimplifiedFlowConfigurations = this.allSimplifiedFlowConfigurations.filter((x) => x.inboundPartnerName.toLowerCase().includes(this.filterInboundPartner.toLowerCase()));
    }
    if(this.filterInboundMessageTypeName !== undefined && this.filterInboundMessageTypeName.length > 0) {
      this.allSimplifiedFlowConfigurations = this.allSimplifiedFlowConfigurations.filter((x) => x.inboundMessageTypeName.toLowerCase().includes(this.filterInboundMessageTypeName.toLowerCase()));
    }
    if(this.filterOutboundPartner !== undefined && this.filterOutboundPartner.length > 0) {
      this.allSimplifiedFlowConfigurations = this.allSimplifiedFlowConfigurations.filter((x) => x.outboundPartnerName.toLowerCase().includes(this.filterOutboundPartner.toLowerCase()));
    }
    if(this.filterOutboundMessageTypeName !== undefined && this.filterOutboundMessageTypeName.length > 0) {
      this.allSimplifiedFlowConfigurations = this.allSimplifiedFlowConfigurations.filter((x) => x.outboundMessageTypeName.toLowerCase().includes(this.filterOutboundMessageTypeName.toLowerCase()));
    }
    
    if((this.filterInboundPartner === undefined || this.filterInboundPartner === "") && 
      (this.filterInboundMessageTypeName === undefined || this.filterInboundMessageTypeName === "") && 
      (this.filterOutboundPartner === undefined || this.filterOutboundPartner === "") && 
      (this.filterOutboundMessageTypeName === undefined || this.filterOutboundMessageTypeName === "")
    ) {
      this.allSimplifiedFlowConfigurations = this.originalSimplifiedFlowConfigurations;      
    }

    this.currentPage = 1;
    this.setStartEndIndex();

    this.pagedSimplifiedFlowConfigurations = this.allSimplifiedFlowConfigurations.slice(this.startIndex, this.endIndex);
    this.lastDisplayIndex = this.allSimplifiedFlowConfigurations.length >= this.pageCount ? (this.currentPage) * this.pageCount : this.allSimplifiedFlowConfigurations.length;
    this.configurationCount = this.allSimplifiedFlowConfigurations.length;   
    this.totalPages = Math.floor(this.configurationCount/this.pageCount) + (this.configurationCount % this.pageCount > 0 ? 1 : 0);
  }
  
  public viewConfig(c: SimpleFlowConfiguration) {
    const modalRef = this.modalService.open(ViewSimplifiedFlowConfigurationModalComponent, { size: 'size_80' });
    modalRef.componentInstance.simpleEntityMapId = c.simpleEntityMapId;
  }

  public createNewConfig() {
    const modalRef = this.modalService.open(AddSimplifiedFlowConfigurationComponent, { size: 'size_50' });
    modalRef.result.then((result) => {            
        if(result === 'save') {
            // Refresh grid after save
            this.getData();
        }
    }, (reason) => {
        // User clicked outside modal window => do nothing (same as cancel)            
    });
  }

  public editConfig(sfc: SimpleFlowConfiguration) {
    const modalRef = this.modalService.open(EditSimplifiedFlowConfigurationComponent, { size: 'size_50' });
    modalRef.componentInstance.simpleFlowConfiguration = sfc;
    modalRef.result.then((result) => {
        if(result === 'save') {
            // Refresh grid after save
            this.getData();
        }
    }, (reason) => {
        // User clicked outside modal window => do nothing (same as cancel)            
    });
  }

  public removeConfig(c: SimpleFlowConfiguration) {
    const modalRef = this.modalService.open(DeleteSimplifiedFlowConfigurationComponent, { size: 'size_50' });
    modalRef.componentInstance.simplifiedFlowConfiguration = c;
    modalRef.result.then((result) => {
        if(result === 'delete') {
            // Refresh grid after save
            this.getData();
        }
    }, (reason) => {
        // User clicked outside modal window => do nothing (same as cancel)            
    });    
  }

  // --- Paging ----------------------------------------------------------------------------------  
  public onclickPrev() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.setStartEndIndex();
      this.lastDisplayIndex = (this.currentPage) * (+this.pageCount);
      this.pagedSimplifiedFlowConfigurations = this.allSimplifiedFlowConfigurations.slice(this.startIndex, this.endIndex);
    }
  }

  public onclickNext() {
    if ((this.currentPage) * (+this.pageCount) < this.configurationCount) {
      this.nextButtonDisabled = false;
      this.currentPage = this.currentPage + 1;
      this.setStartEndIndex();

      if((this.currentPage) * (+this.pageCount) > this.configurationCount) {
        this.lastDisplayIndex = this.configurationCount;
      } else {
        this.lastDisplayIndex = (this.currentPage) * (+this.pageCount);        
      }

      this.pagedSimplifiedFlowConfigurations = this.allSimplifiedFlowConfigurations.slice(this.startIndex, this.endIndex);
    } else {
      this.nextButtonDisabled = true;
    }
  }

  public onclickPage(page: number, index: number) {
    this.currentPage = this.currentPage === page ? this.currentPage : page;
    this.setStartEndIndex();
    if((this.currentPage) * (+this.pageCount) > this.configurationCount) {
      this.lastDisplayIndex = this.configurationCount;
    } else {
      this.lastDisplayIndex = (this.currentPage) * (+this.pageCount);        
    }
    this.pagedSimplifiedFlowConfigurations = this.allSimplifiedFlowConfigurations.slice(this.startIndex, this.endIndex);
  }

  private setStartEndIndex() {
    this.startIndex = +((this.currentPage - 1) * (+this.pageCount));
    this.endIndex = +((this.currentPage - 1) * (+this.pageCount)) + (+this.pageCount);
  }

  selectPagingChangeHandler(event: any) {
    this.currentPage = 1;
    let locPageCount = (+this.pageCount);
    this.setStartEndIndex();    
    this.onFilter();
  }

  private setLastDisplayIndex() {
      if((this.currentPage) * this.pageCount > this.configurationCount) {
          this.lastDisplayIndex = this.configurationCount;
      } else {
          this.lastDisplayIndex = (this.currentPage) * this.pageCount;        
      }
  }

}
