import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CanonicalMapXslt } from '../../models/mapping/canonical-map-xslt';
import { OutboundMapXslt } from '../../models/mapping/outbound-map-xslt';
import { MappingDocumentModel } from './mapping-document-model';
import { CanonicalToOutboundMappingDocumentsGridView } from './models/canonical-to-outbound-mapping-documents-gridview';
import { InboundToCanonicalMappingDocumentsGridView } from './models/inbound-to-canonical-mapping-documents-gridview';
import { DropDownListModelNumber } from 'src/app/models/dropdown-list-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class MappingDocumentService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";

    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getInboundToCanonicalMappingDocuments(pageindex: number, pagesize: number, mappingDocumentName: string, mappingDocumentDescription: string): Observable<InboundToCanonicalMappingDocumentsGridView> {
        let queryStart = '';
        let queryString = '';

        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)

        if(mappingDocumentName != undefined && mappingDocumentName.length > 2) {
            queryString = queryString.concat(`${queryStart}mappingDocumentName=${mappingDocumentName}`)
        }
        if(mappingDocumentDescription != undefined && mappingDocumentDescription.length > 2) {
            queryString = queryString.concat(`${queryStart}mappingDocumentDescription=${mappingDocumentDescription}`)
        }

        return this.http.get<InboundToCanonicalMappingDocumentsGridView>(this.baseUrlLocal + `inboundmappingsxslt/search` + queryString );
    }

    public getCanonicalToOutboundMappingDocuments(pageindex: number, pagesize: number, mappingDocumentName: string, mappingDocumentDescription: string): Observable<CanonicalToOutboundMappingDocumentsGridView> {
        let queryStart = '';
        let queryString = '';

        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pageindex=${pageindex}`)
        queryStart = this.getQueryStart(queryStart);
        queryString = queryString.concat(`${queryStart}pagesize=${pagesize}`)

        if(mappingDocumentName != undefined && mappingDocumentName.length > 2) {
            queryString = queryString.concat(`${queryStart}mappingDocumentName=${mappingDocumentName}`)
        }
        if(mappingDocumentDescription != undefined && mappingDocumentDescription.length > 2) {
            queryString = queryString.concat(`${queryStart}mappingDocumentDescription=${mappingDocumentDescription}`)
        }

        return this.http.get<CanonicalToOutboundMappingDocumentsGridView>(this.baseUrlLocal + `outboundmappingsxslt/search` + queryString );
    }

    public addInboundToCanonicalMappingDocument(mappingDocument: CanonicalMapXslt) {
        return this.http.post(this.baseUrlLocal + 'inboundmappingsxslt', mappingDocument);
    }

    public addOutboundMappingDocument(outboundMappingDocument: OutboundMapXslt) {
        return this.http.post(this.baseUrlLocal + 'outboundmappingsxslt', outboundMappingDocument);
    }

    public updateInboundToCanonicalMappingDocument(mappingDocument: CanonicalMapXslt) {
        return this.http.put(this.baseUrlLocal + 'inboundmappingsxslt', mappingDocument);
    }

    public updateOutboundMappingDocument(outboundMappingDocument: OutboundMapXslt) {
        return this.http.put(this.baseUrlLocal + 'outboundmappingsxslt', outboundMappingDocument);
    }

    public deleteInboundToCanonicalMappingDocument(outboundMappingDocument: CanonicalMapXslt) {
        const options = {
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            }),
            body: outboundMappingDocument
        };
        return this.http.delete(this.baseUrlLocal + 'inboundmappingsxslt', options);
    }

    public deleteOutboundMappingDocument(outboundMappingDocument: OutboundMapXslt) {
        const options = {
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            }),
            body: outboundMappingDocument
        };
        return this.http.delete(this.baseUrlLocal + 'outboundmappingsxslt', options);
    }

    public setEditMode(gridRow: MappingDocumentModel, mappingDocumentGridList: Array<MappingDocumentModel>, editMode: boolean) {
        const foundGridRow = mappingDocumentGridList.filter((obj) => {
            return obj.Id === gridRow.Id;

        });
        foundGridRow[0].editMode = editMode;
    }

    public getMappingDocumentModelList(mappingDocuments: Array<CanonicalMapXslt>) {
        let mappingDocumentGridList: Array<MappingDocumentModel> = new Array<MappingDocumentModel>();

        mappingDocuments.forEach(m => {
            let mappingDocument: MappingDocumentModel = {
                Id: m.id,
                Name: m.name,
                Description: m.description,
                createdBy: m.createdBy,
                createTimestamp: m.createTimestamp,
                editMode: false,
                isNew: false
            };
            mappingDocumentGridList.push(mappingDocument);
        });

        return mappingDocumentGridList;
    }

    public getMappingDocument(gridRow: MappingDocumentModel) {
        let mappingDocument: CanonicalMapXslt = new CanonicalMapXslt()
        mappingDocument.id = gridRow.Id;
        mappingDocument.name = gridRow.Name;
        mappingDocument.description = gridRow.Description;

        if(!gridRow.isNew) {
            mappingDocument.createTimestamp = gridRow.createTimestamp;
            mappingDocument.createdBy = gridRow.createdBy;
        }

        return mappingDocument;
    }

    public populateNewMappingDocument(highestId: number) {
        let mappingDocument: MappingDocumentModel = {
            Id: highestId + 1,
            Name: '',
            Description: '',
            createdBy: null,
            createTimestamp: null,
            editMode: true,
            isNew: true
        };

        return mappingDocument;
    }

    private getQueryStart(queryStart: string) {
        if(queryStart === '') {
            return  '?';
        } else {
            return '&';
        }
    }

    public getModelTypeName(modelType: number) {
        let modelTypeName: string = '';
        switch (modelType) {
            case 0:
                modelTypeName = 'Unknown';
                break;
            case 1:
                modelTypeName = 'AcknowledgePurchaseOrder';
                break;
            case 2:
                modelTypeName = 'ChangePurchaseOrder';
                break;            
            case 3:
                modelTypeName = 'ConfirmBOD';
                break;
            case 4:
                modelTypeName = 'NotifyOrderStatus';
                break;
            case 5:
                modelTypeName = 'NotifyPlanningSchedule';
                break;
            case 6:
                modelTypeName = 'NotifyShipment';
                break;
            case 7:
                modelTypeName = 'ProcessInvoice';
                break;
            case 8:
                modelTypeName = 'ProcessPurchaseOrder';
                break;
            case 9:
                modelTypeName = 'ProcessQuote';
                break;
            case 10:
                modelTypeName = 'ProcessRFQ';
                break;
            case 11:
                modelTypeName = 'SyncEmployeeWorktime';
                break;
            case 12:
                modelTypeName = 'SyncInvoice';
                break;
            case 13:
                modelTypeName = 'SyncQuote';
                break;
            case 14:
                modelTypeName = 'SyncSalesOrder';
                break;
            case 15:
                modelTypeName = 'SyncShipment';
                break;
            default:
                modelTypeName = 'Unknown';
                break;
        }

        return modelTypeName;
    }

    public getModelTypeVersionName(modelTypeVersion: number) {
        let modelTypeVersionName: string = '';
        switch (modelTypeVersion) {
            case 0:
                modelTypeVersionName = 'Unknown';
                break;
            case 1:
                modelTypeVersionName = 'VERSION_10_6';
                break;
            default:

                break;
        }
        return modelTypeVersionName;
    }

    public getModelTypes(): Array<DropDownListModelNumber> {
        let modelTypes: Array<DropDownListModelNumber> = [
            { value: 0, text: 'Unknown'},
            { value: 1, text: 'AcknowledgePurchaseOrder'},
            { value: 2, text: 'ChangePurchaseOrder'},
            { value: 3, text: 'ConfirmBOD'},
            { value: 4, text: 'NotifyOrderStatus'},
            { value: 5, text: 'NotifyPlanningSchedule'},
            { value: 6, text: 'NotifyShipment'},
            { value: 7, text: 'ProcessInvoice'},
            { value: 8, text: 'ProcessPurchaseOrder'},
            { value: 9, text: 'ProcessQuote'},
            { value: 10, text: 'ProcessRFQ'},
            { value: 11, text: 'SyncEmployeeWorktime'},
            { value: 12, text: 'SyncInvoice'},
            { value: 13, text: 'SyncQuote'},
            { value: 14, text: 'SyncSalesOrder'},
            { value: 15, text: 'SyncShipment'},
        ];

        return modelTypes;
    }

    public getModelTypeVersions(): Array<DropDownListModelNumber> {
        let modelTypeVersions: Array<DropDownListModelNumber> = [
            { value: 0, text: 'Unknown'},
            { value: 1, text: 'VERSION_10_6'}           
        ];

        return modelTypeVersions;
    }

    public getEmptyInboundToCanonicalMappingForm() {
        return new FormGroup({
          id:  new FormControl(0, Validators.required), // Zero for new
          name: new FormControl('', Validators.required),
          description: new FormControl('', Validators.required),
          modelType: new FormControl(0, Validators.required), // Default Unknown (0)
          modelTypeVersion: new FormControl(1, Validators.required) // Default VERSION_10_6 (1)
        });        
    }

    public getInboundToCanonicalMappingForm(mappingDocument: CanonicalMapXslt) {
        return new FormGroup({
          id:  new FormControl(mappingDocument.id, Validators.required),
          name: new FormControl(mappingDocument.name, Validators.required),
          description: new FormControl(mappingDocument.description, Validators.required),
          modelType: new FormControl(mappingDocument.modelType, Validators.required),
          modelTypeVersion: new FormControl(mappingDocument.modelTypeVersion, Validators.required)
        });        
    }

    public getInboundToCanonicalMappingModel(formGroup: FormGroup) : CanonicalMapXslt {
        let mappingDocument: CanonicalMapXslt = new CanonicalMapXslt();
        mappingDocument.id = formGroup.value.id;
        mappingDocument.name = formGroup.value.name;
        mappingDocument.description = formGroup.value.description;
        mappingDocument.modelType = formGroup.value.modelType;
        mappingDocument.modelTypeVersion = formGroup.value.modelTypeVersion;

        return mappingDocument;
    }

    public getEmptyCanonicalToOutboundMappingForm() {
        return new FormGroup({
          id:  new FormControl(0, Validators.required), // Zero for new
          name: new FormControl('', Validators.required),
          description: new FormControl('', Validators.required),
        });        
    }

    public getCanonicalToOutboundMappingForm(outboundMapXslt: OutboundMapXslt) {
        return new FormGroup({
            id:  new FormControl(outboundMapXslt.id, Validators.required),
            name: new FormControl(outboundMapXslt.name, Validators.required),
            description: new FormControl(outboundMapXslt.description, Validators.required),
        });        
    }

    public getCanonicalToOutboundMappingModel(formGroup: FormGroup) : OutboundMapXslt {
        let mappingDocument: OutboundMapXslt = new OutboundMapXslt();
        mappingDocument.id = formGroup.value.id;
        mappingDocument.name = formGroup.value.name;
        mappingDocument.description = formGroup.value.description;

        return mappingDocument;
    }
}
