<form [formGroup]="outboundMapXsltForm" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">Add Canonical => Outbound mapping document</h4>
    </div>
    <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
    <error-display [errorModel]="errorModel"></error-display>
    <div class="modal-body">
        <div>
            <div class="form-group row mb5">
                <label for="mappingDocumentName" class="col-sm-3 col-form-label">Name <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="name" required id="mappingDocumentName" name="mappingDocumentName" placeholder="Mapping document name">
                    <div *ngIf="form.name.invalid && (form.name.dirty || form.name.touched)" class="alert alert-danger">
                        <div *ngIf="form.name.errors?.['required']">Name is required.</div>
                    </div>  
                </div>
            </div>
    
            <div class="form-group row mb5">
                <label for="messageTypeDescription" class="col-sm-3 col-form-label">Description <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="description" required id="mappingDocumentDescription" name="mappingDocumentDescription" placeholder="Description">
                    <div *ngIf="form.description.invalid && (form.description.dirty || form.description.touched)" class="alert alert-danger">
                        <div *ngIf="form.description.errors?.['required']">Description is required.</div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="!outboundMapXsltForm.valid">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>
    
</form>

