import { Component, OnInit, Inject } from '@angular/core';
import { MappingExplorerService } from './mapping-explorer.service';
import { CustomerConfiguration } from 'src/app/models/customer-configuration';
import { InboundMapping } from './models/inbound-mapping';
import { PagingService } from 'src/app/common-services/paging.service';

@Component({
  selector: 'mapping-explorer',
  templateUrl: './mapping-explorer.component.html'
})
export class MappingExplorerComponent implements OnInit {
  public error: boolean = false;
  public errorMessage: string = '';
  public showSpinner: boolean = false;
  public inMaps: Array<InboundMapping>;
  public outMaps: Array<InboundMapping>;
  // public mapCount: number = 0;
  public mappingType: number = 1;
  // public originalMaps: Array<InboundMapping> = [];
  // public allMaps: Array<InboundMapping> = [];
  // public pagedMaps: Array<InboundMapping> = [];

  constructor(public meService: MappingExplorerService, public pService: PagingService) {}

  ngOnInit(): void {
    if(this.mappingType === 1) {
      this.getInboundMaps();
    } else {
      this.getOutboundMaps();
    }
  }

  private getInboundMaps() {
    this.showSpinner = true;
    this.meService.getInboundMappings().subscribe(
      {
          next: (maps) => {      
            this.inMaps = maps;
            this.showSpinner = false;
          },
          error: (error) => {
            this.showSpinner = false; 
            this.displayErrorMessage(error);
          }
        }
    );
  }

  private getOutboundMaps() {
    this.showSpinner = true;
    this.meService.getOutboundMappings().subscribe(
      {
          next: (maps) => {      
            this.outMaps = maps;
            this.showSpinner = false;
          },
          error: (error) => {
            this.showSpinner = false; 
            this.displayErrorMessage(error);
          }
        }
    );
  }

  public viewConfig(im: InboundMapping) {
    this.showSpinner = true;
    // this.ccService.getConfiguration(cc.entityMapId).subscribe(
    //   {
    //     next: (result) => {
    //       this.viewConfigData = result;
    //       this.vewConfigurationDisplayStyle = "block";
    //       this.showSpinner = false; 
    //     },
    //     error: (error) => { 
    //         // TODO pick up error message and display.
    //         this.error = true;
    //         let msg: string = '';
    //         let title: string = error?.error?.title;
    //         msg = (title !== undefined) ? title : error?.error;
    //         this.errorMessage = `Admin Tool API returned status ${error.status}, title: ${msg}`;
    //         console.error(error); 
    //         this.showSpinner = false;
    //     }
    //   });
  }

  public onclickEdiTypeOptions(mappingType: number) {
    this.mappingType = mappingType;
    
    if(this.mappingType === 1) {
      this.outMaps = null;
      this.getInboundMaps();
    } else {
      // TODO Add outbound
      this.inMaps = null;
      this.getOutboundMaps();
      
    }
    // this.selectedAgreement = '';
    // this.loadAgreementsDDL(ediType);
  }

  private displayErrorMessage(error: any) {
    let formattedError = '';
    if(error?.error && error?.error.error == undefined) {
      if(error.error.includes('at al.goh.admin.tool.api')) {
        formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
      } else {
        formattedError = error.error;
      }
    } else {
      if(error?.error?.error) {
        formattedError = error?.error.error;
      } else {
        formattedError = 'Unknown eror';
      }
    }

    this.errorMessage = `Error when decoding message. Error Message ${formattedError}`;
    this.error = true;
    console.error(error);
  }
}
