export class EDIPreFormatter {
    id: number;
    inboundPartnerId: number;    
    description: string;
    regEx: string;
    replaceValue: string;
    encoding: string;
    order: number;
    createdBy: string;
    createTimestamp: string;
    updatedBy: string;
    updateTimestamp: string;
}