import { PreFormattingClass } from './pre-formatting-class';

export class PreFormatting
{
    id: number;
    formattingClass: string
    order: number;
    createTimestamp: string;
    updateTimestamp: string;
    createdBy: string;
    updatedBy: string;
    preFormattingClassId: number;
    rootTag: string;
    preFormattingClass: PreFormattingClass
    entityMapId: number;
}