<form [formGroup]="parserClassForm" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">Add parser</h4>
    </div>
    <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
    <error-display [errorModel]="errorModel"></error-display>
    <div class="modal-body">
        <div>            
            <fieldset class="border p-2 mb10 mt10">
                <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Parser details</span></legend>
                <div class="form-group row mb5">
                    <label for="parser" class="col-sm-2 col-form-label">Parser <span class="req-color">*</span></label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" formControlName="parser" required id="parser" name="parser" placeholder="Name">
                        <div *ngIf="form.parser.invalid && (form.parser.dirty || form.parser.touched)" class="alert alert-danger">
                            <div *ngIf="form.parser.errors?.['required']">Name is required.</div>
                        </div>
                    </div>
                </div>
    
                <div class="form-group row mb5">
                    <label for="parserDescription" class="col-sm-2 col-form-label">Description <span class="req-color">*</span></label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" formControlName="description" required id="description" name="description" placeholder="Description">
                        <div *ngIf="form.description.invalid && (form.description.dirty || form.description.touched)" class="alert alert-danger">
                            <div *ngIf="form.description.errors?.['required']">Description is required.</div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="!parserClassForm.valid">Add</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>
</form>

