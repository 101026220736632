import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { WizardResponseModel } from 'src/app/models/wizard-response-model';
import { WizardRequestModel } from 'src/app/models/wizard-request-model';
import { EntityMap } from 'src/app/models/entity-map';
import { Configuration } from 'src/app/models/configuration';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { ConfDestinationData } from 'src/app/models/destination/conf-destination-data';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Scheduler } from 'src/app/models/source/scheduler';
import { PreFormatting } from 'src/app/models/pre-formatting';
import { PostFormatting } from 'src/app/models/post-formatting';
import { Enrichment } from 'src/app/models/enrichment';
import { ResponseResolverClass } from 'src/app/models/response-resolver/response-resolver-class';
import { ResponseResolver } from 'src/app/models/response-resolver/response-resolver';
import { ResponseResolverUpdateData } from 'src/app/models/response-resolver/response-resolver-update-data';
import { Observable } from 'rxjs';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TabStatuses } from 'src/app/models/tab-statuses';
import { OutboundMap } from 'src/app/models/mapping/outbound-map';
import { AS2ConfOut } from 'src/app/models/edi/as2-conf-out';
import { X12Lookup } from 'src/app/models/edi/x12-lookup';
import { EDILookup } from 'src/app/models/edi/edi-lookup';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationWizardService {
  public baseUrlLocal: string = "";
  public basePrevUrlLocal: string = "";

  constructor(private http: HttpClient) {
    this.baseUrlLocal = environment.apiBaseUrl;
    this.basePrevUrlLocal = environment.apiPrevBaseUrl;
  }

  public getBaseData() {
    return this.http.get<WizardResponseModel>(this.baseUrlLocal + 'bff/configurationdata');
  }

  public getConfiguration(id: number): Observable<Configuration> {
    return this.http.get<Configuration>(this.baseUrlLocal + `configurations/${id}`);
  }

  // TODO This also exists in partner.services.ts. Consolidate to sources.service.ts
  public getPartnerSources(partnerId: number): Observable<Array<Scheduler>> {
    return this.http.get<Array<Scheduler>>(`${this.baseUrlLocal}scheduler/${partnerId}`);
  }

  public saveConfiguration(wizardRequestModel: WizardRequestModel, isEdit: boolean) {
    if(isEdit === false) {
      return this.http.post(this.baseUrlLocal + 'configurations', wizardRequestModel);
    } else {
      return this.http.put(this.baseUrlLocal + 'configurations', wizardRequestModel);
    }
  }

  public initModels(responseResolverUpdateData: ResponseResolverUpdateData) {
    responseResolverUpdateData.responseResolver = new ResponseResolverClass();
    responseResolverUpdateData.responseResolver.responseResolverClass = '';
    responseResolverUpdateData.isExisting = true;
  }

  public setResolverClass(formGroup: FormGroup, basedataModel: WizardResponseModel): void {
    const resolverGroup = formGroup.get('resolverClass') as FormGroup;

    if(formGroup.value.inboundPartner.inboundPartnerId !== '') {
      let resolver = basedataModel.resolvers.find(x => x.partner === +formGroup.value.inboundPartner.inboundPartnerId)
      if(resolver) {
          let resolverClass = basedataModel.resolverClasses.find(x => x.id === resolver.resolverClassId)
          resolverGroup.get('name')?.setValue(resolverClass?.resolver);
          resolverGroup.get('description')?.setValue(resolverClass?.description);
      }
    } else {
      resolverGroup.get('name')?.setValue('');
      resolverGroup.get('description')?.setValue('');
    }
  }

  public setParserClass(formGroup: FormGroup, basedataModel: WizardResponseModel): void {
    const parserClassGroup = formGroup.get('parserClass') as FormGroup;

    if(formGroup.value.inboundPartner.inboundPartnerId !== '' && formGroup.value.inboundMessageType.inboundMessageTypeId !== '') {
      let parser = basedataModel.inboundParsers.find(x => x.partner === +formGroup.value.inboundPartner.inboundPartnerId && x.messageType === +formGroup.value.inboundMessageType.inboundMessageTypeId)
      if(parser) {
        let parserClass = basedataModel.parserClasses.find(x => x.id === parser.parserClassId)
        parserClassGroup.get('name')?.setValue(parserClass?.parser);
        parserClassGroup.get('description')?.setValue(parserClass?.description);
      }
    } else {
      parserClassGroup.get('name')?.setValue('');
      parserClassGroup.get('description')?.setValue('');
    }
  }
   
  public setAs2ConfIn(formGroup: FormGroup, basedataModel: WizardResponseModel) {
    let aS2ConfIn = basedataModel.aS2ConfIn.find(x => x.partnerId === +formGroup.value.inboundPartner.inboundPartnerId);
    if(aS2ConfIn != undefined) {
        formGroup.get('specifyAS2Inbound')?.setValue(true);
        formGroup.get('as2ConfIn.id')?.setValue(aS2ConfIn.id);
        formGroup.get('as2ConfIn.requestFormat')?.setValue(aS2ConfIn.requestFormat);
        formGroup.get('as2ConfIn.mdnformat')?.setValue(aS2ConfIn.mdnformat);
        formGroup.get('as2ConfIn.ackFormat')?.setValue(aS2ConfIn.ackFormat);
        formGroup.get('as2ConfIn.ackEndpoint')?.setValue(aS2ConfIn.ackEndpoint);
        formGroup.get('as2ConfIn.ackEnabled')?.setValue(aS2ConfIn.ackEnabled);
    } else {
        formGroup.get('specifyAS2Inbound')?.setValue(false);
    }
  }

  public getPreFormatting(formGroup: FormGroup) {
    if(formGroup.value.preFormatting.preFormattingClassId !== '') {
        let preFormatting: PreFormatting = new PreFormatting();
        
        preFormatting.id = formGroup.value.preFormatting.preFormattingId;
        preFormatting.preFormattingClassId = formGroup.value.preFormatting.preFormattingClassId;
        preFormatting.formattingClass = ''; // Need to be set as empty string at the moment before removed. TODO Not used!!!
        preFormatting.rootTag = formGroup.value.preFormatting.rootTag; // TODO add back
        preFormatting.entityMapId = formGroup.value.entitymap.id;

        return preFormatting;
    }

    return null;
  }

  public getPostFormatting(formGroup: FormGroup) {
    if(formGroup.value.postFormatting.postFormattingClassId !== '') {
      let postFormatting: PostFormatting = new PostFormatting();

      postFormatting.id = formGroup.value.postFormatting.postFormattingId;
      postFormatting.postFormattingClassId = formGroup.value.postFormatting.postFormattingClassId;
      postFormatting.formattingClass = ''; // Need to be set as empty string at the moment before removed. TODO Not used!!!
      postFormatting.entityMapId = formGroup.value.entitymap.id;

      return postFormatting;
    }

    return null;
  }

  public getResponseResolver(formGroup: FormGroup, responseResolverUpdateData: ResponseResolverUpdateData) {
    if(responseResolverUpdateData.responseResolver.responseResolverClass !== '') {
      let responseResolver: ResponseResolver = new ResponseResolver();
      responseResolver.description = `${formGroup.value.entitymap.senderId} - ${formGroup.value.entitymap.receiverId} - ${responseResolverUpdateData.responseResolver.description}`;
      responseResolver.responseResolverClassId = responseResolverUpdateData.responseResolver.id;
      responseResolver.resolver = ''; // TODO Need to be set as empty string at the moment before removed.
      responseResolver.partner = formGroup.value.inboundPartner.inboundPartnerId;
      responseResolver.messageType = formGroup.value.inboundMessageType.inboundMessageTypeId;

      return responseResolver;
    } else {
        return null;
    }
  }

  public setConfDestinationsDropDownList(confDestinationData: Array<ConfDestinationData>): Array<DropDownListModel> {
    let confDestinationsDDL = new Array<DropDownListModel>();
    confDestinationData.forEach((x) => {
        let d: DropDownListModel = {
            value: x.id.toString(),
            text: x.description
          };
        confDestinationsDDL.push(d);
    });

    return confDestinationsDDL;
  }

  public setKeyVaultProfilesDropDownList(keyVaultProfiles: Array<KeyVaultProfile>): Array<DropDownListModel> {
    let keyVaultProfilesDDL = new Array<DropDownListModel>();
    keyVaultProfiles.forEach(x => {
        let d: DropDownListModel = {
            value: x.id.toString(),
            text: x.secretName
          };
        keyVaultProfilesDDL.push(d);
    });

    return keyVaultProfilesDDL;
  }

  public getEnrichments(enrichments: Array<Enrichment>, entityMap: EntityMap) {
    // Set the entity mapid for all enrichments
    enrichments.forEach(e => {
      e.entityMapId = entityMap.id;
    });

    return enrichments;
  }

  public getAS2ConfOutModel(formGroup: FormGroup, entityMapId: number) : AS2ConfOut {
    const as2ConfOutFormGroup = formGroup.get('outboundEdi.as2ConfOut') as FormGroup;

    if(as2ConfOutFormGroup) {
      let aS2ConfOut: AS2ConfOut = new AS2ConfOut();

      aS2ConfOut.id = formGroup.get('outboundEdi.as2ConfOut.id')?.value
      aS2ConfOut.entityMapId = entityMapId;
      aS2ConfOut.responseFormat = formGroup.get('outboundEdi.as2ConfOut.responseFormat')?.value
      aS2ConfOut.mdnformat = formGroup.get('outboundEdi.as2ConfOut.mdnformat')?.value
      aS2ConfOut.as2Agreement = formGroup.get('outboundEdi.as2ConfOut.as2Agreement')?.value
      aS2ConfOut.as2Sender = formGroup.get('outboundEdi.as2ConfOut.as2Sender')?.value
      aS2ConfOut.as2Receiver = formGroup.get('outboundEdi.as2ConfOut.as2Receiver')?.value

      return aS2ConfOut;
    } else {
      return null;
    }
  }

  public getX12LookupModel(formGroup: FormGroup, entityMapId: number) : X12Lookup {
    const x12LookupFormGroup = formGroup.get('outboundEdi.x12Lookup') as FormGroup;

    if(x12LookupFormGroup) {
      let x12Lookup: X12Lookup = new X12Lookup();

      x12Lookup.id = formGroup.get('outboundEdi.x12Lookup.id')?.value
      x12Lookup.entityMapId = entityMapId;
      x12Lookup.hostidentity = formGroup.get('outboundEdi.x12Lookup.hostidentity')?.value
      x12Lookup.hostidentityQualifier = formGroup.get('outboundEdi.x12Lookup.hostidentityQualifier')?.value
      x12Lookup.guestidentity = formGroup.get('outboundEdi.x12Lookup.guestidentity')?.value
      x12Lookup.guestidentityQualifier = formGroup.get('outboundEdi.x12Lookup.guestidentityQualifier')?.value

      return x12Lookup;
    } else {
      return null;
    }
  }

  public getEdifactLookupModel(formGroup: FormGroup, entityMapId: number) : EDILookup {
    const x12LookupFormGroup = formGroup.get('outboundEdi.ediLookup') as FormGroup;

    if(x12LookupFormGroup) {
      let edifactLookup: EDILookup = new EDILookup();

      edifactLookup.id = formGroup.get('outboundEdi.ediLookup.id')?.value
      edifactLookup.entityMapId = entityMapId;
      edifactLookup.hostidentity = formGroup.get('outboundEdi.ediLookup.hostidentity')?.value
      edifactLookup.hostidentityQualifier = formGroup.get('outboundEdi.ediLookup.hostidentityQualifier')?.value
      edifactLookup.guestidentity = formGroup.get('outboundEdi.ediLookup.guestidentity')?.value
      edifactLookup.guestidentityQualifier = formGroup.get('outboundEdi.ediLookup.guestidentityQualifier')?.value

      return edifactLookup;
    } else {
      return null;
    }
  }

  public clearIdForCopy(configuration: Configuration) : void {
    configuration.entityMap.id = 0;
    if(configuration.preFormatting) {
      configuration.preFormatting.id = 0;
    }
    if(configuration.postFormatting) {
      configuration.postFormatting.id = 0;
    }
    if(configuration.destinations) {
      configuration.destinations.forEach(d => {
            d.id = 0;
            d.entityMapId = 0;
        });
    }       
  }

  //------------------------------------------------------------------------------------------------------------------------------------------
  // Form controls functions
  //------------------------------------------------------------------------------------------------------------------------------------------
  //Temporary added (, outboundMap: OutboundMap) to getEntitymapFromFormGroup
  public getEntitymapFromFormGroup(formGroup: FormGroup) {
    let entityMap = new EntityMap();
    entityMap.id = formGroup.value.entitymap.id;
    entityMap.senderId = formGroup.value.entitymap.senderId;
    entityMap.senderName = formGroup.value.entitymap.senderName;
    entityMap.receiverId = formGroup.value.entitymap.receiverId;
    entityMap.receiverName = formGroup.value.entitymap.receiverName;
    entityMap.legalEntityId = formGroup.value.entitymap.legalEntityId;
    entityMap.legalEntityName = formGroup.value.entitymap.legalEntityName;
    entityMap.areaPlantId = formGroup.value.entitymap.areaPlantId;
    entityMap.areaPlantName = formGroup.value.entitymap.areaPlantName;
    entityMap.customLookupId = formGroup.value.entitymap.customLookupId;
    entityMap.country = formGroup.value.entitymap.country;
    entityMap.inboundPartnerId = formGroup.value.inboundPartner.inboundPartnerId;
    entityMap.outboundPartnerId = formGroup.value.outboundPartner.outboundPartnerId;
    entityMap.outboundMessageTypeId = formGroup.value.outboundMessageType.outboundMessageTypeId;
    entityMap.inboundMessageTypeId = formGroup.value.inboundMessageType.inboundMessageTypeId;
    entityMap.outboundMapXsltId = formGroup.value.outboundMapXslt.outboundMapXsltId //   outboundMapXslt.id;

    return entityMap;
  }

  public getEmptyConfigurationFormGroup() {
    let formGroup: FormGroup = new FormGroup({
        specifyEnrichment: new FormControl(''),
        specifyAS2Inbound: new FormControl(''),
        specifyAS2Outbound: new FormControl(''),
        inboundMessageType: new FormGroup({
            inboundMessageTypeId: new FormControl('', Validators.required),
            messageName: new FormControl(''),
            messageTypeDescription: new FormControl(''),
        }),
        outboundMessageType: new FormGroup({
            outboundMessageTypeId: new FormControl('', Validators.required),
            messageName: new FormControl(''),
            messageTypeDescription: new FormControl(''),
        }),
        inboundPartner: new FormGroup({
            inboundPartnerId: new FormControl('', Validators.required),
            basicAuthenticationUsername: new FormControl(''),
            basicAuthenticationPassword: new FormControl(''),
            currentBasicAuthenticationPassword: new FormControl(''),
            useBasicAuthentication: new FormControl(false),
            partnerId: new FormControl(''),
            name: new FormControl(''),
            description: new FormControl(''),
        }),
        outboundPartner: new FormGroup({
            outboundPartnerId: new FormControl('', Validators.required),
            partnerId: new FormControl(''),
            name: new FormControl(''),
            description: new FormControl(''),
        }),
        resolverClass: new FormGroup({
            name: new FormControl(''),
            description: new FormControl(''),
        }),
        parserClass: new FormGroup({                
            name: new FormControl(''),
            description: new FormControl(''),
        }),
        inboundMappings: new FormArray([], [this.requireAtLeastOneInboundMapping()]),
        outboundMapXslt: new FormGroup({
          outboundMapXsltId: new FormControl('', Validators.required),
          outboundMapXsltName: new FormControl(''),
          outboundMapXsltDescription: new FormControl(''),
        }),
        preFormatting: new FormGroup({
          preFormattingId: new FormControl(0),
          preFormattingClassId: new FormControl(''),
          preFormattingClassName: new FormControl(''),
          preFormattingClassDescription: new FormControl(''),
          rootTag: new FormControl(''),
        }),
        postFormatting: new FormGroup({
          postFormattingId: new FormControl(0),
          postFormattingClassId: new FormControl(''),
          postFormattingClassName: new FormControl(''),
          postFormattingClassDescription: new FormControl(''),
        }),
        // preStep: new FormGroup({
        //     preStepClassId: new FormControl(''),
        //     preStepClass: new FormControl(''),                
        // }),
        as2ConfIn: new FormGroup({
            id: new FormControl(''),
            requestFormat: new FormControl(''),
            mdnformat: new FormControl(''),
            ackFormat: new FormControl(''),
            ackEndpoint: new FormControl(''),
            ackEnabled: new FormControl('')
        }),
        entitymap: new FormGroup({
            id: new FormControl(0),
            senderId: new FormControl('', Validators.required),
            senderName: new FormControl('', Validators.required),
            receiverId: new FormControl('', Validators.required),
            receiverName: new FormControl('', Validators.required),
            legalEntityId: new FormControl('', Validators.required),
            legalEntityName: new FormControl('', Validators.required),
            areaPlantId: new FormControl(''),
            areaPlantName: new FormControl(''),
            customLookupId: new FormControl(''),
            country: new FormControl(''),
            outboundMapXsltId: new FormControl(''),
        }),
        confDestConnections: new FormArray([], [this.requireAtLeastOneDestination()])
    });

    // For new configuration we add a more or less empty outboundEdi FormGroup 
    const outboundEdi: FormGroup = new FormGroup({});
    outboundEdi.addControl('configureAS2', new FormControl(false));
    outboundEdi.addControl('configureEdifact', new FormControl(false));
    outboundEdi.addControl('configureX12', new FormControl(false));      
    formGroup.addControl('outboundEdi', outboundEdi);

    return formGroup;
  }

  public getConfigurationFormGroupWithData(configuration: Configuration, tabStatuses: TabStatuses, basedataModel: WizardResponseModel, outboundMaps: Array<OutboundMap>) {
    let formGroup: FormGroup = new FormGroup({
        specifyEnrichment: new FormControl(tabStatuses.specifyEnrichment),
        specifyAS2Inbound: new FormControl(configuration.aS2ConfIn != undefined ? true : false),
        specifyAS2Outbound: new FormControl(tabStatuses.specifyAS2Outbound),
        inboundMessageType: new FormGroup({
            inboundMessageTypeId: new FormControl(configuration.inboundMessageType.inboundMessageTypeId, Validators.required),
            messageName: new FormControl(configuration.inboundMessageType.messageName),
            messageTypeDescription: new FormControl(configuration.inboundMessageType.messageTypeDescription),
        }),
        outboundMessageType: new FormGroup({
            outboundMessageTypeId: new FormControl(configuration.outboundMessageType.messageTypeId, Validators.required),
            messageName: new FormControl(configuration.outboundMessageType.messageName),
            messageTypeDescription: new FormControl(configuration.outboundMessageType.messageTypeDescription),
        }),
        inboundPartner: new FormGroup({
            inboundPartnerId: new FormControl(configuration.inboundPartner.id, Validators.required),
            basicAuthenticationUsername: new FormControl(configuration.basicAuthUsername ?? ''),
            basicAuthenticationPassword: new FormControl(configuration.basicAuthPassword ?? ''),
            currentBasicAuthenticationPassword: new FormControl(configuration.basicAuthPassword ?? ''),
            useBasicAuthentication: new FormControl(configuration.basicAuthUsername == undefined ? false: true),
            partnerId: new FormControl(configuration.inboundPartner.partnerId),
            name: new FormControl(configuration.inboundPartner.name),
            description: new FormControl(configuration.inboundPartner.description),
        }),
        outboundPartner: new FormGroup({
            outboundPartnerId: new FormControl(configuration.outboundPartner.id, Validators.required),
            partnerId: new FormControl(configuration.outboundPartner.partnerId),
            name: new FormControl(configuration.outboundPartner.name),
            description: new FormControl(configuration.outboundPartner.description),
        }),
        resolverClass: new FormGroup({
            name: new FormControl(configuration.resolver.resolverClass.resolver),
            description: new FormControl(configuration.resolver.resolverClass.description),
        }),
        parserClass: new FormGroup({    // Just data for displaying info
            name: new FormControl(configuration.inboundParser.parserClass.parser),
            description: new FormControl(configuration.inboundParser.parserClass.description),
        }),
        inboundMappings: new FormArray([], [this.requireAtLeastOneInboundMapping()]),
        outboundMapXslt: new FormGroup({
            outboundMapXsltId: new FormControl(configuration.outboundMapXslt.id, Validators.required),
            outboundMapXsltName: new FormControl(configuration.outboundMapXslt.name),
            outboundMapXsltDescription: new FormControl(configuration.outboundMapXslt.description),
        }),
        preFormatting: new FormGroup({
          preFormattingId: new FormControl(configuration.preFormatting?.id ?? 0),
          preFormattingClassId: new FormControl(configuration.preFormatting?.preFormattingClassId ?? ''),
          preFormattingClassName: new FormControl(configuration.preFormatting?.preFormattingClass?.formattingClass ?? ''),
          preFormattingClassDescription: new FormControl(configuration.preFormatting?.preFormattingClass?.description ?? ''),
          rootTag: new FormControl(configuration.preFormatting?.rootTag),
        }),
        postFormatting: new FormGroup({
          postFormattingId: new FormControl(configuration.postFormatting?.id ?? 0),
          postFormattingClassId: new FormControl(configuration.postFormatting?.postFormattingClassId ?? ''),
          postFormattingClassName: new FormControl(configuration.postFormatting?.postFormattingClass?.formattingClass ?? ''),
          postFormattingClassDescription: new FormControl(configuration.postFormatting?.postFormattingClass?.description ?? ''),
        }),
        as2ConfIn: new FormGroup({
            id: new FormControl(configuration.aS2ConfIn?.id ?? ''),
            requestFormat: new FormControl(configuration.aS2ConfIn?.requestFormat ?? ''),
            mdnformat: new FormControl(configuration.aS2ConfIn?.mdnformat ?? ''),
            ackFormat: new FormControl(configuration.aS2ConfIn?.ackFormat ?? ''),
            ackEndpoint: new FormControl(configuration.aS2ConfIn?.ackEndpoint ?? ''),
            ackEnabled: new FormControl(configuration.aS2ConfIn?.ackEnabled ?? '')
        }),
        entitymap: new FormGroup({
            id: new FormControl(configuration.entityMap.id, Validators.required),
            senderId: new FormControl(configuration.entityMap.senderId, Validators.required),
            senderName: new FormControl(configuration.entityMap.senderName, Validators.required),
            receiverId: new FormControl(configuration.entityMap.receiverId, Validators.required),
            receiverName: new FormControl(configuration.entityMap.receiverName, Validators.required),
            legalEntityId: new FormControl(configuration.entityMap.legalEntityId, Validators.required),
            legalEntityName: new FormControl(configuration.entityMap.legalEntityName, Validators.required),
            areaPlantId: new FormControl(configuration.entityMap.areaPlantId),
            areaPlantName: new FormControl(configuration.entityMap.areaPlantName),
            customLookupId: new FormControl(configuration.entityMap.customLookupId),
            country: new FormControl(configuration.entityMap.country),
            outboundMapXsltId: new FormControl(configuration.entityMap.outboundMapXsltId)
        }),
        confDestConnections: new FormArray([], [this.requireAtLeastOneDestination()])
    });

    let confDestConnections = (formGroup.get('confDestConnections') as FormArray);

    configuration.destinations.forEach(d => {
        confDestConnections.push(new FormGroup({
                id: new FormControl(d.id),
                entityMapId: new FormControl(d.entityMapId),
                destinationId: new FormControl(d.destinationId),
                enabled: new FormControl(d.enabled),
                removeBom: new FormControl(d.removeBom),
                description: new FormControl(d.description),
                confDestination: new FormGroup({
                type: new FormControl(d.confDestination.type),
            })
        }));
    });

    let selectedCanonicalMaps = basedataModel.canonicalMaps.filter(x => x.inboundMessageTypeId === configuration.inboundMessageType.inboundMessageTypeId && x.inboundPartnerId === configuration.inboundPartner.id);
    let inboundMappings = (formGroup.get('inboundMappings') as FormArray);

    selectedCanonicalMaps.forEach(c => {
        let canonicalMapFormGroup = new FormGroup({
            id: new FormControl(c.id),
            partnerId: new FormControl(c.inboundPartnerId),
            messageTypeId: new FormControl(c.inboundMessageTypeId),
            taskId: new FormControl(c.taskId),
            xsltName: new FormControl(c.canonicalMapXslt.name),
            xsltDescription: new FormControl(c.canonicalMapXslt.description),
        });
        inboundMappings.push(canonicalMapFormGroup);
    });

    // If this is outgoing edi create child formgroup to hold that data
    if(configuration.aS2ConfOut !== null || configuration.ediLookup !== null || configuration.x12Lookup !== null) {
      const outboundEdi: FormGroup = new FormGroup({});

      if(configuration.aS2ConfOut != undefined) {
        const aS2ConfOutFormGroup = new FormGroup({
          id: new FormControl(configuration.aS2ConfOut.id),
          responseFormat: new FormControl(configuration.aS2ConfOut.responseFormat),
          mdnformat: new FormControl(configuration.aS2ConfOut.mdnformat),
          as2Agreement: new FormControl(configuration.aS2ConfOut.as2Agreement),
          as2Sender: new FormControl(configuration.aS2ConfOut.as2Sender),
          as2Receiver: new FormControl(configuration.aS2ConfOut.as2Receiver),
        });
  
        outboundEdi.addControl('as2ConfOut', aS2ConfOutFormGroup);
        outboundEdi.addControl('configureAS2', new FormControl(true));        
        
      } else {
        outboundEdi.addControl('configureAS2', new FormControl(false));        
      }
  
      if(configuration.ediLookup != undefined) {
        const ediLookupFormGroup = new FormGroup({
          id: new FormControl(configuration.ediLookup.id),
          hostidentity: new FormControl(configuration.ediLookup.hostidentity),
          hostidentityQualifier: new FormControl(configuration.ediLookup.hostidentityQualifier),
          guestidentity: new FormControl(configuration.ediLookup.guestidentity),
          guestidentityQualifier: new FormControl(configuration.ediLookup.guestidentityQualifier),
        });
        
        outboundEdi.addControl('ediLookup', ediLookupFormGroup);
        outboundEdi.addControl('configureEdifact', new FormControl(true));
      } else {
        outboundEdi.addControl('configureEdifact', new FormControl(false));
      }
  
      if(configuration.x12Lookup != undefined) {
        const x12LookupFormGroup = new FormGroup({
          id: new FormControl(configuration.x12Lookup.id),
          hostidentity: new FormControl(configuration.x12Lookup.hostidentity),
          hostidentityQualifier: new FormControl(configuration.x12Lookup.hostidentityQualifier),
          guestidentity: new FormControl(configuration.x12Lookup.guestidentity),
          guestidentityQualifier: new FormControl(configuration.x12Lookup.guestidentityQualifier),
        });
  
        outboundEdi.addControl('x12Lookup', x12LookupFormGroup);
        outboundEdi.addControl('configureX12', new FormControl(true));        
      } else {
        outboundEdi.addControl('configureX12', new FormControl(false));        
      }

      formGroup.addControl('outboundEdi', outboundEdi);
    }

    return formGroup;
}

  private requireAtLeastOneDestination(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        const hasAtLeastOneObject = value && value.length > 0;
        return hasAtLeastOneObject ? null : { requireAtLeastOneDestination: true };
    };
  }

  private requireAtLeastOneInboundMapping(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        const hasAtLeastOneObject = value && value.length > 0;
        return hasAtLeastOneObject ? null : { requireAtLeastOneInboundMapping: true };
    };
  }
}