<!-- As as2Sender is required we can check if it exist in order to display this fieldset -->
<fieldset class="border p-2" *ngIf="rs.getNestedFormControlValue(form, 'as2ConfIn', 'id')">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">AS2 Inbound configuration</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Request format:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ viewService.getFormatType(rs.getNestedFormControlValue(form, 'as2ConfIn', 'requestFormat'))  }}</label> 
        </div>
        <div class="col-sm-2">
            <label><b>MDN format:</b></label>
        </div>                        
        <div class="col-sm-3">
            <label>{{ viewService.getFormatType(rs.getNestedFormControlValue(form, 'as2ConfIn', 'mdnformat'))  }}</label> 
        </div>
    </div> 
    
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>ACK format:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ viewService.getFormatType(rs.getNestedFormControlValue(form, 'as2ConfIn', 'ackFormat'))  }}</label> 
        </div>
        <div class="col-sm-2">
            <label><b>ACK endpoint:</b></label>
        </div>                        
        <div class="col-sm-3">
            <label>{{ viewService.getFormatType(rs.getNestedFormControlValue(form, 'as2ConfIn', 'ackEndpoint'))  }}</label> 
        </div>
    </div> 

    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>ACK enabled:</b></label>
        </div>
        <div class="col-sm-3">
            <label>{{ rs.getNestedFormControlValue(form, 'as2ConfIn', 'ackEnabled') == true ? 'YES' : 'NO'  }}</label> 
        </div>
        <div class="col-sm-2">
        </div>                        
        <div class="col-sm-3">
        </div>
    </div> 
    <!-- <two-col-row-display [firstLabel]="'Request format:'" [firstValue]="viewService.getFormatType(configuration.aS2ConfIn.requestFormat)" [secondLabel]="'MDN format:'" [secondValue]="viewService.getFormatType(configuration.aS2ConfIn.mdnformat)" [labelClass]="'col-sm-2'" [valueClass]="'col-sm-3'"></two-col-row-display> -->

</fieldset>