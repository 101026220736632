<h3 id="tableLabel">Administrate partners</h3>

<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div>

<div>
  <table class='table table-striped table-bordered' aria-labelledby="tableLabel">
      <thead>
        <tr>
          <th scope="col" colSpan="9">
            <div>
              <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="createNewPartner()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
            </div>
          </th>
        </tr>
      </thead>
      <thead>
          <tr>
              <th class="vtop">
                <div>Id</div>
              </th>              
              <th>
                <div>Name</div>
                <div>
                  <input type="text" class="form-control" id="filterName" name="filterName" placeholder="Filter name" [(ngModel)]="filterName" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                </div>
              </th>
              <th>
                <div>Description</div>
                <div>
                  <input type="text" class="form-control" id="filterDescription" name="filterDescription" placeholder="Filter description" [(ngModel)]="filterDescription" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                </div>
              </th>
              <th>
                <div>Partner Id</div>
                <div>
                  <input type="text" class="form-control" id="filterPartnerId" name="filterPartnerId" placeholder="Filter partner id" [(ngModel)]="filterPartnerId" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                </div>
              </th>             
              <th class="vtop">
                <div>Created by</div>
              </th>  
              <th class="vtop">
                <div>Created at</div>
              </th>  
              <th class="vtop">
                <div>Updated by</div>
              </th>  
              <th class="vtop">
                <div>Updated at</div>
              </th>  
              <th>                
              </th> 
          </tr>
      </thead>
      <tbody *ngIf="gridView">
          <tr *ngFor="let p of gridView.rows;let index = index;">
              <td>
                <span>{{p.id}}</span>
              </td>              
              <td>
                <span>{{p.name}}</span>
              </td>
              <td>
                <span>{{p.description}}</span>
              </td>
              <td>
                <span>{{p.partnerId}}</span>
              </td>            
              <td>
                <span>{{p.createdBy}}</span>                
              </td>
              <td>
                <span>{{p.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>                
              </td> 
              <td>
                <span>{{p.updatedBy}}</span>                
              </td>
              <td>
                <span>{{p.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>                
              </td>             
              <td>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="isOnboarder" (click)="editPartner(p)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                <!-- <button class="btn btn-outline-secondary btn-sm" (click)="removePartner(p)" style="margin-right: 5px;"><i class="fa fa-trash-o" aria-hidden="true"></i></button> -->
              </td>
          </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="11">
            <div *ngIf="gridView">
              <table>
                <tr>
                  <td style="margin: auto;">
                    <nav>
                      <ul class="pagination">
                        <li class="page-item" ng-class="{disabled: currentPage == 1}">
                          <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(partnerPaging.currentPage, 1, partnerPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(partnerPaging.currentPage, 1, partnerPaging.totalPages), 1)">{{pService.getPageNumber(partnerPaging.currentPage, 1, partnerPaging.totalPages)}}</a></li>
                        <li class="page-item" *ngIf="partnerPaging.totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(partnerPaging.currentPage, 2, partnerPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(partnerPaging.currentPage, 2, partnerPaging.totalPages), 2)">{{pService.getPageNumber(partnerPaging.currentPage, 2, partnerPaging.totalPages)}}</a></li>
                        <li class="page-item" *ngIf="partnerPaging.totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(partnerPaging.currentPage, 3, partnerPaging.totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(partnerPaging.currentPage, 3, partnerPaging.totalPages), 3)">{{pService.getPageNumber(partnerPaging.currentPage, 3, partnerPaging.totalPages)}}</a></li>
                        <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                          <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                        </li>
                      </ul>
                      </nav>
                  </td>
                  <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                    <label>{{(((partnerPaging.currentPage) - 1) * partnerPaging.pageCount) + 1}} - {{partnerPaging.lastDisplayIndex}} of {{gridView.totalRows}}</label>
                  </td>
                  <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;"> 
                    <select dropdown (change)="selectChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="partnerPaging.pageCount">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>                  
              </table>
            </div>
          </td>
        </tr>
      </tfoot>
  </table>
</div>
