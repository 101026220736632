<form [formGroup]="inboundPartnerSourceForm" (submit)="save()">
<div class="modal-header">
    <h4 class="modal-title">Add source</h4>
</div>
<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<error-display [errorModel]="errorModel"></error-display>
<div class="modal-body">    
    <div class="form-group row form-inline">
        <label for="protocolDDL" class="col-sm-2 col-form-label control-label mb10">Protocol</label>
        <div class="col-sm-6">
            <table>
                <tr>
                    <td>
                        <select dropdown id="protocolDDL" name="protocolDDL" formControlName="protocolType" class="form-select form-select-sm control-label" (change)="selectProtocolChangeHandler($event)">
                            <option value="">--- Select protocol ---</option>
                            <option value="2">File Share</option>
                            <option value="3">FTP</option>
                            <option value="7">MQ</option>
                            <option value="1">Service bus</option>
                            <option value="8">SFTP</option>
                        </select>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <!-- Scheduler -->
    <div *ngIf="inboundPartnerSourceForm.value.protocolType !== ''">
        <div class="form-group row mb5">
            <div class="col-sm-12">
                <span class="text-muted"><small>Name should be in the form "[partner] [Protocol Type] source" (example
                        "One4AL MQ source"). If partner use different sources the name should be in the form "[partner]
                        [Protocol Type] [Message Type] source" (example "One4AL MQ ProcessPurchaseOrder
                        source").</small></span>
            </div>
        </div>

        <!-- scheduler.name -->
        <div class="form-group row mb5">
            <label for="schedulerName" class="col-sm-3 col-form-label">Name<span class="req-color">*</span></label>            
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="name" required id="schedulerName"
                    name="schedulerName" placeholder="Source Name">
                <div *ngIf="form.name.invalid && (form.name.dirty || form.name.touched)"
                    class="alert alert-danger">
                    <div *ngIf="form.name.errors?.['required']">Name is required.</div>
                </div>
            </div>
        </div>

        <!-- scheduler.enabled -->
        <div class="form-group row mb5">
            <div class="col-sm-3 col-form-label"></div>
            <div class="col-sm-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="enabled" id="schedulerEnabled" name="schedulerEnabled" placeholder="Scheduler Enabled" value="" aria-label="...">
                    <label class="form-check-label" for="enabled">
                        Enabled
                    </label>
                </div>
            </div>
        </div>

        <!-- scheduler.validationType -->
        <div class="form-group row form-inline mb10">
            <label for="validationTypesDDL" class="col-sm-3 col-form-label control-label">Validation type</label>
            <div class="col-sm-6">
                <select dropdown id="validationTypesDDL" name="validationTypesDDL" formControlName="validationType" class="form-select form-select-sm control-label" >
                    <option *ngFor="let item of sourceInputService.validationTypes" [value]="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
        </div>

        <!-- scheduler.callbackUrl -->
        <div class="form-group row mb5">
            <label for="schedulerCallbackUrl" class="col-sm-3 col-form-label">Callback Url</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="schedulerCallbackUrl" name="schedulerCallbackUrl" formControlName="callbackUrl"
                    placeholder="Callback Url">
            </div>
        </div>

        <!-- scheduler.callbackEnabled -->
        <div class="form-group row mb5">
            <div class="col-sm-3 col-form-label"></div>
            <div class="col-sm-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="callbackEnabled" value="" aria-label="..." formControlName="callbackEnabled">
                    <label class="form-check-label" for="callbackEnabled">
                        Callback enabled
                    </label>
                </div>
            </div>
        </div>

        
        <div class="form-group row mb10">
            <label for="selectAuthentication" class="col-sm-3 col-form-label control-label">EDI Type </label>
            <div class="col-sm-6">
                <select dropdown formControlName="ediType" id="selectEDIType" name="selectEDIType"
                    class="form-select form-select-sm control-label">
                    <option *ngFor="let item of sourceInputService.ediTypes" [value]="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
        </div>

        <!-- IS simplified flow -->
        <div class="form-group row mb5">
            <div class="col-sm-3 col-form-label"></div>
            <div class="col-sm-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="schedulerIsSimplified" formControlName="isSimplified" value="" aria-label="..." (change)="selectIsSimplifiedChangeHandler($event)">
                    <label class="form-check-label" for="isSimplified">
                        Use for simplified flow
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="inboundPartnerSourceForm.value.isSimplified === true && inboundMessageTypesDDL != undefined && inboundMessageTypesDDL.length > 0" class="form-group row mb5">
            <label for="inboundMessageTypeDDL" class="col-sm-3 col-form-label control-label">InboundMessageType for Simplified Flow <span class="req-color">*</span></label>
            <div class="col-sm-6">
                <select dropdown formControlName="inboundMessageTypeId" id="inboundMessageTypeDDL" name="inboundMessageTypeDDL"  class="form-select form-select-sm control-label">
                    <option value="">--- Select message type ---</option>
                    <option *ngFor="let item of inboundMessageTypesDDL" [value]="item.value">
                        {{item.text}}
                    </option>
                </select>
                <div *ngIf="inboundPartnerSourceForm.value.isSimplified && (form.inboundMessageTypeId.invalid && (form.inboundMessageTypeId.dirty || form.inboundMessageTypeId.touched))"
                    class="alert alert-danger">
                    <div *ngIf="form.inboundMessageTypeId.errors?.['required']">Message type is required.</div>
                </div>
            </div>
        </div>
    </div>

    <!-- Source -->
    <div>
        <source-fileshare-input *ngIf="inboundPartnerSourceForm.value.protocolType == '2'" formGroupName="sourceSharedFolderProfileFormGroup" [isEdit]="false"></source-fileshare-input>
        <source-mq-input *ngIf="inboundPartnerSourceForm.value.protocolType == '7'" formGroupName="sourceMqProfileFormGroup" [isEdit]="false"></source-mq-input>
        <source-sb-input *ngIf="inboundPartnerSourceForm.value.protocolType == '1'" formGroupName="sourceSbProfileFormGroup" [isEdit]="false"></source-sb-input>
        <source-sftp-input *ngIf="inboundPartnerSourceForm.value.protocolType == '8'" formGroupName="sourceSftpProfileFormGroup" [isEdit]="false"></source-sftp-input>
        <source-ftp-input *ngIf="inboundPartnerSourceForm.value.protocolType == '3'" formGroupName="sourceFtpProfileFormGroup" [isEdit]="false"></source-ftp-input>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" [disabled]="!inboundPartnerSourceForm.valid" (click)="save()">Add</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
</form>