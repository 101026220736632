import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { DestinationHttpprofile } from 'src/app/models/destination/destination-http-profile';

@Injectable({
  providedIn: 'root',
})
export class HttpProfileService {
    public _http: HttpClient;
    public baseUrlLocal: string = "";
    
    constructor(private http: HttpClient) {
        this._http = http;
        this.baseUrlLocal = environment.apiBaseUrl;
    }

    public getAllHttpProfiles(): Observable<Array<DestinationHttpprofile>> {
        return this._http.get<Array<DestinationHttpprofile>>(`${this.baseUrlLocal}confdestinations/httpprofiles`);
    }
}
