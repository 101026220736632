import { Component, Input, OnInit } from '@angular/core';
import { ConfDestConnection } from 'src/app/models/destination/conf-dest-connection';
import { Scheduler } from 'src/app/models/source/scheduler';
import { TabStatuses } from 'src/app/models/tab-statuses';
import { ResponseResolverUpdateData } from 'src/app/models/response-resolver/response-resolver-update-data';
import { Enrichment } from 'src/app/models/enrichment';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'review-input',
  templateUrl: './review-input.component.html',
})
export class ReviewInputComponent implements OnInit {
  @Input() tabStatuses: TabStatuses;
  @Input() schedulers: Array<Scheduler>
  @Input() confDestConnections: Array<ConfDestConnection>
  @Input() responseResolver: ResponseResolverUpdateData
  @Input() enrichments: Array<Enrichment>;

  public locResponseResolver: ResponseResolverUpdateData;
  form!: FormGroup;
  public inboundMessageTypeName: string = '';

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void { 
    this.form = this.rootFormGroup.form;

    if(this.responseResolver.responseResolver.responseResolverClass !== '') {
      this.locResponseResolver = this.responseResolver;
    } else {
      this.locResponseResolver = null;
    }
  }
}
