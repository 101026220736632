import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { EDIPreFormatter } from 'src/app/models/edi-preformatter/edi-preformatter';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'http';

@Injectable({
  providedIn: 'root',
})
export class MDEdiPreFormatterInputService {
  public baseUrlLocal: string = "";
  constructor(private http: HttpClient) {
    this.baseUrlLocal = environment.apiBaseUrl;
  }

  public getInboundPartnerEdiPreFormatters(inboundpartnerId: number): Observable<Array<EDIPreFormatter>> {
    return this.http.get<Array<EDIPreFormatter>>(`${this.baseUrlLocal}edipreformatter/${inboundpartnerId}`);
  }

  public addInboundPartnerEdiPreFormatter(ediPreFormatter: EDIPreFormatter) {
    return this.http.post(this.baseUrlLocal + 'edipreformatter', ediPreFormatter);
  }

  public updateInboundPartnerEdiPreFormatter(ediPreFormatter: EDIPreFormatter) {
    return this.http.put(this.baseUrlLocal + 'edipreformatter', ediPreFormatter);
  }

  public deleteInboundPartnerEdiPreFormatter(ediPreFormatter: EDIPreFormatter) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: ediPreFormatter
    };
    return this.http.delete(this.baseUrlLocal + 'edipreformatter', options);
  }

  public getEmptyInboundPartnerEdiPreFormatterForm(partnerId: number) {
    return new FormGroup({
      id: new FormControl(0, Validators.required),
      inboundPartnerId: new FormControl(partnerId, Validators.required),
      description: new FormControl('', Validators.required),
      regEx: new FormControl('', Validators.required),
      replaceValue: new FormControl('', Validators.required),
      encoding: new FormControl(''),
      order: new FormControl(0, Validators.required)
    });
  }

  public getInboundPartnerEdiPreFormatterForm(ediPreFormatter: EDIPreFormatter) {
    return new FormGroup({
      id: new FormControl(ediPreFormatter.id, Validators.required),
      inboundPartnerId: new FormControl(ediPreFormatter.inboundPartnerId, Validators.required),
      description: new FormControl(ediPreFormatter.description, Validators.required),
      regEx: new FormControl(ediPreFormatter.regEx, Validators.required),
      replaceValue: new FormControl(ediPreFormatter.replaceValue, Validators.required),
      encoding: new FormControl(ediPreFormatter.encoding),
      order: new FormControl(ediPreFormatter.order, Validators.required)
    });
  }

  public getInboundPartnerEdiPreFormatterModel(formGroup: FormGroup): EDIPreFormatter {
    let ediPreFormatter = new EDIPreFormatter();
    ediPreFormatter.description = formGroup.value.description;
    ediPreFormatter.regEx = formGroup.value.regEx;
    ediPreFormatter.replaceValue = formGroup.value.replaceValue;
    ediPreFormatter.encoding = formGroup.value.encoding;
    ediPreFormatter.order = formGroup.value.order;
    ediPreFormatter.id = formGroup.value.id;
    ediPreFormatter.inboundPartnerId = formGroup.value.inboundPartnerId;
    return ediPreFormatter;
  }
}
