<div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
<div class="alert alert-danger" role="alert" *ngIf="error">
  <b>{{errorMessage}}</b>
</div>

<div>
  <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="comdGridView">
      <thead>
        <tr>
          <th scope="col" colSpan="9">
            <div>
              <button class="btn btn-outline-secondary btn-sm" (click)="createNewCanonicalToOutboundMappingDocument()" style="float: right;"><i class="fa fa-plus" aria-hidden="true"></i></button>
            </div>
          </th>
        </tr>
      </thead>
      <thead>
          <tr>
              <th class="vtop">
                <div>Id</div>
              </th>
              <th>
                <div>Mapping Document Name</div>
                <div>
                  <input type="text" class="form-control" id="mappingDocumentName" name="mappingDocumentName" placeholder="Filter mapping document name" [(ngModel)]="filterCanonicalToOutboundMappingDocumentName" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                </div>
              </th>
              <th>
                <div>Description</div>
                <div>
                  <input type="text" class="form-control" id="mappingDocumentDescription" name="mappingDocumentDescription" placeholder="Filter description" [(ngModel)]="filterCanonicalToOutboundMappingDocumentDescription" (change)="onFilter()" ngbTooltip="Enter at least 3 characters to filter and then press tab.">
                </div>
              </th>
              <th class="vtop">
                <div>Created by</div>
              </th>
              <th class="vtop">
                <div>Created at</div>
              </th>
              <th class="vtop">
                <div>Updated by</div>
              </th>
              <th class="vtop">
                <div>Updated at</div>
              </th>
              <th>
              </th>
          </tr>
      </thead>
      <tbody *ngIf="mappingDocumentGridList">
          <tr *ngFor="let comd of comdGridView.rows;let index = index;">
              <td>
                <span>{{comd.id}}</span>
              </td>
              <td>
                <span>{{comd.name}}</span>
              </td>
              <td>
                <span>{{comd.description}}</span>
              </td>
              <td>
                <span>{{comd.createdBy}}</span>
              </td>
              <td>
                <span>{{comd.createTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
              </td>
              <td>
                <span>{{comd.updatedBy}}</span>
              </td>
              <td>
                <span>{{comd.updateTimestamp | date: 'yyyy-MM-dd hh:mm.ss'}}</span>
              </td>
              <td>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="!isAdmin" (click)="editCanonicalToOutboundMappingDocument(comd)" style="margin-right: 5px;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                <button class="btn btn-outline-secondary btn-sm" [disabled]="!isAdmin" (click)="removeCanonicalToOutboundMappingDocument(comd)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
              </td>
          </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="11">
            <div>
              <table>
                <tr>
                  <td style="margin: auto;">
                    <nav>
                      <ul class="pagination">
                        <li class="page-item" ng-class="{disabled: currentPage == 1}">
                          <a class="page-link" href="" (click)="$event.preventDefault();onclickPrev()" tabindex="-1">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 1, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 1, totalPages), 1)">{{pService.getPageNumber(currentPage, 1, totalPages)}}</a></li>
                        <li class="page-item" *ngIf="totalPages > 1"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 2, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 2, totalPages), 2)">{{pService.getPageNumber(currentPage, 2, totalPages)}}</a></li>
                        <li class="page-item" *ngIf="totalPages > 2"><a class="page-link" [ngStyle]="{'font-weight': (pService.pageNumberBold(currentPage, 3, totalPages)===true) ? 'bold' : ''}" href="#" (click)="$event.preventDefault(); onclickPage(pService.getPageNumber(currentPage, 3, totalPages), 3)">{{pService.getPageNumber(currentPage, 3, totalPages)}}</a></li>
                        <li class="page-item" ng-class="{disabled: nextButtonDisabled}">
                          <a class="page-link" href="" (click)="$event.preventDefault(); onclickNext()">Next</a>
                        </li>
                      </ul>
                      </nav>
                  </td>
                  <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                    <label>{{(((currentPage) - 1) * pageCount) + 1}} - {{lastDisplayIndex}} of {{comdGridView.totalRows}}</label>
                  </td>
                  <td style="vertical-align: baseline; padding-top: 5px; padding-left: 10px;">
                    <select dropdown (change)="selectChangeHandler($event)" id="pageCountDDL" name="pageCountDDL" class="form-select form-select-sm control-label" [(ngModel)]="pageCount">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </tfoot>
  </table>
</div>
