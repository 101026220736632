import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';


@Component({
  selector: 'source-sftp-input',
  templateUrl: './source-sftp.component.html'
})

export class SourceSftpComponent implements OnInit{
    @Input() formGroupName: string;
    @Input() isEdit: boolean;
  
      
    public keyVaultProfiles: Array<KeyVaultProfile>;
      public sourceSftpProfileForm: FormGroup;
  
      constructor(private keyVaultService: KeyVaultService, private rootFormGroup: FormGroupDirective) {}
  
      get form() { return this.sourceSftpProfileForm.controls; }
  
      ngOnInit(): void {
        this.sourceSftpProfileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        // Subscribe on value changes for systemName input control to only allow a-z and 0-9
        this.sourceSftpProfileForm.get('systemName').valueChanges.subscribe((value: string) => {
          const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
          if (sanitizedValue !== value) {
              this.sourceSftpProfileForm.get('systemName').setValue(sanitizedValue, { emitEvent: false });
          }
        });

        this.keyVaultService.getKeyVaultProfiles().subscribe({
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        }); 
    }
  }
