import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { MDOutboundMappingConnectionInputService } from './md-canonicalToOutboundMappingConnection-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { UtilService } from 'src/app/common-services/util.service';
import { OutboundMap } from 'src/app/models/mapping/outbound-map';

export function controlContainerFactory(controlContainer?: ControlContainer) {
  return controlContainer;
}

@Component({
  selector: 'add-canonicalToOutboundMappingConnection',
  templateUrl: './add-canonicalToOutboundMappingConnection-modal.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: controlContainerFactory,
      deps: [[new Optional(), NgForm]]
    }
  ]
})
export class AddOutboundMappingConnectionModalComponent implements OnInit {
  @Input() partner: Partner;
  public ctomcGridList: Array<OutboundMap>;

  public error: boolean = false;
  public errorMessage: string = '';
  public showSpinner: boolean = false;
  public requiredInputSet: boolean = false; // TODO Add validation of required input // TODO do for all!!!!!
  public messageTypeId: number = -1;
  public canonicalToOutboundDocumentsId: number = -1;
  public taskId: string = '';
  public messageTypesDDL: DropDownListModel[];
  public canonicalToOutboundDocumentsDDL: DropDownListModel[];
  public addOrEdit: string = '';
  public isEdit: boolean = false;

  constructor(public activeModal: NgbActiveModal, private outboundMappingConnectionInputService: MDOutboundMappingConnectionInputService, public utilService: UtilService) {}

  ngOnInit(): void {
    this.outboundMappingConnectionInputService.getAllCanonicalToOutboundDocuments().subscribe(
      {
          next: (result) => {
            this.canonicalToOutboundDocumentsDDL = new Array<DropDownListModel>();
            result.forEach(x => {
                let d: DropDownListModel = {
                    value: x.id.toString(),
                    text: x.name
                  };
                  this.canonicalToOutboundDocumentsDDL.push(d);
            });
          },
          // TODO Add better error hanlding to present the issue!
          error: (error) => {
              this.showSpinner = false;
              console.error(error);
          }
        }
    );
  }

  public save() {
    if(this.messageTypeId > 0 || this.canonicalToOutboundDocumentsId > 0){
      let canonicalToOutboundMap: OutboundMap = new OutboundMap();
      canonicalToOutboundMap.id = 0;
      canonicalToOutboundMap.outboundPartnerId = this.partner.id;
      canonicalToOutboundMap.outboundMapXsltId = this.canonicalToOutboundDocumentsId;
        this.outboundMappingConnectionInputService.addPartnerCanonicalToOutboundMappingConnection(canonicalToOutboundMap).subscribe(
            {
                next: (result) => {
                      this.activeModal.close('save');
                },
                error: (error) => {
                    this.displayErrorMessage(error);
                }
              }
        );
    }
  }

  selectChangeHandler(event: any) {

  }

  private displayErrorMessage(error: any) {
      let formattedError = '';
      if(error?.error && error?.error.errors) {
        formattedError = error?.error.errors;
      }

      if(error?.error && error?.error.error == undefined) {
      if(error.error.includes('at al.goh.admin.tool.api')) {
          formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
      } else {
          formattedError = error.error;
      }
      } else {
      if(error?.error?.error) {
          formattedError = error?.error.error;
      } else {
          formattedError = 'Unknown error';
      }
      }

      this.errorMessage = `Error Message: ${formattedError}`;
      this.error = true;
      console.error(error);
  }
}
