<form [formGroup]="canonicalMapXsltForm" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">Add Inbound => Canonical mapping document</h4>
    </div>
    <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
    <error-display [errorModel]="errorModel"></error-display>
    <div class="modal-body">
        <div>
            <div class="form-group row mb5">
                <label for="editMappingDocumentName" class="col-sm-3 col-form-label">Name <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="name" required id="editMappingDocumentName" name="editMappingDocumentName" placeholder="Mapping document name">
                    <div *ngIf="form.name.invalid && (form.name.dirty || form.name.touched)" class="alert alert-danger">
                        <div *ngIf="form.name.errors?.['required']">Name is required.</div>
                    </div>     
                </div>
            </div>
    
            <div class="form-group row mb5">
                <label for="editMappingDocumentDescription" class="col-sm-3 col-form-label">Description <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="description" required id="editMappingDocumentDescription" name="editMappingDocumentDescription" placeholder="Description">
                    <div *ngIf="form.description.invalid && (form.description.dirty || form.description.touched)" class="alert alert-danger">
                        <div *ngIf="form.description.errors?.['required']">Description is required.</div>
                    </div>   
                </div>
            </div>
    
            <div class="form-group row mb5">
                <label for="modelTypeId" class="col-sm-3 col-form-label control-label">Model type <span class="req-color">*</span></label>
                <div class="col-sm-9">
                    <select dropdown id="modelTypeId" formControlName="modelType" name="modelTypeId" class="form-select form-select-sm control-label">
                        <option *ngFor="let item of modelTypes" [value]="item.value">
                        {{item.text}}
                        </option>
                    </select>
                    <div *ngIf="form.modelType.invalid && (form.modelType.dirty || form.modelType.touched)" class="alert alert-danger">
                        <div *ngIf="form.modelType.errors?.['required']">Model type is required.</div>
                    </div>               
                </div>
              </div>
    
              <div class="form-group row mb5">
                <label for="modelTypeVersionId" class="col-sm-3 col-form-label control-label">Model type version<span class="req-color">*</span></label>
                <div class="col-sm-9">
                  <select dropdown id="modelTypeVersionId" formControlName="modelTypeVersion" name="modelTypeVersionId" class="form-select form-select-sm control-label">
                    <option *ngFor="let item of modelTypeVersions" [value]="item.value">
                      {{item.text}}
                    </option>
                  </select>   
                    <div *ngIf="form.modelTypeVersion.invalid && (form.modelTypeVersion.dirty || form.modelTypeVersion.touched)" class="alert alert-danger">
                        <div *ngIf="form.modelTypeVersion.errors?.['required']">Model type version is required.</div>
                    </div>           
                </div>
              </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="!canonicalMapXsltForm.valid">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>
</form>

