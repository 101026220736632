import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ReviewwInputService } from '../review-input.service';

@Component({
  selector: 'review-outbound-message-type',
  templateUrl: './review-outbound-message-type.component.html',
})
export class ReviewOutboundMessageTypeComponent {
  form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective, public rs: ReviewwInputService) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.form;
  }
}
