<fieldset class="border p-2">
    <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Parser</span></legend>
    <div class="row mb5">
        <div class="col-sm-2">
            <label><b>Class name: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label *ngIf="rs.getNestedFormControlValue(form, 'parserClass', 'name')">{{ utilService.getClassName(rs.getNestedFormControlValue(form, 'parserClass', 'name'))  }}</label>
            <label *ngIf="!rs.getNestedFormControlValue(form, 'parserClass', 'name')" class="text-red">No parser configured</label>
        </div>
        <div class="col-sm-2">
            <label><b>Description: <span class="req-color">*</span></b></label>
        </div>
        <div class="col-sm-3">
            <label *ngIf="rs.getNestedFormControlValue(form, 'parserClass', 'description')">{{ rs.getNestedFormControlValue(form, 'parserClass', 'description') }}</label>
            <label *ngIf="!rs.getNestedFormControlValue(form, 'parserClass', 'description')" class="text-red">No parser configured</label>
        </div>
    </div>
</fieldset>
