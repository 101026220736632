import { SchemaReference } from './schema-reference';

export class AgreementRequest {
    name: string;
    agreementType: number;
    qualifier: string;
    guestPartner: string;
    hostPartner: string;
    guestIdentity: string;
    hostIdentity: string;
    receiverSchemaReferences: Array<SchemaReference>;
    senderSchemaReferences: Array<SchemaReference>;
    schemaVersion: string;
    receiverInternalIdentification: string;
    senderSigningCertificateName: string;
    senderEncryptionCertificateName: string;
    receiverSigningCertificateName: string;
    receiverEncryptionCertificateName: string;
    receiverMdnMicHashingAlgorithm: string;
    senderMdnMicHashingAlgorithm: string;
    reveiverEncryptionAlgorithm: string;
    senderEncryptionAlgorithm: string;
    reveiverSigningAlgorithm: string;
    senderSigningAlgorithm: string;
    receiverReceiptDeliveryUrl: string;
    receiverSendAsynchronousMDN: boolean;
    senderReceiptDeliveryUrl: string;
    upsertX12Agreement: boolean;
    upsertEdifactAgreement: boolean;
    upsertAs2Agreement: boolean;
}

