export class SourceMqProfile {
    id: number;
    name: string;
    host: string;
    channel: string;
    qm: string;
    queue: string;
    port: string;
    userName: string;
    password: string;
    keyVaultProfileId: number;
    createdBy: string;
    createTimestamp: string;
}