import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MDEdiPreFormatterInputService } from './md-ediPreFormatter-input.service';
import { Partner } from 'src/app/models/partner/partner';
import { EDIPreFormatter } from 'src/app/models/edi-preformatter/edi-preformatter';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'delete-ediPreFormatter-modal',
  templateUrl: './delete-ediPreFormatter-modal.component.html',
})
export class DeleteEdiPreFormatterModalComponent implements OnInit {
  @Input() partner: Partner;
  @Input() ediPreFormatter: EDIPreFormatter;
  public showSpinner: boolean = false;
  public errorModel: ErrorModel = null;

  constructor(public activeModal: NgbActiveModal, public ediPreFormatterInputService: MDEdiPreFormatterInputService, private errorParserService: ErrorParserService) { }

  ngOnInit(): void {
  }

  public delete() {
    this.showSpinner = true;
    this.ediPreFormatterInputService.deleteInboundPartnerEdiPreFormatter(this.ediPreFormatter).subscribe(
      {
        next: (result) => {
          this.showSpinner = false;
          this.activeModal.close('delete');
        },
        error: (error) => {
          this.showSpinner = false;
          this.errorModel = this.errorParserService.extractErrors(error);
          console.error(error);
        }
      }
    );
  }
}
