import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
// import { CourseReducer } from './store/reducers/product.reducer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
    MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';

//import { AppRoutingModule } from './app-routing.module';

// Application Components
import { msalConfig, loginRequest, protectedResources } from './auth-config';
// import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { RoleGuard } from './role.guard';

// Modules
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EdiConfigurationsModule } from './edi-configurations/edi-configurations.module';

// Services
import { CustomerConfigurationsService } from './customer-configurations/list/customer-configurations.service';
import { ConfigurationTabService } from './customer-configurations/new/configuration-tab.service';
import { ViewConfigurationModelService } from './common-components/view-configuration/view-configuration-modal.service';
import { UtilService } from './common-services/util.service';
import { PartnerService } from './master-data/partners/partner.service';
import { MessageTypeService } from './master-data/message-types/message-type.service';
import { ParserClassService } from './master-data/parsers/md-parser-class.service';
import { MappingDocumentService } from './master-data/mapping-documents/mapping-document.service';

// Components
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './login/login.component';
import { TestComponent } from './testview/test.component';
//import { SourceInputService } from './master-data/partners/child-components/source/source-input.service';
import { EdiEncodeDecodeModule } from './tools/edidecoder/edi-encode-decode.module';
import { ConfigurationWizardModule } from './customer-configurations/new/configuration-wizard.module';
import { ResolverTesterModule } from './tools/resolver-tester/resolver-tester.module';
import { MappingExplorerModule } from './tools/mapping-explorer/mapping-explorer.module';
import { ViewConfigurationModalModule } from './common-components/view-configuration/view-configuration-modal.module';
import { MessageTrackerModule } from './tools/message-tracker/message-tracker.module';
import { MasterDataPartnersModule } from './master-data/partners/md-partners.module';
import { MasterDataResolverModule } from './master-data/resolvers/md-resolver.module';
import { MasterDataParsersModule } from './master-data/parsers/md-parser-class.module';
import { MasterDataMessageTypesModule } from './master-data/message-types/md-message-types.module';
import { MasterDataMappingDocumentsModule } from './master-data/mapping-documents/md-mapping-documents.module';
import { TestFormValidationModule } from './tools/test-form-validation/test-form-validation.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeModule } from './home/home.module';
import { CustomerConfigurationsModule } from './customer-configurations/list/customer-configurations.module';
import { MasterDataPrestepClassesModule } from './master-data/prestep-classes/md-prestep-classes.module';
import { TestGridViewModule } from './tools/test-grid-view/test-grid-view.module';
import { SimpleFlowConfigurationsModule } from './simpleflow-configurations/simpleflow-configurations.module';
import { ServiceModeModule } from './master-data/service-mode/service-mode.module';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
 export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
 export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.ConfigurationsEndpoint.endpoint, [...protectedResources.ConfigurationsEndpoint.scopes]);
  protectedResourceMap.set(protectedResources.customerConfigurations.endpoint, [...protectedResources.customerConfigurations.scopes]);
  protectedResourceMap.set(protectedResources.wizardEndpoint.endpoint, [...protectedResources.wizardEndpoint.scopes]);
  protectedResourceMap.set(protectedResources.bffEndpoint.endpoint, [...protectedResources.bffEndpoint.scopes]);
  protectedResourceMap.set(protectedResources.partners.endpoint, [...protectedResources.partners.scopes]);
  protectedResourceMap.set(protectedResources.parsersClasses.endpoint, [...protectedResources.parsersClasses.scopes]);
  protectedResourceMap.set(protectedResources.inboundParser.endpoint, [...protectedResources.inboundParser.scopes]);
  protectedResourceMap.set(protectedResources.inboundMessageTypes.endpoint, [...protectedResources.inboundMessageTypes.scopes]);
  protectedResourceMap.set(protectedResources.inboundMessageTypesSearch.endpoint, [...protectedResources.inboundMessageTypesSearch.scopes]);
  protectedResourceMap.set(protectedResources.outboundMessageTypes.endpoint, [...protectedResources.outboundMessageTypes.scopes]);
  protectedResourceMap.set(protectedResources.outboundMessageTypesSearch.endpoint, [...protectedResources.outboundMessageTypesSearch.scopes]);
  protectedResourceMap.set(protectedResources.testEdi.endpoint, [...protectedResources.testEdi.scopes]);
  protectedResourceMap.set(protectedResources.resolverTest.endpoint, [...protectedResources.resolverTest.scopes]);
  protectedResourceMap.set(protectedResources.agreements.endpoint, [...protectedResources.agreements.scopes]);
  protectedResourceMap.set(protectedResources.certificates.endpoint, [...protectedResources.certificates.scopes]);
  protectedResourceMap.set(protectedResources.schemas.endpoint, [...protectedResources.schemas.scopes]);
  protectedResourceMap.set(protectedResources.maps.endpoint, [...protectedResources.maps.scopes]);
  protectedResourceMap.set(protectedResources.edipartners.endpoint, [...protectedResources.edipartners.scopes]);
  protectedResourceMap.set(protectedResources.edipartner.endpoint, [...protectedResources.edipartner.scopes]);
  protectedResourceMap.set(protectedResources.keyvaultsecret.endpoint, [...protectedResources.keyvaultsecret.scopes]);
  protectedResourceMap.set(protectedResources.edifactdecode.endpoint, [...protectedResources.edifactdecode.scopes]);
  protectedResourceMap.set(protectedResources.edifactencode.endpoint, [...protectedResources.edifactencode.scopes]);
  protectedResourceMap.set(protectedResources.x12decode.endpoint, [...protectedResources.x12decode.scopes]);
  protectedResourceMap.set(protectedResources.x12encode.endpoint, [...protectedResources.x12encode.scopes]);
  protectedResourceMap.set(protectedResources.inboundmappings.endpoint, [...protectedResources.inboundmappings.scopes]);
  protectedResourceMap.set(protectedResources.inboundmappingdocuments.endpoint, [...protectedResources.inboundmappingdocuments.scopes]);
  protectedResourceMap.set(protectedResources.inboundmappingdocumentssearch.endpoint, [...protectedResources.inboundmappingdocumentssearch.scopes]);
  protectedResourceMap.set(protectedResources.outboundmappings.endpoint, [...protectedResources.outboundmappings.scopes]);
  protectedResourceMap.set(protectedResources.outboundmappingdocuments.endpoint, [...protectedResources.outboundmappingdocuments.scopes]);
  protectedResourceMap.set(protectedResources.outboundmappingdocumentssearch.endpoint, [...protectedResources.outboundmappingdocumentssearch.scopes]);
  protectedResourceMap.set(protectedResources.inboundmappingdocuments.endpoint, [...protectedResources.inboundmappingdocuments.scopes]);
  protectedResourceMap.set(protectedResources.outboundmappingdocuments.endpoint, [...protectedResources.outboundmappingdocuments.scopes]);
  protectedResourceMap.set(protectedResources.tracking.endpoint, [...protectedResources.tracking.scopes]);
  protectedResourceMap.set(protectedResources.trackingv2.endpoint, [...protectedResources.trackingv2.scopes]);
  protectedResourceMap.set(protectedResources.blob.endpoint, [...protectedResources.blob.scopes]);
  protectedResourceMap.set(protectedResources.resolverClasses.endpoint, [...protectedResources.resolverClasses.scopes]);
  protectedResourceMap.set(protectedResources.scheduler.endpoint, [...protectedResources.scheduler.scopes]);
  protectedResourceMap.set(protectedResources.destinations.endpoint, [...protectedResources.destinations.scopes]);
  protectedResourceMap.set(protectedResources.confdestinations.endpoint, [...protectedResources.confdestinations.scopes]);
  protectedResourceMap.set(protectedResources.edipreformatter.endpoint, [...protectedResources.edipreformatter.scopes]);
  protectedResourceMap.set(protectedResources.orderlinesperday.endpoint, [...protectedResources.orderlinesperday.scopes]);
  protectedResourceMap.set(protectedResources.orderspersender.endpoint, [...protectedResources.orderspersender.scopes]);
  protectedResourceMap.set(protectedResources.messagespersender.endpoint, [...protectedResources.messagespersender.scopes]);
  protectedResourceMap.set(protectedResources.messagesperday.endpoint, [...protectedResources.messagesperday.scopes]);
  protectedResourceMap.set(protectedResources.prestepclasses.endpoint, [...protectedResources.prestepclasses.scopes]);
  protectedResourceMap.set(protectedResources.prestepclassesall.endpoint, [...protectedResources.prestepclassesall.scopes]);
  protectedResourceMap.set(protectedResources.prestep.endpoint, [...protectedResources.prestep.scopes]);
  protectedResourceMap.set(protectedResources.simpleconfigurations.endpoint, [...protectedResources.simpleconfigurations.scopes]);
  protectedResourceMap.set(protectedResources.servicemode.endpoint, [...protectedResources.servicemode.scopes]);

  return {
      interactionType: InteractionType.Popup,
      protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
 export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
  };
}

// TODO add module for wizard list!

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,    
    TestComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MsalModule,
    AppRoutingModule,
    HomeModule,
    CollapseModule,
    CustomerConfigurationsModule,
    ConfigurationWizardModule,
    EdiConfigurationsModule,
    EdiEncodeDecodeModule,
    ResolverTesterModule,
    MappingExplorerModule,
    ViewConfigurationModalModule,
    MessageTrackerModule,
    MasterDataPartnersModule,
    MasterDataResolverModule,
    MasterDataParsersModule,
    MasterDataMessageTypesModule,
    MasterDataMappingDocumentsModule,
    TestFormValidationModule,
    MasterDataPrestepClassesModule,
    TestGridViewModule,
    SimpleFlowConfigurationsModule,
    ServiceModeModule,
    BsDropdownModule.forRoot()
    // StoreModule.forRoot({
    //   course: CourseReducer
    // })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    RoleGuard,
    MsalBroadcastService,
    CustomerConfigurationsService,
    ViewConfigurationModelService,
    UtilService,
    ConfigurationTabService,
    PartnerService,
    MessageTypeService,
    MappingDocumentService,
    //SourceInputService,
    ParserClassService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
