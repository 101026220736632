import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UtilService } from 'src/app/common-services/util.service';
import { Partner } from 'src/app/models/partner/partner';
import { OutboundMap } from 'src/app/models/mapping/outbound-map';
import { MDOutboundMappingConnectionInputService } from './md-canonicalToOutboundMappingConnection-input.service';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditOutboundMappingConnectionModalComponent } from './edit-canonicalToOutboundMappingConnection-modal.component';
import { AddOutboundMappingConnectionModalComponent } from './add-canonicalToOutboundMappingConnection-modal.component';
import { DeleteOutboundMappingConnectionModalComponent } from './delete-canonicalToOutboundMappingConnection-modal.component';

@Component({
  selector: 'canonicalToOutboundMappingConnection-input',
  templateUrl: './md-canonicalToOutboundMappingConnection-input.component.html',
})
export class MDOutboundMappingConnectionInputComponent implements OnInit {
    @Input() partner: Partner;
    public ctomcGridList: Array<OutboundMap>;

    public showSpinner: boolean = false;
    public error: boolean = false;
    public errorMessage: string = '';
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;

    constructor(public itcmcInputService: MDOutboundMappingConnectionInputService, private authService: MsalService, public utilService: UtilService, private modalService: NgbModal) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }
        this.getPartnerCanonicalToOutboundMappingConnections(this.partner.id);
    }

    private getPartnerCanonicalToOutboundMappingConnections(outboundpartnerId: number) {
      this.showSpinner = true;
      this.itcmcInputService.getPartnerCanonicalToOutboundMappingConnections(outboundpartnerId).subscribe({
          next: (ctomcGridList) => {
              this.showSpinner = false;
              this.ctomcGridList = ctomcGridList;
          },
          error: (error) => {
              this.showSpinner = false;
              this.displayErrorMessage(error);
          }
      });
    }

    public addPartnerCanonicalToOutboundMappingConnection() {
        const modalRef = this.modalService.open(AddOutboundMappingConnectionModalComponent, { size: 'size_40' });
        let canonicalToOutboundMap: OutboundMap = new OutboundMap();
        canonicalToOutboundMap.id = 0;
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.canonicalToOutboundMap = canonicalToOutboundMap;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getPartnerCanonicalToOutboundMappingConnections(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    public editPartnerCanonicalToOutboundMappingConnection(canonicalToOutboundMap: OutboundMap) {
        const modalRef = this.modalService.open(EditOutboundMappingConnectionModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.canonicalToOutboundMap = canonicalToOutboundMap;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.getPartnerCanonicalToOutboundMappingConnections(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }



    public removePartnerCanonicalToOutboundMappingConnection(canonicalToOutboundMap: OutboundMap) {
        const modalRef = this.modalService.open(DeleteOutboundMappingConnectionModalComponent, { size: 'size_40' });
        modalRef.componentInstance.partner = this.partner;
        modalRef.componentInstance.canonicalToOutboundMap = canonicalToOutboundMap;

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'delete') {
                this.getPartnerCanonicalToOutboundMappingConnections(this.partner.id);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)
        });
    }

    private displayErrorMessage(error: any) {
      let formattedError = '';
      if(error?.error && error?.error.error == undefined) {
      if(error.error.includes('at al.goh.admin.tool.api')) {
          formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
      } else {
          formattedError = error.error;
      }
      } else {
      if(error?.error?.error) {
          formattedError = error?.error.error;
      } else {
          formattedError = 'Unknown eror';
      }
      }

      this.errorMessage = `Error when receiving connected CanonicalToOutbound-mappings. Error Message ${formattedError}`;
      this.error = true;
      console.error(error);
  }

}
