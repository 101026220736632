import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridView } from 'src/app/models/grid-view';
import { PagingService } from 'src/app/common-services/paging.service';
import { UtilService } from 'src/app/common-services/util.service';
import { MasterDataPrestepClassesService } from './md-prestep-classes.service';
import { ErrorModel } from 'src/app/models/error-model';
import { PreStepClass } from 'src/app/models/pre-step-class';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { AddPrestepClassComponent } from './child-components/add-prestep-class.component';
import { DeletePrestepClassComponent } from './child-components/delete-prestep-class.component';
import { EditPrestepClassComponent } from './child-components/edit-prestep-class.component';
import { Paging } from 'src/app/models/paging/paging';

@Component({
    selector: 'md-prestep-classes',
    templateUrl: './md-prestep-classes.component.html',
  })
export class MasterDataPrestepClassesComponent  implements OnInit {
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = true;
    public filterPrestepClasses: string = '';
    public filterDescription: string = '';
    public gridView: GridView<PreStepClass>;
    public prestepPaging: Paging = new Paging();

    constructor(public service: MasterDataPrestepClassesService, private authService: MsalService, private errorParserService: ErrorParserService, public utilService: UtilService, private modalService: NgbModal, public pService: PagingService) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        this.getData();
    }

    private getData() {

        this.service.getPrestepClasses(this.prestepPaging.currentPage, this.prestepPaging.pageCount, this.filterPrestepClasses, this.filterDescription).subscribe(
        {
          next: (result) => {
            this.gridView = result;
            this.prestepPaging.setLastDisplayIndex();
            this.prestepPaging.totalPages = Math.floor(this.gridView.totalRows/this.prestepPaging.pageCount) + (this.gridView.totalRows % this.prestepPaging.pageCount > 0 ? 1 : 0);
            this.showSpinner = false; 
          },
          error: (error) => { 
            this.errorModel = this.errorParserService.extractErrors(error);
            console.error(error);
            this.showSpinner = false; 
          }
        });
    }

    public onFilter() {
        this.prestepPaging.currentPage = 1;
        this.getData();
    }

    public createPrestepClass() {
        const modalRef = this.modalService.open(AddPrestepClassComponent, { size: 'size_40' });
        modalRef.result.then((result) => {            
            if(result === 'save') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public editPrestepClass(gridRow: PreStepClass) {
        const modalRef = this.modalService.open(EditPrestepClassComponent, { size: 'size_40' });
        modalRef.componentInstance.prestepClass = gridRow;
        modalRef.result.then((result) => {
            if(result === 'save') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public removePrestepClass(gridRow: PreStepClass) {
        const modalRef = this.modalService.open(DeletePrestepClassComponent, { size: 'size_40' });
        modalRef.componentInstance.preStepClass = gridRow;
        modalRef.result.then((result) => {            
            if(result === 'delete') {
                // Refresh grid after save
                this.getData();
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }
 
    // --- Paging ----------------------------------------------------------------------------------  
    public onclickPrev() {
        if (this.prestepPaging.currentPage > 1) {
            this.prestepPaging.currentPage = this.prestepPaging.currentPage - 1;
            this.prestepPaging.setStartEndIndex();
            this.getData();      
        }
    }

    public onclickNext() {
        if ((this.prestepPaging.currentPage) * this.prestepPaging.pageCount < this.gridView.totalRows) {
            this.prestepPaging.nextButtonDisabled = false;
            this.prestepPaging.currentPage = this.prestepPaging.currentPage + 1;
            this.prestepPaging.setStartEndIndex();
            this.getData();
        } else {
            this.prestepPaging.nextButtonDisabled = true;
        }
    }

    public onclickPage(page: number, index: number) {
        this.prestepPaging.currentPage = this.prestepPaging.currentPage === page ? this.prestepPaging.currentPage : page;
        this.prestepPaging.setStartEndIndex();
        this.getData();
    }
    
    selectChangeHandler(event: any) {
        this.prestepPaging.currentPage = 1;
        this.prestepPaging.setStartEndIndex();      
        this.getData();
    }
}
