import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParserClass } from 'src/app/models/parser/parser-class';
import { UtilService } from 'src/app/common-services/util.service';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { SimpleFlowConfigurationsService } from '../simpleflow-configurations.service';
import { SimplifiedFlowConfigurationData } from '../models/simplified-flow-configuration-data';
import { SimplifiedFlowDestinationConnection } from '../models/simplified-flow-destination-connection';
import { SimpleFlowConfiguration } from '../models/simple-flow-configuration';
import { MDDestinationService } from 'src/app/master-data/partners/child-components/destinations/md-destination.service';
import { ConfDestination } from 'src/app/models/destination/conf-destination';
import { SimplifiedFlowConfigurationRequest } from '../models/simplified-flow-configuration-request';
import { SimplifiedFlowConfigurationEntitymap } from '../models/simplified-flow-configuration-entitymap';
import { SimplifiedFlowConfigurationResponse } from '../models/simplified-flow-configuration-response';

@Component({
  selector: 'edit-simplified-flow',
  templateUrl: './edit-simplified-flow.component.html'
})
export class EditSimplifiedFlowConfigurationComponent implements OnInit {
    @Input() simpleFlowConfiguration: SimpleFlowConfiguration;
    public simplifiedFlowConfigurationForm: FormGroup;
    public errorModel: ErrorModel = null;
    public showSpinner: boolean = true;
    public parserClass: ParserClass = new ParserClass();
    public simplifiedFlowConfigurationData: SimplifiedFlowConfigurationData = null;
    public selectedDestinationValue: number = -1;
    public simplifiedFlowDestinationConnections: Array<SimplifiedFlowDestinationConnection>;
    public confDestinations: Array<ConfDestination>;
    
    constructor(public sfcs: SimpleFlowConfigurationsService, public utilService: UtilService, 
        private errorParserService: ErrorParserService, public activeModal: NgbActiveModal,
        private destinationService: MDDestinationService) {}

    ngOnInit(): void {
        this.simplifiedFlowConfigurationForm = this.getEmptySimplifiedFlowConfigurationForm();

        this.sfcs.getBaseData().subscribe(
        {
            next: (result) => {
                this.simplifiedFlowConfigurationData = result;
                this.sfcs.getSimpleConfiguration(this.simpleFlowConfiguration.simpleEntityMapId).subscribe(
                {
                    next: (savedresult) => {
                        this.getPartnerDestinations(savedresult.outboundPartner.id);
                        this.simplifiedFlowConfigurationForm = this.getSimplifiedFlowConfigurationForm(savedresult);
                        this.simplifiedFlowDestinationConnections = savedresult.destinations;
                        this.showSpinner = false; 
                    },
                    error: (error) => {
                        this.showSpinner = false; 
                        this.errorModel = this.errorParserService.extractErrors(error);
                        console.error(error);
                    }
                });
            },
            error: (error) => {
                this.showSpinner = false; 
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });
    }

    get form() { return this.simplifiedFlowConfigurationForm.controls; }

    public save() {
        this.showSpinner = true; 
        let simpleFlowConfigurationRequest = this.sfcs.getSimplifiedFlowConfigurationModel(this.simplifiedFlowConfigurationForm);
        simpleFlowConfigurationRequest.simpleFlowDestinationConnections = this.simplifiedFlowDestinationConnections;

        this.sfcs.updateSimplifiedFlowConfiguration(simpleFlowConfigurationRequest).subscribe(
            {
                next: (result) => {
                    this.showSpinner = false; 
                    this.activeModal.close('save');
                },
                error: (error) => {
                    this.showSpinner = false; 
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                }
             }
        );
    }

    // this is executed when clicking the select button
    public addDestination() {
        if(this.simplifiedFlowDestinationConnections == undefined) {
            this.simplifiedFlowDestinationConnections = new Array<SimplifiedFlowDestinationConnection>();
        }

        let simplifiedFlowDestinationConnection: SimplifiedFlowDestinationConnection = new SimplifiedFlowDestinationConnection();

        simplifiedFlowDestinationConnection.id = 0;
        simplifiedFlowDestinationConnection.simpleEntityMapId = 0;
        simplifiedFlowDestinationConnection.destinationId = this.selectedDestinationValue;
        //simplifiedFlowDestinationConnection.confDestination = this.simplifiedFlowConfigurationData.confDestinations.find(x => x.id === +this.selectedDestinationValue);        
        simplifiedFlowDestinationConnection.confDestination = this.confDestinations.find(x => x.id === +this.selectedDestinationValue);        

        this.simplifiedFlowDestinationConnections.push(simplifiedFlowDestinationConnection);

        let confDestConnections = (this.simplifiedFlowConfigurationForm.get('simplifiedFlowDestinationConnections') as FormArray);

        let newDestination = new FormGroup({
            id: new FormControl(simplifiedFlowDestinationConnection.id),
            entityMapId: new FormControl(simplifiedFlowDestinationConnection.simpleEntityMapId),
            destinationId: new FormControl(simplifiedFlowDestinationConnection.destinationId),
            confDestination: new FormGroup({
              type: new FormControl(simplifiedFlowDestinationConnection.confDestination.type),
            })
          });

        confDestConnections.push(newDestination);
    }

    public removeConfDestConnection(simplifiedFlowDestinationConnection: SimplifiedFlowDestinationConnection) {
        this.simplifiedFlowDestinationConnections.forEach( (item, index) => {
          if(item === simplifiedFlowDestinationConnection) this.simplifiedFlowDestinationConnections.splice(index,1);
        });
    }

    selectDestinationChangeHandler(event: any) {
        this.selectedDestinationValue = this.simplifiedFlowConfigurationForm.value.selectedDestinationValue;
    }

    public selectOutboundPartnerChangeHandler(event: any) {
        this.getPartnerDestinations(this.simplifiedFlowConfigurationForm.value.outboundPartnerId);
    }

    private getPartnerDestinations(partnerId: number) {
        this.showSpinner = true;
        this.destinationService.getPartnerDestinations(partnerId).subscribe({
            next: (destinations) => {
                this.confDestinations = destinations;
                this.showSpinner = false; 
            },
            error: (error) => {
                this.showSpinner = false; 
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
        });
    }

    public getEmptySimplifiedFlowConfigurationForm() {
        return new FormGroup({
          id: new FormControl(0, Validators.required),
          inboundPartnerId: new FormControl('', Validators.required),
          inboundMessageTypeId: new FormControl('', Validators.required),
          outboundPartnerId: new FormControl('', Validators.required),
          outboundMessageTypeId: new FormControl('', Validators.required),
          selectedDestinationValue: new FormControl(-1),
          simplifiedFlowDestinationConnections: new FormArray([], [this.requireAtLeastOneDestination()])
        });
    }

    // Custom validator for confDestination
    public requireAtLeastOneDestination(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value) {
                return null;
            }

            const hasAtLeastOneObject = value && value.length > 0;
            return hasAtLeastOneObject ? null : { requireAtLeastOneDestination: true };
        };
    }

    public getSimplifiedFlowConfigurationForm(simplifiedFlowConfigurationResponse: SimplifiedFlowConfigurationResponse) {
        let formGroup = new FormGroup({
          id: new FormControl(simplifiedFlowConfigurationResponse.simpleEntityMap.id, Validators.required),
          inboundPartnerId: new FormControl(simplifiedFlowConfigurationResponse.simpleEntityMap.inboundPartnerId, Validators.required),
          inboundMessageTypeId: new FormControl(simplifiedFlowConfigurationResponse.simpleEntityMap.inboundMessageTypeId, Validators.required),
          outboundPartnerId: new FormControl(simplifiedFlowConfigurationResponse.simpleEntityMap.outboundPartnerId, Validators.required),
          outboundMessageTypeId: new FormControl(simplifiedFlowConfigurationResponse.simpleEntityMap.outboundMessageTypeId, Validators.required),
          selectedDestinationValue: new FormControl(-1),
          simplifiedFlowDestinationConnections: new FormArray([], [this.requireAtLeastOneDestination()])
        });

        let confDestConnections = (formGroup.get('simplifiedFlowDestinationConnections') as FormArray);

        simplifiedFlowConfigurationResponse.destinations.forEach(x => {
            let newDestination = new FormGroup({
                id: new FormControl(x.id),
                entityMapId: new FormControl(x.simpleEntityMapId),
                destinationId: new FormControl(x.destinationId),
                confDestination: new FormGroup({
                  type: new FormControl(x.confDestination.type),
                })
              });

              confDestConnections.push(newDestination);
        });

        return formGroup;
    }
}
